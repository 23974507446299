import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './SideBar.less'

import { withTranslation } from 'react-i18next';

class SideBar extends Component {
    // constructor(props) {
    //     super(props)
    // }
    logout() {
        localStorage.clear();
    }

    render() {

        const { t } = this.props;

        return (
            <div className="sidebar">
                <Link className="sidebarLink" to="/management/"><div className="sidebarHeader"></div></Link>
                <div className="sideLinkBox">
                    <Link className="sidebarLink" to="/management/personProfile"><span>{t('sidebar.profile')}</span></Link>
                    <Link className="sidebarLink" to="/management/lessonPlans"><span>{t('sidebar.Upload')}</span></Link>
                    {/* <Link className="sidebarLink" to="/management/recommendedResources"><span>{t('sidebar.RecommendResources')}</span></Link> */}
                    {/* <Link className="sidebarLink" to="/management/share"><span>{t('sidebar.share')}</span></Link> */}
                    <Link className="sidebarLink" to="/management/lessonplanresources"><span>{t('sidebar.search')}</span></Link>
                    <Link className="sidebarLink" onClick={() => this.logout()} to="/"><span>{t('sidebar.logout')}</span></Link>
                </div>
            </div>
        )
    }
}

export default withTranslation()(SideBar);