import React, { Component } from "react";

import LocalStorage from "../../../Helpers/LocalStorage";

import "./PlanTarget.less";

import { withTranslation } from 'react-i18next';

class PlanTarget extends Component {
  constructor(props) {
    super(props);

    const { planSetting, kernel, reigon, important, content, activity } = this.props;

    this.state = {
      planSetting: planSetting,
      kernel: kernel ? kernel : [],
      reigon: reigon ? reigon : [],
      important: important ? important : [],
      content: content ? content : [],
      activity: activity ? activity : []
    };

    this.kernel = React.createRef();
    this.reigon = React.createRef();
    this.important = React.createRef();
    this.content = React.createRef();
    this.activity = React.createRef();
  }

  componentDidMount(){
    // this.handleAllStateChange("planSetting", "國語");
  }

  getAuthorize() {
    let nthusteam = LocalStorage.getLocalStorage("nthusteam");
    if (nthusteam != null && nthusteam.apiToken != null) {
      return nthusteam.apiToken;
    } else {
      this.props.history.push("/signin");
    }
  }

  handleAllStateChange(k, v) {

    console.log('k =>', k)
    this.props.setAllState(k, v);
  }

  reigonToActive = (e) => {

    const { planSetting } = this.props;
    console.log('e =>', e.target.value)

    let id = e.target.value;

    let reigonList = planSetting.reigon || [];

    let reigon = reigonList.find(r => r._id === id);
    
    // console.log({name: name})
    this.handleAllStateChange("planSetting", this.props.extractRegionName(reigon.reigonName));
    // this.setState({planSetting: nowSetting})
  }

  addSelectedOptional = (e, targetType) => {

    const data = this.props;
    const list = data[targetType] ? data[targetType] : [];
    let targetObject = this.props.planSetting[targetType].filter(item => item._id === e.current.value)
    if (list.filter(item => item._id === targetObject[0]._id).length === 0) {
      list.push(...targetObject)
    }
    this.handleAllStateChange(targetType, list);
  };

  removeSelected = (id, targetType) => {
    let list = this.props[targetType].filter(item => item._id !== id)
    this.handleAllStateChange(targetType, list);
  };

  render() {
    const { planSetting, kernel, reigon, important, content, activity, checkOwner, t } = this.props;

    return (
      <div className="planBasicTarget">
        <h3>{t('step1.target')}</h3>
        <table border="1" className="planTargetTable">
          <tbody>
            <tr>
              <td className="basicLable">{t('step1.kernel')}</td>
              {
                checkOwner
                  ? <td>
                    <div>
                      <select name="kernel" ref={this.kernel} id="kernel">
                        {planSetting &&
                          planSetting.kernel.map((c, k) => (
                            <option key={k} value={c._id}>
                              {
                                c.kernelName
                              }
                            </option>
                          ))}
                      </select>
                      <button onClick={e => this.addSelectedOptional(this.kernel, "kernel")}>{t('step1.add2')}</button>
                    </div>
                    <div className="buttonBox">
                      {
                        kernel &&
                        kernel.map((c, k) => (
                          <button
                            className="deleteEleBtn"
                            key={k}
                            onClick={e => this.removeSelected(c._id, "kernel")}
                            value={c}
                          >
                            <i className="fas fa-times" />
                            {c.kernelName}
                          </button>
                        ))}
                    </div>
                  </td>
                  : <td>
                    <div className="buttonBox">
                      {
                        kernel &&
                        kernel.map((c, k) => (
                          <button
                            className="deleteEleBtn"
                            key={k}
                            value={c}
                          >
                            {c.kernelName}
                          </button>
                        ))}
                    </div>
                  </td>
              }

            </tr>
            <tr>
              <td className="basicLable">{t('step1.region')}</td>
              {
                checkOwner
                  ? <td>
                    <div>
                      <select name="reigon" ref={this.reigon} id="reigon" onChange={this.reigonToActive} >
                        {planSetting &&
                          planSetting.reigon.map((c, k) => (
                            <option key={k} value={c._id}>
                              {c.reigonName}
                            </option>
                          ))}
                      </select>
                      {/* <button onClick={e => this.addSelectedOptional(this.reigon, "reigon")}>{t('step1.add2')}</button> */}
                    </div>
                    <div>
                      {
                        reigon &&
                        reigon.map((c, k) => (
                          <button
                            className="deleteEleBtn"
                            key={k}
                            onClick={e => this.removeSelected(c._id, "reigon")}
                            value={c}
                          >
                            <i className="fas fa-times" />
                            {c.reigonName}
                          </button>
                        ))}
                    </div>
                  </td>
                  : <td>
                    <div className="buttonBox">
                      {
                        reigon &&
                        reigon.map((c, k) => (
                          <button
                            className="deleteEleBtn"
                            key={k}
                            value={c._id}
                          >
                            {c.reigonName}
                          </button>
                        ))}
                    </div>
                  </td>
              }

            </tr>
            <tr style={{ "display": "none" }}>
              <td className="basicLable">{t('step1.important')}</td>
              {
                checkOwner
                  ? <td>
                    <div>
                      <select name="important" ref={this.important} id="important">
                        {planSetting &&
                          planSetting.important.map((c, k) => (
                            <option key={k} value={c._id}>
                              {c.pointName}
                            </option>
                          ))}
                      </select>
                      <button onClick={e => this.addSelectedOptional(this.important, "important")}>{t('step1.add2')}</button>
                    </div>
                    <div className="buttonBox">
                      {
                        important &&
                        important.map((c, k) => (
                          <button
                            className="deleteEleBtn"
                            key={k}
                            onClick={e => this.removeSelected(c._id, "important")}
                            value={c._id}
                          >
                            <i className="fas fa-times" />
                            {c.pointName}
                          </button>
                        ))}
                    </div>
                  </td>
                  : <td>
                    <div className="buttonBox">
                      {
                        important &&
                        important.map((c, k) => (
                          <button
                            className="deleteEleBtn"
                            key={k}
                            value={c._id}
                          >
                            {c.pointName}
                          </button>
                        ))}
                    </div>
                  </td>
              }

            </tr>
            <tr style={{ "display": "none" }}>
              <td className="basicLable">{t('step1.content')}</td>
              {
                checkOwner
                  ? <td>
                    <div>
                      <select name="content" ref={this.content} id="content">
                        {planSetting &&
                          planSetting.content.map((c, k) => (
                            <option key={k} value={c._id}>
                              {c.contentName}
                            </option>
                          ))}
                      </select>
                      <button onClick={e => this.addSelectedOptional(this.content, "content")} >{t('step1.add2')}</button>
                    </div>
                    <div className="buttonBox">
                      {
                        content &&
                        content.map((c, k) => (
                          <button
                            className="deleteEleBtn"
                            key={k}
                            onClick={e => this.removeSelected(c._id, "content")}
                            value={c._id}
                          >
                            <i className="fas fa-times" />
                            {c.contentName}
                          </button>
                        ))}
                    </div>
                  </td>
                  : <td>
                    <div className="buttonBox">
                      {
                        content &&
                        content.map((c, k) => (
                          <button
                            className="deleteEleBtn"
                            key={k}
                            value={c._id}
                          >
                            {c.contentName}
                          </button>
                        ))}
                    </div>
                  </td>
              }

            </tr>
            <tr>
              <td className="basicLable">{t('step1.activity')}</td>
              {
                checkOwner
                  ? <td>
                    <div>
                      <select name="activity" ref={this.activity} id="activity">
                        {planSetting &&
                          planSetting.activity.map((c, k) => (
                            <option key={k} value={c._id}>
                              {c.activeName}
                            </option>
                          ))}
                      </select>
                      <button
                        onClick={e => this.addSelectedOptional(this.activity, "activity")}
                      >
                        {t('step1.add2')}
                      </button>
                    </div>
                    <div className="buttonBox">
                      {
                        activity &&
                        activity.map((c, k) => (
                          <button
                            className="deleteEleBtn"
                            key={k}
                            onClick={e => this.removeSelected(c._id, "activity")}
                            value={c._id}
                          >
                            <i className="fas fa-times" />
                            {c.activeName}
                          </button>
                        ))}
                    </div>

                  </td>
                  : <td>
                    <div className="buttonBox">
                      {
                        activity &&
                        activity.map((c, k) => (
                          <button
                            className="deleteEleBtn"
                            key={k}
                            value={c._id}
                          >
                            {c.activeName}
                          </button>
                        ))}
                    </div>
                  </td>
              }

            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default withTranslation()(PlanTarget);
