import moment from 'moment'
import LevelCourseHourSetting from '../LevelCourseHourSetting'
const LessonPlanHelper = {
	repeatUpdateLevel2Period: function (lessonPlan, courses, passedShares, systemAdminRole) {
		let lessonPlanPeriodCourses = courses.filter(course => moment(course.endDateTime).diff(lessonPlan.startAt) > -1 && moment(course.endDateTime).diff(lessonPlan.expiredAt) < 1)
		let durationPassedShares = passedShares.filter(passedShare => moment(passedShare.approvedDate).diff(lessonPlan.startAt) > -1 && moment(passedShare.approvedDate).diff(lessonPlan.expiredAt) < 1)
		let oriStartAt = moment(lessonPlan.startAt).clone()
		let oriExpiredAt = moment(lessonPlan.expiredAt).clone()
		if (this.isLevel3Complete(lessonPlanPeriodCourses, systemAdminRole) && durationPassedShares.length > 0) {
			lessonPlan.startAt.add(LevelCourseHourSetting.periodTime, LevelCourseHourSetting.timeUnit)
			lessonPlan.expiredAt = moment(lessonPlan.startAt).clone()
			lessonPlan.expiredAt.add(LevelCourseHourSetting.periodTime, LevelCourseHourSetting.timeUnit).add(-1, "day")
			return {
				courses: lessonPlanPeriodCourses.map(course => {
					course.forExpired = false
					course.oriStartAt = oriStartAt
					course.oriExpiredAt = oriExpiredAt
					return course
				}), shares: durationPassedShares.map(course => {
					course.forExpired = false
					course.oriStartAt = oriStartAt
					course.oriExpiredAt = oriExpiredAt
					return course
				}),
				isPass: true
			}
		} else {
			let expiredCourses = courses.filter(course => moment(course.endDateTime).diff(lessonPlan.expiredAt) > 0)
			let expiredPassedShares = passedShares.filter(passedShare => moment(passedShare.approvedDate).diff(lessonPlan.expiredAt) > 0)
			if (this.isLevel3Complete(expiredCourses, systemAdminRole) && expiredPassedShares.length > 0) {
				let passExpiredCourses = this.getCoursesCompleteLevel3(JSON.parse(JSON.stringify(expiredCourses)), systemAdminRole)
				let latestCourseCompleteLevel3 = passExpiredCourses.sort((a, b) => moment(b.endDateTime) - moment(a.endDateTime))[0]
				let sortedExpiredPassedShare = expiredPassedShares.sort((a, b) => moment(a.approvedDate) - moment(b.approvedDate))[0]
				let endDateTime = moment(latestCourseCompleteLevel3.endDateTime).diff(moment(sortedExpiredPassedShare.approvedDate)) > 0 ?
					latestCourseCompleteLevel3.endDateTime : sortedExpiredPassedShare.approvedDate
				lessonPlan.startAt = moment(endDateTime)
				lessonPlan.expiredAt = moment(lessonPlan.startAt).clone()
				lessonPlan.expiredAt.add(LevelCourseHourSetting.periodTime, LevelCourseHourSetting.timeUnit).add(-1, "day")
				return {
					courses: passExpiredCourses.map(course => {
						course.forExpired = true
						course.oriStartAt = oriStartAt
						course.oriExpiredAt = oriExpiredAt
						return course
					}), shares: [sortedExpiredPassedShare].map(share => {
						share.forExpired = true
						share.oriStartAt = oriStartAt
						share.oriExpiredAt = oriExpiredAt
						return share
					}),
					isPass: true
				}
			}
			else {
				lessonPlan.isExpired = true
			}
		}
		return { courses: [], shares: [], isPass: false }
	},
	isLevel3Complete: function (courses, systemAdminRole) {
		let level3TimesMap = JSON.parse(JSON.stringify(LevelCourseHourSetting[systemAdminRole].level3))
		courses.forEach(course => {
			if (level3TimesMap[course.courceType.key]) {
				level3TimesMap[course.courceType.key] = level3TimesMap[course.courceType.key] - 1
			}
		})

		for (var key in level3TimesMap) {
			if (level3TimesMap[key] > 0) {
				return false
			}
		}
		return true
	},
	getCoursesCompleteLevel3: function (courses, systemAdminRole) {
		let sortedCourses = courses.sort((a, b) => moment(b.endDateTime) - moment(a.endDateTime))
		let neededCourses = []
		while (!this.isLevel3Complete(neededCourses, systemAdminRole)) {
			neededCourses.push(sortedCourses.pop())
		}
		return neededCourses
	}
}

export default LessonPlanHelper;