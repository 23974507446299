import React, { useState, useCallback, useEffect } from 'react'
import './PointerItems.less'
import PointerItem from './PointerItem'
import update from 'immutability-helper';



const PointerItems = props => {
	const [pointerItems, setPointerItems] = useState(props.pointerItems)

	useEffect(() => {
		if(pointerItems.length === 0){
			setPointerItems(props.pointerItems)
		}			
	}, [pointerItems]);

	useEffect(() => {
		props.processTeacherAbilityPointersSequence(pointerItems);
	 }, [pointerItems]);


	const moveItem = useCallback((dragIndex, hoverIndex) => {
		const pointerItem= pointerItems[dragIndex];
		setPointerItems(update(pointerItems, {
			$splice: [
				[dragIndex, 1],
				[hoverIndex, 0, pointerItem],
			],
		}));
	}, [pointerItems]);
	return (
		<div className="PointerManagement__items">
			{pointerItems.map((pointerItem, k) => {
				return <PointerItem
					deletePointer={props.deletePointer}
					openPointerPage={props.openPointerPage}
					pointerItem={pointerItem}
					id={pointerItem._id} key={k} index={k}
					moveItem={moveItem} />
			})
			}
		</div>
	);
}
export default PointerItems;