import React, { Component } from 'react'
import axios from 'axios';
import Loader from 'react-loader-spinner'
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal'
import TextField from '@material-ui/core/TextField';
import moment from 'moment'
import './ShareDetail.less'
import { ThemeProvider } from '@material-ui/core';
import { CKEditor } from 'ckeditor4-react';

class ShareDetail extends Component {

    constructor(props) {
        super(props)

        this.shareTitle = React.createRef();
        this.shareContent = null;
        this.approvedDate = moment().format("YYYY-MM-DD");
        this.rejectReason = React.createRef();
        this.state = {
            loading: false,
            saveLoading: false,
            title: "",
            content: "",
            shareId: "",
            status: 0,
            applyDate: ""
        }
    }

    componentDidMount() {
        if (this.props.mode != "new" && this.props.mode != null) {
            this.getShare(this.props.mode)
        }
        else {
            this.setState({
                title: "",
                content: ""
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            if (this.props.mode != "new" && this.props.mode != null) {
                this.getShare(this.props.mode)
            }
            else {
                this.setState({
                    title: "",
                    content: ""
                })
            }
        }
    }

    getShare = (shareId) => {
        this.setState({
            loading: true
        })
        this.getShareByIdAction(shareId).then(data => {            
            this.setState({
                title: data.title,
                content: data.content,
                status: data.status,
                loading: false,
                shareId: data._id,
                applyDate: data.applyDate
            }, () => {
                this.shareContent = data.content
            })
        })
    }

    getShareByIdAction = (shareId) => {
        return new Promise(resolve => {
            axios.get(`${window.CONFS.apiServer}/share/${shareId}`).then(res => {
                resolve(res.data.data)
            }).catch(function (error) {
                console.log('fail post =>', error)
                // window.alert("Server Error")
            });
        })
    }


    createShare = () => {
        return new Promise(resolve => {
            axios.post(`${window.CONFS.apiServer}/share`, {
                title: this.shareTitle.current.value,
                content: this.shareContent//this.shareContent.current.value,
            }).then(res => {
                window.alert("建立成功")
                this.props.history.go(-1)
            }).catch(function (error) {
                console.log('fail post =>', error)
                // window.alert("Server Error")
            });
        })
    }

    updateShare = (event) => {
        let shareId = event.target.getAttribute('share-id')
        return new Promise(resolve => {
            axios.patch(`${window.CONFS.apiServer}/share/${shareId}`, {
                title: this.shareTitle.current.value,
                content: this.shareContent//this.shareContent.current.value,
            }).then(res => {
                window.alert("更新成功")
                this.props.history.go(-1)
            }).catch(function (error) {
                console.log('fail post =>', error)
                // window.alert("Server Error")
            });
        })
    }

    handleChange = (e) => {
        this.approvedDate = e.target.value
    }

    approveShare = (event) => {
        let shareId = event.target.getAttribute('share-id')
        return new Promise(resolve => {
            axios.patch(`${window.CONFS.apiServer}/share/${shareId}`, {
                status: 4,
                approvedDate: this.approvedDate
            }).then(res => {
                window.alert("審核通過成功")
                this.props.history.go(-1)
            }).catch(function (error) {
                console.log('fail post =>', error)
                // window.alert("Server Error")
            });
        })
    }

    rejectShare = (event) => {
        let shareId = event.target.getAttribute('share-id')        
        if (!this.rejectReason.current.value) {
            window.alert("退回原因必填!")
            return false
        }
        return new Promise(resolve => {
            axios.patch(`${window.CONFS.apiServer}/share/${shareId}`, {
                status: 3,
                currentReason: this.rejectReason.current.value,
                applyDate: this.state.applyDate
            }).then(res => {
                window.alert("已退回")
                this.props.history.go(-1)
            }).catch(function (error) {
                console.log('fail post =>', error)
                // window.alert("Server Error")
            });
        })
    }

    render() {
        const { mode, method } = this.props
        let title = (mode === "new") ? "建立教案教學經驗分享" : method === "edit" ? "更新教案教學經驗分享" : "查看教案教學經驗分享"
        let isViewOrVerify = !(method != "view" && method != "verify")
        return (
            <ReactModal
                isOpen={mode != null}
                shouldCloseOnOverlayClick={false}
                ariaHideApp={false}
                onRequestClose={() => {
                    if (!isViewOrVerify) {
                        window.confirm("確定要關閉編輯?") && this.props.history.go(-1)
                    }
                    else {
                        this.props.history.go(-1)
                    }
                }}
                style={{ overlay: { "background": "#000000a3" }, content: { "width": "70%", "height": "85vh", "margin": "0 auto" } }}
            >
                <div className="modalControllArea">
                    <div onClick={() => {
                        if (!isViewOrVerify) {
                            window.confirm("確定要關閉編輯?") && this.props.history.go(-1)
                        }
                        else {
                            this.props.history.go(-1)
                        }
                    }}>
                        <i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
                    </div>
                </div>
                {this.state.loading ? <Loader
                    type="Oval"
                    color="#7f1083"
                    height={22}
                    width={22}
                    visible={true}
                /> : <div className="share-detail">
                    <div className='banner-title'>{title}</div>
                    <div className='row'>
                        <div className='title'>標題</div>
                        <div className='content'><input type="text" disabled={isViewOrVerify} defaultValue={this.state.title} ref={this.shareTitle} /></div>
                    </div>
                    <div className='row'>
                        <div className='title'>內容</div>
                        {method === "verify" || [4, 5].includes(this.state.status) ?
                            <div className='content ckeditor-content' dangerouslySetInnerHTML={{"__html":this.state.content.replaceAll(/\n/g,"")}}></div> :
                            <CKEditor
                                initData={this.state.content}
                                readOnly={isViewOrVerify}
                                config={{
                                    width: '100%',
                                    height: '400px',
                                    filebrowserUploadUrl: `${window.CONFS.apiServer}/sys/ckeditor/uploadFile`
                                }}
                                onChange={(event) => {
                                    this.shareContent = event.editor.getData();
                                    // Define your onSubmit function here
                                    // ...
                                    // for example, setData() here

                                }}
                            // style={{ width: '100%' }}
                            />
                        }
                        {/* <div className='content'>

                            <textarea disabled={isViewOrVerify} defaultValue={this.state.content} ref={this.shareContent}></textarea>
                        </div> */}
                    </div>
                    <div className='row buttons'>
                        {mode === "new" ? <button onClick={this.createShare}>建立</button> :
                            method === "edit" && <button share-id={this.state.shareId} onClick={this.updateShare}>更新</button>}

                        {method === "verify" && this.state.status === 2 && <div className='verify-area'>
                            <div className='row'>
                                <button className="warn-btn" share-id={this.state.shareId} onClick={this.approveShare}>審核通過</button>
                                <TextField
                                    id="date"
                                    type="date"
                                    name="approvedDate"
                                    onChange={this.handleChange}
                                    defaultValue={this.approvedDate}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className='row'>
                                <button className="delete-btn" share-id={this.state.shareId} onClick={this.rejectShare}>退回</button>
                                <input ref={this.rejectReason} placeholder='退回原因' />
                            </div>
                        </div>}

                        {this.state.status === 4 && <div>
                            教案教學經驗分享已通過
                        </div>}
                    </div>
                </div>}

            </ReactModal>
        )
    }

}

export default withRouter(ShareDetail);