
import React, { Component } from 'react'
import axios from 'axios';
import Loader from 'react-loader-spinner'
import { withRouter, Link } from "react-router-dom"
import { withTranslation } from 'react-i18next';

import './SignIn.less'
import './Success.less'



class ForgetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
        let title = this.getTitle();
        let content = this.getContent();
        let contact = this.getContact();

        this.setState({ title: title, content: content, contact: contact })
    }

    getTitle = () => {
        return "新密碼已發送至您信箱中";
    }

    getContent = () => {
        return <div className="content">
            <p>新密碼已發送至您信箱中，新密碼僅30分鐘有效，</p>

            <p>請於30分鐘內使用新密碼登入本系統，並更新您的密碼</p>

        </div>
    }

    getContact = () => {
        return <div className="contact">
            <p>服務時間：週一至週五 09：00 - 17：00 ；週六至週日及國定假日公休。</p>
            <p>服務電話：(03)571-5131 分機：73363</p>
            <p>服務信箱：steam@my.nthu.edu.tw</p>
            <p>感謝您的愛護與支持</p>
        </div>
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.send(this.state)
    }


    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    send = (e) => {
        this.setState({ loading: true })
        axios.put(`${window.CONFS.apiServer}/member/forgetPassword`,
            {
                account: e.account
            })
            .then(res => {

                console.log('=>', res.data)

                if (res.data && res.data.errorCode === 1004) {
                    this.setState({ loading: false })
                    window.alert("查無此帳號 :" +  res.data.errorMessage);
                } else if(res.data && res.data.errorCode === 9999){

                    this.setState({ loading: false })
                    console.log("Server Error", res.data.errorMessage)
                    window.alert("伺服器異常" );

                } else {
                    this.setState({ sendSuccess: true, loading: false  })
                }

            })
            .catch(function (error) {
                console.log('fail post =>', error)
                // window.alert("Server Error")
            });

    }




    render() {

        const { title = "", content = "", contact = "" } = this.state;
        return (
            <div>
                {
                    this.state.sendSuccess ?
                        <div>
                            <div className="successDiv">
                                <h1>{title}</h1>
                                {content}
                                <p></p>
                                {contact}
                            </div>
                        </div>
                        :
                        <div className="signInbarBanner">
                        <form onSubmit={this.handleSubmit} className="signInForm">
                            <div className="signInForm__title">將發送新密碼至您信箱中</div>
                            <div className="signInForm__content">
                                <div className="inputBox">
                                    <label htmlFor="account"></label>
                                    <input className="inputtext-email" type="email" id="account" placeholder="請輸入您的信箱" onChange={this.handleChange} />
                                </div>

                                <div className="btnBox">
                                    {this.state.loading ?
                                        <Loader
                                            type="Oval"
                                            color="#ffffff"
                                            height={22}
                                            width={22}
                                            visible={true}
                                        /> :
                                        <div className="btnBox">
                                            <button className="btn pink lighten-1 z-depth-0">送出</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </form>
                        </div>
                }

            </div>
        )

    }
}

export default withTranslation()(withRouter(ForgetPassword));