import React, { Component } from 'react'
import axios from 'axios';
import Loader from 'react-loader-spinner'
import Moment from 'react-moment'
import moment from 'moment'
import { withTranslation } from 'react-i18next';
import LevelCourseHourSetting from '../../LevelCourseHourSetting'
import LessonPlanHelper from '../../Helpers/LessonPlanHelper'
import { v4 as uuidv4 } from 'uuid';
import './LessonLife.less'

class LessonLife extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            content: {
                level1: {
                    status: 0
                },
                level2: {
                    status: 0
                },
                level3: {
                    status: 0,
                    isPreComplete: false
                },
                level4: {
                    status: 0,
                    isPreComplete: false
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.memberInfo !== prevProps.memberInfo) {
            const { memberInfo, joinedCourses } = this.props
            if (memberInfo && joinedCourses) {
                let content = JSON.parse(JSON.stringify(this.state.content))
                if (memberInfo && joinedCourses) {
                    this.processLevel1(content, memberInfo, joinedCourses)
                    this.processLevel2(content, memberInfo, joinedCourses)
                    this.processLevel3(content, memberInfo)
                    this.processLevel4(content, memberInfo)
                    console.log({ content })
                    // this.processLevel4(content, memberInfo, joinedCourses)
                }

                this.setState({
                    content: content,
                    isLoading: false
                })
            }
        }
    }

    processLevel1 = (content, memberInfo, joinedCourses) => {
        if (memberInfo.levelCertification && memberInfo.levelCertification.level1) {
            content.level1.status = 1
            let level1Data = memberInfo.levelCertification.level1.level1CourseCertifications
            let level1JoinedCourses = joinedCourses.filter(joinedCourse => level1Data.includes(joinedCourse._id)).sort((a, b) => moment(a.endDateTime) - moment(b.endDateTime))
            content.level1.joinedCourses = level1JoinedCourses
        }
        else {
            let systemAdminRole = memberInfo.systemAdminRole
            let level1HourMap = JSON.parse(JSON.stringify(LevelCourseHourSetting[systemAdminRole].level1))
            joinedCourses.forEach(joinedCourse => {
                if (level1HourMap[joinedCourse.courceType.key]) {
                    level1HourMap[joinedCourse.courceType.key] =
                        (level1HourMap[joinedCourse.courceType.key] - parseFloat(joinedCourse.hours)) < 0 ? 0 : level1HourMap[joinedCourse.courceType.key] - parseFloat(joinedCourse.hours)
                }
            })  
            let tips = []
            for (var key in level1HourMap) {
                if (level1HourMap[key] > 0) {
                    tips.push(<div key={uuidv4()} className="tip">{LevelCourseHourSetting.getCourseTypeName(key)} 尚有 {level1HourMap[key]} Hrs</div>)
                }
            }

            if (tips.length == 0) {
                tips.push(<div key={uuidv4()} className="tip">待審核通過</div>)
            }
            content.level1.tips = tips
        }
    }

    generateLevel1Content = (level1, isLoading) => {
        if (isLoading) {
            return <Loader
                type="Oval"
                color="#7f1083"
                height={22}
                width={22}
                visible={true}
            />
        }

        if (level1.status === 1) {
            return this.generateCourseTable(level1.joinedCourses, "level1")
        }

        return level1.tips
    }

    generateCourseTable = (joinedCourses, level) => {
        return <div className='course-table'>
            <div className={`course-table-row ${level}-header`}>
                <div className='type'>類型</div>
                <div className='end-date'>完成時間</div>
                <div className='title'>標題</div>
            </div>
            {joinedCourses.map((joinedCourse, key) => {
                return <div className={`course-table-row ${level}`} key={key}>
                    <div className='type'>{joinedCourse.courceType.title}</div>
                    <div className='end-date'>{joinedCourse.endDateTime}</div>
                    {/* <div className='download'></div> */}
                    <div className='title'>{joinedCourse.name}</div>
                </div>
            })}
        </div>
    }

    generateCourseTableWithExpiredRange = (joinedCourses, level) => {
        return <div className='course-table'>
            <div className={`course-table-row ${level}-header`}>
                <div className='end-date'>效期</div>
                <div className='end-date'></div>
                <div className='type'>類型</div>
                <div className='end-date'>完成時間</div>
                <div className='title'>標題</div>
            </div>
            {joinedCourses.map((joinedCourse, key) => {
                return <div className={`course-table-row ${level}`} key={key}>
                    <div className='end-date'>{moment(joinedCourse.oriStartAt).format("YYYY-MM-DD")}</div>
                    <div className='end-date'>{moment(joinedCourse.oriExpiredAt).format("YYYY-MM-DD")}</div>
                    <div className='type'>{joinedCourse.courceType.title}</div>
                    <div className='end-date'>{joinedCourse.endDateTime}</div>
                    {/* <div className='download'></div> */}
                    <div className='title'>{joinedCourse.name}</div>
                </div>
            })}
        </div>
    }

    processLevel2 = (content, memberInfo, joinedCourses) => {
        if (memberInfo.levelCertification && memberInfo.levelCertification.level2) {
            let lessonPlan = JSON.parse(JSON.stringify(memberInfo.levelCertification.level2))
            lessonPlan.startAt = moment(lessonPlan.approveDate)
            lessonPlan.expiredAt = moment(lessonPlan.startAt).clone();
            lessonPlan.expiredAt.add(LevelCourseHourSetting.periodTime, LevelCourseHourSetting.timeUnit).add(-1, "day")
            lessonPlan.isExpired = false
            content.level2.lessonPlan = lessonPlan
            let level1Data = memberInfo.levelCertification.level1.level1CourseCertifications
            let joinedCoursesExcludeLevel1 = joinedCourses.filter(joinedCourse => !level1Data.includes(joinedCourse._id))
            content.joinedCoursesExcludeLevel1 = joinedCoursesExcludeLevel1
            let systemAdminRole = memberInfo.systemAdminRole
            let processLevel2ExpiredResult = this.processLevel2Expired(content, memberInfo.passedShares, systemAdminRole)
            content.level3.courses = processLevel2ExpiredResult.courses
            content.level4.shares = processLevel2ExpiredResult.shares
        }
        else {
            let tips = []
            if (content.level1.status === 0) {
                tips.push(<div key={uuidv4()} className="tip level2">Level1 未通過</div>)
            }
            tips.push(<div key={uuidv4()} className="tip level2">教案尚未通過審核</div>)
            content.level2.tips = tips
        }
    }

    processLevel2Expired = (content, passedShares, systemAdminRole) => {
        content.level2.status = 1
        let lessonPlan = content.level2.lessonPlan
        let joinedCoursesExcludeLevel1 = JSON.parse(JSON.stringify(content.joinedCoursesExcludeLevel1))
        let level3Courses = []
        let level4Shares = []
        let now = moment()
        let isPreComplete = false
        while (now.diff(lessonPlan.expiredAt, LevelCourseHourSetting.timeUnit) > 0 && !lessonPlan.isExpired) {
            let excludeUsedCourses = joinedCoursesExcludeLevel1.filter(course => !level3Courses.map(course => { return course._id }).includes(course._id))
            let excludeUsedShares = passedShares.filter(share => !level4Shares.map(share => { return share._id }).includes(share._id))
            let periodResult = LessonPlanHelper.repeatUpdateLevel2Period(lessonPlan, excludeUsedCourses, excludeUsedShares, systemAdminRole)
            level3Courses = [...level3Courses, ...periodResult.courses]
            level4Shares = [...level4Shares, ...periodResult.shares]
            content.level3.isPreComplete = JSON.parse(JSON.stringify(isPreComplete));
            content.level4.isPreComplete = JSON.parse(JSON.stringify(isPreComplete));
            isPreComplete = periodResult.isPass;
        }

        if (lessonPlan.isExpired) {
            content.level2.status = 2
        }

        return { courses: level3Courses, shares: level4Shares };
    }

    // repeatUpdateLevel2Period = (lessonPlan, courses, systemAdminRole) => {
    //     let lessonPlanPeriodCourses = courses.filter(course => moment(course.endDateTime).diff(lessonPlan.startAt) > -1 && moment(course.endDateTime).diff(lessonPlan.expiredAt) < 1)
    //     let oriStartAt = moment(lessonPlan.startAt).clone()
    //     let oriExpiredAt = moment(lessonPlan.expiredAt).clone()
    //     if (this.isLevel3Complete(lessonPlanPeriodCourses, systemAdminRole)) {
    //         lessonPlan.startAt.add(LevelCourseHourSetting.periodTime, LevelCourseHourSetting.timeUnit)
    //         lessonPlan.expiredAt = moment(lessonPlan.startAt).clone()
    //         lessonPlan.expiredAt.add(LevelCourseHourSetting.periodTime, LevelCourseHourSetting.timeUnit).add(-1, "day")
    //         return lessonPlanPeriodCourses.map(course => {
    //             course.forExpired = false
    //             course.oriStartAt = oriStartAt
    //             course.oriExpiredAt = oriExpiredAt
    //             return course
    //         })
    //     }
    //     else {
    //         let expiredCourses = courses.filter(course => moment(course.endDateTime).diff(lessonPlan.expiredAt) > 0)
    //         if (this.isLevel3Complete(expiredCourses, systemAdminRole)) {
    //             let passExpiredCourses = this.getCoursesCompleteLevel3(JSON.parse(JSON.stringify(expiredCourses)), systemAdminRole)
    //             let latestCourseCompleteLevel3 = passExpiredCourses.sort((a, b) => moment(b.endDateTime) - moment(a.endDateTime))[0]
    //             lessonPlan.startAt = moment(latestCourseCompleteLevel3.endDateTime)
    //             lessonPlan.expiredAt = moment(lessonPlan.startAt).clone()
    //             lessonPlan.expiredAt.add(LevelCourseHourSetting.periodTime, LevelCourseHourSetting.timeUnit).add(-1, "day")
    //             return passExpiredCourses.map(course => {
    //                 course.forExpired = true
    //                 course.oriStartAt = oriStartAt
    //                 course.oriExpiredAt = oriExpiredAt
    //                 return course
    //             })
    //         }
    //         else {
    //             lessonPlan.isExpired = true
    //         }
    //     }
    //     return []
    // }

    // isLevel3Complete = (courses, systemAdminRole) => {
    //     let level3TimesMap = JSON.parse(JSON.stringify(LevelCourseHourSetting[systemAdminRole].level3))
    //     courses.forEach(course => {
    //         if (level3TimesMap[course.courceType.key]) {
    //             level3TimesMap[course.courceType.key] = level3TimesMap[course.courceType.key] - 1
    //         }
    //     })

    //     for (var key in level3TimesMap) {
    //         if (level3TimesMap[key] > 0) {
    //             return false
    //         }
    //     }
    //     return true
    // }

    // getCoursesCompleteLevel3 = (courses, systemAdminRole) => {
    //     let sortedCourses = courses.sort((a, b) => moment(b.endDateTime) - moment(a.endDateTime))
    //     let neededCourses = []
    //     while (!this.isLevel3Complete(neededCourses, systemAdminRole)) {
    //         neededCourses.push(sortedCourses.pop())
    //     }
    //     return neededCourses
    // }

    generateLevel2Content = (level2, isLoading) => {
        if (isLoading) {
            return <Loader
                type="Oval"
                color="#7f1083"
                height={22}
                width={22}
                visible={true}
            />
        }

        if (level2.status > 0) {
            return this.generateLessonPlanTable(level2)
        }
        return level2.tips
    }

    generateLessonPlanTable = (data) => {
        let timeRange = <div key={uuidv4()}></div>
        if (data.lessonPlan && data.tips && data.tips.length > 0) {
            timeRange = <div key={`lessonPlanTable${moment()}`}><i>{`${moment(data.lessonPlan.startAt).format("YYYY-MM-DD")}->${moment(data.lessonPlan.expiredAt).format("YYYY-MM-DD")}`}</i></div>            
        }
        return <>
            {<>{timeRange}{data.tips}</>}
            <div className='course-table'>
                <div className={`course-table-row ${data.lessonPlan.isExpired ? "default" : "level2"}-header`}>
                    <div className='expired'>效期</div>
                    <div className='expired'></div>
                    <div className='approved'>通過時間</div>
                    {/* <div className='download'>電子證書</div> */}
                    <div className='title'>標題</div>
                </div>
                <div className='course-table-row'>
                    <div className='expired'>{moment(data.lessonPlan.startAt).format("YYYY-MM-DD")}{`->`}</div>
                    <div className='expired'>{moment(data.lessonPlan.expiredAt).format("YYYY-MM-DD")}</div>
                    <div className='approved'>{data.lessonPlan.approveDate}</div>
                    {/* <div className='download'></div> */}
                    <div className='title'>{data.lessonPlan.lessonPlan.title}</div>
                </div>
            </div>
            {data.courses.length > 0 && this.generateCourseTableWithExpiredRange(data.courses, "level2-expired")}
        </>
    }

    processLevel3 = (content, memberInfo) => {
        let lessonPlan = content.level2.lessonPlan
        let joinedCoursesExcludeLevel1 = content.joinedCoursesExcludeLevel1 || []
        let joinedCourses = []
        if (content.level2.status > 1) {
            joinedCourses = joinedCoursesExcludeLevel1.filter(course => moment(course.endDateTime).diff(lessonPlan.expiredAt) > 0)
        }
        else {
            joinedCourses = joinedCoursesExcludeLevel1.filter(course => moment(course.endDateTime).diff(lessonPlan.startAt) > -1 && moment(course.endDateTime).diff(lessonPlan.expiredAt) < 1)
        }

        let settedCoursesIds = (content.level3.courses || []).map(course => course._id)
        joinedCourses = joinedCourses.filter(course => !settedCoursesIds.includes(course._id)).map(course => {
            course.oriStartAt = moment(lessonPlan.startAt)
            course.oriExpiredAt = moment(lessonPlan.expiredAt)
            return course
        })

        console.log({ joinedCourses })
        content.level3.status = 0

        let systemAdminRole = memberInfo.systemAdminRole
        if (LessonPlanHelper.isLevel3Complete(joinedCourses, systemAdminRole) && content.level2.status > 0) {
            content.level3.status = 1
            if (content.level2.status > 1 && content.level3.isPreComplete) {
                content.level3.status = 2
            }
        }
        else {
            let level3HourMap = JSON.parse(JSON.stringify(LevelCourseHourSetting[systemAdminRole].level3))
            // let joinedCourses = content.level3.courses ? JSON.parse(JSON.stringify(content.level3.courses)) : []
            joinedCourses.forEach(joinedCourse => {
                if (level3HourMap[joinedCourse.courceType.key]) {
                    level3HourMap[joinedCourse.courceType.key] =
                        (level3HourMap[joinedCourse.courceType.key] - 1) < 0 ? 0 : level3HourMap[joinedCourse.courceType.key] - 1
                }
            })
            let level2Tips = []
            let level3Tips = []

            if (content.level2.status === 0) {
                level3Tips.push(<div key={uuidv4()} className="tip level3">Level2 未通過</div>)
            }

            if (content.level2.status === 2) {
                level3Tips.push(<div key={uuidv4()} className="tip level3">Level2 過期</div>)
                if (content.level3.isPreComplete) {
                    content.level3.status = 2
                }
            }

            for (var key in level3HourMap) {
                if (level3HourMap[key] > 0) {
                    if (content.level2.status < 2) {
                        level3Tips.push(<div key={uuidv4()} className="tip level3">{LevelCourseHourSetting.getCourseTypeName(key)} 尚有 {level3HourMap[key]} 次</div>)
                    }
                    else {
                        level2Tips.push(<div key={uuidv4()} className="tip level2">{LevelCourseHourSetting.getCourseTypeName(key)} 尚有 {level3HourMap[key]} 次</div>)
                    }
                }
            }

            content.level3.tips = level3Tips
            content.level2.tips = [...(content.level2.tips || []), ...level2Tips]
        }
        let level3Keys = Object.keys(LevelCourseHourSetting[systemAdminRole].level3)
        let sortedCourses = [...(content.level3.courses || []), ...joinedCourses].sort((a, b) => moment(b.endDateTime) - moment(a.endDateTime))
        content.level3.courses = sortedCourses.filter(course => !course.forExpired && level3Keys.includes(course.courceType.key))
        content.level2.courses = sortedCourses.filter(course => course.forExpired && level3Keys.includes(course.courceType.key))
    }

    generateLevel3Content = (content, isLoading) => {
        let level3 = content.level3
        if (isLoading) {
            return <Loader
                type="Oval"
                color="#7f1083"
                height={22}
                width={22}
                visible={true}
            />
        }

        let lessonPlan = content.level2.lessonPlan
        let timeRange = <div key={uuidv4()}></div>
        if (lessonPlan && level3.tips && level3.tips.length > 0) {
            // level3.tips = [<div key={`level3cotent${new Date().getTime()}`}><i>{`${moment(lessonPlan.startAt).format("YYYY-MM-DD")}->${moment(lessonPlan.expiredAt).format("YYYY-MM-DD")}`}</i></div>, ...level3.tips]
            timeRange = <div key={`level3cotent${new Date().getTime()}`}><i>{`${moment(lessonPlan.startAt).format("YYYY-MM-DD")}->${moment(lessonPlan.expiredAt).format("YYYY-MM-DD")}`}</i></div>
        }

        if (level3.status > 1) {
            return <>{timeRange}{level3.tips}{level3.courses.length > 0 && this.generateCourseTableWithExpiredRange(level3.courses, "default")}</>
        }

        return <>{timeRange}{level3.tips}{level3.courses.length > 0 && this.generateCourseTableWithExpiredRange(level3.courses, content.level2.status === 2 ? "default" : "level3")}</>
    }

    processLevel4 = (content, memberInfo) => {
        console.log({ memberInfo })
        let lessonPlan = content.level2.lessonPlan
        let passedShares = memberInfo.passedShares || []
        let unSetShares = []
        if (content.level2.status > 1) {
            unSetShares = passedShares.filter(passedShare => moment(passedShare.approvedDate).diff(lessonPlan.expiredAt) > 0)
        }
        else {
            unSetShares = passedShares.filter(passedShare => moment(passedShare.approvedDate).diff(lessonPlan.startAt) > -1
                && moment(passedShare.approvedDate).diff(lessonPlan.expiredAt) < 1)
        }

        let settedShareIds = (content.level4.shares || []).map(share => share._id)
        unSetShares = unSetShares.filter(share => !settedShareIds.includes(share._id)).map(share => {
            share.oriStartAt = moment(lessonPlan.startAt)
            share.oriExpiredAt = moment(lessonPlan.expiredAt)
            return share
        })

        console.log({ unSetShares })
        content.level4.status = 0

        // let systemAdminRole = memberInfo.systemAdminRole
        let level2Tips = []
        let level4Tips = []
        if (unSetShares.length > 0 && content.level3.status > 0 && content.level2.status > 0) {
            content.level4.status = 1
            if (content.level2.status > 1 && content.level4.isPreComplete) {
                level4Tips.push(<div key={moment()} className="tip level4">Level2 過期</div>)
                content.level4.status = 2
                unSetShares = []
            }
        }
        else {

            if (content.level3.status === 0) {
                level4Tips.push(<div key={moment()} className="tip level4">Level3 未通過</div>)
            }
            if (content.level2.status === 2) {
                level4Tips.push(<div key={moment()} className="tip level4">Level2 過期</div>)
                if (unSetShares.length === 0) {
                    level2Tips.push(<div key={moment()} className="tip level2">STEAM教學經驗分享 尚有 1 次</div>)
                }
                if (content.level4.isPreComplete) {
                    content.level4.status = 2
                }
            }

            if (content.level2.status < 2 && unSetShares.length === 0) {
                level4Tips.push(<div key={moment()} className="tip level4">STEAM教學經驗分享 尚有 1 次</div>)
            }
        }

        content.level4.tips = level4Tips
        content.level2.tips = [...(content.level2.tips || []), ...level2Tips]

        if (content.level4.status === 0 || content.level4.status === 2) {
            unSetShares = []
        }
        let sortedShares = [...(content.level4.shares || []), ...unSetShares].sort((a, b) => moment(b.approvedDate) - moment(a.approvedDate))
        content.level4.shares = sortedShares.filter(share => !share.forExpired)
        let convertToLevel2Attribute = sortedShares.map(share => {
            share.name = share.title;
            share.endDateTime = share.approvedDate;
            share.courceType = { title: "分享" }
            return share
        })
        content.level2.courses = [...content.level2.courses, ...convertToLevel2Attribute.filter(course => course.forExpired)].sort((a, b) => moment(b.endDateTime) - moment(a.endDateTime))
    }

    generateLevel4Content = (content, isLoading) => {
        let level4 = content.level4
        if (isLoading) {
            return <Loader
                type="Oval"
                color="#7f1083"
                height={22}
                width={22}
                visible={true}
            />
        }
        let lessonPlan = content.level2.lessonPlan
        let timeRange = <div key={uuidv4()}></div>
        if (lessonPlan && level4.tips && level4.tips.length > 0) {
            timeRange = <div key={uuidv4()}><i>{`${moment(lessonPlan.startAt).format("YYYY-MM-DD")}->${moment(lessonPlan.expiredAt).format("YYYY-MM-DD")}`}</i></div>
            //level4.tips = [<div key={uuidv4()}><i>{`${moment(lessonPlan.startAt).format("YYYY-MM-DD")}->${moment(lessonPlan.expiredAt).format("YYYY-MM-DD")}`}</i></div>, ...level4.tips]
        }

        if (level4.status > 1) {
            return <>{timeRange}{level4.tips}{level4.shares.length > 0 && this.generateCourseTableWithExpiredRange(level4.shares, "default")}</>
        }

        return <>{timeRange}{level4.tips}{level4.shares.length > 0 && this.generateCourseTableWithExpiredRange(level4.shares, content.level2.status === 2 ? "default" : "level4")}</>
    }

    render() {
        let { content, isLoading } = this.state
        return (
            <div className="lesson-life">
                <div className="level-item" title={content.level1.status === 0 ? "未通過" : null}>
                    <div className={`level-title ${content.level1.status === 1 ? "level1" : "default"}`}>
                        <div className='pre-text'>Level</div>
                        <div className='number'>1</div>
                    </div>
                    <div className="content">{this.generateLevel1Content(content.level1, isLoading)}</div>
                </div>
                <div className="level-item" title={content.level2.status > 0 ? content.level2.status > 1 ? "已失效" : null : "未通過"}>
                    <div className={`level-title ${content.level2.status > 0 ? content.level2.status > 1 ? "default expired" : "level2" : "default"}`}>
                        <div className='pre-text'>Level</div>
                        <div className='number'>2</div>
                    </div>
                    <div className="content">{this.generateLevel2Content(content.level2, isLoading)}</div>
                </div>
                <div className="level-item" title={content.level3.status > 0 ? content.level3.status > 1 ? "已失效" : null : "未通過"}>
                    <div className={`level-title ${content.level3.status > 0 ? content.level3.status > 1 ? "default expired" : "level3" : "default"}`}>
                        <div className='pre-text'>Level</div>
                        <div className='number'>3</div>
                    </div>
                    <div className="content">{this.generateLevel3Content(content, isLoading)}</div>
                </div>
                <div className="level-item" title={content.level4.status > 0 ? content.level4.status > 1 ? "已失效" : null : "未通過"}>
                    <div className={`level-title ${content.level4.status > 0 ? content.level4.status > 1 ? "default expired" : "level4" : "default"}`}>
                        <div className='pre-text'>Level</div>
                        <div className='number'>4</div>
                    </div>
                    <div className="content">{this.generateLevel4Content(content, isLoading)}</div>
                </div>
                {/* <div className="title">認證憑證</div>
                        {this.state.isLoadingCertifications ? <Loader
                            type="Oval"
                            color="#7f1083"
                            height={22}
                            width={22}
                            visible={true}
                        /> : <div className="content">
                            <DataGrid
                                rowsPerPageOptions={[10]}
                                scrollbarSize={30}
                                hideFooterSelectedRowCount
                                rows={this.state.certifications}
                                columns={this.certificationColumns} />
                        </div>} */}
            </div>
        )
    }

}

export default withTranslation()(LessonLife);