import React, { Component } from 'react'
import Login from './Login/Login'
import Dashboard from './Dashboard/Dashboard'
import ReactModal from 'react-modal'
import LocalStorage from '../Helpers/LocalStorage'
import axios from 'axios'

import './Admin.less'

class Admin extends Component {
  constructor(props) {
    super(props);
    this.nthusteam = LocalStorage.getLocalStorage("nthusteamAdmin")
    ReactModal.setAppElement('body')
    this.init()
  }

  generateContent(key) {
    let content = <Dashboard
      action={this.props.match.params.action}
    />
    if (key && key.toUpperCase() === "login".toUpperCase()) {
      content = <Login />
    }
    return content
  }

  init = () => {
    let apiToken = ""
    if (this.nthusteam && this.nthusteam.apiToken) {
      apiToken = this.nthusteam.apiToken
      this.initAdminRequestHeader(apiToken)      
    }
    this.initAdminResponseHandler()
  }

  initAdminRequestHeader = (apiToken) => {
    axios.interceptors.request.eject(window.CONFS.adminAxiosSetting.requestHeader)
    window.CONFS.adminAxiosSetting.requestHeader = axios.interceptors.request.use(
      config => {
        config.headers["X-Admin-Authorize"] = apiToken;
        return config;
      },
      error => Promise.reject(error)
    );
  }

  initAdminResponseHandler = () => {
    axios.interceptors.response.eject(window.CONFS.adminAxiosSetting.responseHandler)
    window.CONFS.adminAxiosSetting.responseHandler = axios.interceptors.response.use(res => {      
      if (res.data.errorCode === 1000 || res.data.errorCode === 1011) {
        axios.interceptors.request.eject(window.CONFS.adminAxiosSetting.requestHeader)
        axios.interceptors.response.eject(window.CONFS.adminAxiosSetting.responseHandler)
        console.log("Admin.js 請重新登入")
        alert("請重新登入");
        console.log({"this.props.location.pathname":window.location.pathname})
        this.props.history.push("/admin/login?redirect=" + window.location.pathname)
      }
      return res;
    }, function (error) {
      return Promise.reject(error);
    });
  }

  render() {
    return (
      <div className="Admin">
        {this.generateContent(this.props.match.params.action)}
      </div>
    );
  }
}

export default Admin;