import React, { Component } from 'react';
import Loader from 'react-loader-spinner'
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { DataGrid } from '@material-ui/data-grid';
import SignatureDetail from './SignatureDetail'

import './Signature.less'

class Signature extends Component {
	constructor(props) {
		super(props);
		this.state = {
			"signatures": [],
			"Loading": true
		}
	}

	componentDidMount() {
		this.initSignatures();
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.initSignatures();
		}
	}

	initSignatures = async () => {
		this.getSignaturesAction().then(datas => {
			this.setState({
				signatures: datas.map(data => { data.id = data._id; return data })
			})
		})
	}

	getSignaturesAction = () => {
		return new Promise(resolve => {
			axios.get(`${window.CONFS.apiServer}/admin/signatures`).then(res => {
				resolve(res.data.data)
			}).catch(function (error) {
				console.log('fail post =>', error)
				// window.alert("Server Error")
			});
		})
	}

	deleteSignature = (event) => {
		let signatureId = event.target.getAttribute('signature-id')
		let signatureName = event.target.getAttribute('signature-name')
		if (!window.confirm(`確定要刪除${signatureName} ?`)) {
			return false;
		}
		this.deleteSignatureAction(signatureId).then(() => {
			window.alert("刪除成功")
			this.initSignatures()
		})
	}

	deleteSignatureAction = (signatureId) => {
		return new Promise(resolve => {
			axios.delete(`${window.CONFS.apiServer}/admin/signature/${signatureId}`).then(res => {
				resolve(res.data.data)
			}).catch(function (error) {
				console.log('fail post =>', error)
				// window.alert("Server Error")
			});
		})
	}

	render() {
		return (
			<div className="Signature">
				<div className="title">
					<span>簽名檔管理</span>
				</div>
				<div className="Signature__controlArea">
					<button onClick={() => { this.props.history.push(`./signature/new`) }}>建立簽名檔</button>
				</div>
				<div className={`Signature__content ${this.props.match.params.id ? "hide" : ""}`}>
					<DataGrid
						rowsPerPageOptions={[10]}
						scrollbarSize={30}
						hideFooterSelectedRowCount
						rows={this.state.signatures}
						columns={[
							{
								field: '_id', headerName: ' ', width: 120, renderCell: (params) => (<>
									{<button signature-id={params.getValue('id')} signature-name={params.getValue('title')} className="delete-btn" onClick={this.deleteSignature}>刪除</button>}
								</>
								)
							},
							{ field: 'createdAt', headerName: '建立時間', width: 200 },
							{ field: 'title', headerName: '標題', width: 250 },
							{
								field: '', headerName: ' ', width: 150, renderCell: (params) => (<div className="signature-image" style={{backgroundImage:`url('${window.CONFS.storageUrl}/${params.getValue('fileName')}')`}}></div>)								
							}
						]} />
				</div>
				{<SignatureDetail mode={this.props.match.params.id} />}
			</div>
		);
	}
}

export default withRouter(Signature);