const setting = {
    student: {
        level1: { LEVEL2: 12, LEVEL3: 18 },
        level3: { LEVEL4: 2 },
    },
    teacher: {
        level1: { LEVEL2: 30 },
        level3: { LEVEL4: 2 }
    }
    ,getCourseTypeName : (val) => {
		switch (val) {
			case "LEVEL1":
				return "一般";
			case "LEVEL2":
				return "初階";
			case "LEVEL3":
				return "進階";
			case "LEVEL4":
				return "回訓";
		}
	}
    ,periodTime: 730
    ,timeUnit: "day"
}

export default setting;