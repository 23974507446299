import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';

import './Index.less'

class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			latestCourses: []
		}
	}

	componentDidMount = () => {
		this.getLatestCourses()
	}

	getLatestCourses = () => {
		return new Promise((resolve) => {
			axios.get(window.CONFS.apiServer + '/tw/latest/courses')
				.then(res => {
					if (res.data.errorCode > 0) {
						window.CONFS.validateAdminLoginSession(res.data, this.props.history)
						console.log(res)
					}
					else {
						resolve(res.data.data)
						this.setState({ latestCourses: res.data.data })
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				});
			this.setState({ "courseItemsLoading": true })
		});
	}

	render() {

		const { t } = this.props;

		return (
			<div className="index">
				<div className="shortcutItems">
					<div className="item"><Link to="/management/lessonplans">{t('Index.management.plan')}</Link></div>
					<div className="item"><Link to='/management/share'>{t('Index.management.share')}</Link></div>
					{/* Share your experience */}
					<div className="item"><Link to='/management/lessonplanresources'>{t('Index.management.search')}</Link></div>
					{/* resource search */}
				</div>
				<div className="trainingCourses">
					<div className="title">{t('Index.news.title')}</div>
					<table className="latestCourses">
						<thead>
							<tr>
								<td>{t('Index.news.name')}</td>
								<td>{t('Index.news.time')}</td>
								<td>{t('Index.news.target')}</td>
								<td>{t('Index.news.content')}</td>
								<td></td>
							</tr>
						</thead>
						<tbody>
							{this.state.latestCourses.map((item, k) => {
								return <tr key={k}>
									<td>{item.name}</td>
									<td>{item.hours ? item.hours : 0}</td>
									<td>{item.target}</td>
									<td>{item.content}</td>
									<td><a href={item.url} target="_blank" rel="noopener noreferrer"><i className="fas fa-external-link-alt"></i></a></td>
								</tr>
							})}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default withTranslation()(Index);