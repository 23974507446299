import React, { Component } from 'react'
import './MemberSharesManage.less'
import Loader from 'react-loader-spinner'
import axios from 'axios';
import Moment from 'react-moment'
import ShareDetail from '../../Management/Share/ShareDetail'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid';
import { Dialog, DialogTitle, DialogContent, withStyles, Tooltip } from '@material-ui/core';

class MemberSharesManage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			passedAndWaitApproveShares: [],
			loading: false
		}
	}

	componentDidMount() {
		// this.getFiles();
		this.initShares();
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.initShares();
		}
	}

	initShares = () => {
		this.getSharesAction().then(datas => {
			datas = datas.map(data => { data["id"] = data["_id"]; return data })
			this.setState({ passedAndWaitApproveShares: datas })
		})
	}

	getSharesAction = () => {
		return new Promise(resolve => {
			axios.get(`${window.CONFS.apiServer}/admin/shares`).then(res => {
				resolve(res.data.data)
			}).catch(function (error) {
				console.log('fail post =>', error)
				// window.alert("Server Error")
			});
		})
	}

	viewShare = (event) => {
		let shareId = event.target.getAttribute('share-id')
		this.props.history.push(`./share/${shareId}/admin/verify`)
	}

	generateRejectHistoryCount = (rejectHistory) => {
		if (rejectHistory) {
			rejectHistory = rejectHistory.sort((a, b) => moment(b.time) - moment(a.time))
			let titleContent = rejectHistory.map(reject => { return `時間:${reject.applyDate}->${reject.time}, 退回原因:${reject.reason}` })
			return <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{titleContent.join("\n")}</div>} ><div className="reject-block">{rejectHistory.length}</div></Tooltip>
		}
	}

	generateApprovedStatusText = (params) => {
		let textContent = params.getValue('status')===4?"已公開":"非公開"
		let button = params.getValue('status')===4?<button go-status="5" share-id={params.getValue('id')} className="cancel-btn" onClick={this.changeStatusShare}>隱藏</button>:<button go-status="4" share-id={params.getValue('id')} className="success-btn" onClick={this.changeStatusShare}>公開</button>
		return <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{params.getValue('approvedDateTime')}</div>} >{<div>{button}{`${textContent} ${params.getValue('approvedDate')}`}</div>}</Tooltip>
	}

	changeStatusShare = (event) => {
        let shareId = event.target.getAttribute('share-id')
        let goStatus = parseInt(event.target.getAttribute('go-status'))
        let checkText = goStatus === 5 ? "確定要隱藏分享" : "確定要公開分享"
        if (!window.confirm(`${checkText}?`)) {
            return false;
        }
        this.changeStatusShareAction(shareId, goStatus).then(() => {
            window.alert("設定成功")
            this.initShares()
        })
    }

    changeStatusShareAction = (shareId, status) => {
        return new Promise(resolve => {
            axios.patch(`${window.CONFS.apiServer}/admin/share/${shareId}/isPublic`, {
                status: status
            }).then(res => {
                resolve(res)
            }).catch(function (error) {
                console.log('fail post =>', error)
                // window.alert("Server Error")
            });
        })
    }

	render() {
		return (
			<div className="MemberSharesManage">
				<div className="title">
					<span>STEAM教案教學經驗分享管理</span>
				</div>
				{/* <div className="MemberCertificationsManage__searchArea">
					<i className="fas fa-search"></i>
					<input type="text" onKeyPress={this.checkKeywordClickKey} onChange={this.handleChange} name="keyword" />
					<button onClick={this.searchMember}>搜尋</button>
					<button onClick={this.downloadMember}>下載</button>
				</div> */}
				<div className={`MemberSharesManage__content ${this.props.match.params.id && "hide"}`}>
					{this.state.loading ? <Loader
						type="Oval"
						color="#7f1083"
						height={22}
						width={22}
						visible={true}
					/> :
						<DataGrid
							rowsPerPageOptions={[10]}
							scrollbarSize={30}
							hideFooterSelectedRowCount
							rows={this.state.passedAndWaitApproveShares}
							columns={[
								{
									field: '_id', headerName: ' ', width: 100, renderCell: (params) => (
										<div className="control-area">
											<button share-id={params.getValue('id')} className="info-btn" onClick={this.viewShare}>檢視</button>
											{this.generateRejectHistoryCount(params.getValue('rejectHistory'))}
										</div>)
								},
								{
									field: 'status', headerName: '狀態', width: 200, renderCell: (params) => (
										<>{[4,5].includes(params.getValue('status'))? this.generateApprovedStatusText(params) : `待審核 ${params.getValue('applyDate')}`}</>)
								},
								{ field: 'memberName', headerName: '教師', minWidth: 150 },
								{ field: 'title', headerName: '標題', minWidth: 150 }
							]} />}
				</div>
				{<ShareDetail mode={this.props.match.params.id} method={this.props.match.params.method} />}
			</div>
		);
	}
}

export default withRouter(MemberSharesManage);