import React, { Component } from 'react';
import Logo from './Logo/Logo';
import Banner from './Banner/Banner';
import Navigation from './Navigation/Navigation';
import Index from './Index/Index';
import SignIn from './Auth/SignIn'
import SignUp from './Auth/SignUp'
import Success from './Auth/Success'
import Certification from './Certification/Certification'
import ForgetPassword from './Auth/ForgetPassword'
import ContactUs from './ContactUs/ContactUs'
import CourseType from './CourseType/CourseType'
import Certificate from './Certificate/Certificate'
import LocalStorage from "../Helpers/LocalStorage"

import Loader from 'react-loader-spinner'
import axios from 'axios';

import { withTranslation } from 'react-i18next';

import './Home.less'

import { resources } from '../i18n'

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
    this.nthusteam = LocalStorage.getLocalStorage("nthusteam")
    this.init()
  }

  componentDidMount = () => {
    this.getHompageSetting()
  }

  getHompageSetting = () => {
    return new Promise((resolve) => {
      axios.get(window.CONFS.apiServer + '/homepage/setting')
        .then(res => {
          if (res.data.errorCode > 0) {
            window.CONFS.validateAdminLoginSession(res.data, this.props.history)
            console.log(res)
          }
          else {
            resolve(res.data.data)
            this.setState({ "loading": false, ...res.data.data })
          }
        })
        .catch(function (error) {
          console.log('fail post =>', error)
          // window.alert("Server Error")
        });
      this.setState({ "courseItemsLoading": true })
    });
  }

  init = () => {
    let apiToken = "";
    this.initI18n();
    if (this.nthusteam && this.nthusteam.apiToken) {
      apiToken = this.nthusteam.apiToken
      this.initMemberRequestHeader(apiToken)
      this.initMemberResponseHandler()
    }
  }


  initI18n = () => {
    // const{ i18n } = this.props;
    // i18n.changeLanguage('en');
  }


  initMemberRequestHeader = (apiToken) => {
    axios.interceptors.request.eject(window.CONFS.memberAxiosSetting.requestHeader)
    window.CONFS.memberAxiosSetting.requestHeader = axios.interceptors.request.use(
      config => {
        config.headers["X-Authorize"] = apiToken;
        return config;
      },
      error => Promise.reject(error)
    );
  }

  initMemberResponseHandler = () => {
    axios.interceptors.response.eject(window.CONFS.memberAxiosSetting.responseHandler)
    window.CONFS.memberAxiosSetting.responseHandler = axios.interceptors.response.use(res => {
      if (res.data.errorCode === 1000 || res.data.errorCode === 1011) {
        axios.interceptors.request.eject(window.CONFS.memberAxiosSetting.requestHeader)
        axios.interceptors.response.eject(window.CONFS.memberAxiosSetting.responseHandler)
        console.log("Home.js 請重新登入")
        alert("請重新登入");
        this.props.history.push("/signin?redirect=" + window.location.pathname)
      }
      return res;
    }, function (error) {
      return Promise.reject(error);
    });
  }

  changeLang = (lang) => {
    const { i18n } = this.props;
    i18n.changeLanguage(lang)

  }

  render() {

    return (
      <div className="home">

        {/* {
          Object.keys(resources).map((i, key) => 
          <button onClick={() => this.changeLang(i)} key={key}>{i}</button>)
        } */}

        {this.state.loading ?
          <Loader
            type="Oval"
            color="#7f1083"
            height={22}
            width={22}
            visible={true}
          /> :
          <div>
            <Logo hompageSetting={this.state.homepage} />
            <Navigation courseTypes={this.state.courseTypes} certificate={this.state.certificate} />

            {(this.props.match.params.action === undefined) &&
              <div>
                {/* <Banner hompageSetting={this.state.homepage} />
                <Index /> */}
                <SignIn />
              </div>
            }
            {(this.props.match.params.action === 'signin') &&
              <div>
                <SignIn />
              </div>
            }
            {(this.props.match.params.action === 'signup') &&
              <div>
                <SignUp />
              </div>
            }
            {(this.props.match.params.action === 'contactus') &&
              <div>
                <ContactUs />
              </div>
            }
            {(this.props.match.params.action === 'certification') &&
              <div>
                <Certification />
              </div>
            }
            {(this.props.match.params.action === 'success') &&
              <div>
                <Success />
              </div>
            }

            {(this.props.match.params.action === 'forgetPassword') &&
              <div>
                <ForgetPassword />
              </div>
            }

            {(this.props.match.params.action === 'coursetype' && this.props.match.params.id) &&
              <div>
                <CourseType courseTypeId={this.props.match.params.id} />
              </div>
            }

            {(this.props.match.params.action === 'certificate' && this.props.match.params.id) &&
              <div>
                <Certificate courseTypeId={this.props.match.params.id} />
              </div>
            }
          </div>
        }
      </div>
    );
  }
}

export default withTranslation()(Home);