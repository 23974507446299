import React, { Component } from 'react'
import './MemberLessonPlans.less'
import Loader from 'react-loader-spinner'
import axios from 'axios'
import Moment from 'react-moment'
import ReactModal from 'react-modal'
import { withRouter } from 'react-router-dom'
import PlanDetail from '../../Management/LessonPlans/PlanDetail'
import { DataGrid } from '@material-ui/data-grid';
import { Dialog, DialogTitle, DialogContent, withStyles } from '@material-ui/core';

class MemberLessonPlans extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lessonPlans: [],
			loading: false,
			openModal: false
		}

		console.log({ "params": this.props.match.params })
		this.memberGUID = this.props.match.params.GUID ? this.props.match.params.GUID : this.props.match.params.id		

		this.columns = [
			{ field: 'title', headerName: '標題', flex: 1 },
			{
				field: 'displayCreatedAt', headerName: '建立', width: 150, renderCell: (params) => (
					<Moment format="YYYY/MM/DD HH:mm">{params.value}</Moment>
				)
			},
			{
				field: 'applyApproveAt', headerName: '提出申請時間', width: 150, renderCell: (params) => (
					<Moment format="YYYY/MM/DD HH:mm">{params.value}</Moment>
				)
			},
			{
				field: ' ', headerName: ' ', width: 150, renderCell: (params) => (
					<button onClick={() => this.props.history.push(`/admin/membership/${this.props.match.params.action}/${this.memberGUID}/${params.getValue('_id')}`)} className="approveBtn">審核</button>
				)
			}
		]
	}

	componentDidMount = () => {
		this.searchLessonPlansByGUID()
		if (this.props.match.params.id) {
			this.setState({
				openModal: true,
				lessonPlanContent: <PlanDetail loginRole="admin" />
			})
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			if (this.props.match.params.id) {
				this.setState({ lessonPlanContent: <PlanDetail loginRole="admin" />, openModal: true })
			}
			else {
				this.setState({ lessonPlanContent: <PlanDetail loginRole="admin" />, openModal: false })
			}
		}
	}

	searchLessonPlansByGUID = () => {
		this.setState({ loading: true }, () => {
			this.searchLessonPlansByGUIDAction().then(lessonPlans => {
				lessonPlans.forEach(lessonplan => {
					lessonplan.id = lessonplan._id
					lessonplan.displayCreatedAt = new Date(lessonplan.createdAt)
					lessonplan.displayApplyApproveAt = new Date(lessonplan.applyApproveAt)
				})
				this.setState({ lessonPlans: lessonPlans, loading: false })
			})
		})
	}

	searchLessonPlansByGUIDAction = () => {
		return new Promise(resolve => {
			axios.get(window.CONFS.apiServer + '/admin/lessonplans/waitingApprove/' + this.memberGUID)
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res.data.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
				});
		})
	}

	render() {
		const StyledDialog = withStyles({
			paper: {
				width: "80%",
				top: "20px"
			}
		})(Dialog);
		return (
			<div className="MemberLessonPlans">
				<div className="MemberLessonPlans__content">
					{this.state.loading ?
						<Loader
							type="Oval"
							color="#7f1083"
							height={22}
							width={22}
							visible={true}
						/> :
						<DataGrid
							rowsPerPageOptions={[10]}
							scrollbarSize={30}
							rows={this.state.lessonPlans}
							columns={this.columns} />
						// <table>
						// 	<thead>
						// 		<tr>
						// 			<td>標題</td>
						// 			<td style={{ "width": "150px" }}>提出申請時間</td>
						// 			<td style={{ "width": "80px" }}></td>
						// 		</tr>
						// 	</thead>
						// 	{this.state.loading ?
						// 		<tbody>
						// 			<tr>
						// 				<td className="loading" colSpan="3">
						// 					<Loader
						// 						type="Oval"
						// 						color="#7f1083"
						// 						height={22}
						// 						width={22}
						// 						visible={true}
						// 					/>
						// 				</td>
						// 			</tr>
						// 		</tbody> :
						// 		<tbody>
						// 			{this.state.lessonPlans.map((item, k) => {
						// 				return (<tr key={k}>
						// 					<td>{item.title}</td>
						// 					<td>{item.applyApproveAt &&
						// 						<Moment format="YYYY/MM/DD HH:mm">{item.applyApproveAt}</Moment>
						// 					}	</td>
						// 					<td>
						// 						<button onClick={() => this.props.history.push(`/admin/member/${this.memberGUID}/${item._id}`)} className="approveBtn">審核</button>
						// 					</td>

						// 				</tr>)
						// 			})
						// 			}
						// 		</tbody>
						// 	}
						// </table>

					}
				</div>
				{/* <ReactModal
					isOpen={this.state.openModal}
					shouldCloseOnOverlayClick={false}
					onRequestClose={() => { this.props.history.push(`../${this.memberGUID}`) }}
					style={{ overlay: { "background": "#000000a3" }, content: { "width": "80%", "height": "85vh", "margin": "0 auto" } }}
				>
					<div className="modalControllArea">
						<div onClick={() => { this.props.history.push(`../${this.memberGUID}`) }}>
							<i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
						</div>
					</div>
					{this.state.lessonPlanContent}
				</ReactModal> */}
				<StyledDialog
					maxWidth={false}
					disableBackdropClick={true}
					fullWidth={true}
					open={this.state.openModal}
					onClose={() => { this.props.history.push(`../${this.memberGUID}`) }}
				>
					<DialogTitle>
						<div className="modalControllArea">
							<div onClick={() => { this.props.history.push(`../${this.memberGUID}`) }}>
								<i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
							</div>
						</div>
					</DialogTitle>
					<DialogContent dividers>
						{this.state.lessonPlanContent}
					</DialogContent>
				</StyledDialog>
			</div>
		);
	}
}

export default withRouter(MemberLessonPlans);