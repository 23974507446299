import React, { Component } from 'react'
import axios from 'axios';
import Loader from 'react-loader-spinner'
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import ShareDetail from './ShareDetail'
import { DataGrid } from '@material-ui/data-grid';
import moment from 'moment';
import { Dialog, DialogTitle, DialogContent, withStyles, Tooltip } from '@material-ui/core';

import './Share.less'

class Share extends Component {

    constructor(props) {
        super(props)

        this.state = {
            files: [],
            passedShares: [],
            personalShares: [],
            saveLoading: false
        }
    }

    componentDidMount() {
        // this.getFiles();
        this.initShares();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.initShares();
        }
    }

    initShares = async () => {
        // let userShares = await this.getSharesAction().then(datas => {
        //     datas = datas.map(data => { data["id"] = data["_id"]; return data })
        //     this.setState({ personalShares: datas })
        // })

        let userShares = await this.getSharesAction();
        let passedShares = await this.getSharesPassedAction();
        userShares = userShares.map(data => { data["id"] = data["_id"]; return data })
        passedShares = passedShares.map(data => { data["id"] = data["_id"]; return data })
        this.setState({
            personalShares: userShares,
            passedShares: passedShares
        })
    }

    getSharesAction = () => {
        return new Promise(resolve => {
            axios.get(`${window.CONFS.apiServer}/shares`).then(res => {
                resolve(res.data.data)
            }).catch(function (error) {
                console.log('fail post =>', error)
                // window.alert("Server Error")
            });
        })
    }

    getSharesPassedAction = () => {
        return new Promise(resolve => {
            axios.get(`${window.CONFS.apiServer}/shares/passed`).then(res => {
                resolve(res.data.data)
            }).catch(function (error) {
                console.log('fail post =>', error)
                // window.alert("Server Error")
            });
        })
    }

    deleteShare = (event) => {
        let shareId = event.target.getAttribute('share-id')
        let shareTitle = event.target.getAttribute('share-title')
        if (!window.confirm(`確定要刪除「${shareTitle}」 ?`)) {
            return false;
        }
        this.deleteSharesAction(shareId).then(() => {
            window.alert("刪除成功")
            this.initShares()
        })
    }

    deleteSharesAction = (shareId) => {
        return new Promise(resolve => {
            axios.delete(`${window.CONFS.apiServer}/share/${shareId}`).then(res => {
                resolve(res.data.data)
            }).catch(function (error) {
                console.log('fail post =>', error)
                // window.alert("Server Error")
            });
        })
    }

    updateShare = (event) => {
        let shareId = event.target.getAttribute('share-id')
        this.props.history.push(`./share/${shareId}/edit`)
    }

    verifyShare = (event) => {
        let shareId = event.target.getAttribute('share-id')
        let shareTitle = event.target.getAttribute('share-title')
        let shareStatus = parseInt(event.target.getAttribute('share-status'))
        console.log({ shareStatus })
        let checkText = shareStatus === 2 ? "確定要審核" : "確定要取消審核"
        if (!window.confirm(`${checkText} 「${shareTitle}」?`)) {
            return false;
        }
        this.verifyShareAction(shareId, shareStatus).then(() => {
            window.alert("設定成功")
            this.initShares()
        })
    }

    verifyShareAction = (shareId, status) => {
        return new Promise(resolve => {
            axios.patch(`${window.CONFS.apiServer}/share/${shareId}/verify`, {
                status: status
            }).then(res => {
                resolve(res)
            }).catch(function (error) {
                console.log('fail post =>', error)
                // window.alert("Server Error")
            });
        })
    }

    viewShare = (event) => {
        let shareId = event.target.getAttribute('share-id')
        this.props.history.push(`./share/${shareId}/view`)
    }

    generateRejectHistoryCount = (rejectHistory) => {
        if (rejectHistory) {
            rejectHistory = rejectHistory.sort((a, b) => moment(b.time) - moment(a.time))
            let titleContent = rejectHistory.map(reject => { return `時間:${reject.applyDate}->${reject.time}, 退回原因:${reject.reason}` })
            return <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{titleContent.join("\n")}</div>} ><div className="reject-block">{rejectHistory.length}</div></Tooltip>
        }
    }

    // getFiles = () => {
    //     axios.get(`${window.CONFS.apiServer}/storage/files`)
    //         .then(res => {
    //             console.log('storage files', res.data);


    //             let data = res.data.data ? res.data.data : []

    //             if (res.data.data) {
    //                 this.setState({ files: data });
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("fail get", error);
    //             window.alert("伺服器異常");
    //         });
    // }

    // handleUploadChange = (e) => {

    //     const { files = [] } = this.state
    //     const data = new FormData()

    //     data.append("file[]", e.target.files[0]);
    //     e.target.value = ""
    //     axios.post(`${window.CONFS.apiServer}/sys/uploadFiles`, data)
    //         .then(res => {
    //             console.log('share uploadFiles res =>', res);
    //             let name = res.data.data[0].fileName;
    //             let uuid = res.data.data[0].uuidName;
    //             let id = res.data.data[0]._id;

    //             let f = {
    //                 id: id,
    //                 fileName: name,
    //                 filePath: uuid
    //             }
    //             files.push(f);
    //             if (res.data.data) {
    //                 this.setState({ files: files });
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("fail get", error);
    //             window.alert("伺服器異常");
    //         });
    // }

    // updateFiles = () => {

    //     const { files = [] } = this.state;
    //     let f = files.filter(f => !f._id)

    //     this.setState({ saveLoading: true })
    //     axios.post(`${window.CONFS.apiServer}/storage/files`,
    //         f)
    //         .then(res => {
    //             console.log(' updateFiles res =>', res.data);
    //             if (res.data.errorCode === 0) {
    //                 this.getFiles();
    //                 this.setState({ saveLoading: false })
    //                 window.alert("儲存成功");
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("fail get", error);
    //             window.alert("伺服器異常");
    //         });
    // }

    // deleteFiles = (id, k) => {
    //     const { files = [] } = this.state;

    //     if (id) {
    //         let f = [{ id: id }];
    //         console.log('files', files)
    //         axios.post(`${window.CONFS.apiServer}/storage/files`, f)
    //             .then(res => {
    //                 let list = files.filter(file => file._id !== id)

    //                 if (res.data && res.data.errorCode === 0) {
    //                     this.setState({ files: list });
    //                 }
    //             })
    //             .catch(function (error) {
    //                 console.log("fail get", error);
    //                 window.alert("伺服器異常");
    //             });
    //     } else if (k) {
    //         if (files[k]) {
    //             files.splice(k, 1);
    //             this.setState({ files: files });
    //         }
    //     }
    // }

    render() {

        const { t } = this.props;
        const { files = [] } = this.state;
        return (
            <div className="shareContent">
                <div className="shareContent_title">{t('share.title')}</div>
                <div className="shareContent__controlArea">
                    <button onClick={() => { this.props.history.push('./share/new') }}>建立教案經驗分享</button>
                </div>
                {this.state.passedShares.length > 0 && <div className={`shareContent_section ${this.props.match.params.id && "hide"}`}>
                    <div className="title">教學經驗分享</div>
                    <div className='content'>
                        <DataGrid
                            rowsPerPageOptions={[10]}
                            scrollbarSize={30}
                            hideFooterSelectedRowCount
                            rows={this.state.passedShares}
                            columns={[
                                {
                                    field: '_id', headerName: ' ', width: 120, renderCell: (params) => (<>
                                        {[1, 3].includes(params.getValue('status')) ? <button share-id={params.getValue('id')} className="update-btn" onClick={this.updateShare}>更新</button> :
                                            <button share-id={params.getValue('id')} className="info-btn" onClick={this.viewShare}>檢視</button>}
                                        {params.getValue('status') != 4 && <button share-id={params.getValue('id')} share-title={params.getValue('title')} className="delete-btn" onClick={this.deleteShare}>刪除</button>}
                                    </>
                                    )
                                },
                                { field: 'createdAt', headerName: '分享時間', width: 200 },
                                { field: 'title', headerName: '標題', minWidth: 150 }
                            ]} />
                    </div>
                </div>}
                {this.state.personalShares.length > 0 && <div className={`shareContent_section ${this.props.match.params.id && "hide"}`}>
                    <div className="title">個人教學經驗分享紀錄</div>
                    <div className='content'>
                        <DataGrid
                            rowsPerPageOptions={[10]}
                            scrollbarSize={30}
                            hideFooterSelectedRowCount
                            rows={this.state.personalShares}
                            columns={[
                                {
                                    field: '_id', headerName: ' ', width: 120, renderCell: (params) => (<>
                                        {[1, 3].includes(params.getValue('status')) ? <button share-id={params.getValue('id')} className="update-btn" onClick={this.updateShare}>更新</button> :
                                            <button share-id={params.getValue('id')} className="info-btn" onClick={this.viewShare}>檢視</button>}
                                        {![4,5].includes(params.getValue('status')) && <button share-id={params.getValue('id')} share-title={params.getValue('title')} className="delete-btn" onClick={this.deleteShare}>刪除</button>}
                                    </>
                                    )
                                }, {
                                    field: '', headerName: '狀態', width: 200, renderCell: (params) => (<>
                                        {params.getValue('status') === 1 && <button share-id={params.getValue('id')} share-title={params.getValue('title')} share-status="2" className="success-btn" onClick={this.verifyShare}>審核</button>}
                                        {params.getValue('status') === 2 && <button share-id={params.getValue('id')} share-title={params.getValue('title')} share-status="1" className="cancel-btn" onClick={this.verifyShare}>取消審核</button>}
                                        {params.getValue('status') === 3 &&
                                            <div className="control-area">
                                                <button share-id={params.getValue('id')} share-title={params.getValue('title')} share-status="2" className="warn-btn" onClick={this.verifyShare}>再審核</button>
                                                {this.generateRejectHistoryCount(params.getValue('rejectHistory'))}
                                            </div>
                                        }
                                        {params.getValue('status')===4 && <div>{`已通過 ${params.getValue('approvedDate')}`}</div>}
                                        {params.getValue('status')===5 && <div>{`未公開 ${params.getValue('approvedDate')}`}</div>}
                                    </>
                                    )
                                },
                                { field: 'createdAt', headerName: '分享時間', width: 200 },
                                { field: 'title', headerName: '標題', minWidth: 150 }
                            ]} />
                    </div>
                </div>}
                {<ShareDetail mode={this.props.match.params.id} method={this.props.match.params.method} />}
                {/* <div className="shareContent__controlArea">
                    <label className="uploadLabel">
                        {t('share.uploadLabel')}
                        <input type="file" name="files" onChange={this.handleUploadChange}></input>
                    </label>
                    {this.state.saveLoading ?
                        <Loader
                            type="Oval"
                            color="#7f1083"
                            height={22}
                            width={22}
                            visible={true}
                        />
                        : <button onClick={this.updateFiles} className="sendFiles">{t('share.save')}</button>
                    }
                </div> */}
                {/* <div className="showList">
                    {
                        files.map((f, k) =>

                            <div key={k} className="fileBox" style={{ "backgroundImage": `url('${window.CONFS.storageUrl + "/" + f.filePath}')` }}>
                                <div className="fileBox__file__removeBtn">
                                    <div onClick={() => this.deleteFiles(f._id, k)}>
                                        <i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
                                    </div>
                                    <div onClick={() => {
                                        window.open(`${window.CONFS.storageUrl + "/" + f.filePath}`)
                                    }}>
                                        <i className="fas fa-arrow-circle-down" style={{ "fontSize": "25px" }}></i>
                                    </div>
                                </div>
                                <div className="fileBox__fileName">{f.fileName}</div>
                            </div>
                        )
                    }
                </div> */}
            </div>
        )
    }

}

export default withTranslation()(withRouter(Share));