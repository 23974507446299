import React, { Component, createRef } from 'react'
import axios from "axios"
// import { Player } from 'video-react';
import LocalStorage from "../../../Helpers/LocalStorage";
import { withRouter } from "react-router-dom";
// import Upload from '../../../Helpers/Upload'
import './StudentModal.less'
import "../../../../node_modules/video-react/dist/video-react.css";

import { withTranslation } from 'react-i18next';
// import { Document, Page , pdfjs} from 'react-pdf';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Player } from 'video-react';



class StudentModal extends Component {
    constructor(props) {
        super(props);

        const { currentStudentPlan = {}, approveStep2, approveStep3, checkOwner } = this.props;
        // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;        
        let editorModel = false;
        if (approveStep2 || approveStep3 || checkOwner) {

            editorModel = (approveStep2 !== true && approveStep3 !== true && checkOwner)
        } else {
            editorModel = true;
        }

        console.log('editorModel', editorModel)
        this.state = {

            numPages: null,
            pageNumber: 1,

            id: currentStudentPlan._id || null,
            name: currentStudentPlan.name || '',
            plans: currentStudentPlan.plans || [],
            // "path": "plans",
            // "fileName": "plans",
            // "description": "plans"
            videos: currentStudentPlan.videos || [],
            photos: currentStudentPlan.photos || [],
            files: currentStudentPlan.files || [],
            ownerTeacher: currentStudentPlan.ownerTeacher,
            editorModel: editorModel
        }

        this.modal = createRef()
        // this.delta = this.getPlans.bind(this);
    }

    onChangeHandler = (e, targetType) => {

        console.log('upload file start =>', targetType);
        let token = this.getAuthorize();

        const data = new FormData();

        let type = ''

        switch (targetType) {
            case 'plans':
                type = 'isPlans'
                break;
            case 'videos':
                type = 'isVideos'
                break;
            case 'photos':
                type = 'isPhotos'
                break;
            case 'files':
                type = 'isFiles'
                break;
            default:
                type = '';
        }

        let app = this

        var cancelToken = axios.CancelToken;
        app.source = cancelToken.source();

        const config = {
            cancelToken: app.source.token,
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                app.setState({ uploadPercent: percentCompleted })

                switch (targetType) {
                    case 'plans':
                        app.setState({ planPercent: percentCompleted })
                        break;
                    case 'videos':
                        app.setState({ videoPercent: percentCompleted })
                        break;
                    case 'photos':
                        app.setState({ photoPercent: percentCompleted })
                        break;
                    case 'files':
                        app.setState({ filePercent: percentCompleted })
                        break;
                    default:
                        app.setState({ uploadPercent: percentCompleted })
                }

            },
            headers: { "X-Authorize": token }
        }

        data.append("file[]", e.target.files[0])

        this.setState({ [type]: true });
        axios.post(`${window.CONFS.apiServer}/sys/uploadFiles`, data, config)
            .then(res => {
                console.log('upload student file  res =>', res);
                let name = res.data.data[0].fileName;
                let uuid = res.data.data[0].uuidName;
                // let fileObj = { fileName: name, path: uuid };

                if (uuid) {
                    let ls = this.state[targetType] || [];

                    let obj = {
                        path: uuid,
                        fileName: name,
                        description: ''
                    }

                    ls.push(obj);

                    this.setState({ [targetType]: ls, uploadPercent: 0, [type]: false });

                } else {
                    window.alert('UPLOAD FAIL PLEASE RETRY')
                    this.setState({ uploadPercent: '', [type]: true })
                }


            }).catch(function (error) {
                console.log("fail post", error);
            })
    }

    deleteTargetArrayElement = (index, targetType) => {

        const { editorModel } = this.state
        let ls = this.state[targetType] || [];

        let type = ''

        switch (targetType) {
            case 'plans':
                type = 'plans'
                break;
            case 'videos':
                type = 'video'
                break;
            case 'photos':
                type = 'photo'
                break;
            case 'files':
                type = 'otherFIle'
                break;
            default:
                type = '';
        }

        document.getElementById(type).value = null;

        if (ls[index]) {
            ls.splice(index, 1);
        }
        if (editorModel) {
            this.setState({ [targetType]: ls });
        }
    }

    openNewWindows = (url) => {
        window.open(window.CONFS.storageUrl + "/" + url)
    }

    handleChange = event => {
        console.log('handleChange', event.target.name, event.target.value)
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    getAuthorize = () => {
        let nthusteam = LocalStorage.getLocalStorage("nthusteam")
        if (nthusteam != null && nthusteam.apiToken != null) {
            return nthusteam.apiToken;
        } else {
            // window.location = '/signin';
            this.props.history.push('/signin');
        }
    }

    closeModal = (e) => {

        const { isPlans, isVideos, isPhotos, isFiles } = this.state
        if (isPlans || isVideos || isPhotos || isFiles) {
            // window.alert("正在上傳中 , 請勿關閉");
            console.log("正在上傳中 , 請勿關閉");
        }
        else if (e.target === this.modal.current) {
            this.props.closeModal();
        }
    }

    saveStudent = () => {
        const { currentStudentPlan = {}, targetKey, teachers } = this.props
        const { id, name, plans, videos, photos, files, ownerTeacher, isPlans, isVideos, isPhotos, isFiles } = this.state
        let s = {
            id: id || currentStudentPlan.id,
            name: name || currentStudentPlan.name,
            plans: plans || currentStudentPlan.plans,
            videos: videos || currentStudentPlan.videos,
            photos: photos || currentStudentPlan.photos,
            files: files || currentStudentPlan.files
        }

        if (teachers) {
            s.ownerTeacher = ownerTeacher ? ownerTeacher : '';
        }
        console.log('saveStudent', s)

        if (isPlans || isVideos || isPhotos || isFiles) {
            window.alert("正在上傳中 , 無法儲存");
            // console.log("正在上傳中 , 無法儲存");
        } else {
            this.props.updateStudents(targetKey, s)
            this.props.closeModal();
        }

        // testCallBack('test');
        // this.props.history.push('/management/lessonPlans')
    }

    deleteStudent = () => {
        const { targetKey } = this.props
        this.props.removeStudent(targetKey)
    }

    checkConfirm = () => {
        // <button onClick={this.deleteStudent}>{t('step3.delete')}</button>
        const { t } = this.props
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui alertButtonBox'>
                        <h1>確定刪除此學生嗎？</h1>
                        {/* <p>You want to delete this file?</p> */}
                        <button onClick={onClose}>取消</button>
                        <button
                            onClick={() => {
                                this.deleteStudent();
                                onClose();
                            }}
                        >
                            {t('step3.delete')}
                        </button>
                    </div>
                );
            }
        });
    };


    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    render() {
        const { name = '', plans, videos, photos, files, ownerTeacher, isPlans, isVideos, isPhotos, isFiles, editorModel } = this.state
        const { teachers, t } = this.props

        return (
            <div id="myModal" className="modal" onClick={this.closeModal} ref={this.modal}>
                <div className="modal-content">
                    <div>
                        <table border="1" className="studentTable">
                            <tbody>
                                {
                                    teachers &&
                                    <tr>

                                        <td className="title"><div >{t('step3.teacher')}</div></td>
                                        <td>
                                            <select onChange={this.handleChange} disabled={this.props.loginRole === "admin"} name="ownerTeacher" className="ownerTeacher" defaultValue={ownerTeacher || ''}>
                                                <option></option>
                                                {
                                                    teachers &&
                                                    teachers.map((t, k) =>
                                                        <option key={k} value={t.GUID}>{t.firstName && t.firstName} {'  '} {t.lastName && t.lastName}</option>
                                                    )
                                                }
                                            </select>
                                        </td>
                                    </tr>
                                }

                                <tr>
                                    <td className="title"><div >{t('step3.name')}</div></td>
                                    <td>
                                        <input name="name" className="studentName" value={name} onChange={this.handleChange}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="title"><div >{t('step3.plan')}</div></td>
                                    <td>
                                        <div className="fileNameBox">

                                            {
                                                editorModel && this.props.loginRole != "admin"
                                                    ? <div><label className="uploadLabel">
                                                        <i className="fas fa-plus-circle"></i>
                                                        <input type="file" className="uploadInput" name="plans" id="plans" onChange={e => this.onChangeHandler(e, 'plans')} />
                                                    </label></div>
                                                    : <div />
                                            }
                                            {
                                                plans &&
                                                plans.map((p, k) =>
                                                    <div className="fileName" key={k} >
                                                        <div className="fileNameHeader"
                                                            onClick={e => this.deleteTargetArrayElement(k, 'plans')}>
                                                            <i className="fas fa-times" />
                                                            {p.fileName}
                                                        </div>
                                                        <div onClick={e => this.openNewWindows(p.path)} className="open_pdf">
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="UploadFile__content_setting_preview">
                                            {isPlans && this.state.planPercent > 0 &&
                                                <div className="percentNumber">{this.state.planPercent} %</div>
                                            }

                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="title"><div >{t('step3.videos')}</div></td>
                                    <td>
                                        <div className="fileNameBox">
                                            {
                                                editorModel && this.props.loginRole != "admin"
                                                    ? <div>
                                                        <label className="uploadLabel">
                                                            <i className="fas fa-plus-circle"></i>
                                                            <input type="file" className="uploadInput"
                                                                name="video" id="video" onChange={e => this.onChangeHandler(e, 'videos')} />
                                                        </label></div>
                                                    : <div />
                                            }
                                            {
                                                videos &&
                                                videos.map((p, k) =>
                                                    <div className="fileName video_block" key={k} >
                                                        <div className="fileNameHeader"
                                                            onClick={e => this.deleteTargetArrayElement(k, 'videos')}>
                                                            <i className="fas fa-times" />
                                                            <a href={window.CONFS.storageUrl + "/" + p.path} target="_blank">{p.fileName}</a>
                                                        </div>

                                                        {/* <div onClick={e => this.openNewWindows(p.path)}
                                                            className="open_video">
                                                        </div> */}
                                                        <Player
                                                            playsInline
                                                            className="video"
                                                            poster={window.CONFS.storageUrl + "/" + p.path}
                                                            src={window.CONFS.storageUrl + "/" + p.path}
                                                        />
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className="UploadFile__content_setting_preview">
                                            {isVideos && this.state.videoPercent > 0 &&
                                                <div className="percentNumber">{this.state.videoPercent} %</div>
                                            }


                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><div className="title">{t('step3.photos')}</div></td>
                                    <td>
                                        <div className="fileNameBox">
                                            {
                                                editorModel && this.props.loginRole != "admin"
                                                    ? <div>
                                                        <label className="uploadLabel"><i className="fas fa-plus-circle"></i>
                                                            <input type="file" className="uploadInput" name="photo" id="photo" onChange={e => this.onChangeHandler(e, 'photos')} />
                                                        </label></div>
                                                    : <div />
                                            }
                                            {
                                                photos &&
                                                photos.map((p, k) =>
                                                    <div className="fileName" key={k} >
                                                        <div className="fileNameHeader" onClick={e => this.deleteTargetArrayElement(k, 'photos')}>
                                                            <i className="fas fa-times" />
                                                            {p.fileName}
                                                        </div>
                                                        <div className="fileNameContent"
                                                            onClick={e => this.openNewWindows(p.path)}
                                                            style={{ "backgroundImage": "url(" + window.CONFS.storageUrl + "/" + p.path + ")" }}>


                                                        </div>
                                                    </div>
                                                    // <div className="fileName" key={k}>
                                                    //     <button className="remove" key={k} onClick={e => this.deleteTargetArrayElement(k, 'photos')}><i className="fas fa-times" /> {'  '}{p.fileName}</button>
                                                    // </div>
                                                )
                                            }
                                        </div>

                                        <div className="UploadFile__content_setting_preview">
                                            {isPhotos && this.state.photoPercent > 0 &&
                                                <div className="percentNumber">{this.state.photoPercent} %</div>
                                            }

                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><div className="title">{t('step3.files')}</div></td>
                                    <td>
                                        <div className="fileNameBox">
                                            {
                                                editorModel && this.props.loginRole != "admin"
                                                    ? <div>
                                                        <label className="uploadLabel">
                                                            <i className="fas fa-plus-circle"></i>
                                                            <input type="file" className="uploadInput"
                                                                name="otherFIle" id="otherFIle"
                                                                onChange={e => this.onChangeHandler(e, 'files')} />
                                                        </label>
                                                    </div>
                                                    : <div />
                                            }
                                            {
                                                files &&
                                                files.map((p, k) =>
                                                    <div className="fileName" key={k}>
                                                        <div className="fileNameHeader" onClick={e => this.deleteTargetArrayElement(k, 'files')}>
                                                            <i className="fas fa-times" />
                                                            {p.fileName}
                                                        </div>
                                                        <div className="fileNameContent"
                                                            onClick={e => this.openNewWindows(p.path)}
                                                            style={{ "backgroundImage": "url(" + window.CONFS.storageUrl + "/" + p.path + ")" }}></div>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className="UploadFile__content_setting_preview">
                                            {isFiles && this.state.filePercent > 0 &&
                                                <div className="percentNumber">{this.state.filePercent} %</div>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {
                        editorModel && this.props.loginRole != "admin"
                            ? <div className="actionButtonBox">
                                <button onClick={this.checkConfirm}>{t('step3.delete')}</button>
                                {/* <button onClick={this.deleteStudent}>{t('step3.delete')}</button> */}
                                <button onClick={this.saveStudent}>{t('step3.save')}</button>
                            </div>
                            : <div />
                    }
                </div>
            </div>

        )
    }
}

export default withTranslation()(withRouter(StudentModal));