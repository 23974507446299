import React, { Component } from 'react';
import axios from 'axios';

import './PlanBasic.less'
import Moment from 'react-moment'
import ReactModal from 'react-modal'
import LocalStorage from '../../../Helpers/LocalStorage'
import TeacherAbilityPage from "../TeacherAbilityPage";
import TeacherAbilityDisplay from "../TeacherAbilityDisplay";
import { withTranslation } from 'react-i18next';

class PlanBasic extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedKeywords: [],
            result: { name: '' },
            isOpenTeacherAbility: false,
            teacherAbilityPage: null,
            isChangeAbilityContent: false
        }

        this.newKeyword = React.createRef();
    }

    getName() {

        let name;
        const { fullTeachers, creator } = this.props;

        if (fullTeachers && creator) {
            let teacher = fullTeachers.filter(t => t.GUID === creator)
            if (teacher.length > 0) {
                if (teacher[0].firstName) {
                    name = teacher[0].firstName
                }

                if (teacher[0].lastName) {
                    name += teacher[0].lastName
                }
            }
        }

        if (!name) {
            let nthusteam = LocalStorage.getLocalStorage("nthusteam");
            name = (nthusteam) ? nthusteam.name : null;
        }

        return name;
    }

    handleSysKeywordBtnClick(keyword) {
        const { keywords = [], checkOwner } = this.props;
        if (checkOwner) {
            let keys = [];
            if (keywords.includes(keyword)) {
                keys = keywords.filter((key) => key !== keyword)
            } else {
                keys = [...keywords, keyword];
            };

            this.props.setAllState("keywords", keys);
        }

    }

    getSysKeyWordsBtn = () => {
        const { planSetting = {} } = this.props;
        const { keywords = [] } = this.props;

        let temp = <div></div>
        if (planSetting && planSetting.sysKeyWords) {
            temp = planSetting.sysKeyWords.map((c, idx) => {
                const changed = keywords.indexOf(c.keyword) !== -1
                const keywordBtnClass = `keywordBtn ${changed ? 'changed' : ''}`;
                return <button className={keywordBtnClass} keyword-id={c._id} key={`keywordBtn ${idx}`} onClick={() => this.handleSysKeywordBtnClick(c.keyword)}>{c.keyword}</button>
            });
        }

        return temp
    }

    getSelectedKeyword = () => {
        const { keywords = [] } = this.props;
        let selectedItems = keywords.map((c, idx) => {
            return <button className="selfKeyWord" keyword-id={c._id} key={`keywordBtn ${idx}`} onClick={() => this.handleSysKeywordBtnClick(c)}>{c}</button>
        });

        return selectedItems
    }

    addNewKeyword = e => {

        let keyword = e.current.value.trim()
        if (keyword !== "") {

            const { keywords = [] } = this.props;
            let keys = [];
            if (!keywords.includes(keyword)) {
                keys = [...keywords, keyword];
                // this.setState({ selectedKeywords: keywords });
                this.props.setAllState("keywords", keys);
                e.current.value = ""
            };
        }
    };

    search() {
        let url = document.getElementById("userSearchInputField").value.trim();
        let encodeUrl = `${window.CONFS.apiServer}/member/search?email=${encodeURIComponent(url)}`;
        axios.get(encodeUrl)
            .then(res => {
                let result = {}
                if (res.data.data) {
                    result = res.data.data
                    result.found = true
                    result.name = res.data.data.firstName + ' ' + res.data.data.lastName
                    this.setState({ result: res.data.data })
                }
                else {
                    result.found = false
                    result.name = "Not found"
                    this.setState({ result: result })
                }
            })
            .catch(function (error) {
                console.log('fail post', error)
                window.alert("伺服器異常")
            });
    }

    handleSubmitSearch = (e) => {
        e.preventDefault();
        this.search()
    }

    getAuthorize() {
        let nthusteam = LocalStorage.getLocalStorage("nthusteam")
        if (nthusteam != null && nthusteam.apiToken != null) {
            return nthusteam.apiToken;
        } else {
            this.props.history.push('/signin');
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.props.setAllState(event.target.name, event.target.value)
    }

    searchResult() {
        if (this.state && this.state.result)
            return (this.state.result.found)
        else
            return false
    }

    getGradeName = () => {
        const { planSetting = {}, grade } = this.props;

        let result = ''
        let list = []
        if (planSetting.grade) {
            list = planSetting.grade.filter(element => element._id && element._id === grade);
        }

        if (list[0])
            result = list[0].gradeName

        return result;
    }

    pushTeachersName = () => {

        let teacherList = this.props.teachers || [];
        let list = teacherList.map(s => s.GUID)

        if (!list.includes(this.state.result.GUID)) {
            teacherList.push(this.state.result)
        }

        this.props.setTeachers(teacherList);
    }

    removeTeacher = (e) => {

        let teacherList = this.props.teachers || [];
        console.log('remove teacher', teacherList)

        let t = teacherList.filter((t) => t.GUID !== e.GUID)
        this.props.setTeachers(t);
    }

    openTeacherAbilityPointersPage = () => {
        this.setState({
            isOpenTeacherAbility: true,
            teacherAbilityPage: <TeacherAbilityPage
                teacherAbilityPointers={this.props.planSetting.teacherAbilityPointers}
                currentPosition="position1"
                teacherAbility={this.props.teacherAbility && this.props.teacherAbility.position1 ? this.props.teacherAbility.position1 : {}}
                setTeacherAbility={this.props.setTeacherAbility}
                closeTeacherAbilityPointersPage={this.closeTeacherAbilityPointersPage}
                isChangeAbilityContent = {this.isChangeAbilityContent}
            />
        })
    }

    closeTeacherAbilityPointersPage = () => {
        this.setState({ isOpenTeacherAbility: false })
    }

    isChangeAbilityContent = () =>{
        this.setState({ isChangeAbilityContent: true })
    }

    render() {
        const { planSetting = {}, title, teachers, academicYear, grade, description, checkOwner, t } = this.props;
        return (
            <div className="planBasicContent">
                <h3>{t('step1.basic')}
                    {this.props.loginRole === "admin" ?
                        <div className="stepStatusArea">
                            {this.props.approveStep1 === true ?
                                <div className="approvedLessonPlanStatus">{t('step1.pass')} (<Moment format="YYYY/MM/DD HH:mm">{this.props.approveStep1At}</Moment>)</div> :
                                <button className="submitApproveLessonPlanBtn" onClick={() => this.props.approveLessonPlanStep(1)}>{t('step1.classPass')}</button>
                            }
                        </div> :
                        <div className="stepStatusArea">
                            {this.props.approveStep1 === true &&
                                <div className="approvedLessonPlanStatus">{t('step1.pass')} (<Moment format="YYYY/MM/DD HH:mm">{this.props.approveStep1At}</Moment>)</div>
                            }
                        </div>
                    }
                </h3>
                <div>
                    <table broder="1" >
                        <tbody>
                            <tr>
                                <td className="basicLable"><span className="notice">*</span>{t('step1.creator')} </td>
                                <td><div className="planCreater">{this.getName()}</div></td>
                            </tr>
                            <tr>
                                <td className="basicLable">{t('step1.design')}</td>
                                {
                                    checkOwner
                                        ? <td>
                                            <div className="creater">
                                                <i className="fas fa-search"></i>
                                                <input className="search" id="userSearchInputField" placeholder="請輸入共同課程設計者信箱" name="search" type="email"></input>
                                                <button className="searchBtn" onClick={this.handleSubmitSearch}>{t('step1.emailSearch')}</button>

                                                {
                                                    this.searchResult() ?
                                                        <button className="waitAdd" onClick={this.pushTeachersName}>
                                                            <i className="fas fa-plus"></i>&nbsp;&nbsp;
                                                {this.searchResult()
                                                                ? this.state.result.name : null}</button>
                                                        : <span>{this.state.result.found && this.state.result.name}</span>
                                                }
                                            </div>
                                            {
                                                teachers && teachers.map((c, k) =>
                                                    <button className="deleteMember" key={k} onClick={() => this.removeTeacher(c)} >
                                                        <i className="fas fa-times"></i>  {c.firstName || ''} {' '}  {c.lastName || ''}
                                                    </button>)
                                            }
                                        </td>
                                        : <td>
                                            {
                                                teachers && teachers.map((c, k) =>
                                                    <button className="deleteMember" key={k}  >
                                                        {c.firstName || ''} {' '}  {c.lastName || ''}
                                                    </button>)
                                            }
                                        </td>

                                }
                            </tr>
                            <tr>
                                <td className="basicLable"><span className="notice">*</span>{t('step1.year')}</td>
                                {
                                    checkOwner
                                        ? <td>
                                            <select className="academicYear" name="academicYear" defaultValue={academicYear || ''} onChange={this.handleChange}>
                                                <option></option>
                                                {
                                                    planSetting.academicYear &&
                                                    planSetting.academicYear.map((c, k) => {
                                                        return c.toString() === academicYear
                                                            ? <option value={c} key={k} selected>{c}</option>
                                                            : <option value={c} key={k}>{c}</option>
                                                    })
                                                }
                                            </select>
                                        </td>
                                        : <td><span>{academicYear}</span></td>
                                }
                            </tr>
                            <tr>
                                <td className="basicLable"><span className="notice">*</span>{t('step1.title')}</td>
                                <td>
                                    {
                                        checkOwner
                                            ? <input type="text"
                                                className="title"
                                                name="title"
                                                defaultValue={title || ''}
                                                onChange={this.handleChange}>
                                            </input>
                                            : <div><span>{title}</span></div>
                                    }

                                </td>
                            </tr>
                            <tr>
                                <td className="basicLable"><span className="notice">*</span>{t('step1.classYear')}</td>
                                {
                                    checkOwner
                                        ? <td>
                                            <select className="grade" name="grade" onChange={this.handleChange}>
                                                <option></option>
                                                {
                                                    planSetting.grade &&
                                                    planSetting.grade.map((c, k) => {
                                                        return c._id === grade
                                                            ? <option value={c._id} key={k} selected>{c.gradeName} </option>
                                                            : <option value={c._id} key={k}>{c.gradeName}</option>
                                                    }
                                                    )
                                                }
                                                {/* <option value="幼稚園小班">幼稚園小班</option>
                                        <option value="幼稚園大班">幼稚園大班</option> */}
                                            </select>
                                        </td>
                                        : <td><span>{this.getGradeName()}</span></td>

                                }

                            </tr>
                            <tr>
                                <td className="basicLable">{t('step1.keyword')}</td>
                                {
                                    checkOwner
                                        ? <td>
                                            <div className="keyWordList">
                                                {this.getSysKeyWordsBtn()}
                                            </div>

                                            <div className="keywordBox">
                                                <div>{t('step1.selfKeyword')}</div>
                                                <input className="keywordInput" ref={this.newKeyword}></input>
                                                <button type="button" className="keywordBtn" onClick={e => this.addNewKeyword(this.newKeyword)}>{t('step1.add')}</button>
                                            </div>
                                            {this.getSelectedKeyword()}
                                        </td>
                                        : <td>
                                            {this.getSelectedKeyword()}
                                        </td>
                                }

                            </tr>
                            <tr>
                                <td className="basicLable">{t('step1.courseIntroduction')}<br />(限200字)</td>
                                {
                                    checkOwner
                                        ? <td>
                                            <textarea className="description"
                                                name="description"
                                                value={description || ''}
                                                rows="10" cols="30"
                                                onChange={this.handleChange}></textarea>
                                        </td>
                                        :
                                        <td><textarea className="description"
                                            readOnly="readOnly"
                                            name="description"
                                            value={description || ''}
                                            rows="10" cols="30"></textarea>
                                        </td>
                                }
                            </tr>
                            <tr>
                                <td className="basicLable">
                                    <div><span className="notice">*</span>{t('step1.teacherAbilityLabel')}</div>
                                    {this.props.isShowEditBtn && this.props.planSetting && this.props.planSetting.teacherAbilityPointers &&
                                        <div><button className="teacherAbilityManageBtn" onClick={this.openTeacherAbilityPointersPage}>{t('step1.teacherAbilityManageBtn')}</button></div>
                                    }
                                </td>
                                <td>
                                    {this.props.planSetting && <TeacherAbilityDisplay teacherAbilityPointers={this.props.planSetting.teacherAbilityPointers}
                                        currentPosition="position1"
                                        teacherAbility={this.props.teacherAbility && this.props.teacherAbility.position1 ? this.props.teacherAbility.position1 : {}} />}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <ReactModal
                    isOpen={this.state.isOpenTeacherAbility}
                    shouldCloseOnOverlayClick={false}
                    onRequestClose={() => { this.setState({ isOpenTeacherAbility: false }) }}
                    style={{ overlay: { "background": "#000000a3" }, content: { "width": "70%", "height": "85vh", "margin": "0 auto" } }}
                >
                    <div className="modalControllArea">
                        <div onClick={() => {
                            if (this.state.isChangeAbilityContent) {
                                if(!window.confirm("您操作的設定未儲存, 是否確定要關閉?")){
                                    return false
                                }
                            }

                            this.setState({ isOpenTeacherAbility: false, isChangeAbilityContent: false })
                        }}>
                            <i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
                        </div>
                    </div>
                    {this.state.teacherAbilityPage}
                </ReactModal>
            </div>
        )
    }

}

export default withTranslation()(PlanBasic);
// export default PlanBasic;