import React, { Component } from 'react'
import './MemberCertificationDetail.less'
import Loader from 'react-loader-spinner'
import axios from 'axios';
import Moment from 'react-moment'
import ReactModal from 'react-modal'
import MemberLessonPlans from './MemberLessonPlans'
import MemberApproveCertification from './MemberApproveCertification'
import PlanDetail from '../../Management/LessonPlans/PlanDetail'
import { withRouter } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid';
import { Dialog, DialogTitle, DialogContent, withStyles } from '@material-ui/core';

class MemberCertificationDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			certificationNumber: "",
			openLessonPlanModal: false,
			lessonPlanContent: <PlanDetail loginRole={this.props.loginRole} />,
			courses: [],
			lessonPlans: []
		}
	}

	componentDidMount = () => {
		this.searchCertification()
		this.initModalSetting()
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.initModalSetting()
		}
	}

	initModalSetting = () => {
		if (this.props.match.params.id) {
			this.setState({ memberlessonPlans: <PlanDetail loginRole={this.props.loginRole} />, openLessonPlanModal: true })
		}
		else {
			this.setState({ openLessonPlanModal: false })
		}
	}

	searchCertification = () => {
		this.setState({ loading: true }, async () => {
			let certification = await this.searchCertificationAction()
			certification.courses.forEach(course => { course.id = course._id })
			certification.lessonPlans.forEach(lessonPlan => { lessonPlan.id = lessonPlan._id })
			this.setState({
				certificationNumber: certification.certificationNumber,
				loading: false,
				courses: certification.courses,
				lessonPlans: certification.lessonPlans,
			})
		})
	}

	searchCertificationAction = () => {
		return new Promise(resolve => {
			axios.get(window.CONFS.apiServer + '/certification/' + this.props.match.params.actionId)
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res.data.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				});
		})
	}

	render() {
		const StyledDialog = withStyles({
			paper: {
				width: "80%"
			}
		})(Dialog);
		return (
			<div className="MemberCertificationDetail">
				<div className="title">
					<span>憑證編號: {this.state.certificationNumber}</span>
				</div>
				{this.state.loading ? <Loader
					type="Oval"
					color="#7f1083"
					height={22}
					width={22}
					visible={true}
				/> :
					<>
						<div className="MemberCertificationDetail__content">
							<div className="teacher-lessonplans">
								<DataGrid
									rowsPerPageOptions={[10]}
									scrollbarSize={30}
									rows={this.state.lessonPlans}
									columns={[
										{
											field: 'title', headerName: '教案名稱', disableClickEventBubbling: true, flex: 1, renderCell: (params) => (
												<a href onClick={() => { this.props.history.push(`lessonplan/${params.getValue("_id")}/`) }} >{params.value}</a>
											)
										}
									]} />
							</div>
							<div className="teacher-courses">
								<DataGrid
									rowsPerPageOptions={[10]}
									scrollbarSize={30}
									rows={this.state.courses}
									columns={[
										{ field: 'hours', headerName: '時數(hr)', width: 100 },
										{
											field: 'name', headerName: '活動名稱', renderCell: (params) => (
												<a href={params.getValue('url')} style={{ textDecoration: "None" }} target="_blank">{params.value.tw}</a>
											)
										}
									]} />

							</div>
						</div></>}

				<StyledDialog
					maxWidth={false}
					disableBackdropClick={true}
					fullWidth={true}
					open={this.state.openLessonPlanModal}
					onClose={() => { if (window.confirm("確定要關閉編輯?")) { this.props.history.push(`../../`) } }}
				>
					<DialogTitle>
						<div className="modalControllArea">
							<div onClick={() => { if (window.confirm("確定要關閉編輯?")) { this.props.history.push(`../../`) } }}>
								<i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
							</div>
						</div>
					</DialogTitle>
					<DialogContent dividers>
						{this.state.lessonPlanContent}
					</DialogContent>
				</StyledDialog>
			</div>
		);
	}
}

export default withRouter(MemberCertificationDetail);