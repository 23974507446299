import React, { Component } from 'react';
import logo from '../../Assets/Home/logo.png'
import './Banner.less'

class Banner extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    
    let bannerImage = (this.props.hompageSetting)?(this.props.hompageSetting.promotionImage)?window.CONFS.storageUrl + "/" +this.props.hompageSetting.promotionImage: logo:logo    
    return (    
        <div className="banner">
       		<div className="image" style={{"backgroundImage":"url('"+bannerImage+"')"}}>
       		</div>
       		<div className="title">
       			{this.props.hompageSetting.promotionContent.tw}
       		</div>	       
	    </div>
    );
  }
}

export default Banner;