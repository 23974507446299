import React, { Component } from 'react'
import './MemberCertificationsManage.less'
import Loader from 'react-loader-spinner'
import axios from 'axios';
import Moment from 'react-moment'
import ReactModal from 'react-modal'
import MemberLessonPlans from './MemberLessonPlans'
import MemberApproveCertification from './MemberApproveCertification'
import MemberCertifications from './MemberCertifications'
import moment from 'moment'
import MemberInfo from './MemberInfo'
import { withRouter } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid';
import { Dialog, DialogTitle, DialogContent, withStyles, Tooltip } from '@material-ui/core';

class MemberCertificationsManage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			members: [],
			keyword: "",
			loading: false,
			openModal: false,
			openMemberCertificationsModal: false,
			memberlessonPlans: <MemberLessonPlans />,
			openMemberApproveCertification: false,
			memberApproveCertification: <MemberApproveCertification />,
			openMemberCertificationsDetailModal: false,
			memberCertificationsDetailModal: <MemberCertifications />,
			showMemberInfo: false,
			memberInfo: <MemberInfo />,
			btnStatus: {}
		}

		this.columns = [
			{ field: 'account', headerName: '帳號', width: 200, renderCell: this.displayAccountDetail },
			{ field: 'displayName', headerName: '姓名', width: 100, renderCell: this.displayDataGridCell },
			{ field: 'companyName', headerName: '學校', width: 200, renderCell: this.displayDataGridCell },
			// {
			// 	field: 'certifications', headerName: '通過', width: 150, renderCell: (params) => (
			// 		<button onClick={() => this.props.history.push(`/admin/membership/approved_certification/${params.getValue('GUID')}/certifications/`)} className="deatilBtn">查看</button>
			// 	)
			// },
			{
				field: 'certificationNumber', headerName: '證號', width: 210, renderCell: (params) => (
					<>
						{<><button onClick={() => this.rejectCertification(params.getValue('GUID'), params.getValue('certificationNumber'))} className="approveBtn">
							{this.state.btnStatus === "loading" ? "Loading" : "撤回"}
						</button>
							{params.getValue('certificationNumber')}</>
						}
					</>
				)
				// field: 'lessonPlanWaitingForApprove', headerName: '教案', width: 90
				// , renderCell: (params) => (
				// 	<>
				// 		{params.value === true &&
				// 			<button onClick={() => this.props.history.push(`/admin/membership/approved_certification/${params.getValue('GUID')}/`)} className="approveBtn">審核</button>
				// 		}
				// 	</>
				// )
			},
			{
				field: 'lessonPlanName', headerName: '教案名稱', width: 200
				// field: 'certificationWaitingForApprove', headerName: '憑證', width: 90
				// , renderCell: (params) => (
				// 	<>
				// 		{params.value === true &&
				// 			<button onClick={() => this.props.history.push(`/admin/membership/approved_certification/${params.getValue('GUID')}/approve_certification/`)} className="approveBtn">審核</button>
				// 		}
				// 	</>
				// )
			}
		]
	}

	displayAccountDetail = (params) => (
		<Tooltip title={params.value} >
			<span className="table-cell-trucate"><a href="#!" onClick={() => { this.openMemberInfo(params.getValue("GUID")) }} >{params.value}</a></span>
		</Tooltip>
	)

	displayDataGridCell = (params) => (
		<Tooltip title={params.value} >
			<span className="table-cell-trucate">{params.value}</span>
		</Tooltip>
	)

	componentDidMount = () => {
		this.searchMember();
		if (this.props.match.params.GUID) {
			this.initModalSetting()
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.initModalSetting()
		}
	}

	initModalSetting = () => {
		if (this.props.match.params.GUID) {
			if (this.props.location.pathname.indexOf("approve_certification") > -1) {
				this.setState({ memberApproveCertification: <MemberApproveCertification searchMember={this.searchMember} />, openMemberApproveCertification: true })
			}
			else if (this.props.location.pathname.indexOf("certifications") > -1) {
				this.setState({ memberCertificationsDetailModal: <MemberCertifications searchMember={this.searchMember} />, openMemberCertificationsDetailModal: true })
			}
			else {
				this.setState({ memberlessonPlans: <MemberLessonPlans />, openModal: true })
			}
		}
		else {
			this.setState({ openModal: false, openMemberApproveCertification: false, openMemberCertificationsDetailModal: false })
		}
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	rejectCertification = (GUID, name) => {
		if (!window.confirm(`確定要撤銷證書: ${name}?`)) {
			return
		}
		let btnStatus = JSON.parse(JSON.stringify(this.state.btnStatus))
		btnStatus[GUID] = "loading"
		console.log({ btnStatus })
		this.setState({ btnStatus: btnStatus },()=>{
			this.actionRejectCertification(GUID).then(()=>{
				this.searchMember();
			})
		})
	}

	actionRejectCertification = (GUID)=>{
		return new Promise(resolve => {
			axios.post(`${window.CONFS.apiServer}/admin/certificates/reject/${GUID}`,{})
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res.data.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				});
		})
	}

	searchMember = () => {
		this.setState({ "loading": true }, () => {
			this.searchMemberAction().then((members) => {
				console.log({ members: members })
				members.forEach(member => {
					member.id = member.GUID
					member.displayName = `${member.firstName} ${member.lastName}`
				})
				let btnStatus = {}
				members.forEach(() => {
					btnStatus[members.GUID] = "normal";
				})
				this.setState({ members: members, loading: false, btnStatus: btnStatus })
			})
		})
	}

	searchMemberAction = () => {
		return new Promise(resolve => {
			axios.get(window.CONFS.apiServer + '/admin/members/approved_certification',
				{
					params: {
						keyword: encodeURIComponent(this.state.keyword)
					}
				})
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res.data.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				});
		})
	}

	downloadMember = () => {
		this.downloadSearchMemberAction()
	}

	downloadSearchMemberAction = () => {
		axios({
			url: `${window.CONFS.apiServer}/admin/members/approved_certification/download?keyword=${encodeURIComponent(this.state.keyword)}`, //your url
			method: 'GET'
		}).then((response) => {
			let csv = `\ufeff${response.data}`
			const blob = new Blob([csv], { type: 'text/csv,charset=UTF-8' })
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			let today = moment().format("YYYY_MM_DD")
			link.setAttribute('download', `certification_${today}.csv`); //or any other extension
			document.body.appendChild(link);
			link.click();
		});
	}

	updateMemberStatus = (status, GUID) => {
		axios.patch(window.CONFS.apiServer + '/admin/member/active/' + GUID,
			{
				status: status
			})
			.then(res => {
				if (res.data.errorCode > 0) {
					console.log(res)
				}
				else {
					this.searchMember()
				}
			})
			.catch(function (error) {
				console.log('fail post =>', error)
				// window.alert("Server Error")
			});
	}

	checkKeywordClickKey = e => {
		if (e.key.toUpperCase() === "ENTER")
			this.searchMember()
	}

	openMemberInfo = (GUID) => {
		this.setState({
			showMemberInfo: true,
			memberInfo: <MemberInfo GUID={GUID} />
		})
	}

	render() {
		const StyledDialog = withStyles({
			paper: {
				width: "60%"
			}
		})(Dialog);
		return (
			<div className="MemberCertificationsManage">
				<div className="title">
					<span>認證夥伴管理</span>
				</div>
				<div className="MemberCertificationsManage__searchArea">
					<i className="fas fa-search"></i>
					<input type="text" onKeyPress={this.checkKeywordClickKey} onChange={this.handleChange} name="keyword" />
					<button onClick={this.searchMember}>搜尋</button>
					<button onClick={this.downloadMember}>下載</button>
				</div>
				<div className="MemberCertificationsManage__content">
					{this.state.loading ? <Loader
						type="Oval"
						color="#7f1083"
						height={22}
						width={22}
						visible={true}
					/> :
						<DataGrid
							rowsPerPageOptions={[10]}
							scrollbarSize={30}
							hideFooterSelectedRowCount
							showToolbar
							rows={this.state.members}
							columns={this.columns} />}
					{/* <table>
						<thead>
							<tr>
								<td>帳號</td>
								<td style={{"width":"100px"}}>姓名</td>
								<td>最後登入時間</td>
								<td>註冊時間</td>
								<td></td>
							</tr>
						</thead>
						{this.state.loading?
							<tbody>						
								<tr>
									<td className="loading" colSpan="3">
										<Loader
											type="Oval"
											color="#7f1083"
											height={22}
											width={22}
											visible = {true}
										/>
									</td>
								</tr>
							</tbody>:
							<tbody>											
								{this.state.members.map((item, k)=>{
									return (<tr key={k}>
										<td>{item.account}</td>
										<td>{item.firstName+" "+ item.lastName}</td>
										<td>
											{item.lastLoggedInAt&&
												<Moment format="YYYY/MM/DD HH:mm">{item.lastLoggedInAt}</Moment>											
											}											
										</td>
										<td>
											<Moment format="YYYY/MM/DD HH:mm">{item.createdAt}</Moment>
										</td>
										<td>
											{item.status === "active"?
												<button onClick={() => this.updateMemberStatus("inactive", item.GUID)} className="stopMember">停用</button>:
												<button onClick={() => this.updateMemberStatus("active", item.GUID)} className="activeMember">啟用</button>
											}
											{item.waitingForApprove === true&&
												<button onClick={() => this.props.history.push(`/admin/member/${item.GUID}`)} className="approveBtn">審核</button>
											}
										</td>
									</tr>)
								})
								}
							</tbody>
						}						
					</table> */}
				</div>
				{/* <ReactModal
					isOpen={this.state.openModal}
					shouldCloseOnOverlayClick={false}
					onRequestClose={() => { this.props.history.push('../members') }}
					style={{ overlay: { "background": "#000000a3" }, content: { "width": "90%", "height": "85vh", "margin": "0 auto" } }}
				>
					<div className="modalControllArea">
						<div onClick={() => { this.props.history.push('../members') }}>
							<i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
						</div>
					</div>
					{this.state.memberlessonPlans}
				</ReactModal> */}
				<StyledDialog
					maxWidth={false}
					disableBackdropClick={true}
					fullWidth={true}
					open={this.state.openModal}
					onClose={() => { this.props.history.push('../') }}
				>
					<DialogTitle>
						<div className="modalControllArea">
							<div onClick={() => { this.props.history.push('../') }}>
								<i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
							</div>
						</div>
					</DialogTitle>
					<DialogContent dividers>
						{this.state.memberlessonPlans}
					</DialogContent>
				</StyledDialog>
				<StyledDialog
					maxWidth={false}
					disableBackdropClick={true}
					fullWidth={true}
					open={this.state.openMemberApproveCertification}
					onClose={() => { this.props.history.push('../../') }}
				>
					<DialogTitle>
						<div className="modalControllArea">
							<div onClick={() => { this.props.history.push('../../') }}>
								<i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
							</div>
							<div className="title">憑證審核</div>
						</div>
					</DialogTitle>
					<DialogContent dividers>
						{this.state.memberApproveCertification}
					</DialogContent>
				</StyledDialog>
				<StyledDialog
					maxWidth={false}
					disableBackdropClick={true}
					fullWidth={true}
					open={this.state.openMemberCertificationsDetailModal}
					onClose={() => { this.props.history.push('../../') }}
				>
					<DialogTitle>
						<div className="modalControllArea">
							<div onClick={() => { this.props.history.push('../../') }}>
								<i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
							</div>
						</div>
					</DialogTitle>
					<DialogContent dividers>
						{this.state.memberCertificationsDetailModal}
					</DialogContent>
				</StyledDialog>
				<StyledDialog
					maxWidth={false}
					fullWidth={true}
					open={this.state.showMemberInfo}
					onClose={() => { this.setState({ showMemberInfo: false }) }}
				>
					<DialogTitle>
						<div className="modalControllArea">
							<div onClick={() => { this.setState({ showMemberInfo: false }) }}>
								<i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
							</div>
							<div className="title">會員資料</div>
						</div>
					</DialogTitle>
					<DialogContent dividers>
						{this.state.memberInfo}
					</DialogContent>
				</StyledDialog>
			</div>
		);
	}
}

export default withRouter(MemberCertificationsManage);