import React, { Component } from 'react'
import axios from 'axios';
import Loader from 'react-loader-spinner'

import LocalStorage from '../../Helpers/LocalStorage'
import { withRouter, Link } from "react-router-dom"
import qs from 'query-string'
import { withTranslation } from 'react-i18next';

import './SignIn.less'

class SignIn extends Component {


    state = {
        account: '',
        password: '',
        loading: false
    }

    getMemberName = () => {
        return new Promise((resolve) => {
            axios.get(`${window.CONFS.apiServer}/member/my`)
                .then(res => {
                    resolve(res.data.data.firstName + ' ' + res.data.data.lastName)
                })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    login = (e) => {
        let nthusteam = LocalStorage.getLocalStorage("nthusteam")
        this.setState({ loading: true })
        axios.post(`${window.CONFS.apiServer}/member/login`,
            {
                account: e.account,
                password: e.password
            })
            .then(res => {

                console.log('=>', res.data)

                if (res.data && res.data.errorCode === 1001) {
                    this.setState({ loading: false })
                    window.alert("登入失敗 :" + res.data.errorMessage);
                } else {

                    nthusteam = { apiToken: res.data.data.apiToken, GUID: res.data.data.GUID };
                    this.initMemberRequestHeader(nthusteam.apiToken)
                    this.initMemberResponseHandler()
                    this.getMemberName().then((memberName) => {
                        nthusteam.name = memberName
                        LocalStorage.saveLocalStorage("nthusteam", nthusteam);
                        let params = qs.parse(this.props.location.search)
                        let path = "/management/"
                        if (params.redirect) {
                            if (params.redirect === "/management/share/new") {
                                path = "/management/share"
                            }
                            else {
                                path = params.redirect
                            }
                        }
                        this.props.history.push(path);
                    })
                    this.setState({ loading: false })
                }

            })
            .catch(function (error) {
                console.log('fail post =>', error)
                // window.alert("Server Error")
            });

    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.login(this.state)
    }

    initMemberRequestHeader = (apiToken) => {
        axios.interceptors.request.eject(window.CONFS.memberAxiosSetting.requestHeader)
        window.CONFS.memberAxiosSetting.requestHeader = axios.interceptors.request.use(
            config => {
                config.headers["X-Authorize"] = apiToken;
                return config;
            },
            error => Promise.reject(error)
        );
    }

    initMemberResponseHandler = () => {
        axios.interceptors.response.eject(window.CONFS.memberAxiosSetting.responseHandler)
        window.CONFS.memberAxiosSetting.responseHandler = axios.interceptors.response.use(res => {
            if (res.data.errorCode === 1000 || res.data.errorCode === 1011) {
                axios.interceptors.request.eject(window.CONFS.memberAxiosSetting.requestHeader)
                axios.interceptors.response.eject(window.CONFS.memberAxiosSetting.responseHandler)
                console.log("SignIn.js 請重新登入")
                alert("請重新登入");
                this.props.history.push("/signin?redirect=" + window.location.pathname)
            }
            return res;
        }, function (error) {
            return Promise.reject(error);
        });
    }


    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    render() {

        const { t } = this.props;
        return (

            <div className="signInbarBanner">

                {
                    // Object.keys(resources).map((i, key) =>
                    //     <button onClick={() => i18n.changeLanguage(i)} key={key}>{i}</button>)
                }

                <form onSubmit={this.handleSubmit} className="signInForm">
                    <div className="signInForm__title">{t('SignIn.signIn')}</div>
                    <div className="signInForm__content">
                        <div className="inputBox">
                            <label htmlFor="account">{t('SignIn.account')}</label>
                            <input type="email" id="account" onChange={this.handleChange} />
                        </div>
                        <div className="inputBox">
                            <label htmlFor="password">{t('SignIn.password')}</label>
                            <input type="password" id="password" onChange={this.handleChange} />
                        </div>
                        <div className="btnBox">
                            {this.state.loading ?
                                <Loader
                                    type="Oval"
                                    color="#ffffff"
                                    height={22}
                                    width={22}
                                    visible={true}
                                /> :
                                <div className="btnBox">
                                    <button className="btn pink lighten-1 z-depth-0">登入</button>

                                    <Link className="btn forget-password-btn" to="/forgetPassword"><span>忘記密碼</span></Link>
                                </div>
                            }
                        </div>
                    </div>
                </form>

            </div>
        )
    }

}

// export default withRouter(SignIn);
export default withTranslation()(withRouter(SignIn));