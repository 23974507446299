import React, { Component, createRef } from 'react'
import { withRouter } from "react-router-dom";

import axios from "axios";
import LocalStorage from "../../Helpers/LocalStorage";
import './StudentPlan.less'
import StudentModal from './PlanStep3/StudentModal'
import Moment from 'react-moment'

class StudentPlan extends Component {
    constructor(props) {
        super(props);

        const { lessonPlanAchievements = [] } = this.props;

        let achievement = lessonPlanAchievements[0] || {}

        console.log('lessonPlanAchievements', achievement);

        this.state = {
            students: achievement.students || [],
            id: achievement._id || null,
            ownerTeacher: achievement.ownerTeacher || ''
        }

        this.modal = createRef();
        this.closeModal = this.closeModal.bind(this);
        this.updateStudents = this.updateStudents.bind(this);
        this.removeStudent = this.removeStudent.bind(this);

        // this.delta = this.getPlans.bind(this);
    }

    addStudent = () => {
        let newStudent = {
            ownerTeacher: this.props.ownerTeacher || '',
            name: '',
            plans: [],
            videos: [],
            photos: [],
            files: []
        };
        let studentList = this.state.students || [];

        studentList.push(newStudent);
        console.log(this.state);
        this.setState({ students: studentList }, () => {
            this.props.setIsChanged(true)
            this.props.setIsChangedData(true)
        })
    }

    updateStudents = (idx, student) => {
        const { students = [] } = this.state
        students[idx] = student;
        this.setState({ students: students }, () => {
            this.props.setIsChanged(true)
            this.props.setIsChangedData(true)
        });
    }

    removeStudent = (idx) => {
        const { students = [] } = this.state
        if (students.length > idx) {
            students.splice(idx, 1)
        }
        this.setState({ students: students, showModal: false });
    }

    sendAchievements = () => {

        let token = this.getAuthorize();

        const { students = [], id = null } = this.state
        students.forEach(student => {
            if (student._id) {
                student.id = student._id
            }
        })

        let achievements = [{
            lessonPlanId: this.props.planId || '',
            students: students,
            id: id
        }]

        console.log('send achievements =>', achievements)

        axios.post(
            `${window.CONFS.apiServer}/lessonplanachievements`, achievements,
            {
                headers: { "X-Authorize": token }
            }).then(res => {
                console.log(res);
                this.setState({ students: students });
                window.alert("儲存成功");
                // this.props.history.push('/management/lessonPlans');
            })
            .catch(function (error) {
                console.log("fail post");
                console.log(error);
                window.alert("伺服器異常");
            });
    }


    removeAchievements = (targetId) => {
        let token = this.getAuthorize();
        axios.post(
            `${window.CONFS.apiServer}/lessonplanachievements`,
            {
                id: targetId,
                remove: true
            },
            {
                headers: { "X-Authorize": token }
            }).then(res => {
                console.log(res);
                window.location = "/management/lessonPlan/" + res.data.data.id;
                // this.props.history.push("/management");
            })
            .catch(function (error) {
                console.log("fail post");
                console.log(error);
                window.alert("伺服器異常");
            });
    }


    getAuthorize = () => {
        let nthusteam = LocalStorage.getLocalStorage("nthusteam")
        if (nthusteam != null && nthusteam.apiToken != null) {
            return nthusteam.apiToken;
        } else {
            // window.location = '/signin';
            this.props.history.push('/signin');
        }
    }

    openModal = (data, targetKey) => {
        this.setState({
            currentStudentPlan: data,
            showModal: true,
            targetKey: targetKey
        })
    }

    closeModal = (e) => {
        this.setState({ showModal: false });
    }

    getTeacherName = (guid) => {
        const { teachers = [] } = this.props;
        let teacher = teachers ? teachers.filter(t => t.GUID === guid) : {};
        let name = '';
        name = teacher ? teacher.map(t => {
            name = '';
            name = t.firstName + ' ' + t.lastName;
            return name;
        }) : '';

        return name;

    }

    render() {

        // loginRole admin
        const { teachers = [], checkOwner, approveStep3, loginRole, isShowEditBtn } = this.props;
        const { currentStudentPlan = {}, targetKey, students } = this.state

        return (
            <div className="achievementsBody">
                <div className="StudentPlanTitle">課程最終    學生學習成果
                    {this.props.loginRole === "admin" ?
                        <div className="stepStatusArea">
                            {this.props.approveStep3 === true ?
                                <div className="approvedLessonPlanStatus">已通過 (<Moment format="YYYY/MM/DD HH:mm">{this.props.approveStep3At}</Moment>)</div> :
                                <button className="submitApproveLessonPlanBtn" onClick={() => this.props.approveLessonPlanStep(3)}>審核通過</button>
                            }
                        </div> :
                        <div className="stepStatusArea">
                            {this.props.approveStep3 === true &&
                                <div className="approvedLessonPlanStatus">已通過 (<Moment format="YYYY/MM/DD HH:mm">{this.props.approveStep3At}</Moment>)</div>
                            }
                        </div>
                    }
                </div>
                <table className="StudentPlanContent" border="1">
                    <tbody>
                        <tr>
                            <td>
                                {isShowEditBtn ?
                                    <button className="addStudentBtn" onClick={this.addStudent}>新增學生</button>
                                    // : <button className="studentBtn" >新增學生</button>
                                    : <span></span>
                                }

                            </td>
                            <td>教師</td>
                            <td>姓名</td>
                            <td>實作企劃</td>
                            <td>作品影片</td>
                            <td>作品照片</td>
                            <td>其他檔案</td>
                        </tr>
                        {
                            students &&
                            students.map((a, k) =>
                                <tr key={k}>
                                    <td>{isShowEditBtn ? <button onClick={() => this.openModal(a, k)} className="editor">編輯</button>
                                        : <button onClick={() => this.openModal(a, k)} className="editor">查看</button>
                                    }</td>
                                    <td>{a.ownerTeacher && this.getTeacherName(a.ownerTeacher)}</td>
                                    <td>{a.name}</td>
                                    <td>{a.plans && a.plans.length}</td>
                                    <td>{a.videos && a.videos.length}</td>
                                    <td>{a.photos && a.photos.length}</td>
                                    <td>{a.files && a.files.length}</td>
                                </tr>)
                        }
                    </tbody>
                </table>
                {
                    this.state.showModal &&
                    <StudentModal
                        currentStudentPlan={currentStudentPlan}
                        targetKey={targetKey}
                        teachers={teachers}
                        closeModal={this.closeModal}
                        updateStudents={this.updateStudents}
                        removeStudent={this.removeStudent}
                        loginRole={loginRole}
                        approveStep3={approveStep3}
                        checkOwner={checkOwner}
                    />
                }

                {isShowEditBtn && 
                    <button className="sendAchievements" onClick={this.sendAchievements}>儲存</button>
                }
            </div>
        )
    }
}

export default withRouter(StudentPlan);