import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';

import './Navigation.less'



class Navigation extends Component {
    render() {

        const { certificate, courseTypes, t } = this.props
        return (
            <div className="Navitaion">
                {/* <div className="dropdown">
                    <div className="dropbtn">
                    {t('Navigation.crouse.title')}<i className="fa fa-caret-down"></i>
                    </div>
                    <div className="dropdown-content">
                        {
                            courseTypes.map(item => {
                                return <Link to={"/coursetype/" + item._id} key={item._id}>{item.title}</Link>
                            })
                        }
                    </div>
                </div>
                <div className="dropdown">
                    <div className="dropbtn">
                    {t('Navigation.classification.title')}<i className="fa fa-caret-down"></i>
                    </div>
                    <div className="dropdown-content">
                        {
                            certificate.map(item => {
                                return <Link to={"/certificate/" + item._id} key={item._id}>{item.title}</Link>
                            })
                        }
                    </div>
                </div>
                <div className="dropdown">
                    <div className="dropbtn">
                    {t('Navigation.list.title')}<i className="fa fa-caret-down"></i>
                    </div>
                    <div className="dropdown-content">
                        <Link to="/">清華STEAM認證學校</Link>
                        <Link to="/">清華STEAM認證教師</Link>
                    </div>
                </div> */}
                <div className="dropdown">
                    <div className="dropbtn">
                    {t('Navigation.teacher.title')}<i className="fa fa-caret-down"></i>
                    </div>
                    <div className="dropdown-content">
                        <Link to="/signin">{t('Navigation.teacher.SignIn')}</Link>
                        <Link to="/signup">{t('Navigation.teacher.SignUp')}</Link>                        
                    </div>
                </div>
                <div className="dropdown">
                    <div className="dropbtn" >
                        <Link to="/contactus" className="contactusLink">{t('Navigation.contactUs.title')}</Link>
                    </div>
                    <div className="dropdown-content">
                        {/* <Link to="/signin">Signin</Link>
                        <Link to="/signup">SignUp</Link> */}
                    </div>
                </div>
                <div className="dropdown">
                    <div className="dropbtn" >
                        <Link to="/certification" className="contactusLink">證書查詢</Link>
                    </div>
                    <div className="dropdown-content">
                        {/* <Link to="/signin">Signin</Link>
                        <Link to="/signup">SignUp</Link> */}
                    </div>
                </div>
            </div>
            // </nav>
        )
    }
}

export default withTranslation()(Navigation);