import React, { Component } from 'react'
import axios from 'axios';
import { withRouter } from "react-router-dom";
import './SignUp.less'
import LocalStorage from "../../Helpers/LocalStorage";
import { withTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner'

class SignUp extends Component {

    constructor(props) {
        super(props);

        const { my = {} } = this.props;

        this.state = {
            account: my.account || '',
            password: '',
            firstName: my.firstName || '',
            lastName: my.lastName || '',
            identifyID: my.identifyID || '',
            memberRole: my.memberRole || '',
            gender: my.gender || '',
            companyUnit: my.companyUnit || '',
            companyCountry: my.companyCountry || '5d5ac401c7025f142821d425',
            companyAddress: my.companyAddress || '',
            companyName: my.companyName || '',
            country: my.country || '5d5ac413c7025f142821d42e',
            address: my.address || '',
            telephone: my.telephone || '',
            socialType: my.socialType || '',
            socialNumber: my.socialNumber || '',
            questions: [],
            passwordCheck: true,

            registerCompanyUnits: [
                {
                    _id: "5d5e04d882eeec67e54aa8b9",
                    unitName: "無"
                },
                {
                    _id: "5d5e04d882eeec67e54aa8ba",
                    unitName: "在職教師"
                },
                {
                    _id: "5d5e04d882eeec67e54aa8bb",
                    unitName: "其他任職單位"
                }],
            registerCompanyCountries: [
                {
                    _id: "5d5ac401c7025f142821d425",
                    countryName: "臺灣"
                }],
            registerUserCountries: [
                {
                    _id: "5d5ac413c7025f142821d42e",
                    countryName: "臺灣"
                }
            ],
            socialTypes: [
                {
                    name: "Line",
                    type: "line"
                },
                {
                    name: "Facebook",
                    type: "facebook"
                },
                {
                    name: "WeChat",
                    type: "wechat"
                }
            ],
            isSendRegister: false
        }
    }

    getI18n = () => {
        let localLanguage = this.props.location.local || 'zh-TW';
        const lanFile = require(`./../../Assets/i18n/${localLanguage}.json`)

        return lanFile.profile
    }

    componentDidMount() {

        // let nthusteam = LocalStorage.getLocalStorage("nthusteam");

        // axios.get(`${window.CONFS.apiServer}/member/my`)
        //     .then(res => {
        //         nthusteam.name = res.data.data.firstName + ' ' + res.data.data.lastName;
        //         nthusteam.firstName = res.data.data.firstName;
        //         nthusteam.lastName = res.data.data.lastName;

        //         LocalStorage.saveLocalStorage("nthusteam", nthusteam);
        //         this.setState({ my: res.data.data });
        //     })
        //     .catch(function (error) {
        //         console.log("fail get", error);
        //         // window.alert("Server Error");
        //     });

        this.getOptions();
    }

    getOptions() {
        axios.get(`${window.CONFS.apiServer}/tw/member/registerData`)
            .then(res => {
                if (res.data.data) {
                    this.setState(res.data.data);
                }
            })
            .catch(function (error) {
                console.log('fail get registerData =>', error)
            });
    }

    signup(e) {

        const { my = {} } = this.state;

        const data = {
            account: this.state.account || my.account,
            firstName: this.state.firstName || my.firstName,
            lastName: this.state.lastName || my.lastName,
            identifyID: this.state.identifyID || my.identifyID,
            memberRole: this.state.memberRole || my.memberRole,
            gender: this.state.gender || my.gender,
            companyCountry: this.state.companyCountry || my.companyCountry,
            companyAddress: this.state.companyAddress || my.companyAddress,
            companyUnit: this.state.companyUnit || my.companyUnit,
            companyName: this.state.companyName || my.companyName,
            country: this.state.country || my.country,
            address: this.state.address || my.address,
            telephone: this.state.telephone || my.telephone,
            cellphone: this.state.cellphone || my.cellphone,
            socialType: this.state.socialType || my.socialType,
            socialNumber: this.state.socialNumber || my.socialNumber,
            questions: this.state.questions || my.questions
        }

        if (this.props.match.params.action !== "personProfile") {
            data.password = this.state.password;
        }

        let errorMessage = this.validateForm(data);

        let isValid = true;
        if (errorMessage && errorMessage.length > 0) {
            isValid = false;
        }

        let errors = []
        this.setState(errors)

        if (isValid) {

            if (this.props.match.params.action === "personProfile") {

                let nthusteam = LocalStorage.getLocalStorage("nthusteam");

                data.GUID = nthusteam.GUID;
                axios.post(`${window.CONFS.apiServer}/member/my`, data, {
                    headers: { "X-Authorize": nthusteam.apiToken }
                })
                    .then(res => {
                        console.log('update my =>', res.data);
                        if (res.data && res.data.errorCode === 0) {
                            window.alert('更新成功');
                        } else {
                            errors.push(res.errorMessage)
                        }
                        this.setState(errors)
                    })
                    .catch(function (error) {
                        console.log('fail post', error);
                    });

            } else {
                this.setState({ isSendRegister: true }, () => {
                    axios.post(`${window.CONFS.apiServer}/member/register`, data)
                        .then(res => {
                            console.log('signup =>', res.data);
                            if (res.data && res.data.errorCode === 0) {
                                window.alert('註冊成功');
                                window.location = '/success';
                            } else {
                                errors.push(res.data.errorMessage)
                                console.log('errors =>', errors)
                            }
                            this.setState({ 'errors': errors })
                        })
                        .catch(function (error) {
                            console.log('fail post', error);
                        });
                })
            }


        } else {
            this.setState({ "errors": errorMessage })
        }

    }

    checkGenderF = (g) => {

        const { gender } = this.state;

        if ((gender !== '' && gender === 'female')) {
            return true
        }

        return (gender === '' && g === 'female')

    }

    checkGenderM = (g) => {

        const { gender } = this.state;

        if ((gender !== '' && gender === 'male')) {
            return true
        }

        return (gender === '' && g === 'male')

    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    passwordCheck = (e) => {
        let check = this.state.password === e.target.value;
        this.setState({
            passwordCheck: check
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.signup(e)
    }

    validateForm = (e) => {

        let errorMessage = []

        if (!e.account) {
            errorMessage.push('信箱為必填 Need Email')
        }

        if (this.props.match.params.action !== "personProfile") {

            if (!e.password) {
                errorMessage.push('密碼為必填 Need Password')
            }

            if (!this.state.passwordCheck) {
                errorMessage.push('密碼請確認 Need Check Password')
            }
        }

        if (!e.firstName) {
            errorMessage.push('姓氏為必填 Need First Name')
        }

        if (!e.lastName) {
            errorMessage.push('名字為必填 Need Last Name')
        }

        if (!e.identifyID) {
            errorMessage.push('身分證字號為必填 Need Identify ID')
        }

        if (!e.memberRole) {
            errorMessage.push('會員身份為必填 Need Member Role')
        }
        if (!e.gender) {
            errorMessage.push('性別為必填 Need Gender')
        }
        if (!e.companyCountry) {
            errorMessage.push('公司國家為必填 Need Company Country')
        }
        if (!e.companyUnit) {
            errorMessage.push('任職單位為必填 Need Company Unit')
        }
        if (!e.companyName) {
            errorMessage.push('公司名稱為必填 Need Company Name')
        }
        if (!e.country) {
            errorMessage.push('國家為必填 Need Country')
        }
        if (!e.address) {
            errorMessage.push('通訊地址為必填 Need Address')
        }
        if (!e.telephone) {
            errorMessage.push('電話為必填 Need Telephone')
        }
        if (!e.cellphone) {
            errorMessage.push('手機為必填 Need Cellphone')
        }
        if (!e.socialType) {
            errorMessage.push('社群類型為必填 Need Social Type')
        }
        if (!e.socialNumber) {
            errorMessage.push('社群帳號為必填 Need Social Account')
        }

        return errorMessage;
    }


    render() {

        // const { mailInfo = {}, serviceUnits = {}, signUp = {} } = this.getI18n();
        const { registerMemberRoles = [], my = {} } = this.state;
        const { t } = this.props;

        return (
            <div className="SignUp">

                <div className="memberRegisterContent">
                    <div className="memberRegisterTitle">
                        NTHU STEAM School - {

                            t('profile.signUp.signUp')
                        }
                    </div>

                    {/* <div className="errorMsg">{this.state.errorMsg}</div> */}
                    {
                        this.state.errors && this.state.errors.map((m, k) =>
                            <div className="errorMsg" key={k}>{m}</div>
                        )
                    }

                    <div className="memberRegisterRow">
                        <i className="fas fa-user"></i>
                        {
                            t('profile.signUp.signUp')
                        }
                    </div>

                    <table className="tableBasic" border="1">
                        <tbody className="">
                            <tr>
                                <td className="title">{t('profile.signUp.email')}</td>
                                <td><input type="email" className="signupinput" onChange={this.handleChange} name="account" defaultValue={my.account}></input></td>

                                <td className="title">{
                                    t('profile.signUp.identity')
                                }</td>
                                <td><input className="signupinput" onChange={this.handleChange} name="identifyID" defaultValue={my.identifyID}></input></td>


                            </tr>
                            <tr>
                                <td className="title">{
                                    t('profile.signUp.firstName')
                                }</td><td><input className="signupinput" onChange={this.handleChange} name="firstName" defaultValue={my.firstName}></input></td>
                                <td className="title">{
                                    t('profile.signUp.lastName')
                                }</td>
                                <td>
                                    <input className="signupinput" onChange={this.handleChange} name="lastName" defaultValue={my.lastName}></input>
                                </td>
                            </tr>
                            <tr>
                                <td className="title">{
                                    t('profile.signUp.membership')
                                }</td>
                                <td>
                                    <select className="signupinput" onChange={this.handleChange} defaultValue={my.memberRole} name="memberRole">
                                        <option ></option>
                                        {
                                            registerMemberRoles.filter(role => { return role.removed ? !role.removed : true }).map((role, key) =>
                                                role._id === my.memberRole
                                                    ? <option value={role._id} key={key} selected>{role.roleName}</option>
                                                    : <option value={role._id} key={key}>{role.roleName}</option>
                                            )
                                        }
                                    </select>
                                </td>

                                <td className="title">{
                                    t('profile.signUp.sex')
                                }</td>
                                {/* <td>Gender</td> */}
                                <td colSpan="3">
                                    <div className="divGender">
                                        <input type="radio"
                                            id="signUpMaleRadio"
                                            name="gender"
                                            value="male"
                                            onClick={this.handleChange}
                                            checked={this.checkGenderM(my.gender)}
                                        // checked={ my.gender === "male" }
                                        >

                                        </input>
                                        <label htmlFor="signUpMaleRadio">{t('profile.signUp.male')}</label>
                                    </div>
                                    <div className="divGender">
                                        <input type="radio"
                                            id="signUpFemaleRadio"
                                            name="gender"
                                            checked={this.checkGenderF(my.gender)}
                                            // checked={ my.gender === "female" }
                                            value="female" onClick={this.handleChange}></input>
                                        <label htmlFor="signUpFemaleRadio">{t('profile.signUp.female')}</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="title">{
                                    t('profile.signUp.password')
                                }</td><td><input type="password" className="signupinput" onChange={this.handleChange} name="password" /></td>

                                <td className="title">{
                                    t('profile.signUp.passwordCheck')
                                }</td>
                                <td className="passwordCheck">
                                    <input type="password" className="signupinput" onChange={this.passwordCheck} name="passwordCheck" />
                                    {
                                        !this.state.passwordCheck &&
                                        '密碼不一致 Password Different'
                                    }
                                </td>

                            </tr>

                        </tbody>
                    </table>

                    <div className="memberRegisterRow">
                        <i className="fas fa-user"></i>
                        {t('profile.serviceUnits.serviceUnits')}
                    </div>

                    <table className="tableBasic" border="1">
                        <tbody className="">
                            <tr>
                                <td className="title">{t('profile.serviceUnits.unit')}</td>
                                <td>
                                    <select className="signupinput" onChange={this.handleChange} defaultValue={my.companyUnit} name="companyUnit">
                                        <option value=""></option>
                                        {
                                            this.state.registerCompanyUnits.map((job, key) =>
                                                job._id === my.companyUnit
                                                    ? <option value={job._id} key={key} selected>{job.unitName}</option>
                                                    : <option value={job._id} key={key}>{job.unitName}</option>
                                            )
                                        }
                                    </select>
                                </td>
                                <td className="title">{t('profile.serviceUnits.country')}</td>
                                <td>
                                    <select className="signupinput" onChange={this.handleChange} defaultValue={my.companyCountry} name="companyCountry">
                                        {
                                            this.state.registerCompanyCountries &&
                                            this.state.registerCompanyCountries.map((c, a) =>
                                                c._id === my.companyCountry
                                                    ? <option value={c._id} key={a} selected>{c.countryName}</option>
                                                    : <option value={c._id} key={a}>{c.countryName}</option>
                                            )
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className="title">{t('profile.serviceUnits.companyAddress')}</td>
                                <td><input className="signupinput" onChange={this.handleChange} name="companyAddress" defaultValue={my.companyAddress}></input></td>
                                <td className="title">{t('profile.serviceUnits.companyName')}</td>
                                <td><input className="signupinput" onChange={this.handleChange} name="companyName" defaultValue={my.companyName}></input></td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="memberRegisterRow">
                        <i className="fas fa-user"></i>
                        {t('profile.mailInfo.mailInfo')}
                    </div>

                    <table className="tableBasic" border="1">
                        <tbody className="">
                            <tr>
                                <td className="title">{t('profile.mailInfo.country')}</td>
                                <td>
                                    <select className="signupinput" onChange={this.handleChange} defaultValue={my.country} name="country">
                                        {
                                            this.state.registerUserCountries &&
                                            this.state.registerUserCountries.map((c, k) =>
                                                c._id === my.country
                                                    ? <option value={c._id} key={k} selected>{c.countryName}</option>
                                                    : <option value={c._id} key={k}>{c.countryName}</option>
                                            )
                                        }
                                    </select>
                                </td>
                                <td className="title">{t('profile.mailInfo.address')}</td>
                                <td>
                                    <input className="signupinput" onChange={this.handleChange} name="address" defaultValue={my.address}></input>
                                </td>
                            </tr>
                            <tr>
                                <td className="title">{t('profile.mailInfo.phone')}</td>
                                <td><input className="signupinput" onChange={this.handleChange} name="telephone" defaultValue={my.telephone}></input></td>
                                <td className="title">{t('profile.mailInfo.mobilePhone')}</td>
                                <td><input className="signupinput" onChange={this.handleChange} name="cellphone" defaultValue={my.cellphone}></input></td>
                            </tr>
                            <tr>
                                <td className="title">{t('profile.mailInfo.socialMedia')}</td>
                                <td colSpan="3">
                                    <div className="socialArea">
                                        <div className="socialType">
                                            <select className="signupinput" onChange={this.handleChange} defaultValue={my.socialType} name="socialType">
                                                <option value=""></option>
                                                {
                                                    this.state.socialTypes &&
                                                    this.state.socialTypes.map((c, k) =>
                                                        c.type === my.socialType
                                                            ? <option value={c.type} key={k} selected>{c.name}</option>
                                                            : <option value={c.type} key={k}>{c.name}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                        <div className="socialNumber">
                                            <input className="signupinput" onChange={this.handleChange} name="socialNumber" defaultValue={my.socialNumber}></input>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {this.props.match.params.action === "personProfile" ?
                        <button className="submitBtn" type="submit" onClick={this.handleSubmit}>{t('profile.save.save')}</button> :
                        <>{
                            this.state.isSendRegister ?
                                <div className="submitBtn"><Loader
                                    type="Oval"
                                    color="#7f1083"
                                    height={22}
                                    width={22}
                                    visible={true}
                                /></div> :
                                <button className="submitBtn" type="submit" onClick={this.handleSubmit}>
                                    {t('profile.save.registered')}
                                </button>
                        }</>
                    }

                </div>

            </div>
        )
    }

}


export default withTranslation()(withRouter(SignUp));