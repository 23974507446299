import React, { Component } from 'react';
import logo from '../../Assets/Home/logo.png'
import Loader from 'react-loader-spinner'
import axios from 'axios';
import LocalStorage from '../../Helpers/LocalStorage'
import { withRouter } from "react-router-dom"
import qs from 'query-string';

import './Login.less'

class Login extends Component {
    constructor(props) {
		super(props);
		this.state= {
			"loading": false,
			"account": "angelwar52112002+admin2@gmail.com",
			"password": "123456"
		}
		localStorage.clear()

		// let params = qs.parse(this.props.location.search)
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    submitLogin = ()=>{
    	let isValid = true;
    	let errorMsg = "";
    	if(!this.state.account){
    		isValid = false
    		errorMsg += "帳號不可為空"
    	}

    	if(!this.state.password){
    		isValid = false
    		errorMsg += " 密碼不可為空"
    	}

    	if(isValid){
    		axios.post(window.CONFS.apiServer+'/member/login',
            {
                account: this.state.account,
                password: this.state.password
            })
            .then(res => {
            	if(res.data.errorCode>0){
            		this.setState({"errorMsg": res.data.errorMessage})
            	}
            	else{
            		let nthusteamAdmin = {
            			"apiToken": res.data.data.apiToken
            		}
					LocalStorage.saveLocalStorage("nthusteamAdmin", nthusteamAdmin);					
					this.initAdminRequestHeader(nthusteamAdmin.apiToken)
					this.initAdminResponseHandler()
					let params = qs.parse(this.props.location.search)
					let path = "/admin"
					if(params.redirect){
						params.redirect = params.redirect.replace("signature/new","signature")
						path = params.redirect
					}
            		this.props.history.push(path)
            	}            	
            })
            .catch(function (error) {
                console.log('fail post =>', error)
            });
    		this.setState({"loading":true})
    	}
    	else{
    		this.setState({"errorMsg":errorMsg})    	
    	}    	
	}
	
	initAdminRequestHeader = (apiToken)=>{
		window.CONFS.adminAxiosSetting.requestHeader = axios.interceptors.request.use(
			config => {
				config.headers["X-Admin-Authorize"] = apiToken;
				return config;
			},
			error => Promise.reject(error)
		);
	}

	initAdminResponseHandler = ()=>{		
		window.CONFS.adminAxiosSetting.responseHandler = axios.interceptors.response.use(res => {
			if (res.data.errorCode === 1000 || res.data.errorCode === 1011) {
				axios.interceptors.request.eject(window.CONFS.adminAxiosSetting.requestHeader)
				axios.interceptors.response.eject(window.CONFS.adminAxiosSetting.responseHandler)
				console.log("Login.js 請重新登入")
				alert("請重新登入");
				this.props.history.push("/admin/login?redirect=" + window.location.pathname)			}
			return res;
		}, function (error) {
			return Promise.reject(error);
		});
	}

    render() {
        return (
            <div className="Login">
	            <div className="content">
	            	<div className="logo" style={{"backgroundImage":"url('"+logo+"')"}}></div>
					<div className="title">清華STEAM學校管理後台</div>
					<div className="form loginform">
						<div className="row">
							<div className="label">帳號</div>
							<div><input type="text" name="account" className="textInput" onChange={this.handleChange}/></div>
						</div>
						<div className="row">
							<div className="label">密碼</div>
							<div><input type="password" name="password" className="textInput" onChange={this.handleChange}/></div>
						</div>
						<div className="row">
							{this.state.loading?
								<div className="loginBtn">																
									<Loader
										type="Oval"
										color="#7f1083"
										height={22}
										width={22}
										visible = {true}
									/>
								</div>:
								<div className="loginBtn" onClick={this.submitLogin}>																
									登入
								</div>
							}							
						</div>
						<div className="row">							
							<div className="errorMsg">{this.state.errorMsg}</div>													
						</div>
					</div>					
	            </div>				
			</div>
        );
    }
}

export default withRouter(Login);