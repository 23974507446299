import React, { Component } from "react";
import axios from "axios";
import "./PlanDetail.less";
import PlanTarget from "./PlanStep1/PlanTarget";
import PlanResource from "./PlanStep1/PlanResouce";
import PlanBasic from "./PlanStep1/PlanBasic";
import Step2Basic from "./PlanStep2/Step2Basic";
import StudentPlan from './StudentPlan'
import { withRouter } from "react-router-dom";
import LocalStorage from "../../Helpers/LocalStorage";
import Loader from 'react-loader-spinner'
import ReactModal from 'react-modal'
import Moment from 'react-moment'
import { resolve } from "dns";
import { Dialog, DialogTitle, DialogContent, withStyles, TextareaAutosize } from '@material-ui/core';

class PlanDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sendButtonFlag: false,
      stepStatus: 1,
      teachers: [],
      kernel: [],
      reigon: [],
      important: [],
      content: [],
      activity: [],
      checkOwner: true,
      teacherAbility: {},

      id: this.props.match.params.id,
      planId: this.props.match.params.id,
      submitLessonPlanLoading: false,
      openRejectReason: false,
      submitRjectReasonLoading: false,
      isChangedData: false
    };
    this.setAllState = this.setAllState.bind(this);
    this.getNewPlan = this.getNewPlan.bind(this);
  }

  setIsChangedData = (data) => {    
    if (this.isAvailableSavePlan()) {
      this.setState({ isChangedData: data })
    }
  }

  componentDidMount() {
    this.getPlanSetting();
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    }
  }


  // componentDidUpdate(prevState) {
  //   if (this.state.creator !== prevState.creator) {
  //     // this.checkOwner();
  //     // this.state[checkOwner] = checkOwner;
  //     // this.setState({checkOwner:checkOwner})
  //   }
  // }

  checkOwner = () => {
    let nthusteam = LocalStorage.getLocalStorage("nthusteam");

    const { creator = '' } = this.state;
    // const { waitingApprove } = this.state;

    if (creator && nthusteam) {
      console.log('checkOwner =>', (nthusteam.GUID === creator && !(this.state.waitingApprove)));
      // waitingApprove: 當送出審核 此欄位true, step1, 2 ,3 全部不可編輯
      let checkOwner = (nthusteam.GUID === creator && !(this.state.waitingApprove));
      this.state['checkOwner'] = checkOwner;
    } else {
      // return true;
      this.state['checkOwner'] = true;
    }
  }

  getGUID() {
    let nthusteam = LocalStorage.getLocalStorage("nthusteam");
    return nthusteam ? nthusteam.GUID : null;
  }

  setAllState(k, v) {
    // console.log("set All state =>", k, '----', v);
    if ('planSetting' === k) {

      this.filterActivity(v);
      this.setState({ isChangedData: true })
      if (!this.isAvailableSavePlan()) {
        this.props.setIsChanged(true);
      }
    } else {
      this.setState({ [k]: v }, () => {
        this.setState({ isChangedData: true })
        if (!this.isAvailableSavePlan()) {
          this.props.setIsChanged(true);
        }
      });
    }
  }

  filterActivity = (reigonName) => {

    let setting = this.state['defaultPlanSetting'];
    let name = ''

    if (reigonName.includes('-')) {
      let nameList = reigonName.split('-')
      name = nameList[nameList.length - 1]
      if (name) {
        name = name.replace('文', '')
      }
    } else {
      name = reigonName.replace('領域', '')
    }

    let newList = []

    let activityList = setting.activity || [];

    newList = activityList.filter(a => a.activeName.indexOf(name) === 0)

    this.setState({ planSetting: { ...setting, activity: newList } });

  }

  setTeachers = t => {
    this.setState({ teachers: t });
  };


  close = () => { this.setState({ "openRejectReason": false }) }

  getPlanSetting = () => {
    axios
      .get(`${window.CONFS.apiServer}/tw/lessonplan/setting`)
      .then(res => {
        let settings = {};
        // console.log('res.dare =====>', res.data)
        settings.grade = res.data.data.lessonPlanGrades;
        settings.academicYear = res.data.data.academicYears;
        settings.sysKeyWords = res.data.data.lessonPlanKeywords;
        settings.kernel = res.data.data.lessonTargetKernels;
        settings.reigon = res.data.data.lessonTargetReigons;
        settings.important = res.data.data.lessonTargetPoints;
        settings.content = res.data.data.lessonTargetContents;
        settings.activity = res.data.data.lessonTargetActivities;
        settings.lessonPlanSteps = res.data.data.lessonPlanSteps;
        settings.teacherAbilityPointers = res.data.data.teacherAbilityPointers;
        settings.teacherLessonplanResourceClasses = res.data.data.teacherLessonplanResourceClasses;

        if (this.props.match.params.id) {

          this.getNewPlan();

          this.setState({ defaultPlanSetting: settings }, () => {
            let reigonName = settings.reigon[0] ? settings.reigon[0].reigonName : '';
            this.filterActivity(reigonName)
          });
          // this.setState({ planSetting: settings });

        } else {
          this.setState({ defaultPlanSetting: settings, planSetting: settings }, () => {
            let reigonName = settings.reigon[0] ? settings.reigon[0].reigonName : '';
            this.filterActivity(reigonName)
          });
        }

        // defaultData
      })
      .catch(function (error) {
        console.log('getPlanSetting => ', error);
      });
  }

  extractRegionName = (reigonName) => {

    let name = '';

    if (reigonName.includes('-')) {
      let nameList = reigonName.split('-')
      name = nameList[nameList.length - 1]
      if (name) {
        name = name.replace('文', '')
      }
    } else {
      name = reigonName.replace('領域', '')
    }

    return name
  }

  getNewPlan = () => {
    axios
      .get(`${window.CONFS.apiServer}/lessonplan/${this.props.match.params.id}`)
      .then(res => {

        console.log('res =>>', res)

        let teachers = [];
        let fullTeachers = [];
        if (res.data.errorCode !== 0) {
          window.alert(res.data.errorMessage)
        }
        if (res.data.data) {
          if (res.data.data.teachers) {
            fullTeachers = res.data.data.teachers;
            teachers = res.data.data.teachers.filter(t => t.GUID !== res.data.data.creator)
          }
          let lessonTarget = res.data.data.lessonTargetI18N ? res.data.data.lessonTargetI18N : {};

          let kernels = lessonTarget.kernel ? lessonTarget.kernel.map(k => { return { _id: k._id, kernelName: k.kernelNames.tw } }) : [];
          let regions = lessonTarget.reigon ? lessonTarget.reigon.map(k => { return { _id: k._id, reigonName: k.reigonNames.tw } }) : [];
          let importunities = lessonTarget.important ? lessonTarget.important.map(k => { return { _id: k._id, pointName: k.pointNames.tw } }) : [];
          let contents = lessonTarget.content ? lessonTarget.content.map(k => { return { _id: k._id, contentName: k.contentNames.tw } }) : [];
          let activities = lessonTarget.activity ? lessonTarget.activity.map(k => { return { _id: k._id, activeName: k.activeNames.tw } }) : [];

          let classes = this.cleanLessonPlanClasses(res.data.data.lessonPlanClasses);

          let certificationData = res.data.data.certificationData ? res.data.data.certificationData : {};

          let res2state = {
            id: res.data.data._id,
            creator: res.data.data.creator,
            title: res.data.data.title,
            teachers: teachers,
            fullTeachers: fullTeachers,
            academicYear: res.data.data.academicYear,
            grade: res.data.data.grade,
            keywords: res.data.data.keywords,
            description: res.data.data.description,
            teacherAbility: res.data.data.teacherAbility,
            // lessonTarget
            kernel: kernels,
            reigon: regions,
            important: importunities,
            content: contents,
            activity: activities,
            certificationData: certificationData,
            lessonPlanClasses: classes,
            lessonPlanAchievements: res.data.data.lessonPlanAchievements,

            // showAllDates: res.data.data.showAllDates,
            waitingApprove: res.data.data.waitingApprove,
            approveStep1: res.data.data.approveStep1,
            approveStep1At: res.data.data.approveStep1At,
            approveStep2: res.data.data.approveStep2,
            approveStep2At: res.data.data.approveStep2At,
            approveStep3: res.data.data.approveStep3,
            approveStep3At: res.data.data.approveStep3At,
            rejectHistory: res.data.data.rejectHistory,
            allApprove: res.data.data.allApprove,
            allApproveAt: res.data.data.allApprovedAt,
          }

          if (res.data.data.teacherAbilityPointers) {
            res2state.planSetting = {
              ...this.state.planSetting,
              teacherAbilityPointers: res.data.data.teacherAbilityPointers
            }
          }
          if (this.props.loginRole != "admin") {
            //偷懶不建立審核中狀態
            this.props.setAllApprove(res.data.data.allApprove || res.data.data.waitingApprove);
          }
          console.log('res2state showAllDates :', res.data)
          this.setState(res2state);

          return resolve();
        }
      })
      .catch(function (error) {
        console.log(error);
      })

  }

  cleanLessonPlanClasses = (lessonPlanClasses) => {

    lessonPlanClasses.forEach(item => {
      item.id = item._id;
      delete item._id;

      if (item.videos) {
        item.videos.forEach(r => {
          r.id = r._id;
          delete r._id;
        })
      }

      if (item.photos) {
        item.photos.forEach(r => {
          r.id = r._id;
          delete r._id;
        })
      }

      if (item.resources) {
        item.resources.forEach(r => {
          r.id = r._id;
          delete r._id;
        })
      }
    })
    return lessonPlanClasses
  }

  checkShowAllDates = () => {

    const { loginRole } = this.props;
    const { allApprove } = this.state;

    if (loginRole === "admin") {
      return true;
    }

    if (allApprove === true) {
      return true;
    }

    return false;
  }

  validateBasicForm = e => {
    let f = true;
    if (e.title === undefined || e.title === "" || e.title === null) f = false;
    if (
      e.academicYear === undefined ||
      e.academicYear === "" ||
      e.academicYear === null
    )
      f = false;
    if (e.grade === undefined || e.grade === "" || e.grade === null) f = false;

    return f;
  };

  sendPlanDetail = (e) => {
    let nthusteam = LocalStorage.getLocalStorage("nthusteam");

    let ttt = this.state.teachers ? this.state.teachers : [];
    let teachers = ttt.map(t => t.GUID);

    if (!teachers.includes(nthusteam.GUID)) {
      teachers.push(nthusteam.GUID)
    }

    let kernelIds = this.state.kernel.map(i => i._id)
    let reigonIds = this.state.reigon.map(i => i._id)
    let importantIds = this.state.important.map(i => i._id)
    let contentIds = this.state.content.map(i => i._id)
    let activityIds = this.state.activity.map(i => i._id)
    let teacherAbility = this.state.teacherAbility
    let teacherAbilityPointers = this.state.planSetting.teacherAbilityPointers

    if (!this.checkTeacherAbilityRequired(teacherAbilityPointers, "position1", teacherAbility)) {
      alert("STEAM教學能力有未填項目")
      return false
    }

    let payload = {
      id: this.state.id,
      creator: nthusteam.GUID,
      title: this.state.title,
      teachers: teachers,
      academicYear: this.state.academicYear,
      grade: this.state.grade,
      keywords: this.state.keywords,
      description: this.state.description,
      lessonTarget: {
        kernel: kernelIds,
        reigon: reigonIds,
        important: importantIds,
        content: contentIds,
        activity: activityIds
      },
      certificationData: this.state.certificationData,
      teacherAbility: teacherAbility,
      teacherAbilityPointers: teacherAbilityPointers
    }


    axios.post(
      `${window.CONFS.apiServer}/lessonplan`, payload
    ).then(res => {
      console.log(res);
      window.location = "/management/lessonPlan/" + res.data.data.id;
      // this.props.history.push("/management/lessonPlan/" + res.data.data.id);
      // this.props.history.push("/management");
    })
      .catch(function (error) {
        console.log("fail post", error);
        window.alert("伺服器異常");
      });
  }

  checkTeacherAbilityRequired = (teacherAbilityPointers, position, selectedTeacherAbility) => {
    let result = true

    // if (teacherAbilityPointers && teacherAbilityPointers.classes) {
    //   teacherAbilityPointers.classes.every(classObject => {
    //     if (classObject.pointers) {
    //       // console.log({filtered:classObject.pointers.filter(pointer => pointer.positions.indexOf(position) > -1)})
    //       classObject.pointers.filter(pointer => pointer.positions.indexOf(position) > -1)
    //         .every(pointer => {
    //           // console.log({pointer: pointer,position: position,selectedTeacherAbility: selectedTeacherAbility})
    //           result = this.findPointerKey(pointer._id, position, selectedTeacherAbility)
    //           return result
    //         })
    //     }

    //     return result
    //   })
    // }
    return result
  }

  findPointerKey = (key, position, selectedTeacherAbility) => {
    let result = false
    // console.log({selectedTeacherAbility:selectedTeacherAbility})
    // console.log((selectedTeacherAbility && selectedTeacherAbility.content))
    if (selectedTeacherAbility && selectedTeacherAbility[position] && selectedTeacherAbility[position].content) {
      Object.keys(selectedTeacherAbility[position].content).every(classKey => {
        // console.log({classKey:classKey})
        // console.log({key:key})
        if (selectedTeacherAbility[position].content[classKey][key]) {
          result = true
          return false
        }
        else {
          return true
        }
      })
    }
    // console.log({[key]:result})
    return result
  }

  changeTab = tabNumber => {
    this.setState({ stepStatus: tabNumber })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleChangeSaveLocal = (e) => {
    this[e.target.name] = e.target.value
  }


  checkAllApprove = () => {

    const { allApprove, allApproveAt, waitingApprove } = this.state

    if (waitingApprove) {
      return <div className="waitingApprove">審核中</div>
    }

    if (allApprove) {
      return <div className="waitingApprove" style={{ "color": "blue" }}> 教案審核通過 (<Moment format="YYYY/MM/DD HH:mm">{allApproveAt}</Moment>)</div>
    } else {
      return <div className="waitingApprove"></div>
    }
  }

  clickChangeTab = (num) => {
    if (!this.state.isChangedData || this.state.waitingApprove) {
      this.changeTab(num)
      return
    }
    if (window.confirm("您尚未儲存教案，確定要切換頁面?")) {
      // this.setIsChangedData(false)
      this.setState({ isChangedData: false }, ()=>{
        this.changeTab(num)
      })
    }
  }

  generateTabs = () => {
    const { id, creator, waitingApprove, allApprove, allApproveAt } = this.state
    return <div className="controllBtn">
      <button className={`stepController ${(this.state.stepStatus === 1) ? "selected" : ""}`} onClick={() => this.clickChangeTab(1)}>
        教案資訊
      </button>
      {this.props.match.params.id &&
        <button className={`stepController ${(this.state.stepStatus === 2) ? "selected" : ""}`} onClick={() => this.clickChangeTab(2)}>
          認證資料
        </button>
      }
      {this.props.match.params.id &&
        <button className={`stepController ${(this.state.stepStatus === 3) ? "selected" : ""}`} onClick={() => this.clickChangeTab(3)}>
          學生成果
        </button>
      }

      <div className="controll--Btns">
        {(this.state.rejectHistory && this.state.rejectHistory.length > 0) &&
          <div className="rejectHistoryArea">
            <div className="rejectTimes">
              <div>{this.state.rejectHistory.length}</div>
              <div className="rejectDetail">
                <div className="rejectDetailContent">
                  <table border="1">
                    <tbody>
                      {this.state.rejectHistory.map((item, key) => {
                        return <tr key={key}>
                          <td>{key + 1}</td>
                          <td><Moment format="YYYY/MM/DD HH:mm">{item.createdAt}</Moment></td>
                          <td>{item.reason}</td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        }
        {
          // 有id , 建立者 === 使用者GUID , waitingApprove != true , approveStep1 != true 第一階段未審合通過
          (id && creator === this.getGUID() && !waitingApprove && !allApprove && this.props.loginRole !== "admin") ?
            <div>
              {
                this.state.submitLessonPlanLoading === true ?
                  <button className="submitLessonPlan">
                    <Loader
                      type="Oval"
                      color="#ffffff"
                      height={22}
                      width={22}
                      visible={true}
                    /></button> :
                  <button className="submitLessonPlan" onClick={this.sendWaitingApprove}>
                    提交審核
                  </button>
              }
            </div> :
            <div>
              {this.props.loginRole !== "admin" ?
                <div>
                  {
                    // allApprove === true ?
                    //   <div className="waitingApprove" style={{"color":"blue"}}> 教案審核通過 (<Moment format="YYYY/MM/DD HH:mm">{allApproveAt}</Moment>)</div> :
                    //   <div className="waitingApprove">審核中</div>
                    this.checkAllApprove()

                  }
                </div> :
                <div>
                  {allApprove === true &&
                    <div className="waitingApprove" style={{ "color": "blue" }}> 教案審核通過 (<Moment format="YYYY/MM/DD HH:mm">{allApproveAt}</Moment>)</div>
                  }
                </div>
              }
            </div>
        }

        {
          // 有id , 建立者 === 使用者GUID , waitingApprove != true , approveStep1 != true 第一階段未審合通過
          (waitingApprove && this.props.loginRole === "admin") &&
          <div>
            <button className="submitLessonPlan" onClick={() => { this.setState({ openRejectReason: true }) }}>
              退回申請
            </button>
          </div>
        }
      </div>
    </div>
  }

  sendWaitingApprove = () => {

    const { id } = this.state;
    let msg = this.checkSendApprove()
    if (msg != "") {
      alert(msg)
    }
    else {
      if (!window.confirm("是否確認要送出審核?")) {
        return false;
      }
      this.setState({ "submitLessonPlanLoading": true })
      axios.patch(`${window.CONFS.apiServer}/lessonPlan/waitingApprove/${id}`)
        .then(res => {
          console.log(res);
          window.location = "/management/lessonPlan/" + id;
        })
        .catch(function (error) {
          console.log("fail post", error);
          window.alert("伺服器異常");
        });
    }
    // console.log({ state: this.state })

  }

  checkSendApprove = () => {
    let certificationData = this.state.certificationData
    let lessonPlanAchievements = this.state.lessonPlanAchievements
    let lessonPlanClasses = this.state.lessonPlanClasses
    let teacherAbility = this.state.teacherAbility
    let msg = ""
    if (certificationData.level1) {
      console.log({ "level1WordCheck": (certificationData.level1[0] === null || !certificationData.level1[0]) })
      if (certificationData.level1[0] === null || !certificationData.level1[0]) {
        msg += "課程規劃設計(第一階段) 未上傳 WORD\n\r"
      }

      console.log({ "level1PDFCheck": (certificationData.level1[1] === null || !certificationData.level1[1]) })
      if (certificationData.level1[1] === null || !certificationData.level1[1]) {
        msg += "課程規劃設計(第一階段) 未上傳 PDF\n\r"
      }
    }
    else {
      msg += "課程規劃設計(第一階段) 未上傳 WORD & PDF\n\r"
    }

    if (certificationData.level2) {
      if (certificationData.level2[0] === null || !certificationData.level2[0]) {
        msg += "課程規劃設計(第二階段) 未上傳 WORD\n\r"
      }

      if (certificationData.level2[1] === null || !certificationData.level2[1]) {
        msg += "課程規劃設計(第二階段) 未上傳 PDF\n\r"
      }
    }
    else {
      msg += "課程實踐案例(第二階段) 未上傳 WORD & PDF\n\r"
    }

    console.log({ certificationData: this.state })
    if (certificationData.lessonMap) {
      if (certificationData.lessonMap[0] === null || !certificationData.lessonMap[0]) {
        msg += "課程地圖 未上傳 WORD\n\r"
      }

      if (certificationData.lessonMap[1] === null || !certificationData.lessonMap[1]) {
        msg += "課程地圖 未上傳 PDF\n\r"
      }
    }
    else {
      msg += "課程地圖 未上傳 WORD & PDF\n\r"
    }
    if (!(lessonPlanAchievements && lessonPlanAchievements.length > 0 && lessonPlanAchievements[0].students.length>0) || JSON.stringify(lessonPlanAchievements) == "[]") {
      msg += "學生學習成果沒有資料\n\r"
    }
    if (!teacherAbility || JSON.stringify(teacherAbility) === "{}") {
      msg += "[教案資訊]-STEAM教學能力沒有設置\n\r"
    }

    if(lessonPlanClasses.length == 0){
      msg += "認證資料沒有設置\n\r"
    }

    return msg
  }

  rejectWaitingApprove = () => {

    this.setState({ rejectReason: this.rejectReason }, () => {
      const { id, rejectReason } = this.state;
      this.setState({ "submitRjectReasonLoading": true })
      axios.patch(`${window.CONFS.apiServer}/admin/lessonPlan/rejectApprove/${id}`, {
        "reason": rejectReason
      })
        .then(res => {
          console.log(res)
          window.location.reload()
        })
        .catch(function (error) {
          console.log("fail post", error);
          window.alert("伺服器異常");
        });
    })
  }

  approveLessonPlanStep = (stepNumber) => {
    const { id } = this.state;
    axios.patch(`${window.CONFS.apiServer}/admin/lessonPlan/Approve/${id}/${stepNumber}`)
      .then(res => {
        console.log(res)
        window.location.reload()
      })
      .catch(function (error) {
        console.log("fail post", error);
        window.alert("伺服器異常");
      });

  }

  setTeacherAbility = (teacherAbility) => {

    this.setState({
      teacherAbility: {
        ...this.state.teacherAbility,
        position1: teacherAbility
      }
    })
  }

  isAvailableSavePlan = () => {
    const { id, creator, waitingApprove, approveStep1 } = this.state
    return (id && creator === this.getGUID() && !waitingApprove && !approveStep1) || (!id)
  }

  render() {

    const { id, stepStatus, creator, showAllDates, waitingApprove, approveStep1, approveStep1At, approveStep2, approveStep2At, approveStep3, approveStep3At } = this.state
    const StyledDialog = withStyles({
      paper: {
        width: "60%"
      }
    })(Dialog);
    return (
      <div className="planDetailContainer">
        {this.generateTabs()}
        <div className="planDetailContent">
          {stepStatus === 1 && (
            <div className="planStep1Body">
              <PlanBasic
                planSetting={this.state.planSetting}
                match={this.props.match}
                setAllState={this.setAllState}
                setTeachers={this.setTeachers}
                title={this.state.title}
                teachers={this.state.teachers}
                fullTeachers={this.state.fullTeachers}
                academicYear={this.state.academicYear}
                grade={this.state.grade}
                keywords={this.state.keywords}
                description={this.state.description}
                creator={this.state.creator}
                checkOwner={this.state.checkOwner}
                approveStep1={approveStep1}
                approveStep1At={approveStep1At}
                loginRole={this.props.loginRole}
                approveLessonPlanStep={this.approveLessonPlanStep}
                teacherAbility={this.state.teacherAbility}
                setTeacherAbility={this.setTeacherAbility}
                isShowEditBtn={(!id) || (id && creator === this.getGUID() && !waitingApprove && !approveStep1)}

              />
              <PlanTarget
                planSetting={this.state.planSetting}
                setAllState={this.setAllState}
                kernel={this.state.kernel}
                reigon={this.state.reigon}
                important={this.state.important}
                content={this.state.content}
                activity={this.state.activity}
                checkOwner={this.state.checkOwner}
                approveStep1={approveStep1}
                extractRegionName={this.extractRegionName}
              />
              <PlanResource
                planSetting={this.planSetting}
                setAllState={this.setAllState}
                certificationData={this.state.certificationData}
                checkOwner={this.state.checkOwner}
                approveStep1={approveStep1}
              />

              {
                // 有id , 建立者 === 使用者GUID , waitingApprove != true , approveStep1 != true 第一階段未審合通過
                this.isAvailableSavePlan() &&
                <button className="sendAll" onClick={this.sendPlanDetail}>
                  儲存
                </button>
              }

            </div>
          )}

          {(id && stepStatus === 2) && (
            <div>
              <Step2Basic
                planSetting={this.state.planSetting}
                history={this.props.history}
                setIsChanged={this.props.setIsChanged}
                setIsChangedData={this.setIsChangedData}
                match={this.props.match}
                planId={this.state.planId}
                lessonPlanClasses={this.state.lessonPlanClasses}
                teachers={this.state.fullTeachers}
                creator={this.state.creator}
                checkOwner={this.state.checkOwner}
                showAllDates={showAllDates}
                approveStep2={approveStep2}
                approveStep2At={approveStep2At}
                loginRole={this.props.loginRole}
                approveLessonPlanStep={this.approveLessonPlanStep}
                waitingApprove={waitingApprove}
                getNewPlan={this.getNewPlan}
                teacherAbility={this.state.teacherAbility}
                setTeacherAbility={this.setTeacherAbility}
                isShowEditBtn={(id && !waitingApprove && !approveStep1)}
                checkTeacherAbilityRequired={this.checkTeacherAbilityRequired}
              />
            </div>
          )}
          {(id && stepStatus === 3) && (
            <div>
              <StudentPlan
                planId={this.state.planId}
                history={this.props.history}
                setIsChanged={this.props.setIsChanged}
                setIsChangedData={this.setIsChangedData}
                match={this.props.match}
                teachers={this.state.fullTeachers}
                fullTeachers={this.state.fullTeachers}
                creator={this.state.creator}
                lessonPlanAchievements={this.state.lessonPlanAchievements}
                checkOwner={this.state.checkOwner}
                approveStep3={approveStep3}
                approveStep3At={approveStep3At}
                loginRole={this.props.loginRole}
                approveLessonPlanStep={this.approveLessonPlanStep}
                isShowEditBtn={(!id) || (id && creator === this.getGUID() && !waitingApprove && !approveStep1)}
              />
            </div>
          )}
        </div>
        <StyledDialog
          maxWidth={false}
          disableBackdropClick={true}
          fullWidth={true}
          open={this.state.openRejectReason}
          disableEnforceFocus={true}
          onClose={() => { this.setState({ "openRejectReason": false }) }}
        >
          <DialogTitle>
            <div className="modalControllArea">
              <div onClick={() => { this.setState({ "openRejectReason": false }) }}>
                <i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
              </div>
            </div>
          </DialogTitle>
          <DialogContent dividers>
            <div>
              <div>退回原因:</div>
              <div style={{ "display": "flex" }}>
                <TextareaAutosize style={{
                  "borderRadius": "5px",
                  "width": "80%",
                  "margin": "10px auto",
                  "height": "150px"
                }} onChange={this.handleChangeSaveLocal} name="rejectReason"></TextareaAutosize>
              </div>
              <div style={{ "display": "flex" }}>
                {this.state.submitRjectReasonLoading ?
                  <button style={{
                    "margin": "0 auto",
                    "background": "#871e8c",
                    "padding": "8px 15px",
                    "borderRadius": "5px",
                    "color": "#ffffff",
                    "cursor": "pointer"
                  }}>
                    <Loader
                      type="Oval"
                      color="#ffffff"
                      height={22}
                      width={22}
                      visible={true}
                    /></button> :
                  <button style={{
                    "margin": "0 auto",
                    "background": "#871e8c",
                    "padding": "8px 15px",
                    "borderRadius": "5px",
                    "color": "#ffffff",
                    "cursor": "pointer"
                  }} onClick={this.rejectWaitingApprove}>確定</button>
                }
              </div>
            </div>
          </DialogContent>
        </StyledDialog>
      </div>
    );
  }
}

export default withRouter(PlanDetail);
