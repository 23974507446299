import React, { Component } from 'react'
import './LessonPlans.less'

import axios from 'axios';
import LocalStorage from '../../Helpers/LocalStorage'
import moment from 'moment';
import ReactModal from 'react-modal';
import { withRouter } from "react-router-dom";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import PlanDetail from "../LessonPlans/PlanDetail";

import { withTranslation } from 'react-i18next';

class Lessonplans extends Component {

    constructor(props) {
        super(props);
        ReactModal.setAppElement('body')
        this.state = {
            planlist: [],
            openModal: false,
            isChanged: false
        };

        this.delta = this.getPlans.bind(this)
        if (this.props.match.params.action === "lessonPlan") {
            this.state.openModal = true
            this.state.lessonPlanContent = <PlanDetail setIsChanged={this.setIsChanged} />
        }
    }

    editorPlan = (e) => {
        this.props.history.push(`/management/lessonPlan/${e.target.value}`)
    }

    deletePlan = (id) => {

        let token = this.getAuthorize();

        console.log('deletePlan id:', id)
        axios.delete(
            `${window.CONFS.apiServer}/lessonplan/${id}`,
            {
                headers: { "X-Authorize": token }
            }).then(res => {
                console.log('delete plan =>', res.data);
                // this.setState({deletePlan: res.data});
                window.location = '/management/lessonPlans';
            });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            if (this.props.match.params.action === "lessonPlan") {
                this.setState({ lessonPlanContent: <PlanDetail setIsChanged={this.setIsChanged} setAllApprove={this.setAllApprove} />, openModal: true })
            }
            else {
                this.setState({ lessonPlanContent: <PlanDetail setIsChanged={this.setIsChanged} setAllApprove={this.setAllApprove} />, openModal: false }, () => {
                    this.getPlans()
                })
            }
        }
    }

    componentDidMount() {
        this.getPlans();
        if (this.props.match.params.action === "lessonPlan") {
            this.setState({ lessonPlanContent: <PlanDetail setIsChanged={this.setIsChanged} setAllApprove={this.setAllApprove} />, openModal: true })
        }
        else {
            this.setState({ lessonPlanContent: <PlanDetail setIsChanged={this.setIsChanged} setAllApprove={this.setAllApprove} />, openModal: false }, () => {
                this.getPlans()
            })
        }
    }

    getPlans() {

        axios.get(`${window.CONFS.apiServer}/lessonplans`,
            {
                headers: {
                    "X-Authorize": this.getAuthorize()
                }
            })
            .then(res => {
                if (res.data.errorCode === 0)
                    this.setState({ planlist: res.data.data })

                if (res.data.errorCode !== null && res.data.errorCode !== 0 && res.data.errorMessage !== null) {
                    if (res.data.errorCode === 1000) {
                        this.props.history.push('/signin')
                    }
                    window.alert(res.data.errorMessage)
                }
            })
            .catch(function (error) {
                console.log('fail get lesson plan')
                console.log(error);
            });

    }

    getAuthorize = () => {
        let nthusteam = LocalStorage.getLocalStorage("nthusteam")
        if (nthusteam != null && nthusteam.apiToken != null) {
            return nthusteam.apiToken;
        } else {
            this.props.history.push('/signin');
        }
    }

    newPlan = () => {
        this.props.history.push('/management/lessonPlan')
    }

    resolveKeywords = keywords => {
        let content = ""
        content = keywords.map((c, k) => {
            return <div key={k}>{c}</div>
        })
        return content
    }

    generateLessonPlanTitle = (lessonPlan) => {
        let lessonPlanTitle = lessonPlan.title
        if (lessonPlan.allApprove) {
            lessonPlanTitle += " <span style='color:blue'>(審核通過)</span>"
        }
        else {
            if (lessonPlan.waitingApprove) {
                lessonPlanTitle += " <span style='color:#d9534f'>(審核中)</span>"
            }
        }
        return lessonPlanTitle
    }

    setAllApprove = (isAllApprove) => {
        this.setState({isAllApprove: isAllApprove});
    }

    setIsChanged = (isChanged) => {
        this.setState({isChanged: isChanged});
    }
    checkConfirm = (id) => {
        // <button onClick={this.deleteStudent}>{t('step3.delete')}</button>
        const { t } = this.props
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui alertButtonBox'>
                        <h1>確定刪除此教案嗎？</h1>
                        {/* <p>You want to delete this file?</p> */}
                        <button onClick={onClose}>取消</button>
                        <button
                            onClick={() => {
                                this.deletePlan(id);
                                onClose();
                            }}
                        >

                            {t('lessonPlans.delete')}
                        </button>
                        {/* <button className="deletebtn" value={plan._id} onClick={this.deletePlan}>{t('lessonPlans.delete')}</button> */}
                    </div>
                );
            }
        });
    };

    render() {

        const { t } = this.props;

        return (

            <div className="planslist">
                <div className="plansBanner">
                    <p className="listHeader">{t('lessonPlans.list')}</p>
                    <button className="newPlanBtn" onClick={this.newPlan}>{t('lessonPlans.addNew')}</button>
                </div>
                <div className="planslist__content">
                    <table border="1">
                        <thead>
                            <tr>
                                <th >{t('lessonPlans.name')}</th>
                                <th>{t('lessonPlans.year')}</th>
                                <th style={{ "width": "200px" }}>{t('lessonPlans.keyword')}</th>
                                <th style={{ "width": "120px" }}>{t('lessonPlans.updateDate')}</th>
                                <th style={{ "width": "90px" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.planlist.map((plan, key) =>
                                    <tr key={key}>
                                        <td dangerouslySetInnerHTML={{ __html: this.generateLessonPlanTitle(plan) }}></td>
                                        <td>{plan.grade && plan.grade.gradeNames['tw']}</td>
                                        <td>
                                            {(plan.keywords && plan.keywords.length > 0) &&
                                                <div className="keywordArea">
                                                    <div className="count">{plan.keywords.length}</div>
                                                    <div className="keywords" title={plan.keywords}>
                                                        {this.resolveKeywords(plan.keywords)}
                                                    </div>
                                                </div>
                                            }
                                        </td>
                                        <td>{moment(plan.modifiedAt).format('YYYY/MM/DD HH:mm')}</td>
                                        <td style={{ "textAlign": "left" }}>
                                            {!plan.allApprove && !plan.waitingApprove ?
                                                <button className="editor" value={plan._id} onClick={this.editorPlan} >{t('lessonPlans.editor')}</button> :
                                                <button className="editor" value={plan._id} onClick={this.editorPlan} >{t('lessonPlans.lookFor')}</button>
                                            }
                                            {!plan.allApprove && !plan.waitingApprove &&
                                                <button className="deletebtn" onClick={() => this.checkConfirm(plan._id)}>{t('lessonPlans.delete')}</button>
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <ReactModal
                    isOpen={this.state.openModal}
                    shouldCloseOnOverlayClick={false}
                    onRequestClose={() => { if (this.closeWindowAndCheck()) { this.props.history.push('/management/lessonPlans') } }}
                    style={{ overlay: { "background": "#000000a3" }, content: { "width": "80%", "height": "85vh", "margin": "0 auto" } }}
                >
                    <div className="modalControllArea">
                        <div onClick={() => { if (this.closeWindowAndCheck()) { this.props.history.push('/management/lessonPlans') } }}>
                            <i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
                        </div>
                    </div>
                    {this.state.lessonPlanContent}
                </ReactModal>
            </div>

        )
    }

    closeWindowAndCheck = ()=>{
        if(!this.state.isChanged){
            return true
        }
        return window.confirm("您尚未儲存, 確定要關閉編輯?");
    }
}

export default withTranslation()(withRouter(Lessonplans));