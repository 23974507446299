import axios from 'axios';
import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import './TeachersManagement.less';

class TeachersManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props,
            loading: false,
            showSaveBtn: false,
            filterString: "",
            showErrorMsg: false,
            responseMsg: ""
        }
        this.state.selectedTeachers.forEach(teacher => {
            teacher.updateAdminFullName = teacher.adminFullName;
            teacher.updateAdminIdentifyID = teacher.updateAdminIdentifyID;
        })
    }

    handleChange = (e) => {
        let filterString = e.target.value
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            if (filterString.trim().length >= 1) {
                this.setState({
                    teachers: this.updateHideAttributeTeacherItem(filterString.trim())
                })
            }
        });

    }

    updateHideAttributeTeacherItem = (filterString) => {
        let teachers = JSON.parse(JSON.stringify(this.state.teachers))
        let regex = new RegExp(`^${filterString}.*`, "g")
        teachers.forEach(teacher => {
            let isHide = true
            Object.keys(teacher).forEach(key => {
                if (isHide) {
                    isHide = !regex.test(teacher[key])
                }
            })

            if (isHide) {
                isHide = !regex.test(teacher.firstName + teacher.lastName)
            }

            teacher.isHide = isHide
        })
        return teachers;
    }

    joinTeacherIntoCourse = (GUID) => {
        let teacher = this.state.teachers.find(teacher => teacher.GUID === GUID)
        teacher.isCheckedIn = teacher.isCheckedIn ? true : false
        teacher.isCheckedOut = teacher.isCheckedOut ? true : false
        let selectedTeachers = [...this.state.selectedTeachers, teacher]
        this.processJoinedTeachersOfCourse(selectedTeachers)
    }

    removeTeacherFromCourse = (GUID) => {
        let selectedTeachers = this.state.selectedTeachers.filter(obj => obj.GUID != GUID)
        this.processJoinedTeachersOfCourse(selectedTeachers)
    }

    processJoinedTeachersOfCourse = (selectedTeachers) => {
        let teachers = JSON.parse(JSON.stringify(this.state.teachers))
        teachers.forEach(teacher => { teacher.isSelected = false })
        let newSelectedTeacherIds = selectedTeachers.map(obj => { return obj.GUID })
        let selectedTeachersOfOri = teachers.filter(teacher => !(newSelectedTeacherIds.indexOf(teacher.GUID) < 0))
        selectedTeachersOfOri.forEach(selectedTeacher => {
            selectedTeacher.isSelected = true
        })
        this.setState({
            selectedTeachers: selectedTeachers,
            teachers: teachers
        })
    }

    isShowSaveBtn = () => {

        if (!this.state.name) {
            return false
        }

        if (!this.state.openDateTime) {
            return false
        }

        if (!this.state.hours || !(/^\d+\.{0,1}\d*$/g).test(this.state.hours) || !parseFloat(this.state.hours) > 0) {
            return false
        }

        if (!this.state.url || !(/^(http:\/\/|https:\/\/)/gi).test(this.state.url)) {
            return false
        }
        return true
    }

    saveChang = () => {
        this.setState({ "loading": true, responseMsg: "" })
        const { courseId, selectedTeachers } = this.state
        let selectedTeacherObjects = selectedTeachers.map(teacher => {
            return {
                GUID: teacher.GUID,
                isCheckedIn: teacher.isCheckedIn,
                isCheckedOut: teacher.isCheckedOut,
                adminFullName: teacher.updateAdminFullName === teacher.adminFullName ? null : teacher.updateAdminFullName,
                adminIdentifyID: teacher.adminIdentifyID === teacher.updateAdminIdentifyID ? null : teacher.updateAdminIdentifyID,
                courseCertificate: teacher.courseCertificate || null
            }
        })
        console.log(selectedTeacherObjects)
        this.createAndUpdateTeachersOfCourse(courseId, selectedTeacherObjects).then(() => {
            // this.props.closeTeacherModal()
            this.getParticipatedTeachersStatus(courseId).then(teachers => {

                teachers.forEach(teacher => {
                    teacher.isCheckedIn = teacher.isCheckedIn ? true : false
                    teacher.isCheckedOut = teacher.isCheckedOut ? true : false
                })
                let selectedTeachers = teachers.filter(teacher => teacher.isSelected)
                selectedTeachers.forEach(selectedTeacher=>{
                    if(!(selectedTeacher.adminFullName && selectedTeacher.adminIdentifyID)){
                        selectedTeacher.adminFullName = null;
                        selectedTeacher.adminIdentifyID = null;
                    }
                })
                this.setState({
                    "loading": false,
                    showErrorMsg: false,
                    responseMsg: "儲存成功",
                    teachers: teachers,
                    selectedTeachers: selectedTeachers
                })
                // this.setState({
                //     waitingParticipatedTeachersOfCourse: false,
                //     openTeacherModal: true,
                //     teacherPage: <TeachersManagement
                //         courseId={courseId}
                //         courseName={this.props.name}
                //         teachers={teachers}
                //         closeTeacherModal={this.closeTeacherModal}
                //         selectedTeachers={selectedTeachers} />
                // })
            })

        }).catch((res) => {
            let errorData = res.response.data.data;
            this.setState({
                "loading": false,
                showErrorMsg: true,
                responseMsg: errorData + "已設定"
            })
            console.log(res.response.data.data)
        })
    }

    createAndUpdateTeachersOfCourse = (courseId, teachersOfCourse) => {
        return new Promise((resolve, error) => {
            axios.post(window.CONFS.apiServer + `/admin/course/${courseId}/teachers`, teachersOfCourse)
                .then(res => {
                    if (res.data.errorCode > 0) {
                        console.log(res)
                    }
                    else {
                        resolve(res)
                    }
                })
                .catch(function (res) {
                    error(res)
                })
        });
    }

    getParticipatedTeachersStatus = (courseId) => {
        return new Promise(resolve => {
            axios.get(window.CONFS.apiServer + `/admin/course/${courseId}/participated-teachers-status`)
                .then(res => {
                    if (res.data.errorCode > 0) {
                        console.log(res)
                    }
                    else {
                        resolve(res.data.data)
                    }
                })
                .catch(function (error) {
                    console.log('fail post =>', error)
                });
        })
    }

    changeCheckedStatus = (GUID, type) => {
        let selectedTeachers = JSON.parse(JSON.stringify(this.state.selectedTeachers))
        let selectedTeacher = selectedTeachers.find(teacher => teacher.GUID === GUID)
        selectedTeacher[type] = !selectedTeacher[type]

        this.setState({
            selectedTeachers: selectedTeachers
        })
    }

    setAdminUserFullName = (e) => {
        let GUID = e.target.getAttribute("data-guid");
        let type = e.target.getAttribute("data-type");
        let selectedTeachers = JSON.parse(JSON.stringify(this.state.selectedTeachers))
        let selectedTeacher = selectedTeachers.find(teacher => teacher.GUID === GUID)
        if (type === "fullName") {
            selectedTeacher.updateAdminFullName = e.target.value;
        }
        else {
            selectedTeacher.updateAdminIdentifyID = e.target.value;
        }

        this.setState({
            selectedTeachers: selectedTeachers
        })
    }

    setCourseCertificateCode = (e) => {
        let GUID = e.target.getAttribute("data-guid");
        let selectedTeachers = JSON.parse(JSON.stringify(this.state.selectedTeachers))
        let selectedTeacher = selectedTeachers.find(teacher => teacher.GUID === GUID)
        selectedTeacher.courseCertificate = { ...selectedTeacher.courseCertificate, updateCode: e.target.value };

        this.setState({
            selectedTeachers: selectedTeachers
        })
    }

    removeCourseCertificateCode = (e) => {
        let GUID = e.target.getAttribute("data-guid");
        let selectedTeachers = JSON.parse(JSON.stringify(this.state.selectedTeachers))
        let selectedTeacher = selectedTeachers.find(teacher => teacher.GUID === GUID)
        selectedTeacher.courseCertificate.isRemoved = true;
        console.log({ selectedTeachers: selectedTeachers })
        this.setState({
            selectedTeachers: selectedTeachers
        })
    }

    // setUserCourse

    render() {
        return (
            <div className="TeachersManagement">
                <div className="Admin__row TeachersManagement__title">
                    {this.props.courseName}-參與教師管理
                </div>
                <div className="Admin__row TeachersManagement__control_panel">
                    <i className="fas fa-search"></i><input type="text" name="filterString" className="textInput" onChange={this.handleChange} />
                    {this.state.selectedTeachers.length > 0 && <div className="Admin__row">已加入 {this.state.selectedTeachers.length} 位</div>}
                </div>
                <div className="Admin__row TeachersManagement__content">
                    <div className="Admin__row TeachersManagement__unselected_content">
                        {this.state.teachers && this.state.teachers.map((teacher, key) => {
                            return this.state.filterString.trim().length >= 1 && teacher.isHide ?
                                null : !teacher.isSelected &&
                                <div className="teacher-item" onClick={() => { this.joinTeacherIntoCourse(teacher.GUID) }} key={key} title={teacher.account}>
                                    {`${teacher.firstName + teacher.lastName}`}
                                </div>
                        })}</div>
                    <div className="Admin__row TeachersManagement__selected_content">
                        {this.state.selectedTeachers.map((teacher, key) => {
                            return <div className="teacher-item" key={key} title={teacher.account}>
                                <div className='row'>
                                    <div className="left-area">
                                        <div className="remove-btn" onClick={() => { this.removeTeacherFromCourse(teacher.GUID) }}>
                                            {!(teacher.courseCertificate && teacher.courseCertificate.code) && <i className="fas fa-times" style={{ "fontSize": "25px" }}></i>}
                                        </div>
                                        {`${teacher.firstName + teacher.lastName}`}
                                    </div>
                                    <div className="right-area">
                                        <div>Checked
                                            <div onClick={() => this.changeCheckedStatus(teacher.GUID, "isCheckedIn")} className={`option ${teacher.isCheckedIn && 'selected'}`}>In</div>/
                                            <div onClick={() => this.changeCheckedStatus(teacher.GUID, "isCheckedOut")} className={`option ${teacher.isCheckedOut && 'selected'}`}>Out</div></div>
                                    </div>
                                </div>
                                <div className='row'>
                                    {this.generateAdminArea(teacher)}
                                </div>
                                <div className='row'>
                                    {this.generateCertificate(teacher)}
                                </div>
                            </div>
                        })}
                    </div>
                </div>

                <div className="Admin__row TeachersManagement__save_area">
                    {this.state.loading ? <Loader
                        type="Oval"
                        color="#7f1083"
                        height={22}
                        width={22}
                        visible={true}
                    /> :
                        <>
                            <div><button className="saveBtn" onClick={this.saveChang}>儲存</button></div>
                            {this.state.showErrorMsg && <div className={this.state.showErrorMsg ? "error-msg" : "success-msg"}>{this.state.responseMsg}</div>}
                        </>}
                </div>
            </div>
        );
    }

    generateAdminArea = (teacher) => {
        let isDisabled = (teacher.adminFullName && teacher.adminIdentifyID);
        return <div className='admin-info'>
            <div className='admin-full-name'>
                <div className='title'>姓名</div><input onKeyUp={this.setAdminUserFullName} data-guid={teacher.GUID} data-type="fullName" defaultValue={teacher.adminFullName} disabled={isDisabled} />
            </div>
            <div className='admin-id'>
                <div className='title'>身分證字號</div><input onKeyUp={this.setAdminUserFullName} data-guid={teacher.GUID} data-type="identifyId" defaultValue={teacher.adminIdentifyID} disabled={isDisabled} />
            </div>
        </div>;
    }

    generateCertificate = (teacher) => {
        let isAvailable = teacher.isCheckedIn && teacher.isCheckedOut && teacher.adminFullName && teacher.adminIdentifyID;
        let isShow = (teacher.courseCertificate && teacher.courseCertificate.code) || isAvailable;
        let isDisalbled = teacher.courseCertificate && teacher.courseCertificate.code && !teacher.courseCertificate.isRemoved;
        let defaultValue = teacher.courseCertificate ? teacher.courseCertificate.isRemoved ? teacher.courseCertificate.updateCode : teacher.courseCertificate.code : "";
        let isShowDeleteBtn = teacher.courseCertificate && teacher.courseCertificate.code && !teacher.courseCertificate.isRemoved;
        let divKey = (teacher.courseCertificate && teacher.courseCertificate.isRemoved) ? teacher.courseCertificate.isRemoved : false;

        return <>{isShow && <div className='admin-info' key={divKey}>
            <div className="title">活動憑證</div>
            {isShowDeleteBtn && <button className='delete-certification' onClick={this.removeCourseCertificateCode} data-guid={teacher.GUID}>刪除</button>}
            <input onKeyUp={this.setCourseCertificateCode} data-guid={teacher.GUID} defaultValue={defaultValue} disabled={isDisalbled} />
        </div>}</>;
    }
}

export default TeachersManagement;