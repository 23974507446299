import React, { Component } from 'react'
import './MemberCertifications.less'
import Loader from 'react-loader-spinner'
import axios from 'axios';
import Moment from 'react-moment'
import ReactModal from 'react-modal'
import MemberLessonPlans from './MemberLessonPlans'
import MemberCertificationDetail from './MemberCertificationDetail'
import { withRouter } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid';
import { Dialog, DialogTitle, DialogContent, withStyles } from '@material-ui/core';

class MemberCertifications extends Component {
	constructor(props) {
		super(props);
		this.state = {
			certifications: [],
			loading: false,
			openMemberCertificationDetailModal: false,
			memberCertificationDetailModal: <MemberCertificationDetail />,
		}

		this.columns = [
			{ field: 'certificationNumber', headerName: '憑證號碼', flex: 1 },
			{
				field: 'col1', headerName: ' ', width: 80, renderCell: (params) => (
					<button type="button" onClick={() => this.props.history.push(`/admin/membership/approved_certification/${params.getValue('GUID')}/certification/${params.getValue('_id')}/`)} className="detailBtn">查看</button>
				)
			},
			{
				field: 'col2', headerName: ' ', width: 80, renderCell: (params) => (
					<button type="button" onClick={() => this.rejectCertification(params.getValue('_id'))} className="deleteBtn">退回</button>
				)
			}
		]
	}

	componentDidMount = () => {
		this.searchCertifications();
		if (this.props.match.params.actionId) {
			this.initModalSetting()
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.initModalSetting()
		}
	}

	initModalSetting = () => {
		if (this.props.match.params.actionId) {
			this.setState({ memberCertificationDetailModal: <MemberCertificationDetail />, openMemberCertificationDetailModal: true })
		}
		else {
			this.setState({ openMemberCertificationDetailModal: false })
		}
	}

	searchCertifications = () => {
		this.setState({ "loading": true }, async () => {
			let certifications = await this.searchCertificationsAction()
			certifications.forEach(certification => {
				certification.id = certification._id
			})
			this.setState({ certifications: certifications, loading: false })
		})
	}

	searchCertificationsAction = () => {
		return new Promise(resolve => {
			axios.get(window.CONFS.apiServer + '/admin/certifications/' + this.props.match.params.GUID)
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res.data.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				});
		})
	}

	rejectCertification = async (certificationId) => {
		if (!window.confirm("確定要退回憑證?")) {
			return
		}

		await axios.delete(window.CONFS.apiServer + '/admin/certificate/' + certificationId)
		this.searchCertifications()
		this.props.searchMember()
	}



	render() {
		const StyledDialog = withStyles({
			paper: {
				width: "60%",
				top: "20px"
			}
		})(Dialog);
		return (
			<div className="MemberCertifications">
				<div className="MemberCertifications__content">
					{this.state.loading ? <Loader
						type="Oval"
						color="#7f1083"
						height={22}
						width={22}
						visible={true}
					/> :
						<DataGrid
							rowsPerPageOptions={[10]}
							scrollbarSize={30}
							rows={this.state.certifications}
							columns={this.columns} />}
				</div>
				<StyledDialog
					maxWidth={false}
					disableBackdropClick={true}
					fullWidth={true}
					open={this.state.openMemberCertificationDetailModal}
					onClose={() => { this.props.history.push('../../certifications/') }}
				>
					<DialogTitle>
						<div className="modalControllArea">
							<div onClick={() => { this.props.history.push('../../certifications/') }}>
								<i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
							</div>
						</div>
					</DialogTitle>
					<DialogContent dividers>
						{this.state.memberCertificationDetailModal}
					</DialogContent>
				</StyledDialog>
			</div>
		);
	}
}

export default withRouter(MemberCertifications);