import React, { useRef } from 'react';
import './PointerItem.less'
import Moment from 'react-moment'
import { useDrag, useDrop } from 'react-dnd';



const PointerItem = (props) => {
    const {pointerItem, id, moveItem, index} = props
	const ref = useRef(null);
    const [, drop] = useDrop({
        accept: 'card',
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?ref.current.getBoundingClientRect():null;
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveItem(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        item: { type: 'card', id, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drag(drop(ref));
	return (
		<div className="PointerManagement__item" ref={ref}>
			<div className="PointerManagement__item__attr" title={(pointerItem.class.description && pointerItem.class.description.tw) ? pointerItem.class.description.tw : ""}
				dangerouslySetInnerHTML={{ __html: `${pointerItem.class.title.tw.replaceAll(/\r\n|\r|\n/g, '<br/>')} ${pointerItem.class.removed ? '(Removed)' : ''}` }}>
			</div>
			<div className="PointerManagement__item__attr" dangerouslySetInnerHTML={{ __html: pointerItem.title.tw.replaceAll(/\r\n|\r|\n/g, '<br/>') }}></div>
			<div className="PointerManagement__item__attr">{pointerItem.positions.map((position, key) => {
				return <div key={key}>{position.displayName}</div>
			})}
			</div>
			<div className="PointerManagement__item__attr"><Moment format="YYYY/MM/DD HH:mm">{pointerItem.modifiedAt}</Moment></div>
			<div className="PointerManagement__item__attr">
				<button type="button" className="PointerManagement__content__editBtn" onClick={() => props.openPointerPage(pointerItem._id)}>編輯</button>
				<button type="button" className="PointerManagement__content__deleteBtn" onClick={() => props.deletePointer(pointerItem._id)}>刪除</button>
			</div>
		</div>
	);
}

export default PointerItem;