import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';


window.CONFS = {
	apiServer: 'http://127.0.0.1:23369',
	storageUrl: "http://127.0.0.1:8000",
	adminAxiosSetting: {},
	memberAxiosSetting: {}
}
if (process.env.REACT_APP_ENV) {
	window.CONFS.apiServer = process.env.REACT_APP_API_SERVER
	window.CONFS.storageUrl = process.env.REACT_APP_CLOUD_SERVER
}
console.log({ "window.CONFS": window.CONFS })
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
