import React, { Component } from "react";

class BackHome extends Component {
    //   constructor(props) {
    //     super(props);
    //     this.state = {
    //       visible: false
    //     }
    //   }

    goBackToManagement = () => {
        window.location = "/management/"
    }

    render() {

        return (
            <div className="">
                {
                    this.goBackToManagement()
                }

            </div>
        );
    }
}

export default BackHome;
