import React, { Component } from "react";
import axios from "axios";

import LocalStorage from "../../../Helpers/LocalStorage";
import "./PlanResouce.less";


import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { withTranslation } from 'react-i18next';

class PlanResouce extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  openNewWindows = (url) => {
    window.open(window.CONFS.storageUrl + "/" + url)
  }

  onChangeHandler = event => {
    const data = new FormData();

    let validFilesTypes = ["docx", "doc", "pdf"];
    // let validFilesTypes = ['png', 'jpg', 'jpeg', 'gif'];

    let id = event.target.id;
    let ext = "";
    if (event.target.files[0]) {

      let filePath = event.target.files[0].name;
      let temp = filePath.split(".");
      ext = temp[temp.length - 1];
      // filePath.endsWith('.pdf')

      if (!validFilesTypes.includes(ext)){
        console.warn(event.target.files[0].name + " is not " + validFilesTypes);
        this.checkConfirm();
      }


      Object.defineProperty(event.target.files[0], 'name', {
        writable: true,
        value: encodeURIComponent(event.target.files[0].name)
      });
  
    }

   

    data.append("file[]", event.target.files[0]);
    let token = this.getAuthorize();
    event.target.classList.add("uping");

    axios
      .post(`${window.CONFS.apiServer}/sys/uploadFiles`, data, {
        headers: { "X-Authorize": token }
      })
      .then(res => {
        console.log(res.data.data);

        let name = res.data.data[0].fileName;
        let uuid = res.data.data[0].uuidName;
        // window.open("https://storage.hisoft.site/" + res.data.data[0].uuidName)
        this.setState({ [id]: { fileName: name, uuidName: uuid } });

        let level1;
        let level2;
        let lessonMap;
        let { certificationData = {} } = this.props;

        if (id === "file1" || id === "file2") {
          level1 = certificationData.level1 ? certificationData.level1 : [];
          if (id === "file1") {
            level1[0] = { fileName: name, uuidName: uuid }
          }
          if (id === "file2") {
            level1[1] = { fileName: name, uuidName: uuid }
          }
          certificationData.level1 = level1;
        } else if (id === "file3" || id === "file4") {
          level2 = certificationData.level2 ? certificationData.level2 : [];

          if (id === "file3") {
            level2[0] = { fileName: name, uuidName: uuid }
          }
          if (id === "file4") {
            level2[1] = { fileName: name, uuidName: uuid }
          }

          certificationData.level2 = level2;
        } else if (id === "file5" || id === "file6") {
          lessonMap = certificationData.lessonMap ? certificationData.lessonMap : [];

          if (id === "file5") {
            lessonMap[0] = { fileName: name, uuidName: uuid }
          }
          if (id === "file6") {
            lessonMap[1] = { fileName: name, uuidName: uuid }
          }

          certificationData.lessonMap = lessonMap;
        }
        this.props.setAllState("certificationData", certificationData);
      })
      .catch(function (error) {
        console.log("fail post", error);
      });
  };

  checkConfirm = () => {
    // <button onClick={this.deleteStudent}>{t('step3.delete')}</button>
    const { t } = this.props
    confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui alertButtonBox'>
              <h1>請上傳 Word 或是 PDF </h1>
              {/* <p>You want to delete this file?</p> */}
              <button onClick={onClose}>確認</button>
              {/* <button
                onClick={() => {
                    this.deleteInstances(indexKey);
                  onClose();
                }}
              >
                {t('step3.delete')}
              </button> */}
            </div>
          );
        }
      });
  };
  

  deleteLevelX = (id) => {

    let level1;
    let level2;
    let lessonMap;
    let { certificationData = {} } = this.props;

    if (id === "file1" || id === "file2") {
      level1 = certificationData.level1 ? certificationData.level1 : [];
      if (id === "file1") {
        level1[0] = false
      }
      if (id === "file2") {
        level1[1] = false
      }
      certificationData.level1 = level1;
    } else if (id === "file3" || id === "file4") {
      level2 = certificationData.level2 ? certificationData.level2 : [];

      if (id === "file3") {
        level2[0] = false
      }
      if (id === "file4") {
        level2[1] = false
      }

      certificationData.level2 = level2;
    } else if (id === "file5" || id === "file6") {
      lessonMap = certificationData.lessonMap ? certificationData.lessonMap : [];

      if (id === "file5") {
        lessonMap[0] = false
      }
      if (id === "file6") {
        lessonMap[1] = false
      }

      certificationData.lessonMap = lessonMap;
    }

    this.props.setAllState("certificationData", certificationData);
  }

  getAuthorize() {
    let nthusteam = LocalStorage.getLocalStorage("nthusteam");
    if (nthusteam != null && nthusteam.apiToken != null) {
      return nthusteam.apiToken;
    } else {
      this.props.history.push("/signin");
    }
  }

  render() {
    const { certificationData = {}, checkOwner, t } = this.props;
    return (
      <div className="resourceBody">
        <h3 className="resourceTile">{t('step1.data')}</h3>
        <table className="resourceTable" border="1">
          <tbody>
            <tr>
              <td className="title">{t('step1.step1plan')}</td>
              <td>
                <div className="fileUpBox">
                  {
                    checkOwner
                      ? <div>

                        {
                          certificationData.level1 && certificationData.level1[0] ? (
                            <div className="uploadedFile">
                              <div onClick={ () => this.deleteLevelX('file1')} >
                                <i className="fas fa-times" />
                              </div>
                              <div className="uploadFileName" onClick={e => this.openNewWindows(certificationData.level1[0].uuidName)}>
                                {certificationData.level1[0].fileName}
                              </div>
                            </div>
                          ) : (
                              <label htmlFor="file1" className="upFile">
                                <div> WORD</div>
                              </label>
                            )}
                        <input
                          className="file"
                          type="file"
                          id="file1"
                          onChange={this.onChangeHandler}
                          accept=".doc,.docx"
                        />
                      </div>
                      : <div>
                        {
                          certificationData.level1 && certificationData.level1[0] ? (
                            <div className="uploadedFile">
                              <div>
                              </div>
                              <div className="uploadFileName">
                                {certificationData.level1[0].fileName}
                              </div>
                            </div>
                          ) : (
                              <label htmlFor="file1" className="upFile">
                                <div> WORD</div>
                              </label>
                            )}
                      </div>
                  }


                  {
                    checkOwner
                      ? <div>
                        {
                          certificationData.level1 && certificationData.level1[1] ? (
                            <div className="uploadedFile">
                              <div onClick={() => this.deleteLevelX('file2')} >
                                <i className="fas fa-times" />
                              </div>
                              <div className="uploadFileName" onClick={e => this.openNewWindows(certificationData.level1[1].uuidName)}>
                                {certificationData.level1[1].fileName}
                              </div>
                            </div>
                          ) : (
                              <label htmlFor="file2" className="upFile">
                                <div> PDF</div>
                              </label>
                            )}
                        <input className="file" type="file" id="file2" onChange={this.onChangeHandler} accept=".pdf" />

                      </div>
                      : <div>
                        {
                          certificationData.level1 && certificationData.level1[1] ? (
                            <div className="uploadedFile">
                              <div>
                              </div>
                              <div className="uploadFileName">
                                {certificationData.level1[1].fileName}
                              </div>
                            </div>
                          ) : (
                              <label htmlFor="file2" className="upFile">
                                <div> PDF</div>
                              </label>
                            )}
                      </div>
                  }
                </div>
              </td>
            </tr>
            <tr>
              <td className="title">{t('step1.step2instance')}</td>
              <td>
                <div className="fileUpBox">

                  {
                    checkOwner
                      ? <div>
                        {
                          certificationData.level2 && certificationData.level2[0] ? (
                            <div className="uploadedFile">
                              <div onClick={() => this.deleteLevelX('file3')} >
                                <i className="fas fa-times" />
                              </div>
                              <div className="uploadFileName" onClick={e => this.openNewWindows(certificationData.level2[0].uuidName)}>
                                {certificationData.level2[0].fileName}
                              </div>
                            </div>
                          ) : (
                              <label htmlFor="file3" className="upFile">
                                <div> WORD</div>
                              </label>
                            )}
                        <input
                          className="file"
                          type="file"
                          id="file3"
                          onChange={this.onChangeHandler}
                          accept=".doc,.docx"
                        />
                      </div>
                      : <div>

                        {
                          certificationData.level2 && certificationData.level2[0] ? (
                            <div className="uploadedFile">
                              <div>
                              </div>
                              <div className="uploadFileName">
                                {certificationData.level2[0].fileName}
                              </div>
                            </div>
                          ) : (
                              <label htmlFor="file3" className="upFile">
                                <div> WORD</div>
                              </label>
                            )}
                      </div>
                  }


                  {
                    checkOwner
                      ? <div>
                        {
                          certificationData.level2 && certificationData.level2[1] ? (
                            <div className="uploadedFile">
                              <div onClick={() => this.deleteLevelX('file4')} >
                                <i className="fas fa-times" />
                              </div>
                              <div className="uploadFileName" onClick={e => this.openNewWindows(certificationData.level2[1].uuidName)}>
                                {certificationData.level2[1].fileName}
                              </div>
                            </div>
                          ) : (
                              <label htmlFor="file4" className="upFile">
                                <div> PDF</div>
                              </label>
                            )}
                        <input
                          className="file"
                          type="file"
                          id="file4"
                          onChange={this.onChangeHandler}
                          accept=".pdf"
                        />
                      </div>
                      : <div>
                        {
                          certificationData.level2 && certificationData.level2[1] ? (
                            <div className="uploadedFile">
                              <div>
                              </div>
                              <div className="uploadFileName">
                                {certificationData.level2[1].fileName}
                              </div>
                            </div>
                          ) : (
                              <label htmlFor="file4" className="upFile">
                                <div> PDF</div>
                              </label>
                            )}
                      </div>

                  }

                </div>
              </td>
            </tr>
            <tr>
              <td className="title">{t('step1.map')}</td>
              <td>
                <div className="fileUpBox">

                  {
                    checkOwner
                      ? <div>
                        {
                          certificationData.lessonMap && certificationData.lessonMap[0] ? (
                            <div className="uploadedFile">
                              <div onClick={() => this.deleteLevelX('file5')} >
                                <i className="fas fa-times" />
                              </div>
                              <div className="uploadFileName" onClick={e => this.openNewWindows(certificationData.lessonMap[0].uuidName)}>
                                {certificationData.lessonMap[0].fileName}
                              </div>
                            </div>
                          ) : (
                              <label htmlFor="file5" className="upFile">
                                <div> WORD</div>
                              </label>
                            )}
                        <input
                          className="file"
                          type="file"
                          id="file5"
                          onChange={this.onChangeHandler}
                          accept=".doc,.docx"
                        />
                      </div>
                      : <div>
                        {
                          certificationData.lessonMap && certificationData.lessonMap[0] ? (
                            <div className="uploadedFile">
                              <div>
                              </div>
                              <div className="uploadFileName">
                                {certificationData.lessonMap[0].fileName}
                              </div>
                            </div>
                          ) : (
                              <label htmlFor="file5" className="upFile">
                                <div> WORD</div>
                              </label>
                            )}
                      </div>
                  }

                  {
                    checkOwner
                      ? <div>
                        {
                          certificationData.lessonMap && certificationData.lessonMap[1] ? (
                            <div className="uploadedFile">
                              <div onClick={() => this.deleteLevelX('file6')} >
                                <i className="fas fa-times" />
                              </div>
                              <div className="uploadFileName" onClick={e => this.openNewWindows(certificationData.lessonMap[1].uuidName)} >
                                {certificationData.lessonMap[1].fileName}
                              </div>
                            </div>
                          ) : (
                              <label htmlFor="file6" className="upFile">
                                <div> PDF</div>
                              </label>
                            )}

                        <input
                          className="file"
                          type="file"
                          id="file6"
                          onChange={this.onChangeHandler}
                          accept=".pdf"
                        />
                      </div>
                      : <div>
                        {
                          certificationData.lessonMap && certificationData.lessonMap[1] ? (
                            <div className="uploadedFile">
                              <div>
                              </div>
                              <div className="uploadFileName">
                                {certificationData.lessonMap[1].fileName}
                              </div>
                            </div>
                          ) : (
                              <label htmlFor="file6" className="upFile">
                                <div> PDF</div>
                              </label>
                            )}
                      </div>
                  }

                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

}

export default withTranslation()(PlanResouce);
