import React, { Component } from 'react'
import './MemberInfo.less'
import Loader from 'react-loader-spinner'
import axios from 'axios'
import Moment from 'react-moment'
import ReactModal from 'react-modal'
import { withRouter } from 'react-router-dom'

class MemberInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			userInfo: {},
			isShowUpdateBtn: false,
			isShowUpdateSuccess: false,
			isUpdating: false
		}

		this.adminFullName = React.createRef();
		this.adminIdentifyID = React.createRef();
	}

	componentDidMount = () => {
		this.setState({ loading: true }, async () => {
			let response = await this.getMemberInfoAction()
			console.log({ response: response.data.data })
			this.setState({
				userInfo: response.data.data,
				loading: false
			})
		})
	}

	getMemberInfoAction = () => {
		return axios.get(window.CONFS.apiServer + '/admin/memberinfo/' + this.props.GUID, {
			params: {
				properties: [
					"account",
					"firstName",
					"lastName",
					"identifyID",
					"memberRole",
					"gender",
					"companyCountry",
					"companyAddress",
					"companyUnit",
					"companyName",
					"country",
					"address",
					"telephone",
					"cellphone",
					"socialType",
					"socialNumber",
					"createdAt",
					"loginTimes",
					"status",
					"lastLoggedInAt",
					"validateEmail",
					"email",
					"isRecieveNotification",
					"teacherCertification",
					"adminFullName",
					"adminIdentifyID"
				]
			}
		})
	}

	changeInputText = () => {
		if (this.adminFullName.current.value !== (this.state.userInfo.adminFullName || "")
			|| this.adminIdentifyID.current.value !== (this.state.userInfo.adminIdentifyID || "")) {
			this.setState({
				isShowUpdateBtn: true,
				isShowUpdateSuccess: false
			})
			return
		}

		this.setState({
			isShowUpdateBtn: false
		})
	}

	updateMemberAdminSettingAction = () => {
		return axios.post(window.CONFS.apiServer + '/admin/memberinfo/' + this.props.GUID, {
			adminFullName: this.adminFullName.current.value,
			adminIdentifyID: this.adminIdentifyID.current.value
		})
	}

	updateMemberAdminSetting = () => {
		this.setState({ isUpdating: true }, async () => {
			let response = await this.updateMemberAdminSettingAction()
			this.setState({
				userInfo: {
					...this.state.userInfo,
					adminFullName: this.adminFullName.current.value,
					adminIdentifyID: this.adminIdentifyID.current.value,
				},
				isShowUpdateBtn: false,
				isShowUpdateSuccess: true,
				isUpdating: false
			})
		})
	}

	render() {
		const userInfo = this.state.userInfo
		return (
			<div className="MemberInfo">
				{this.state.loading ?
					<Loader
						type="Oval"
						color="#7f1083"
						height={22}
						width={22}
						visible={true}
					/> : <>
						<div className="Admin__row underline">
							<div className="Admin__row__item">帳號</div><div className="Admin__row__item2">{userInfo.account}</div>
						</div>
						<div className="Admin__row underline">
							<div className="Admin__row__item">姓名</div><div className="Admin__row__item2">{`${userInfo.firstName} ${userInfo.lastName}`}</div>
						</div>
						<div className="Admin__row underline">
							<div className="Admin__row__item"><i>管理者設定姓名</i></div><div className="Admin__row__item2"><input type="text" disabled={this.state.isUpdating} ref={this.adminFullName} defaultValue={userInfo.adminFullName} onKeyUp={this.changeInputText} /></div>
						</div>
						<div className="Admin__row underline">
							<div className="Admin__row__item">身分證字號</div><div className="Admin__row__item2">{userInfo.identifyID}</div>
						</div>
						<div className="Admin__row underline">
							<div className="Admin__row__item"><i>管理者設定身分證字號</i></div><div className="Admin__row__item2"><input type="text" disabled={this.state.isUpdating} ref={this.adminIdentifyID} defaultValue={userInfo.adminIdentifyID} onKeyUp={this.changeInputText} /></div>
						</div>
						<div className="Admin__row underline">
							<div className="Admin__row__item">性別</div><div className="Admin__row__item2">{userInfo.gender}</div>
						</div>
						<div className="Admin__row underline">
							<div className="Admin__row__item">會員身分</div><div className="Admin__row__item2">{userInfo.memberRole && userInfo.memberRole.tw}</div>
						</div>
						<div className="Admin__row underline">
							<div className="Admin__row__item">教師證</div>
							<div className="Admin__row__item2">{userInfo.teacherCertification ? <a href={window.CONFS.storageUrl + "/" + userInfo.teacherCertification.uuidName} target="_blank">{userInfo.teacherCertification.fileName}</a> : <>未上傳</>}</div>
						</div>
						<div className="Admin__row underline">
							<div className="Admin__row__item">社群帳號</div><div className="Admin__row__item2">{`${userInfo.socialType}: ${userInfo.socialNumber === '0' ? '' : userInfo.socialNumber}`}</div>
						</div>
						<div className="Admin__row underline">
							<div className="Admin__row__item">地址</div><div className="Admin__row__item2">{userInfo.address}</div>
						</div>
						<div className="Admin__row underline">
							<div className="Admin__row__item">手機號碼</div><div className="Admin__row__item2">{userInfo.telephone}</div>
						</div>
						<div className="Admin__row underline">
							<div className="Admin__row__item">學校</div><div className="Admin__row__item2">{userInfo.companyName}</div>
						</div>
						<div className="Admin__row underline">
							<div className="Admin__row__item">學校地址</div><div className="Admin__row__item2">{userInfo.companyAddress}</div>
						</div>
						{this.state.isShowUpdateBtn && <div className="Admin__row underline flex-center">
							{this.state.isUpdating ? <Loader
								type="Oval"
								color="#7f1083"
								height={22}
								width={22}
								visible={true}
							/> : <button onClick={this.updateMemberAdminSetting}>更新</button>}
						</div>
						}
						{this.state.isShowUpdateSuccess && <div className="Admin__row underline flex-center update-success">
							更新成功
						</div>}
					</>}
			</div>
		);
	}
}

export default withRouter(MemberInfo);