import React, { Component } from 'react';
import { withRouter } from "react-router-dom"
import axios from 'axios'
import logo from '../../Assets/Home/logo.png'
import MemberManagement from '../MemberManagement/MemberManagement'
import MemberCertificationsManage from '../MemberManagement/MemberCertificationsManage'
import ApprovedMemberManagement from '../ApprovedMemberManagement/ApprovedMemberManagement'
import MemberSharesManage from '../MemberManagement/MemberSharesManage'
import RecommendResources from '../RecommendResources/RecommendResources'
import LessonPlanResources from '../LessonPlanResources/LessonPlanResources'
import Home from '../PageManagement/Home/Home'
import Courses from '../PageManagement/Courses/Courses'
import Certificates from '../PageManagement/Certificates/Certificates'
import Signature from '../PageManagement/Signature/Signature'
import PointerManagement from '../AbilityManagement/PointerManagement'


import './Dashboard.less'

class Dashboard extends Component {
    // constructor(props) {
    //     super(props);
    // }
    generateMenu(key) {
        if (!key) {
            key = "membership/members"
        }
        const itemDatas = [
            {
                "key": "membership",
                "target": "membership/members",
                "title": "夥伴管理",
                "subItems": [
                    {
                        "key": "membership/members",
                        "title": "會員管理"
                    },
                    {
                        "key": "membership/approved_members",
                        "title": "教案夥伴管理"
                    },
                    {
                        "key": "membership/approved_certification",
                        "title": "認證夥伴管理"
                    },
                    {
                        "key": "membership/shares",
                        "title": "教案教學經驗分享管理"
                    }]
            },
            {
                "key": "ability_management",
                "target": "ability_management/ablility",
                "title": "能力指標管理",
                "subItems": [
                    {
                        "key": "ability_management/ablility",
                        "title": "教師教學能力指標管理"
                    }]
            },
            {
                "key": "pagemanagement",
                "target": "pagemanagement/home",
                "title": "網頁資訊更新",
                "subItems": [
                    {
                        "key": "pagemanagement/home",
                        "title": "首頁頁面管理"
                    },
                    {
                        "key": "pagemanagement/courses",
                        "title": "活動管理"

                    },
                    {
                        "key": "pagemanagement/certificates",
                        "title": "認證層級管理"

                    },
                    {
                        "key": "pagemanagement/signature",
                        "title": "簽名檔管理"

                    }
                ]
            },
            {
                "key": "recommendresources",
                "title": "教學與學習資源推薦"
            },
            {
                "key": "lessonplanresources",
                "title": "教案資源資料庫"
            },
            {
                "key": "logout",
                "title": "登出",
                "logout": () => {
                    axios.interceptors.request.eject(window.CONFS.adminAxiosSetting.requestHeader)
                    axios.interceptors.response.eject(window.CONFS.adminAxiosSetting.responseHandler)
                    this.props.history.push("/admin/login")
                }
            }

        ]

        let menus = itemDatas.map((item, k) => {
            let isActive = false
            let subMenus = null;
            let subPath = item.key + "/" + key
            if (item.subItems) {
                subMenus = item.subItems.map((content, sk) => {
                    isActive = (content.key.toUpperCase() === subPath.toUpperCase())
                    return <div
                        key={sk}
                        className={`submenu ${isActive ? 'active' : ''}`}
                        onClick={() => { this.props.history.push('/admin/' + content.key) }}>{content.title}</div>
                })
                subMenus = <div className="submenus" key="submenus">{
                    subMenus
                }</div>
            }

            isActive = (item.key.toUpperCase() === key.toUpperCase())
            let path = (item.target) ? item.target : item.key
            let menu = <div key={k}></div>
            if (item.key !== "logout") {
                menu = <div
                    key={k}
                    className={`menu ${isActive ? 'active' : ''}`}
                    onClick={() => { this.props.history.push('/admin/' + path) }}>{item.title}</div>
            }
            else {
                menu = <div
                    key={k}
                    className={`menu ${isActive ? 'active' : ''}`}
                    onClick={() => { item.logout(); }}>{item.title}</div>
            }


            return [menu, subMenus]
        })
        return <div>
            {menus}
        </div>
    }

    generateContent = (key) => {
        let content = <MemberManagement />

        if (key && (key.toUpperCase() === "members".toUpperCase() || key.toUpperCase() === "member".toUpperCase()))
            content = <MemberManagement />

        if (key && key.toUpperCase() === "recommendresources".toUpperCase())
            content = <RecommendResources />

        if (key && (key.toUpperCase() === "lessonplanresources".toUpperCase() || key.toUpperCase() === "lessonplanresource".toUpperCase()))
            content = <LessonPlanResources />

        if (key && ("pagemanagement/" + key).toUpperCase() === "pagemanagement/home".toUpperCase())
            content = <Home />

        if (key && ("pagemanagement/" + key).toUpperCase() === "pagemanagement/courses".toUpperCase())
            content = <Courses />

        if (key && ("pagemanagement/" + key).toUpperCase() === "pagemanagement/certificates".toUpperCase())
            content = <Certificates />

        if (key && ("pagemanagement/" + key).toUpperCase() === "pagemanagement/signature".toUpperCase())
            content = <Signature />

        if (key && ("ability_management/" + key).toUpperCase() === "ability_management/ablility".toUpperCase())
            content = <PointerManagement />

        if (key && ("membership/" + key).toUpperCase() === "membership/approved_members".toUpperCase())
            content = <ApprovedMemberManagement />

        if (key && ("membership/" + key).toUpperCase() === "membership/approved_certification".toUpperCase())
            content = <MemberCertificationsManage />

        if (key && ("membership/" + key).toUpperCase() === "membership/shares".toUpperCase() || ("membership/" + key).toUpperCase() === "membership/share".toUpperCase())
            content = <MemberSharesManage />

        return content
    }

    render() {
        return (
            <div className="Dashboard">
                <div className="menus">
                    <div className="logo" style={{ "backgroundImage": "url('" + logo + "')" }}></div>
                    {this.generateMenu(this.props.action)}
                </div>
                <div className="content">
                    {
                        this.generateContent(this.props.action)
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(Dashboard);