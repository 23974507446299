import React, { Component } from 'react'
import axios from 'axios';
import Loader from 'react-loader-spinner'
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal'
import TextField from '@material-ui/core/TextField';
import moment from 'moment'
import './SignatureDetail.less'
import { ThemeProvider } from '@material-ui/core';

class SignatureDetail extends Component {

    constructor(props) {
        super(props)
        this.signatureName = React.createRef();
        this.state = {
            loading: false,
            saveLoading: false,
            signatureName: "",
            uploadedImage: "",
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            if (this.props.mode != "new" && this.props.mode != null) {
                this.getShare(this.props.mode)
            }
            else {
                this.setState({
                    title: "",
                    content: ""
                })
            }
        }
    }

    createSignature = () => {
        if (!(this.signatureName.current.value && this.state.uploadedImage)) {
            alert("標題或圖片未填!")
            return
        }
        this.setState({
            "loading": true
        })
        return new Promise(resolve => {
            axios.post(`${window.CONFS.apiServer}/admin/signature`, {
                title: this.signatureName.current.value,
                fileName: this.state.uploadedImage//this.shareContent.current.value,
            }).then(res => {
                window.alert("建立成功")
                this.props.history.go(-1)
            }).catch(function (error) {
                console.log('fail post =>', error)
                // window.alert("Server Error")
            });
        })
    }

    fileUpload = event => {
        if (event.target.files.length === 0) {
            this.setState({ "uploadedImage": "" })
            return
        }
        const data = new FormData();
        console.log({ files: event.target.files.length })
        data.append("file[]", event.target.files[0]);

        // 上傳檔案
        axios.post(window.CONFS.apiServer + "/sys/admin/uploadFiles", data)
            .then(res => {
                //let certificate = Object.assign({}, this.state.selectedCertificate);
                // let certificate = this.state.selectedCertificate;
                // certificate.modifiedFilepath = res.data.data[0].uuidName;
                // this.setState({ selectedCertificate: certificate })                
                this.setState({
                    uploadedImage: res.data.data[0].uuidName
                })

            })
            .catch(function (error) {
                console.log("fail post", error);
            });
    };

    render() {
        const { mode } = this.props
        return (
            <ReactModal
                isOpen={mode != null}
                shouldCloseOnOverlayClick={false}
                ariaHideApp={false}
                onRequestClose={() => {
                    window.confirm("確定要關閉編輯?") && this.props.history.go(-1)
                }}
                style={{ overlay: { "background": "#000000a3" }, content: { "width": "70%", "height": "65vh", "margin": "0 auto" } }}
            >
                <div className="modalControllArea">
                    <div onClick={() => {
                        window.confirm("確定要關閉編輯?") && this.props.history.go(-1)
                    }}>
                        <i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
                    </div>
                </div>
                <div className="share-detail">
                    <div className='banner-title'>建立簽名檔</div>
                    <div className='row'>
                        <div className='title'>標題</div>
                        <div className='content'><input type="text" defaultValue={this.state.signatureName} ref={this.signatureName} /></div>
                    </div>
                    <div className='row'>
                        <div className='title'>圖片</div>
                        <div className='content'>
                            <div><input type="file" name="signatureImage" onChange={this.fileUpload} /></div>
                            <div className="uploaded-image" style={{ "backgroundImage": this.state.uploadedImage ? `url('${window.CONFS.storageUrl}/${this.state.uploadedImage}')` : "" }}></div>
                        </div>
                    </div>
                    <div className='row buttons'>
                        {this.state.loading ? <Loader
                            type="Oval"
                            color="#7f1083"
                            height={22}
                            width={22}
                            visible={true}
                        /> : <button onClick={this.createSignature}>建立</button>}
                    </div>
                </div>

            </ReactModal>
        )
    }

}

export default withRouter(SignatureDetail);