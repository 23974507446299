import React, { Component } from 'react'
import './MemberApproveCertification.less'
import Loader from 'react-loader-spinner'
import axios from 'axios';
import Moment from 'react-moment'
import ReactModal from 'react-modal'
import MemberLessonPlans from './MemberLessonPlans'
import PlanDetail from '../../Management/LessonPlans/PlanDetail'
import { withRouter } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid';
import TextField from '@material-ui/core/TextField';
import LevelCourseHourSetting from '../../LevelCourseHourSetting'
import { Dialog, DialogTitle, DialogContent, withStyles } from '@material-ui/core';

class MemberApproveCertification extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			openModal: false,
			openLessonPlanModal: false,
			lessonPlanContent: <PlanDetail loginRole="admin" />,
			standardCourseHoursMap: {},
			selectedHoursMap: {},
			selectedLessonPlans: [],
			selectedCourses: [],
			isShowSaveBtn: false,
			isProcessApproveCertification: false
		}
	}

	componentDidMount = () => {
		this.getMemberInfoAction().then(memberInfo => {
			memberInfo.courses.forEach(course => { course.id = course._id })
			memberInfo.lessonPlans.forEach(lessonPlan => { lessonPlan.id = lessonPlan._id })
			let standardCourseHoursMap = {}
			if (memberInfo.memberInfo.systemAdminRole === "student") {
				standardCourseHoursMap = LevelCourseHourSetting.student.level1//{ LEVEL2: 12, LEVEL3: 18 }
			}
			else {
				standardCourseHoursMap = LevelCourseHourSetting.teacher.level1//{ LEVEL2: 30 }
			}

			this.setState({
				...memberInfo,
				loading: false,
				standardCourseHoursMap: standardCourseHoursMap
			})
		})
		this.initModalSetting()
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.initModalSetting()
		}
	}

	initModalSetting = () => {
		if (this.props.match.params.id) {
			this.setState({ lessonPlanContent: <PlanDetail loginRole="admin" />, openLessonPlanModal: true })
		}
		else {
			this.setState({ lessonPlanContent: <PlanDetail loginRole="admin" />, openLessonPlanModal: false })
		}
	}

	handleChange = (e) => {
		this[e.target.name] = e.target.value
		// console.log({[e.target.name]:e.target.value})
		this.checkShowSaveBtn()
	}

	checkShowSaveBtn = (currentLevel) => {
		let isShowSaveBtn = true
		if (currentLevel < 1) {
			let standardCourseHoursMap = JSON.parse(JSON.stringify(this.state.standardCourseHoursMap))
			for (let key in standardCourseHoursMap) {
				standardCourseHoursMap[key] -= this.state.selectedHoursMap[key] || 0
			}

			for (let key in standardCourseHoursMap) {
				if (standardCourseHoursMap[key] > 0) {
					isShowSaveBtn = false;
					break;
				}
			}
		}
		else {
			if (this.state.selectedLessonPlans.length !== 1 ||
				!(this.certificationNumber && this.certificationNumber.length > 0) ||
				!(this.certificationDate && (/^\d{4}\-\d{2}\-\d{2}$/g).test(this.certificationDate))) {
				isShowSaveBtn = false
			}
		}
		this.setState({ isShowSaveBtn: isShowSaveBtn })
	}

	getMemberInfoAction = () => {
		return new Promise(resolve => {
			axios.get(window.CONFS.apiServer + '/admin/certificates/wait_approve_data/' + this.props.match.params.GUID)
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res.data.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				});
		})
	}

	sendApproveCertification = (e) => {
		let currentLevel = parseInt(e.target.getAttribute("data-current-level"))
		this.setState({ isProcessApproveCertification: true }, async () => {
			await this.sendApproveCertificationAction(currentLevel)
			this.setState({ isProcessApproveCertification: false })
			this.props.searchMember()
			this.props.history.push(`../../`)
		})
	}

	sendApproveCertificationAction = (currentLevel) => {
		return new Promise(resolve => {
			let payload = {}
			if (currentLevel < 1) {
				payload = {
					currentLevel: currentLevel,
					courseIds: this.state.selectedCourses
				}
			} else {
				payload = {
					currentLevel: currentLevel,
					lessonplanIds: this.state.selectedLessonPlans,
					certificationNumber: this.certificationNumber,
					certificationDate: this.certificationDate
				}
			}
			axios.post(window.CONFS.apiServer + '/admin/certificates/approve/' + this.props.match.params.GUID, payload)
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res.data.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				});
		})
	}

	generateLessonPlanTip = (currentLevel) => {
		if (currentLevel > 0)
			return this.state.selectedLessonPlans.length === 0 && <div>* 請選擇一個教案</div>
		return null;
	}

	generateCoursesTip = (currentLevel) => {
		if (1 > currentLevel) {
			let hourStringList = []
			let standardCourseHoursMap = JSON.parse(JSON.stringify(this.state.standardCourseHoursMap))
			for (let key in standardCourseHoursMap) {
				standardCourseHoursMap[key] -= this.state.selectedHoursMap[key] || 0
			}

			for (let key in standardCourseHoursMap) {
				if (standardCourseHoursMap[key] <= 0)
					continue;
				hourStringList.push(`${LevelCourseHourSetting.getCourseTypeName(key)}時數尚缺 ${standardCourseHoursMap[key]} Hours`)
			}
			return (hourStringList.length == -0) ? null : `* ${hourStringList.join(`、`)}`
		}
		return null;
	}

	// getCourseTypeName = (val) => {
	// 	switch (val) {
	// 		case "LEVEL1":
	// 			return "一般";
	// 		case "LEVEL2":
	// 			return "初階";
	// 		case "LEVEL3":
	// 			return "進階";
	// 		case "LEVEL4":
	// 			return "回訓";
	// 	}
	// }

	render() {
		const StyledDialog = withStyles({
			paper: {
				width: "80%"
			}
		})(Dialog);

		let currentLevel = (this.state.memberInfo && this.state.memberInfo.levelCertification && this.state.memberInfo.levelCertification.level1.level1CourseCertifications.length > 0) ? 1 : 0
		let approvedCourses = (currentLevel > 0) ? this.state.memberInfo.levelCertification.level1.level1CourseCertifications : []
		let displayCourses = this.state.courses ? (currentLevel > 0) ? this.state.courses.filter(course => approvedCourses.indexOf(course._id) > -1) : this.state.courses : []
		return (
			<>{this.state.loading ?
				<Loader
					type="Oval"
					color="#7f1083"
					height={22}
					width={22}
					visible={true}
				/> : <div className="MemberApproveCertification">
					<div className="MemberApproveCertification__content">
						<div className="title">
							<div>{currentLevel > 0 ? `Level 2 審核` : `Level 1 審核`}</div>
							<div>{`帳號名稱: ${this.state.memberInfo.firstName} ${this.state.memberInfo.lastName}`}</div>
							<div>{`真實姓名: ${this.state.memberInfo.adminFullName || ""}`}</div>
							<div>{`身分證字號: ${this.state.memberInfo.adminIdentifyID || ""}`}</div>
							<div>{`身分: ${this.state.memberInfo.roleName.tw}`}</div>
						</div>
						<div className="teacher-info">

						</div>
						<div className="teacher-courses">
							{currentLevel > 0 ? <div>Level 1 課程</div> : <div>請選擇課程</div>}
							<DataGrid
								rowsPerPageOptions={[10]}
								scrollbarSize={30}
								rows={displayCourses}
								disableSelectionOnClick={currentLevel > 0}
								checkboxSelection={!currentLevel > 0}
								onSelectionChange={(newSelection) => {
									// let selectedHours = 0
									let selectedHoursMap = {}
									this.state.courses.filter(course => {
										return (newSelection.rowIds.indexOf(course._id) > -1)
									}).forEach(selectedCourse => {
										let hours = parseFloat(selectedHoursMap[selectedCourse.level]) || 0
										hours += parseFloat(selectedCourse.hours)
										selectedHoursMap[selectedCourse.level] = hours.toFixed(2)

									})
									this.setState({
										selectedHoursMap: selectedHoursMap,
										selectedCourses: newSelection.rowIds
									}, () => {
										this.checkShowSaveBtn(currentLevel)
									})
								}}
								columns={[
									{ field: 'hours', headerName: '時數(hr)', width: 100 },
									{
										field: 'level', headerName: '類型', width: 100, renderCell: (params) => (
											<span>{LevelCourseHourSetting.getCourseTypeName(params.value)}</span>
											// <span>{params.value.tw}</span>
										)
									},
									{ field: 'certificationCode', headerName: '活動證號', width: 100 },
									{
										field: 'name', headerName: '活動名稱', renderCell: (params) => (
											<span>{params.value.tw}</span>
										)
									}
								]} />

						</div>
						{currentLevel > 0 &&
							<div className="teacher-lessonplans">
								<DataGrid
									rowsPerPageOptions={[10]}
									scrollbarSize={30}
									rows={this.state.lessonPlans}
									checkboxSelection
									onSelectionChange={(newSelection) => {
										this.setState({
											selectedLessonPlans: newSelection.rowIds
										}, () => {
											this.checkShowSaveBtn(currentLevel)
										})
									}}
									columns={[
										{
											field: 'title', headerName: '教案名稱', disableClickEventBubbling: true, flex: 1, renderCell: (params) => (
												<a href="#!" onClick={() => { this.props.history.push(`approve_certification/${params.getValue("_id")}`) }} >{params.value}</a>
											)
										}
									]} />
							</div>
						}
						<div className="warning">
							{!(this.state.memberInfo.teacherCertification) && <div>* 未上傳教師證</div>}
							{this.generateLessonPlanTip(currentLevel, this.state.memberInfo.systemAdminRole)}
							{this.generateCoursesTip(currentLevel)}
						</div>
						<div className="certification-form">
							{currentLevel > 0 && <>
								<div className="Admin__row underline">
									<div className="certification-form__title">
										證書編號
									</div>
									<div className="Admin__row__item">
										<input type="text" name="certificationNumber" data-current-level={currentLevel} onChange={this.handleChange} />
									</div>
								</div>
								<div className="Admin__row underline">
									<div className="certification-form__title">
										通過日期
									</div>
									<div className="Admin__row__item">
										<TextField
											id="date"
											type="date"
											name="certificationDate"
											data-current-level={currentLevel}
											onChange={this.handleChange}
											InputLabelProps={{
												shrink: true,
											}}
										/>
										{/* <input type="text" name="certificationDate" placeholder="format: yyyy/MM/dd" onChange={this.handleChange} /> */}
									</div>
								</div>
							</>}
							<div className="Admin__row certification-form-controller">
								{this.state.isShowSaveBtn && <>
									{this.state.isProcessApproveCertification ? <Loader
										type="Oval"
										color="#7f1083"
										height={22}
										width={22}
										visible={true}
									/> : <button className="approveBtn" type="button" data-current-level={currentLevel} onClick={this.sendApproveCertification} >審核通過</button>}
								</>
								}
							</div>
						</div>
					</div>
				</div>

			}
				<StyledDialog
					maxWidth={false}
					disableBackdropClick={true}
					fullWidth={true}
					open={this.state.openLessonPlanModal}
					onClose={() => { this.props.history.push(`../approve_certification`) }}
				>
					<DialogTitle>
						<div className="modalControllArea">
							<div onClick={() => { this.props.history.push(`../approve_certification`) }}>
								<i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
							</div>
						</div>
					</DialogTitle>
					<DialogContent dividers>
						{this.state.lessonPlanContent}
					</DialogContent>
				</StyledDialog>
			</>
		);
	}
}

export default withRouter(MemberApproveCertification);