import React, { Component } from 'react'
import './ApprovedMemberManagement.less'
import Loader from 'react-loader-spinner'
import axios from 'axios';
import Moment from 'react-moment'
import ReactModal from 'react-modal'
import ApprovedMemberLessonPlans from './ApprovedMemberLessonPlans'
import MemberInfo from '../MemberManagement/MemberInfo'
import { withRouter } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid';
import { Dialog, DialogTitle, DialogContent, withStyles, Tooltip } from '@material-ui/core';

class ApprovedMemberManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			members: [],
			keyword: "",
			loading: false,
			openModal: false,
			memberlessonPlans: <ApprovedMemberLessonPlans />,
			showMemberInfo: false,
			memberInfo: <MemberInfo />
		}

		this.columns = [
			{ field: 'account', headerName: '帳號', width: 300, renderCell: this.displayAccountDetail },
			{ field: 'displayName', headerName: '姓名', width: 100, renderCell: this.displayDataGridCell },
			{ field: 'companyName', headerName: '學校', width: 200, renderCell: this.displayDataGridCell },
			// {
			// 	field: 'displayLastLoggedInAt', headerName: '最後登入時間', width: 150, renderCell: (params) => (
			// 		<Moment format="YYYY/MM/DD HH:mm">{params.value}</Moment>
			// 	)
			// },
			// {
			// 	field: 'displayCreatedAt', headerName: '註冊時間', width: 150, renderCell: (params) => (
			// 		<Moment format="YYYY/MM/DD HH:mm">{params.value}</Moment>
			// 	)
			// },
			{
				field: 'GUID', headerName: ' ', width: 150, renderCell: (params) => (
					<button onClick={() => this.props.history.push(`/admin/membership/approved_members/${params.value}`)} className="approveBtn">查看</button>
				)
			},
		]
	}

	displayAccountDetail = (params) => (
		<Tooltip title={params.value} >
			<span className="table-cell-trucate"><a href="#!" onClick={() => { this.openMemberInfo(params.getValue("GUID"))}} >{params.value}</a></span>			
		</Tooltip>
	)

	displayDataGridCell = (params) => (
		<Tooltip title={params.value} >
			<span className="table-cell-trucate">{params.value	}</span>
		</Tooltip>
	)

	componentDidMount = () => {
		this.searchMember();
		if (this.props.match.params.GUID) {
			this.setState({ memberlessonPlans: <ApprovedMemberLessonPlans />, openModal: true })
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			if (this.props.match.params.GUID) {
				this.setState({ memberlessonPlans: <ApprovedMemberLessonPlans />, openModal: true })
			}
			else {
				this.setState({ memberlessonPlans: <ApprovedMemberLessonPlans />, openModal: false })
			}
		}
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	searchMember = () => {
		this.setState({ "loading": true }, () => {
			this.searchMemberAction().then(members => {
				members.forEach(member => {
					member.id = member.GUID
					member.displayName = `${member.firstName} ${member.lastName}`
					member.displayLastLoggedInAt = new Date(member.lastLoggedInAt)
					member.displayCreatedAt = new Date(member.createdAt)
				})
				this.setState({ "members": members, loading: false })
			})
		})
	}

	searchMemberAction = () => {
		return new Promise(resolve => {
			axios.get(window.CONFS.apiServer + '/admin/members/approved_lessonplan',
				{
					params: {
						keyword: encodeURIComponent(this.state.keyword)
					}
				})
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res.data.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				});
		})
	}

	checkKeywordClickKey = e => {
		if (e.key.toUpperCase() === "ENTER")
			this.searchMember()
	}

	openMemberInfo = (GUID) => {
		this.setState({showMemberInfo: true,
			memberInfo: <MemberInfo GUID={GUID} />})
	}

	render() {
		const StyledDialog = withStyles({
			"paper": {
				width: "60%"
			}
		})(Dialog);
		return (
			<div className="MemberManagement">
				<div className="title">
					<span>教案夥伴管理</span>
				</div>
				<div className="MemberManagement__searchArea">
					<i className="fas fa-search"></i>
					<input type="text" onKeyPress={this.checkKeywordClickKey} onChange={this.handleChange} name="keyword" />
					<button onClick={this.searchMember}>搜尋</button>
				</div>
				<div className="MemberManagement__content">
					{this.state.loading ? <Loader
						type="Oval"
						color="#7f1083"
						height={22}
						width={22}
						visible={true}
					/> :
						<DataGrid
							rowsPerPageOptions={[10]}
							scrollbarSize={30}
							hideFooterSelectedRowCount={true}
							rows={this.state.members}
							columns={this.columns} />}
					{/* <table>
						<thead>
							<tr>
								<td>帳號</td>
								<td style={{"width":"100px"}}>姓名</td>
								<td>最後登入時間</td>
								<td>註冊時間</td>
								<td></td>
							</tr>
						</thead>
						{this.state.loading?
							<tbody>						
								<tr>
									<td className="loading" colSpan="3">
										<Loader
											type="Oval"
											color="#7f1083"
											height={22}
											width={22}
											visible = {true}
										/>
									</td>
								</tr>
							</tbody>:
							<tbody>											
								{this.state.members.map((item, k)=>{
									return (<tr key={k}>
										<td>{item.account}</td>
										<td>{item.firstName+" "+ item.lastName}</td>
										<td>
											{item.lastLoggedInAt&&
												<Moment format="YYYY/MM/DD HH:mm">{item.lastLoggedInAt}</Moment>											
											}											
										</td>
										<td>
											<Moment format="YYYY/MM/DD HH:mm">{item.createdAt}</Moment>
										</td>
										<td>
											<button onClick={() => this.props.history.push(`/admin/approvedmember/${item.GUID}`)} className="approveBtn">查看</button>
										</td>
									</tr>)
								})
								}
							</tbody>
						}						
					</table> */}
				</div>
				{/* <ReactModal
					isOpen={this.state.openModal}
					shouldCloseOnOverlayClick={false}
					onRequestClose={() => { this.props.history.push('../approvedmembers') }}
					style={{ overlay: { "background": "#000000a3" }, content: { "width": "90%", "height": "85vh", "margin": "0 auto", "z-index": 100 } }}
				>
					<div className="modalControllArea">
						<div onClick={() => { this.props.history.push('../approvedmembers') }}>
							<i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
						</div>
					</div>
					{this.state.memberlessonPlans}
				</ReactModal> */}
				<StyledDialog
					maxWidth={false}
					disableBackdropClick={true}
					fullWidth={true}
					open={this.state.openModal}
					onClose={() => { this.props.history.push('../approved_members') }}
				>
					<DialogTitle>
						<div className="modalControllArea">
							<div onClick={() => { this.props.history.push('../approved_members') }}>
								<i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
							</div>
						</div>
					</DialogTitle>
					<DialogContent dividers>
						{this.state.memberlessonPlans}
					</DialogContent>
				</StyledDialog>				
				<StyledDialog
					maxWidth={false}
					fullWidth={true}
					open={this.state.showMemberInfo}
					onClose={() => { this.setState({ showMemberInfo: false }) }}
				>
					<DialogTitle>
						<div className="modalControllArea">
							<div onClick={() => { this.setState({ showMemberInfo: false }) }}>
								<i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
							</div>
							<div className="title">會員資料</div>
						</div>
					</DialogTitle>
					<DialogContent dividers>
						{this.state.memberInfo}
					</DialogContent>
				</StyledDialog>
			</div>
		);
	}
}

export default withRouter(ApprovedMemberManagement);