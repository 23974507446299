import React, { Component } from 'react'
import './PointerManagement.less'
import Loader from 'react-loader-spinner'
import axios from 'axios'
import Moment from 'react-moment'
import ReactModal from 'react-modal'
import ClassesMangement from './ClassesMangement'
import PointerPage from './PointerPage'
import PointerItems from './PointerItems'
import { withRouter } from 'react-router-dom'
import { DndProvider, useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

class PointerManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pointers: [],
			pointersLoading: true,
			isPointerModalOpen: false,
			isClassModalOpen: false,
			isClassBtnLoading: false,
			classesMangement: null,
			pointerPage: null,
			isSortingAction: false,
		}
	}

	componentDidMount = () => {
		this.loadPointers()
	}

	loadPointers = (attrs) => {
		this.setState({ pointersLoading: true }, () => {
			this.searchPointers().then((pointers) => {
				if (!attrs) {
					attrs = {}
				}
				this.setState({
					...attrs,
					pointersLoading: false, pointers: pointers
				})
			})
		})
	}

	searchPointers = () => {
		return new Promise(resolve => {
			axios.get(window.CONFS.apiServer + '/admin/teacher_ability/pointers')
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res.data.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
				});
		})
	}

	openPointerPage = (id) => {
		if (id) {
			this.setState({ [id]: { buttonLoading: true } }, () => {
				this.getPointer(id).then(pointer => {
					this.setState({
						isPointerModalOpen: true,
						pointerPage: <PointerPage pointer={pointer} getPointer={this.getPointer} loadPointers={this.loadPointers} />,
						[id]: { buttonLoading: false }
					})
				})
			})
		} else {
			this.setState({
				isPointerModalOpen: true,
				pointerPage: <PointerPage getPointer={this.getPointer} loadPointers={this.loadPointers} />
			})
		}
	}

	getPointer = (id) => {
		return new Promise((resolve) => {
			axios.get(window.CONFS.apiServer + '/admin/teacher_ability/pointer/' + id)
				.then(res => {
					if (res.data.errorCode > 0) {
						window.CONFS.validateAdminLoginSession(res.data, this.props.history)
						console.log(res)
					}
					else {
						resolve(res.data.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				});
			this.setState({ "coursesLoading": true })
		});
	}

	deletePointer = (id) => {
		if (!window.confirm("確定要刪除能力指標?"))
			return false
		this.setState({ [id]: { buttonLoading: true } }, () => {
			this.deletePointerAction(id).then(
				() => {
					this.setState({ pointersLoading: true })
					return this.searchPointers()
				}
			).then((pointers) => {
				this.setState({ pointersLoading: false, pointers: pointers, [id]: { buttonLoading: false } })
			})
		})
	}

	deletePointerAction = (id) => {
		return new Promise((resolve) => {
			axios.delete(window.CONFS.apiServer + '/admin/teacher_ability/pointer/' + id)
				.then(res => {
					if (res.data.errorCode > 0) {
						window.CONFS.validateAdminLoginSession(res.data, this.props.history)
						console.log(res)
					}
					else {
						resolve(res.data.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				});
			this.setState({ "coursesLoading": true })
		});
	}

	openClassPage = () => {
		this.setState({ isClassBtnLoading: true }, () => {
			this.getClasses().then(data => {
				data = data.map(item => {
					item.isEditMode = false
					item.currentEdit = false
					return item
				})
				this.setState({
					isClassBtnLoading: false,
					isClassModalOpen: true,
					classesMangement: <ClassesMangement classes={data} getClasses={this.getClasses} loadPointers={this.loadPointers} />
				})
			})
		})
	}

	getClasses = () => {
		return new Promise((resolve) => {
			axios.get(window.CONFS.apiServer + '/admin/teacher_ability/classes')
				.then(res => {
					if (res.data.errorCode > 0) {
						window.CONFS.validateAdminLoginSession(res.data, this.props.history)
						console.log(res)
					}
					else {
						resolve(res.data.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				});
		});
	}

	render() {

		return (
			<div className="PointerManagement">
				<div className="title">
					<span>教師教學能力指標管理</span>
				</div>
				<div className="PointerManagement__controlArea">
					{this.state.pointersLoading || this.state.isSortingAction ?
						<Loader
							type="Oval"
							color="#7f1083"
							height={22}
							width={22}
							visible={true}
						/> :
						<div>
							<button onClick={() => { this.openPointerPage() }}>新增指標</button>
							{
								this.state.isClassBtnLoading ? <Loader
									type="Oval"
									color="#7f1083"
									height={22}
									width={22}
									visible={true}
								/> : <button onClick={() => { this.openClassPage() }}>項目管理</button>
							}
							{this.state.orderedPointerItems && this.state.orderedPointerItems.length > 0 && this.state.isShowSaveOrderedBtn && <button onClick={() => { this.updateTeacherAbilityPointersSequence() }}>更新順序</button>}
						</div>
					}
				</div>
				<div className="PointerManagement__content">
					{this.state.pointersLoading ?
						<Loader
							type="Oval"
							color="#7f1083"
							height={22}
							width={22}
							visible={true}
						/> :
						<div>
							<div className="PointerManagement__header">
								<div>項目</div>
								<div>指標</div>
								<div>位置</div>
								<div>更新時間</div>
								<div></div>
							</div>
							<DndProvider backend={HTML5Backend}>
								<PointerItems
									pointerItems={this.state.pointers}
									deletePointer={this.deletePointer}
									openPointerPage={this.openPointerPage}
									processTeacherAbilityPointersSequence={this.processTeacherAbilityPointersSequence}
								/>
							</DndProvider>
						</div>
					}
				</div>
				<ReactModal
					isOpen={this.state.isPointerModalOpen}
					shouldCloseOnOverlayClick={false}
					onRequestClose={() => { this.setState({ isPointerModalOpen: false }) }}
					style={{ overlay: { "background": "#000000a3" }, content: { "width": "70%", "height": "85vh", "margin": "0 auto" } }}
				>
					<div className="modalControllArea">
						<div onClick={() => { this.setState({ isPointerModalOpen: false }) }}>
							<i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
						</div>
					</div>
					{this.state.pointerPage}
				</ReactModal>
				<ReactModal
					isOpen={this.state.isClassModalOpen}
					shouldCloseOnOverlayClick={false}
					onRequestClose={() => { this.setState({ isClassModalOpen: false }) }}
					style={{ overlay: { "background": "#000000a3" }, content: { "width": "50%", "height": "85vh", "margin": "0 auto" } }}
				>
					<div className="modalControllArea">
						<div onClick={() => { this.setState({ isClassModalOpen: false }) }}>
							<i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
						</div>
					</div>
					{this.state.classesMangement}
				</ReactModal>
			</div>
		);
	}

	updateTeacherAbilityPointersSequence = () => {
		if (!window.confirm("確認是否要更新順序?")) {
			return false;
		}

		this.setState({
			isSortingAction: true
		}, () => {
			let sortedPointers = this.state.orderedPointerItems.map(item => {
				return {
					id: item._id,
					sequence: item.sequence
				}
			})
			this.updateTeacherAbilityPointersSequenceAction(sortedPointers)
			this.setState({isSortingAction: false})
		})
	}

	updateTeacherAbilityPointersSequenceAction = (sortedPointers) => {
		return new Promise((resolve) => {
			axios.patch(window.CONFS.apiServer + '/admin/teacher_ability/pointers/sequence', sortedPointers)
				.then(res => {
					if (res.data.errorCode > 0) {
						window.CONFS.validateAdminLoginSession(res.data, this.props.history)
						console.log(res)
					}
					else {
						resolve(res.data.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				});
			this.setState({ "coursesLoading": true })
		});
	}

	processTeacherAbilityPointersSequence = (pointers) => {
		let initialInex = 1
		let pointerItems = JSON.parse(JSON.stringify(pointers))
		pointerItems.forEach(pointerItem => {
			pointerItem.sequence = initialInex++
		})

		this.setState({
			orderedPointerItems: pointerItems,
			isShowSaveOrderedBtn: (this.state.pointers != pointerItems)
		})
	}
}

export default withRouter(PointerManagement);