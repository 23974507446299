import React, { Component, createRef } from 'react'
import axios from 'axios'
import Loader from 'react-loader-spinner'
import './LessonPlanResources.less'
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal'
import PlanDetail from "../../Management/LessonPlans/PlanDetail";

import LocalStorage from '../../Helpers/LocalStorage'

class LessonPlanResources extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lessonplans: [],
			openModal: false
		}

		this.setComponentRef()
		this.init()

		if (this.props.match.params.action === "lessonplanresource") {
            this.state.openModal = true
            this.state.lessonPlanContent = <PlanDetail loginRole="admin" />
        }
	}

	setComponentRef = () => {
		this.academicYearsDropdownList = createRef()
		this.lessonPlanGradesDropdownList = createRef()
		this.lessonTargetReigonsDropdownList = createRef()
		this.lessonTargetKernelsDropdownList = createRef()
		this.lessonTargetPointsDropdownList = createRef()
		this.lessonTargetContentsDropdownList = createRef()
		this.lessonTargetActivitiesDropdownList = createRef()
	}

	init = () => {
		this.getLessonPlanSetting().then(res => {
			this.setState({ settingData: res.data.data }, () => {
				this.searchLessonPlans().then((datas) => {
					this.setState({ datas: datas })
				})
			})
		})
	}

	getLessonPlanSetting = () => {
		return new Promise((resolve) => {
			axios.get(window.CONFS.apiServer + '/admin/tw/lessonplan/setting')
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
				});
		})
	}

	addSelectedOption = (e, target) => {
		let datas = this.state[target] || []
		if (datas.indexOf(e.current.value) === -1) {
			this.setState({ [target]: [...datas, e.current.value] })
		}
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	removeSelectedOption = (val, target) => {
		let datas = Object.assign([], this.state[target] || [])
		datas.splice(datas.indexOf(val), 1)
		this.setState({ [target]: datas })
	}

	searchLessonPlans = (selectedParams) => {
		return new Promise((resolve) => {
			this.setState({ "loading": true })

		 console.log('selectedParams:', selectedParams)
			axios.post(window.CONFS.apiServer + '/admin/lessonPlans/search',
				{
					...selectedParams
				})
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res.data.data, this)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				})
		})
	}

	generateDropdownList = (settingData, type, ref, showNameCol) => {
		return <div className={`Admin__row__item ${type}`}>
			<div>
				<select ref={ref} style={{"maxWidth":"450px"}}>
					{settingData[type].map((c, k) => {
						return <option key={k} value={c._id} >{c[showNameCol]}</option>
					})}
				</select>
				<button className="selectedBtn" onClick={() => this.addSelectedOption(ref, type)}>新增</button>
			</div>
			<div>
				{(this.state[type] && this.state[type].length > 0) &&
					this.state[type].map((c, k) => {
						let result = settingData[type].find(item => {
							return item._id === c;           // 取得大於五歲的
						});
						if (result) {
							return <button className="selectedItem" key={k} onClick={() => this.removeSelectedOption(c, type)}>{result[showNameCol]}</button>
						}
						return ''
					})
				}
			</div>
		</div>
	}

	generateLessonPlanResourcesSearchCondition(settingData) {
		let conditions = <Loader
			type="Oval"
			color="#7f1083"
			height={22}
			width={22}
			visible={true}
		/>
		if (settingData) {
			conditions = (
				<div className="LessonPlanResources__searchCondition">
					<div className="Admin__row underline">
						<div className="LessonPlanResources__searchCondition__title">
							年度
						</div>
						<div className="Admin__row__item academicYears">
							<div>
								<select ref={this.academicYearsDropdownList}>
									{settingData.academicYears.map((c, k) => {
										return <option key={k} value={c} >{c}</option>
									})}
								</select>
								<button className="selectedBtn" onClick={() => this.addSelectedOption(this.academicYearsDropdownList, "academicYears")}>新增</button>
							</div>
							<div>
								{(this.state.academicYears && this.state.academicYears.length > 0) &&
									this.state.academicYears.map((c, k) => {
										return <button className="selectedItem" key={k} onClick={() => this.removeSelectedOption(String(c), "academicYears")}>{c}</button>
									})
								}
							</div>
						</div>
					</div>
					<div className="Admin__row underline">
						<div className="LessonPlanResources__searchCondition__title">
							課程主題
						</div>
						<div className="Admin__row__item">
							<input type="text" name="keyword" onChange={this.handleChange} />
						</div>
					</div>
					<div className="Admin__row underline">
						<div className="LessonPlanResources__searchCondition__title">
							課程年級
						</div>
						{this.generateDropdownList(settingData, "lessonPlanGrades", this.lessonPlanGradesDropdownList, "gradeName")}
					</div>
					<div className="Admin__row underline">
						<div className="LessonPlanResources__searchCondition__title">
							領域
						</div>
						{this.generateDropdownList(settingData, "lessonTargetReigons", this.lessonTargetReigonsDropdownList, "reigonName")}
					</div>
					<div className="Admin__row underline">
						<div className="LessonPlanResources__searchCondition__title">
							總綱核心素養
						</div>
						{this.generateDropdownList(settingData, "lessonTargetKernels", this.lessonTargetKernelsDropdownList, "kernelName")}
					</div>
					<div className="Admin__row underline" style={{"display":"none"}}>
						<div className="LessonPlanResources__searchCondition__title">
							領綱學習重點
						</div>
						{this.generateDropdownList(settingData, "lessonTargetPoints", this.lessonTargetPointsDropdownList, "pointName")}
					</div>
					{/* <div className="Admin__row underline">
						<div className="LessonPlanResources__searchCondition__title">
							學習內容
						</div>
						{this.generateDropdownList(settingData, "lessonTargetContents", this.lessonTargetContentsDropdownList, "contentName")}
					</div> */}
					<div className="Admin__row underline">
						<div className="LessonPlanResources__searchCondition__title">
							學習表現
						</div>
						{this.generateDropdownList(settingData, "lessonTargetActivities", this.lessonTargetActivitiesDropdownList, "activeName")}
					</div>
					<div className="Admin__row LessonPlanResources__searchCondition__controlArea">
						<button onClick={this.search}>搜尋</button>
					</div>
				</div>)
		}

		return conditions;
	}

	getGUID = () => {
        let nthusteam = LocalStorage.getLocalStorage("nthusteam")
        if (nthusteam != null && nthusteam.apiToken != null) {
            return nthusteam.GUID;
        } else {
            this.props.history.push('/signin');
        }
    }

	search = () => {
		let selectedParams = Object.assign({}, this.state)
		delete selectedParams.settingData
		delete selectedParams.lessonplans
		delete selectedParams.datas
		delete selectedParams.loading
		delete selectedParams.openModal
		delete selectedParams.lessonPlanContent
		
		this.searchLessonPlans(selectedParams).then((lessonplans) => {
			this.setState({ "lessonplans": lessonplans })
		})
	}

	showLessonPlan = (id)=>{
		if( id && (id != '')){
			this.props.history.push("lessonplanresource/"+id)
		}
	}

	componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location){
            if (this.props.match.params.action === "lessonplanresource") {
                this.setState({lessonPlanContent: <PlanDetail loginRole="admin" />, openModal: true})
            }
            else{
                this.setState({lessonPlanContent: <PlanDetail loginRole="admin" />, openModal: false})
            }
        }            
    }

	render() {
		return (
			<div className="LessonPlanResources">
				<div className="title">
					<span>教案資源資料庫</span>
				</div>
				<div className="warn_word">
					<span>本資料僅供通過認證者下載，請勿隨意傳播資料。</span>
				</div>
				{this.generateLessonPlanResourcesSearchCondition(this.state.settingData)}
				<div className="LessonPlanResources__result">
					<table>
						<thead>
							<tr>
								<td>課程主題</td>
								<td>課程簡介</td>
								<td style={{ "width": "100px" }}></td>
							</tr>
						</thead>
						<tbody>
							{this.state.lessonplans.map((item, k) => {
								return (<tr key={k}>
									<td>{item.title}</td>
									<td>{item.description}</td>
									<td>
										{item._id&& <button className="LessonPlanResources__result__item__readBtn" onClick={()=>this.showLessonPlan(item._id)}>查看</button>}
									</td>
								</tr>)
							})
							}
						</tbody>
					</table>
				</div>
				<ReactModal
                    isOpen={this.state.openModal}
                    shouldCloseOnOverlayClick={false}
                    onRequestClose={() => { this.props.history.push('../lessonplanresources') }}
                    style={{ overlay: { "background": "#000000a3" }, content: { "width": "80%", "height": "85vh", "margin": "0 auto" } }}
                >
                    <div className="modalControllArea">
                        <div onClick={() => { this.props.history.push('../lessonplanresources') }}>
                            <i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
                        </div>
                    </div>
                    {this.state.lessonPlanContent}
                </ReactModal>
			</div>
		);
	}
}

export default withRouter(LessonPlanResources);