import React, { Component, createRef } from 'react'
import { withRouter } from "react-router-dom";
import './TeacherAbilityDisplay.less'

class TeacherAbilityDisplay extends Component {
    constructor(props) {
        super(props);
        this.teacherAbilityPointers = {}
        this.computeTeacherAbilityPointersShow()
    }

    componentDidMount = () => {
        this.computeTeacherAbilityPointersShow()    
    }

    computeTeacherAbilityPointersShow = () => {
        let teacherAbilityPointers = JSON.parse(JSON.stringify(this.props.teacherAbilityPointers))
        teacherAbilityPointers.classes.forEach(classObject => {
            let isShowClass = false
            classObject.pointers.forEach(pointer=>{
                if(!isShowClass)
                    isShowClass = pointer.positions.indexOf(this.props.currentPosition)>-1
            })
            classObject.isShowClass = isShowClass
        });
        this.teacherAbilityPointers = teacherAbilityPointers
    }

    getOptionTitle = (itemAttributeKey) => {
        const pointerLevels = this.props.teacherAbilityPointers.pointerLevels;
        const pointerLevel = pointerLevels.filter(item => item.key === itemAttributeKey).pop()
        return pointerLevel.displayName.tw
    }

    render() {
        const pointerLevels = this.props.teacherAbilityPointers.pointerLevels
        const teacherAbilityContent = (this.props.teacherAbility && this.props.teacherAbility.content) ? this.props.teacherAbility.content : {}
        const { currentPosition } = this.props
        return (
            <div className="TeacherAbilityDisplay">
                <div className="TeacherAbilityDisplay_content-area">
                    {this.teacherAbilityPointers.classes.filter(classObject=> classObject.isShowClass).map((item, key_1) => {
                        return teacherAbilityContent[item._id] && <div key={key_1} className="TeacherAbilityDisplay__Pointers">
                            <div className="TeacherAbilityDisplay__Class"
                                dangerouslySetInnerHTML={{ __html: `${item.title.tw.replaceAll(/\r\n|\r|\n/g, '<br/>')} ${item.removed ? '(Removed)' : ''}` }}></div>
                            <div className="TeacherAbilityDisplay__ClassContent">
                                <div className="TeacherAbilityDisplay__ClassDescription"
                                    dangerouslySetInnerHTML={{ __html: `${(item.description && item.description.tw) ? item.description.tw.replaceAll(/\r\n|\r|\n/g, '<br/>') : ""} ${item.removed ? '(Removed)' : ''}` }}></div>
                                {item.pointers.filter(pointer=> pointer.positions.indexOf(currentPosition)>-1).map((pointer, key_2) => {
                                    return teacherAbilityContent[item._id][pointer._id] && <div key={key_2} className="TeacherAbilityDisplay__PointerItem">
                                        <div className="TeacherAbilityDisplay__PointerItem_title">{pointer.title.tw}</div>
                                        <div className="TeacherAbilityDisplay__PointerItem_content">
                                            {
                                                pointerLevels.map((pointerLevel, key_3) => {
                                                    return (teacherAbilityContent[item._id][pointer._id] === pointerLevel.key) && <label key={key_3} htmlFor={`${pointer._id}_${pointerLevel.key}`}>
                                                        <div className="TeacherAbilityDisplay__PointerItem_Option">
                                                            <div className="TeacherAbilityDisplay__PointerItem_Option_Title">
                                                                {pointerLevel.displayName.tw}
                                                            </div>
                                                            <div className="TeacherAbilityDisplay__PointerItem_Option_Content"
                                                                dangerouslySetInnerHTML={{ __html: `${(pointer.itemAttribute[pointerLevel.key]) ? pointer.itemAttribute[pointerLevel.key].tw.replaceAll(/\r\n|\r|\n/g, '<br/>') : ""}` }}></div>
                                                        </div>
                                                    </label>
                                                })
                                            }</div>
                                    </div>
                                })}
                            </div>
                        </div>
                    })}
                </div>
            </div>
        )
    }
}

export default withRouter(TeacherAbilityDisplay);