import React, { Component } from 'react';
import Loader from 'react-loader-spinner'
import axios from 'axios';
import logo from '../../../Assets/Home/logo.png'

import './Certificates.less'

class Certificates extends Component {
	constructor(props) {
		super(props);
		this.state = {
			"certificates": [],
			"certificatesLoading": true,
			"selectedCertificate": null,
			"saveLoading": false
		}
	}

	componentDidMount = () => {
		this.getCertificates()
	}

	handleChange = (e) => {
		let certificate = this.state.certificates.filter(item => item._id === this.state.selectedCertificate._id)[0]
		certificate[e.target.name] = e.target.value
		let selectedCertificate = Object.assign({}, certificate);
		this.setState({ selectedCertificate: selectedCertificate })
	}

	getCertificates = () => {
		return new Promise((resolve) => {
			axios.get(window.CONFS.apiServer + '/admin/tw/certificates')
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res.data.data)
						let certificates = res.data.data
						certificates.forEach(element => {
							element.filepath = (element.filepath)?element.filepath:""
							element.modifiedFilepath = (element.modifiedFilepath)?element.modifiedFilepath:""
							element.modifiedFilepath = element.filepath
							element.modifiedDesc = element.desc
						});
						//let certificate = Object.assign({}, certificates[0]);
						let certificate = certificates[0];
						this.setState({ "certificates": certificates, "certificatesLoading": false, "selectedCertificate": certificate })
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				});
			this.setState({ "courseItemsLoading": true })
		});
	}

	changeCertificateItem = e => {
		let selectedCertificate = this.state.certificates.filter(item => item._id === e.target.value)[0]
		//let certificate = Object.assign({}, selectedCertificate);
		let certificate = selectedCertificate;
		this.setState({ selectedCertificate: certificate })
	}

	fileUpload = event => {
		const data = new FormData();

		data.append("file[]", event.target.files[0]);

		// 上傳檔案
		axios.post(window.CONFS.apiServer + "/sys/admin/uploadFiles", data)
			.then(res => {
				//let certificate = Object.assign({}, this.state.selectedCertificate);
				let certificate = this.state.selectedCertificate;
				certificate.modifiedFilepath = res.data.data[0].uuidName;
				this.setState({ selectedCertificate: certificate })

			})
			.catch(function (error) {
				console.log("fail post", error);
			});
	};

	removeCertificate = () => {
		//let certificate = Object.assign({}, this.state.selectedCertificate);
		let certificate = this.state.selectedCertificate;
		certificate.modifiedFilepath = "";
		this.setState({ selectedCertificate: certificate })
	}

	saveCertificate = () => {
		this.setState({"saveLoading":true})
		axios.patch(window.CONFS.apiServer+'/admin/certificate/'+this.state.selectedCertificate._id,
		{
			desc: this.state.selectedCertificate.modifiedDesc,
			filepath: this.state.selectedCertificate.modifiedFilepath
        })
        .then(res => {
        	if(res.data.errorCode>0){
				window.CONFS.validateAdminLoginSession(res.data, this.props.history)
        		console.log(res)
			}
			
			this.setState({"saveLoading":false});
        })
        .catch(function (error) {
            console.log('fail post =>', error)
            // window.alert("Server Error")
        });
	}

	render() {
		let filepath = (this.state.selectedCertificate) ? (this.state.selectedCertificate.modifiedFilepath) ? window.CONFS.storageUrl + "/"+ this.state.selectedCertificate.modifiedFilepath : logo : logo
		let modifiedDesc = (this.state.selectedCertificate) ? (this.state.selectedCertificate.modifiedDesc) ? this.state.selectedCertificate.modifiedDesc : "" : ""		
		return (
			<div className="Certificates">
				<div className="title">
					<span>認證層級管理</span>
				</div>
				<div className="Certificates__controlArea">
					{this.state.certificatesLoading ?
						<Loader
							type="Oval"
							color="#7f1083"
							height={22}
							width={22}
							visible={true}
						/> :
						<div>
							<select onChange={this.changeCertificateItem}>
								{
									this.state.certificates.map((item, k) => {
										return <option value={item._id} key={k}>{item.title}</option>
									})
								}
							</select>
							{this.state.saveLoading?
								<Loader
									type="Oval"
									color="#7f1083"
									height={22}
									width={22}
									visible={true}
								/>:
								<button onClick={this.saveCertificate}>儲存</button>
							}							
						</div>
					}
				</div>
				{!this.state.certificatesLoading &&
					<div className="Certificates__content">
						<textarea className="Certificates__content__desc" name="modifiedDesc" onChange={this.handleChange} value={modifiedDesc}>
						</textarea>
						<div className="Certificates__content__certificate">
							<div className="Certificates__content__controll">
								<div onClick={this.removeCertificate}><i className="fa fa-trash"></i></div>
								<label className="uploadLabel"><i className="far fa-edit"></i>
									<input type="file" className="uploadInput" name="certificateImage" onChange={this.fileUpload} />
								</label>
							</div>
							<div className="Certificates__content__image" style={{ "backgroundImage": "url(" + filepath + ")" }}></div>
						</div>
					</div>
				}
			</div>
		);
	}
}

export default Certificates;