import React, { Component } from 'react';
import logo from '../../Assets/Home/logo.png'
import {Link} from 'react-router-dom'

import './Logo.less'

class Logo extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {    
    let logoUrl = (this.props.hompageSetting)?(this.props.hompageSetting.logo)?window.CONFS.storageUrl + "/" +this.props.hompageSetting.logo: logo:logo        
    return (    
       <Link to='/' className="logo" style={{"backgroundImage":"url('"+logoUrl+"')"}}></Link>
    );
  }
}

export default Logo;