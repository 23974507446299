import React, { Component } from 'react';
import Loader from 'react-loader-spinner'
import axios from 'axios';
import Course from './Course'
import ReactModal from 'react-modal';
import './Courses.less'

class Courses extends Component {
	constructor(props) {
		super(props);
		this.state = {
			"courseItems": [],
			"courses": [],
			"courseItemsLoading": true,
			"coursesLoading": true
		}
		this.createAndUpdateCourse = this.createAndUpdateCourse.bind(this)
		this.getCourses = this.getCourses.bind(this)
	}

	componentDidMount = () => {
		this.getCourseItems().then((courseItems) => {
			if (courseItems && courseItems.length > 0) {
				this.setState({ "selectedItemId": courseItems[0]._id }, () => {
					this.getCourses()
				})
			}
		})
		this.getCourses = this.getCourses.bind(this)
	}

	getCourseItems = () => {
		return new Promise((resolve) => {
			axios.get(window.CONFS.apiServer + '/admin/tw/courses')
				.then(res => {
					resolve(res.data.data)
					this.setState({ "courseItems": res.data.data, "courseItemsLoading": false })
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				});
			this.setState({ "courseItemsLoading": true })
		});
	}

	getCourses = () => {
		return new Promise((resolve) => {
			axios.get(window.CONFS.apiServer + '/admin/tw/coursetype/' + this.state.selectedItemId)
				.then(res => {
					if (res.data.errorCode > 0) {
						window.CONFS.validateAdminLoginSession(res.data, this.props.history)
						console.log(res)
					}
					else {
						resolve(res.data.data)
						this.setState({ "courses": res.data.data, "coursesLoading": false })
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				});
			this.setState({ "coursesLoading": true })
		});
	}

	changeCourseItem = e => {
		this.setState({ "selectedItemId": e.target.value }, () => {
			this.getCourses()
		})
	}

	openCourse = (type, course) => {
		if (type === "new") {
			this.setState({
				course: <Course
					courseItemId={this.state.selectedItemId}
					getCourses={this.getCourses}
					createAndUpdateCourse={this.createAndUpdateCourse}
					closeCourse={this.closeCourse}
					title="新增活動"
				/>,
				openModal: true
			})
		}
		else {
			this.setState({
				course: <Course
					{...course}
					getCourses={this.getCourses}
					createAndUpdateCourse={this.createAndUpdateCourse}
					closeCourse={this.closeCourse}
					title="更新活動"
				/>,
				openModal: true
			})
		}
	}

	closeCourse = ()=>{
		this.setState({
			openModal: false
		})
	}

	createAndUpdateCourse = (course) => {
		return new Promise((resolve) => {
			this.setState({ "loading": true })
			axios.post(window.CONFS.apiServer + '/admin/course',
				{
					...course
				})
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				})
		});
	}

	deleteCourse = (id) => {
		if (!window.confirm("確定要刪除資料?")) {
			return false;
		}
		axios.post(window.CONFS.apiServer + '/admin/course',
			{
				id: id,
				removed: true
			})
			.then(res => {
				if (res.data.errorCode > 0) {
					console.log(res)
				}
				else {
					this.getCourses()
				}
			})
			.catch(function (error) {
				console.log('fail post =>', error)
				// window.alert("Server Error")
			})
	}

	showCourseName = (item)=>{
		if(!item.isPublic)
		return `[隱藏] ${item.name}`
		return item.name
	}

	render() {
		return (
			<div className="Courses">
				<div className="title">
					<span>活動管理</span>
				</div>
				<div className="Courses__controlArea">
					{this.state.courseItemsLoading ?
						<Loader
							type="Oval"
							color="#7f1083"
							height={22}
							width={22}
							visible={true}
						/> :
						<select onChange={this.changeCourseItem}>
							{
								this.state.courseItems.map((item, k) => {
									return <option value={item._id} key={k}>{item.title}</option>
								})
							}
						</select>
					}
					{!this.state.courseItemsLoading &&
						<button onClick={() => this.openCourse("new")}>新增活動</button>
					}
				</div>
				<div className="Courses__content">
					{this.state.coursesLoading ?
						<Loader
							type="Oval"
							color="#7f1083"
							height={22}
							width={22}
							visible={true}
						/> :
						<table>
							<thead>
								<tr>
									<td>活動名稱</td>
									<td>活動時間</td>
									<td>結束時間</td>
									<td>時數 (hr)</td>
									<td></td>
								</tr>
							</thead>
							<tbody>
								{this.state.courses.map((item, k) => {
									return <tr key={k} title={item.content}>
										<td><a href={item.url} target="_blank" rel="noopener noreferrer">{this.showCourseName(item)}</a></td>
										<td>{item.openDateTime}</td>
										<td>{item.endDateTime}</td>
										<td>{item.hours ? item.hours : 0}</td>
										<td>
											<button className="Courses__content__editBtn" onClick={() => this.openCourse("update", item)}>編輯</button>
											<button className="Courses__content__deleteBtn" onClick={() => this.deleteCourse(item._id)}>刪除</button>
										</td>
									</tr>
								})}
							</tbody>
						</table>
					}
				</div>
				<ReactModal
					isOpen={this.state.openModal}
					shouldCloseOnOverlayClick={true}
					onRequestClose={() => { this.setState({ "openModal": false }) }}
					style={{ overlay: { "background": "#000000a3" }, content: { "width": "600px", "height": "80vh", "margin": "0 auto" } }}
				>
					<div className="modalControllArea">
						<div onClick={() => { this.setState({ "openModal": false }) }} id="closeModal">
							<i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
						</div>
					</div>
					{this.state.course}
				</ReactModal>
			</div>
		);
	}
}

export default Courses;