import React, { Component } from 'react'
import './PointerPage.less'
import Loader from 'react-loader-spinner'
import axios from 'axios'
import Moment from 'react-moment'
import ReactModal from 'react-modal'
import { withRouter } from 'react-router-dom'
import { i18nKeys } from '../../Enums/i18nEnum'

class PointerPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentLang: 'tw',
			isPointerLoading: false,
			isSavingPointer: false,
			openModal: false,
			classes: [],
			positions: [],
			pointerLevels: [],
			selectedPositions: [],
			pointer: this.convertPropsData()
		}
		this.langs = i18nKeys
	}

	convertPropsData = () => {
		if (this.props.pointer) {
			let inputPointer = this.props.pointer

			return {
				_id: inputPointer._id,
				class: inputPointer.class._id,
				itemAttribute: inputPointer.itemAttribute,
				title: inputPointer.title,
				positions: inputPointer.positions.map(position => { return position.key })
			}
		}
		return {}
	}

	componentDidMount = () => {
		this.getBasicSetting().then(
			(setting) => {
				if(this.props.pointer){
					if(setting.classes.filter(c=>c._id===this.props.pointer.class._id).length===0){
						setting.classes.push(this.props.pointer.class)
					}	
				}
				
				this.setState({
					classes: setting.classes,
					positions: setting.positions,
					pointerLevels: setting.pointerLevels.sort(function (a, b) {
						return a.sequence > b.sequence ? 1 : -1;
					}),
					selectedPositions: (this.props.pointer && this.props.pointer.positions) ? this.props.pointer.positions : []
				})
			}
		)
	}

	searchPointers = () => {
		return new Promise(resolve => {
			axios.get(window.CONFS.apiServer + '/admin/teacher_ability/pointers')
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						this.setState({ "pointers": res.data.data })
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
				});
		})
	}

	getPointer = () => {
		return new Promise((resolve) => {
			axios.get(window.CONFS.apiServer + '/admin/tw/coursetype/' + this.state.selectedItemId)
				.then(res => {
					if (res.data.errorCode > 0) {
						window.CONFS.validateAdminLoginSession(res.data, this.props.history)
						console.log(res)
					}
					else {
						resolve(res.data.data)
						this.setState({ "courses": res.data.data, "coursesLoading": false })
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				});
			this.setState({ "coursesLoading": true })
		});
	}

	appendPosition = () => {
		if (this.state.objectPosition) {
			if (this.state.selectedPositions.filter(item => item.key === this.state.objectPosition).length === 0) {
				let targetObject = this.state.positions.filter(item => item.key === this.state.objectPosition).pop()
				let selectedPositions = [...this.state.selectedPositions, targetObject]
				this.setState({
					selectedPositions: selectedPositions
				})
			}
		}
	}

	removePosition = (objectKey) => {
		this.setState({ selectedPositions: this.state.selectedPositions.filter(item => item.key !== objectKey) })
	}

	savePointer = () => {

		this.state.pointer.positions = this.state.selectedPositions.map(position => { return position.key })

		this.setState({ isSavingPointer: true }, () => {
			if (this.state.pointer._id) {
				if(!window.confirm("確認是否要更新指標?")){
					this.setState({ isSavingPointer: false })
					return false
				}
				this.updatePointerAction(this.state.pointer, this.state.pointer._id).then(result => {
					console.log(result)
					if (result.errorCode && result.errorCode > 0) {
						alert(result.errorMessage)
						this.setState({ isSavingPointer: false })
					}
					this.props.loadPointers({ isPointerModalOpen: false })
				})
			}
			else {
				this.createPointerAction(this.state.pointer).then(result => {
					console.log(result)
					if (result.errorCode && result.errorCode > 0) {
						alert(result.errorMessage)
						this.setState({ isSavingPointer: false })
					}
					this.props.loadPointers({ isPointerModalOpen: false })
				})
			}
		})

	}

	createPointerAction = (payload) => {
		return new Promise(resolve => {
			axios.post(window.CONFS.apiServer + '/admin/teacher_ability/pointer', payload)
				.then(res => {
					resolve(res.data)
				})
				.catch(function (error) {
					console.log('fail post =>', error)
				});
		})
	}

	updatePointerAction = (payload, id) => {
		delete payload["_id"]
		return new Promise(resolve => {
			axios.patch(window.CONFS.apiServer + '/admin/teacher_ability/pointer/' + id, payload)
				.then(res => {
					resolve(res.data)
				})
				.catch(function (error) {
					console.log('fail post =>', error)
				});
		})
	}

	getBasicSetting = () => {
		return new Promise((resolve) => {
			axios.get(window.CONFS.apiServer + '/admin/teacher_ability/pointer/setting')
				.then(res => {
					if (res.data.errorCode > 0) {
						window.CONFS.validateAdminLoginSession(res.data, this.props.history)
						console.log(res)
					}
					else {
						resolve(res.data.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				});
		});
	}

	handleChange = event => {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	handlePointerSelectChange = event => {
		this.setState({
			pointer: {
				...this.state.pointer,
				class: event.target.value
			}
		});
	}

	handlePointerInputChange = (event) => {
		let lang = event.target.getAttribute('lang')
		let pointerKey = event.target.getAttribute('pointer-key')
		let content = event.target.value
		if (pointerKey === 'title') {
			let title = this.state.pointer.title
			if (!title) {
				title = {}
			}
			title[lang] = content

			this.setState({
				pointer: {
					...this.state.pointer,
					title: title
				}
			});
		}
		else {
			let itemAttribute = this.state.pointer.itemAttribute ? this.state.pointer.itemAttribute : {}
			let itemAttributeValuOfKey = itemAttribute[pointerKey] ? itemAttribute[pointerKey] : {}

			itemAttributeValuOfKey[lang] = content
			itemAttribute = {
				...itemAttribute,
				[pointerKey]: itemAttributeValuOfKey
			}
			this.setState({
				pointer: {
					...this.state.pointer,
					itemAttribute: itemAttribute
				}
			});

		}
	}

	render() {
		return (
			<div className="PointerPage">
				<div className="title">
					{this.props.pointer && this.props.pointer._id ?
						<span>更新能力指標</span> :
						<span>建立能力指標</span>
					}
				</div>
				{this.state.isSavingPointer || this.state.isPointerLoading ?
					<Loader
						type="Oval"
						color="#7f1083"
						height={22}
						width={22}
						visible={true}
					/> : <div className="PointerPage__controlArea">
						<button onClick={() => this.savePointer()}>儲存</button>
					</div>
				}

				<div className="PointerPage__content">
					<div className="tabs">
						{this.langs.map((item, key) => {
							return <div key={key} className={`langTab ${this.state.currentLang === item ? '' : 'inactive'}`} onClick={() => { this.setState({ currentLang: item }) }}>{item}</div>
						})}
					</div>
				</div>
				<table>
					<tbody>
						<tr>
							<td>項目分類</td>
							<td>
								<select name='objectClass' value={this.state.pointer.class} onChange={this.handlePointerSelectChange}>
									<option value='' ></option>
									{
										this.state.classes.map((item, k) => {
											return <option value={item._id} key={k}>{item.title.tw}</option>
										})
									}
								</select>
							</td>
						</tr>
						<tr>
							<td>顯示位置</td>
							<td>
								<select name='objectPosition' onChange={this.handleChange}>
									<option value='' ></option>
									{
										this.state.positions.map((item, k) => {
											return <option value={item.key} key={k}>{item.displayName}</option>
										})
									}
								</select>
								<button onClick={() => this.appendPosition()}>加入</button>
								<div className="selected-positions">
									{this.state.selectedPositions.map((item, key) => {
										return <div className="selected-position" key={key}>
											{item.displayName}
											<div className="removed-button" onClick={() => this.removePosition(item.key)}>
												<i className="fas fa-times" style={{ "color": '#ffffff' }}></i>
											</div>
										</div>
									})}
								</div>
							</td>
						</tr>
					</tbody>
				</table>
				<table>
					<tbody>
						<tr>
							<td>指標內容</td>
							<td>
								{this.langs.map((lang, key) => {
									return <textarea key={key} pointer-key='title'
										value={(this.state.pointer.title && this.state.pointer.title[lang]) ? this.state.pointer.title[lang] : ''}
										lang={lang} className={`${this.state.currentLang === lang ? '' : 'global_hide'}`}
										onChange={this.handlePointerInputChange}></textarea>
								})}
							</td>
						</tr>
						{this.state.pointerLevels.map((item, key) => {
							return <tr key={key}>
								<td>{item.displayName[this.state.currentLang]}</td>
								<td>
									{this.langs.map((lang, key) => {
										return <textarea key={key}
											pointer-key={item.key}
											value={(this.state.pointer.itemAttribute && this.state.pointer.itemAttribute[item.key] && this.state.pointer.itemAttribute[item.key][lang]) ? this.state.pointer.itemAttribute[item.key][lang] : ''}
											lang={lang}
											className={`${this.state.currentLang === lang ? '' : 'global_hide'}`}
											onChange={this.handlePointerInputChange}></textarea>
									})}
								</td>
							</tr>
						})}
					</tbody>
				</table>
			</div>
		);
	}
}

export default withRouter(PointerPage);