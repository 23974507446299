import React, { Component } from 'react'
import Loader from 'react-loader-spinner'
import logo from '../../Assets/Home/logo.png'
import axios from 'axios'
import './Certificate.less'
import { withRouter } from "react-router-dom";

class Certificate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      title: "",
      desc: "",
      filepath: "",
      courses: []
    }
  }

  componentDidUpdate = (prevProps) => {    
    if (this.props.location !== prevProps.location)
      this.getCertificates()
  }

  componentDidMount = () => {
    this.getCertificates()
  }

  getCertificates = () => {
    return new Promise((resolve) => {
      axios.get(window.CONFS.apiServer + '/tw/certificate/' + this.props.courseTypeId)
        .then(res => {
          if (res.data.errorCode > 0) {
            window.CONFS.validateAdminLoginSession(res.data, this.props.history)
            console.log(res)
          }
          else {
            resolve(res.data.data)
            let result = res.data.data
            this.setState({
              "loading": false,
              title: result.title,
              desc: result.desc,
              filepath: result.filepath
            })
          }
        })
        .catch(function (error) {
          console.log('fail post =>', error)
          // window.alert("Server Error")
        });
      this.setState({ "courseItemsLoading": true })
    });
  }

  render() {
    let imageUrl = (this.state.filepath)? window.CONFS.storageUrl + "/" +this.state.filepath: logo
    return (
      <div className="Certificate">
        {this.state.loading ?
          <Loader
            type="Oval"
            color="#7f1083"
            height={22}
            width={22}
            visible={true}
          /> :
          <div>
            <div className="Certificate__title">
              {this.state.title}
            </div>
            <div className="Certificate__content">
              {this.state.desc &&
                <div className="Certificate__content__desc">{this.state.desc}</div>
              }
              <div className="Certificate__content__image" style={{"backgroundImage": "url("+imageUrl+")"}}></div>
            </div>
          </div>
        }

      </div>
    );
  }
}

export default withRouter(Certificate);