import React, { Component } from "react";
import axios from "axios";
import "./UploadFile.less";
import LocalStorage from "../../../Helpers/LocalStorage";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { withTranslation } from 'react-i18next';
class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadPercent: 0,
      uploadUrl: "",
      uploadFileName: "",
    }
    this.nthusteam = LocalStorage.getLocalStorage("nthusteam");
    console.log(this.props)
  }

  fileUpload = event => {
    const data = new FormData();
    let app = this
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        app.setState({ uploadPercent: percentCompleted })
      },
      headers: { "X-Authorize": this.nthusteam.apiToken }
    }

    data.append("file[]", event.target.files[0]);

    // 上傳檔案
    axios.post(window.CONFS.apiServer + "/sys/uploadFiles", data, config)
      .then(res => {

        console.log('PlanInstances uploadFiles res =>', res);
        // let name = res.data.data[0].fileName;
        let uuid = res.data.data[0].uuidName;

        // window.open("https://storage.hisoft.site/" + res.data.data[0].uuidName)
        if(uuid){
          this.setState({
            uploadUrl: res.data.data[0].uuidName,
            uploadFileName: res.data.data[0].fileName,
            uploadPercent: 0
          })
        }else{
          window.alert('上傳失敗請再試一次')
        }

        
      })
      .catch(function (error) {
        console.log("fail post", error);
      });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });

  }

  removeFile = () => {

    document.getElementById('uploadFile').value = null;

    this.setState({
      uploadUrl: "",
      uploadFileName: ""
    })
  }

  save = () => {
    if(this.state.uploadUrl){
      
      this.props.saveFn({
        description: this.state.desc,
        fileName: this.state.uploadFileName,
        path: this.state.uploadUrl
      })
      document.getElementById("modalCloseBtn").click()
    }else{
      this.checkUploadStatus()
    }
    
  }


  checkUploadStatus = () => {
    // <button onClick={this.deleteStudent}>{t('step3.delete')}</button>
    const { t } = this.props
    confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui alertButtonBox'>
              <h1>上傳未完成請稍候</h1>
              <button onClick={onClose}>確認</button>
            </div>
          );
        }
      });
  };

  render() {

    const{ t } = this.props;

    let urlPath = window.CONFS.storageUrl + "/" + this.state.uploadUrl
    let isVideo = (this.props.fileType === "video")
    return (
      <div className="UploadFile">
        <div className="UploadFile__title">{
          this.props.title
        }</div>
        <div className="UploadFile__content">
          <div className="UploadFile__content__controll">
            <div onClick={this.removeFile}><i className="fa fa-trash"></i></div>
            <label className="uploadLabel"><i className="far fa-edit"></i>
              <input type="file" className="uploadInput" name="uploadFile" id="uploadFile" onChange={this.fileUpload} />
            </label>
            <div>{this.state.uploadFileName}</div>
          </div>
          <div className="UploadFile__content_setting">
            {this.state.uploadUrl && isVideo ?
              <video className="UploadFile__content_setting_preview" controls>
                <source src={urlPath} type="video/mp4" />
                Your browser does not support HTML5 video.
              </video>:
              (this.state.uploadUrl && !isVideo) ?
                <div className="UploadFile__content_setting_preview" style={{ "backgroundImage": "url(" + urlPath + ")" }}>
                </div> :
                <div className="UploadFile__content_setting_preview">
                  {this.state.uploadPercent > 0 &&
                    <div className="percentNumber">{this.state.uploadPercent} %</div>
                  }
                </div>
            }

            <textarea className="UploadFile__content_setting_desc" name="desc" onChange={this.handleChange} placeholder="文件描述">
            </textarea>
          </div>
          <div className="UploadFile__content__controllArea">
            <button className="UploadFile__content__controllArea__saveBtn" onClick={this.save}>{t('step2.save')}</button>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(UploadFile);