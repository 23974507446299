import React, { Component } from 'react'
import axios from 'axios';
import Loader from 'react-loader-spinner'
import LessonLife from "./LessonLife";
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import { DataGrid } from '@material-ui/data-grid';
import moment from 'moment'
import { Dialog, DialogTitle, DialogContent, withStyles } from '@material-ui/core';
import MemberCertificationDetail from '../../Admin/MemberManagement/MemberCertificationDetail'
import LevelCourseHourSetting from '../../LevelCourseHourSetting'

import './Index.less'

class Index extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoadingCertifications: true,
            isLoadingCourses: true,
            isLoadingJoinedCourses: true,
            isOpenCertification: false,
            certificationDetail: <MemberCertificationDetail loginRole="member" />,
            upcomingCourses: [],
            joinedCourses: [],
            memberInfo: null,
            // certifications: [],
            currentTab: "processingActive"
        }

        this.columns = [
            { field: 'openDateTime', headerName: '活動時間', width: 130 },
            { field: 'endDateTime', headerName: '結束時間', width: 130 },
            {
                field: 'name', headerName: '活動名稱', flex: 4, renderCell: (params) => (
                    <a href={params.getValue('url')} style={{ textDecoration: "None" }} target="_blank">{params.value}</a>
                )
            },
            { field: 'hours', headerName: '時數(hr)', flex: 1 },
        ]

        this.certificationColumns = [
            {
                field: 'certificationNumber', headerName: '編號', flex: 1, renderCell: (params) => (
                    <a href onClick={() => this.props.history.push(`certification/${params.getValue('id')}/`)} style={{ textDecoration: "None" }}>{params.value}</a>
                )
            },
            { field: 'certificationDate', headerName: '通過日期', flex: 1 }
        ]
    }

    componentDidMount() {
        // console.log({"action":this.props.match.params.action})
        // console.log({"id":this.props.match.params.id})
        this.init()
        this.initModalSetting()
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.initModalSetting()
        }
    }

    initModalSetting = () => {
        if (this.props.match.params.actionId) {
            this.setState({ memberlessonPlans: <MemberCertificationDetail loginRole="member" />, isOpenCertification: true })
        }
        else {
            this.setState({ isOpenCertification: false })
        }
    }

    init = () => {
        this.setState({
            isLoadingCourses: true,
            isLoadingJoinedCourses: true,
            isLoadingCertifications: true
        }, async () => {
            let upCommingCourses = await this.getCourses()
            upCommingCourses.forEach(course => { course.id = course._id })

            let joinedCourses = await this.getJoinedCourses()
            joinedCourses.forEach(course => { course.id = course._id })

            upCommingCourses = upCommingCourses.filter(upCommingCourse=>!joinedCourses.map(joinedCourse=>joinedCourse.id).includes(upCommingCourse.id))

            let memberInfo = await this.getLevelCertification()
            let certifications = await this.getCertifications()
            certifications.forEach(certification => { certification.id = certification._id })
            this.setState({
                certifications: certifications,
                isLoadingCertifications: false,
                upcomingCourses: upCommingCourses,
                isLoadingCourses: false,
                joinedCourses: joinedCourses,
                isLoadingJoinedCourses: false,
                memberInfo: memberInfo
            },()=>{
                console.log({state:this.state})
            })
        })
    }

    getCourses = () => {
        return new Promise(resolve => {
            axios.get(window.CONFS.apiServer + '/courses/tw')
                .then(res => {
                    if (res.data.errorCode > 0) {
                        console.log(res)
                    }
                    else {
                        resolve(res.data.data)
                    }
                })
                .catch(function (error) {
                    console.log('fail post =>', error)
                });
        })
    }

    getJoinedCourses = () => {
        return new Promise(resolve => {
            axios.get(window.CONFS.apiServer + '/courses/participated/tw')
                .then(res => {
                    if (res.data.errorCode > 0) {
                        console.log(res)
                    }
                    else {
                        resolve(res.data.data)
                    }
                })
                .catch(function (error) {
                    console.log('fail post =>', error)
                });
        })
    }

    getCertifications = () => {
        return new Promise(resolve => {
            axios.get(window.CONFS.apiServer + '/member/approved/certifications')
                .then(res => {
                    if (res.data.errorCode > 0) {
                        console.log(res)
                    }
                    else {
                        resolve(res.data.data)
                    }
                })
                .catch(function (error) {
                    console.log('fail post =>', error)
                });
        })
    }

    getLevelCertification = () => {
        return new Promise(resolve => {
            axios.get(window.CONFS.apiServer + '/member/levelCertification')
                .then(res => {
                    if (res.data.errorCode > 0) {
                        console.log(res)
                    }
                    else {
                        resolve(res.data.data)
                    }
                })
                .catch(function (error) {
                    console.log('fail post =>', error)
                });
        })
    }

    changeActiveTab = function (self, value) {
        self.setState({ currentTab: value });
    }

    render() {
        const StyledDialog = withStyles({
            paper: {
                width: "60%"
            }
        })(Dialog);
        return (
            <>
                <div className="Index">
                    <div className="upcoming-course">
                        <div className="titles">
                            <div className={`title ${this.state.currentTab === "processingActive" ? "selected" : ""}`} onClick={() => { this.changeActiveTab(this, "processingActive") }}>
                                進行中的活動<>{this.state.upcomingCourses.length > 0 && `(${this.state.upcomingCourses.length})`}</></div>
                            <div className={`title ${this.state.currentTab != "processingActive" ? "selected" : ""}`} onClick={() => { this.changeActiveTab(this, "joinedCourses") }}>
                                已參與的活動<>{this.state.joinedCourses.length > 0 && `(${this.state.joinedCourses.length})`}</></div>
                        </div>
                        {this.state.currentTab === "processingActive" ?
                            <>{this.state.isLoadingCourses ? <Loader
                                type="Oval"
                                color="#7f1083"
                                height={22}
                                width={22}
                                visible={true}
                            /> : <div className="content">
                                <DataGrid
                                    rowsPerPageOptions={[10]}
                                    scrollbarSize={30}
                                    hideFooterSelectedRowCount
                                    rows={this.state.upcomingCourses}
                                    columns={this.columns} />
                            </div>}</> : <>{this.state.isLoadingJoinedCourses ? <Loader
                                type="ThreeDots"
                                color="#7f1083"
                                height={22}
                                width={22}
                                visible={true}
                            /> : <>{this.state.joinedCourses.length > 0 &&
                                <div className="joined-courses">
                                    <div className="content">
                                        <DataGrid
                                            rowsPerPageOptions={[10]}
                                            scrollbarSize={30}
                                            hideFooterSelectedRowCount
                                            rows={this.state.joinedCourses.sort((a, b) => moment(b.endDateTime) - moment(a.endDateTime))}
                                            columns={this.columns} />
                                    </div>
                                </div>}
                            </>}</>}
                    </div>
                    <LessonLife 
                     joinedCourses={this.state.joinedCourses}
                     memberInfo={this.state.memberInfo} />
                    {/* <div className="certifications">
                        <div className="level-item">
                            <div className="level-title level1">Level 1</div>
                            <div className="content"></div>
                        </div>
                        <div className="level-item">
                            <div className="level-title level2">Level 2</div>
                            <div className="content"></div>
                        </div>
                        <div className="level-item">
                            <div className="level-title level3">Level 3</div>
                            <div className="content"></div>
                        </div>
                        <div className="level-item">
                            <div className="level-title level4">Level 4</div>
                            <div className="content"></div>
                        </div>
                        <div className="title">認證憑證</div>
                        {this.state.isLoadingCertifications ? <Loader
                            type="Oval"
                            color="#7f1083"
                            height={22}
                            width={22}
                            visible={true}
                        /> : <div className="content">
                            <DataGrid
                                rowsPerPageOptions={[10]}
                                scrollbarSize={30}
                                hideFooterSelectedRowCount
                                rows={this.state.certifications}
                                columns={this.certificationColumns} />
                        </div>}
                    </div> */}
                </div>
                <StyledDialog
                    maxWidth={false}
                    disableBackdropClick={true}
                    fullWidth={true}
                    open={this.state.isOpenCertification}
                    onClose={() => { this.props.history.push('../../') }}
                >
                    <DialogTitle>
                        <div className="modalControllArea">
                            <div onClick={() => { this.props.history.push('../../') }}>
                                <i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
                            </div>
                        </div>
                    </DialogTitle>
                    <DialogContent dividers>
                        {this.state.certificationDetail}
                    </DialogContent>
                </StyledDialog>
            </>
        )
    }
}

export default withRouter(withTranslation()(Index));