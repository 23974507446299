import React, { Component } from 'react';
import Loader from 'react-loader-spinner'
import axios from 'axios';
import './CourseType.less'
import { withRouter } from "react-router-dom";

class CourseType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      title: "",
      courses: []
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.location !== prevProps.location)
      this.getCourses()
  }

  componentDidMount = () => {
    this.getCourses()
  }

  getCourses = () => {
    return new Promise((resolve) => {
      axios.get(window.CONFS.apiServer + '/tw/coursetype/' + this.props.courseTypeId)
        .then(res => {
          if (res.data.errorCode > 0) {
            window.CONFS.validateAdminLoginSession(res.data, this.props.history)
            console.log(res)
          }
          else {
            resolve(res.data.data)
            let result = res.data.data
            this.setState({
              "loading": false,
              title: result.courseType.title, courses: result.courses
            })
          }
        })
        .catch(function (error) {
          console.log('fail post =>', error)
          // window.alert("Server Error")
        });
    });
  }

  render() {
    return (
      <div className="CourseType">
        {this.state.loading ?
          <Loader
            type="Oval"
            color="#7f1083"
            height={22}
            width={22}
            visible={true}
          /> :
          <div><div className="CourseType__title">
            {this.state.title}
          </div>
            <div className="CourseType__content">
              <table>
                <thead>
                  <tr>
                    <td>研習課程名稱</td>
                    <td>時數 (hr)</td>
                    <td>課程目標</td>
                    <td>課程內容</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {this.state.courses.map((item, k) => {
                    return <tr key={k}>
                      <td>{item.name}</td>
                      <td>{item.hours ? item.hours : 0}</td>
                      <td>{item.target}</td>
                      <td>{item.content}</td>
                      <td><a href={item.url} target="_blank" rel="noopener noreferrer"><i className="fas fa-external-link-alt"></i></a></td>
                    </tr>
                  })}
                </tbody>
              </table>
            </div></div>
        }

      </div>
    );
  }
}

export default withRouter(CourseType);