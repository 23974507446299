import React, { Component } from 'react'
import './ClassesManagement.less'
import Loader from 'react-loader-spinner'
import axios from 'axios'
import Moment from 'react-moment'
import ReactModal from 'react-modal'
import { withRouter } from 'react-router-dom'
import ClassPage from './ClassPage'

class ClassesManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			classes: this.props.classes,
			classesLoading: false,
			showAddClassArea: false,
			isDeletingClass: false,
			isClassPageModalOpen: false,
			classPage: null
		}
	}

	editClass = (id, val, isEdit) => {
		let data = this.state.classes.map(item => {
			item.isEditMode = isEdit
			return item
		})
		let classObject = data.filter(item => item._id == id).pop()
		classObject.currentEdit = isEdit
		this.setState({ classes: data, [id]: isEdit ? val : null })
	}

	executeEditClass = (id) => {
		if (window.confirm("確定要更新類別?")) {
			this.setState({ isUpdatingClass: true }, () => {
				this.editClassAction(id).then(
					() => this.props.getClasses()
				).then((classes) => {
					this.setState({
						classes: classes,
						isUpdatingClass: false
					})
				})
			})

		}
	}

	editClassAction = (id) => {
		return new Promise(resolve => {
			axios.patch(window.CONFS.apiServer + `/admin/lessonplan/resource/class/${id}`,
				{
					title: this.state[id]
				})
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res.data.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
				});
		})
	}

	executeDeleteClass = (id) => {
		if (window.confirm("確定要刪除類別?")) {
			this.setState({ isDeletingClass: true }, () => {
				this.deleteClassAction(id).then(
					() => this.props.getClasses()
				).then((classes) => {
					// this.props.loadPointers()
					this.setState({
						classes: classes,
						isDeletingClass: false
					})
				})
			})

		}
	}

	deleteClassAction = (id) => {
		return new Promise(resolve => {
			axios.delete(window.CONFS.apiServer + `/admin/lessonplan/resource/class/${id}`,
				{
					title: this.state[id]
				})
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res.data.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
				});
		})
	}

	refreshClasses = (stateSetting) => {
		this.props.getClasses().then(classes => {
			this.setState({
				classes: classes,
				...stateSetting
			})
		})

	}

	getClassAction = (id) => {
		return new Promise(resolve => {
			axios.get(window.CONFS.apiServer + `/lessonplan/resource/class/${id}`)
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res.data.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
				});
		})
	}

	openMaintainClassPage = (id) => {
		if (id) {
			this.getClassAction(id).then(classObject => {
				this.setState({ isClassPageModalOpen: true, classPage: <ClassPage class={classObject} refreshClasses={this.refreshClasses} /> })
			})
		}
		else {
			this.setState({ isClassPageModalOpen: true, classPage: <ClassPage  class={{}} refreshClasses={this.refreshClasses} /> })
		}
	}

	render() {
		return (
			<div className="ClassesManagement">
				<div className="title">
					<span>類型管理</span>
				</div>
				<div className="ClassesManagement__controlArea">
					<button onClick={()=>{ this.openMaintainClassPage()}}>新增類型</button>					
				</div>
				<div className="ClassesManagement__content">
					{this.state.classesLoading ?
						<Loader
							type="Oval"
							color="#7f1083"
							height={22}
							width={22}
							visible={true}
						/> :
						<table>
							<thead>
								<tr>
									<td>項目</td>
									<td>更新時間</td>
									<td></td>
								</tr>
							</thead>
							<tbody>
								{this.state.classes.map((item, k) => {
									return <tr key={k}>
										<td>
											{(item.isEditMode && item.currentEdit) ?
												<input type='text' defaultValue={item.title} name={item._id} onChange={this.handleUpdateClassChange} /> :
												<span>{item.title.tw}</span>
											}
										</td>
										<td><Moment format="YYYY/MM/DD HH:mm">{item.modifiedAt}</Moment></td>
										<td>
											<div>
												<button className="ClassesMangement__content__editBtn" onClick={() => this.openMaintainClassPage(item._id)}>編輯</button>
												<button className="ClassesMangement__content__deleteBtn" onClick={() => this.executeDeleteClass(item._id)}>刪除</button>
											</div>
										</td>
									</tr>
								})}
							</tbody>
						</table>
					}
				</div>
				<ReactModal
					isOpen={this.state.isClassPageModalOpen}
					shouldCloseOnOverlayClick={false}	
					onRequestClose={() => { this.setState({ isClassPageModalOpen: false }) }}
					style={{ overlay: { "background": "#000000a3" }, content: { "width": "40%", "height": "60vh", "margin": "0 auto" } }}
				>
					<div className="modalControllArea">
						<div onClick={() => { this.setState({ isClassPageModalOpen: false }) }}>
							<i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
						</div>
					</div>
					{this.state.classPage}
				</ReactModal>
			</div>
		);
	}
}

export default withRouter(ClassesManagement);