import React, { Component } from 'react'
import './ClassPage.less'
import Loader from 'react-loader-spinner'
import axios from 'axios'
import Moment from 'react-moment'
import ReactModal from 'react-modal'
import { withRouter } from 'react-router-dom'
import { i18nKeys } from '../../Enums/i18nEnum'

class ClassPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSavingClass: false,
			isShowSaveClassBtn: false,
			currentLang: i18nKeys[0],
			class: this.props.class
		}
		this.langs = i18nKeys
	}

	saveClass = () => {
		if (this.state.class._id && !window.confirm("確定要更新類型?")) {
			return
		}

		this.setState({ isSavingClass: true }, () => {
			if (this.state.class._id) {
				this.udpateClassAction().then(result => {
					if (result.errorCode && result.errorCode > 0) {
						alert(result.errorMessage)
						this.setState({ isSavingClass: false })
					}
					this.props.refreshClasses({ isClassPageModalOpen: false })
				})
			}
			else {
				this.saveClassAction().then(result => {
					if (result.errorCode && result.errorCode > 0) {
						alert(result.errorMessage)
						this.setState({ isSavingClass: false })
					}
					this.props.refreshClasses({ isClassPageModalOpen: false })
				})
			}
		})

	}

	saveClassAction = () => {
		return new Promise(resolve => {
			axios.post(window.CONFS.apiServer + '/admin/lessonplan/resource/class',
				this.state.class)
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
				});
		})
	}

	udpateClassAction = () => {
		const classId = this.state.class._id
		this.state.class._id = null
		return new Promise(resolve => {
			axios.patch(window.CONFS.apiServer + '/admin/lessonplan/resource/class/'+classId,
				this.state.class)
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
				});
		})
	}

	handleClassInputChange = (event) => {
		let lang = event.target.getAttribute('lang')
		let classKey = event.target.getAttribute('class-key')
		let content = event.target.value
		let classKeyObject = this.state.class[classKey]
		if (!classKeyObject) {
			classKeyObject = {}
		}
		classKeyObject[lang] = content

		this.setState({
			class: {
				...this.state.class,
				[classKey]: classKeyObject
			}
		}, () => {
			this.setState({
				isShowSaveClassBtn: (this.state.class.title && this.state.class.title.tw) ? true : false
			})
		});
	}

	render() {
		return (
			<div className="ClassPage">
				<div className="title">
					{this.props.class && this.props.class._id ?
						<span>更新類型</span> :
						<span>建立類型</span>
					}
				</div>
				{this.state.isSavingClass ?
					<Loader
						type="Oval"
						color="#7f1083"
						height={22}
						width={22}
						visible={true}
					/> : <div className="ClassPage__controlArea">
						{this.state.isShowSaveClassBtn && <button onClick={() => this.saveClass()}>儲存</button>}
					</div>
				}

				<div className="ClassPage__content">
					<div className="tabs">
						{this.langs.map((item, key) => {
							return <div key={key} className={`langTab ${this.state.currentLang === item ? '' : 'inactive'}`} onClick={() => { this.setState({ currentLang: item }) }}>{item}</div>
						})}
					</div>
				</div>
				<table>
					<tbody>
						<tr>
							<td>項目名稱</td>
							<td>
								{this.langs.map((lang, key) => {
									return <textarea key={key} class-key='title'
										value={(this.state.class.title && this.state.class.title[lang]) ? this.state.class.title[lang] : ''}
										lang={lang} className={`${this.state.currentLang === lang ? '' : 'global_hide'}`}
										onChange={this.handleClassInputChange}></textarea>
								})}
							</td>
						</tr>
						{/* <tr>
							<td>項目說明</td>
							<td>
								{this.langs.map((lang, key) => {
									return <textarea key={key} class-key='description'
										value={(this.state.class.description && this.state.class.description[lang]) ? this.state.class.description[lang] : ''}
										lang={lang} className={`ClassPage__content__descripton ${this.state.currentLang === lang ? '' : 'global_hide'}`}
										onChange={this.handleClassInputChange}></textarea>
								})}
							</td>
						</tr> */}

					</tbody>
				</table>
			</div>
		);
	}
}

export default withRouter(ClassPage);