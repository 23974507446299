
import React, { Component } from 'react'

import './Success.less'

class Success extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
        let title = this.getTitle();
        let content = this.getContent();
        let contact = this.getContact();

        this.setState({ title: title, content: content, contact: contact })
    }

    getTitle = () => {
        return "清華STEAM School會員申請";
    }

    getContent = () => {
        return <div className="content">
            <p>感謝您的註冊，</p>

            <p>請您確認是否於您註冊的信箱中，收到註冊成功信件。</p>
            <p>若收到註冊成功信件，表示您已可以登入清華STEAM學校平台系統(<a href="https://certification.ddmt.education/signin">https://certification.ddmt.education/signin</a>)</p>
            <p>若您沒有收到會員認證信，可能是您的E-Mail信箱將通知信件過濾成為垃圾郵件，請您至垃圾郵件資料夾中搜尋，並將本網站寄送的信件，設定成為安全的郵件(帳號)，或者重新檢查您的E-Mail信箱。</p>
            <p>若您的E-Mail信箱填寫有誤，亦會造成「會員認證信」無法寄達的問題。</p>
            <p>若查看垃圾桶及其他收件匣都還是無法收到，請與我們聯絡。</p>

        </div>
    }

    getContact = () => {
        return <div className="contact">
            <p>服務時間：週一至週五 09：00 - 17：00 ；週六至週日及國定假日公休。</p>
            <p>服務電話：(03)571-5131 分機：73363</p>
            <p>服務信箱：steam@my.nthu.edu.tw</p>
            <p>感謝您的愛護與支持</p>
        </div>
    }

    render() {

        const { title = "", content = "", contact = "" } = this.state;
        return (
            <div className="successDiv">
                <h1>{title}</h1>
                {content}
                <p></p>
                {contact}
            </div>
        )
    }
}

export default Success;