import React, { Component } from 'react';
import Loader from 'react-loader-spinner'
import axios from 'axios';
import logo from '../../../Assets/Home/logo.png'
import './Home.less'

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			"loading": true
		}
	}

	componentDidMount = () => {
		this.getHomePageSetting()
	}

	getHomePageSetting = () => {
		return new Promise((resolve) => {
			axios.get(`${window.CONFS.apiServer}/admin/homepage/setting`)
				.then(res => {
					if (res.data.errorCode > 0) {
						window.CONFS.validateAdminLoginSession(res.data, this.props.history)
						console.log(res)
					}
					else {
						resolve(res.data.data)
						let homepageSetting = res.data.data
						this.setState({
							"loading": false,
							"homepageSetting_id": homepageSetting._id,
							"logo": homepageSetting.logo,
							"promotionImage": homepageSetting.promotionImage,
							"promotionContent_tw": homepageSetting.promotionContent.tw,
							"promotionContent_cn": homepageSetting.promotionContent.cn,
							"promotionContent_en": homepageSetting.promotionContent.en
						})
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				});
		});
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	fileUpload = (event) => {
		const data = new FormData();

		data.append("file[]", event.target.files[0]);
		let name = event.target.name

		// 上傳檔案
		axios.post(window.CONFS.apiServer + "/sys/admin/uploadFiles", data)
			.then(res => {
				this.setState({ [name]: res.data.data[0].uuidName })
			})
			.catch(function (error) {
				console.log("fail post", error);
			});
	};

	saveHomepageSetting = () => {
		console.log(this.state)
		this.setState({ "loading": true })
		axios.post(window.CONFS.apiServer + '/admin/homepage/setting',
			{
				"id": this.state.homepageSetting_id,
				"logo": this.state.logo,
				"promotionContent": {
					"cn": this.state.promotionContent_cn,
					"en": this.state.promotionContent_en,
					"tw": this.state.promotionContent_tw
				},
				"promotionImage": this.state.promotionImage
			})
			.then(res => {
				if (res.data.errorCode > 0) {
					window.CONFS.validateAdminLoginSession(res.data, this.props.history)
					console.log(res)
				}

				this.setState({ "loading": false });
			})
			.catch(function (error) {
				console.log('fail post =>', error)
				// window.alert("Server Error")
			});
	}

	deleteImage = (name)=>{
		this.setState({[name]:""})
	}

	render() {
		let homepageLogo = (this.state.logo) ? window.CONFS.storageUrl + "/" + this.state.logo : logo
		let promotionImage = (this.state.promotionImage) ? window.CONFS.storageUrl + "/" + this.state.promotionImage : logo
		return (
			<div className="Home">
				<div className="title">
					<span>首頁頁面管理</span>
				</div>
				{this.state.loading ? <Loader
					type="Oval"
					color="#7f1083"
					height={22}
					width={22}
					visible={true}
				/> : <div className="Home__content">
						<div className="Admin__row Resource__title">
							<button onClick={this.saveHomepageSetting}>儲存</button>
						</div>
						<div className="Admin__row underline">
							<div className="Admin__row__item">Logo</div>
							<div className="Admin__row__item4">
								<div className="Home_Image_controll">
									<label className="uploadLabel"><i className="far fa-edit"></i>
										<input type="file" className="uploadInput" name="logo" onChange={this.fileUpload} />
									</label>
									<div onClick={()=>this.deleteImage("logo")}><i className="fa fa-trash"></i></div>
								</div>
								<div>
									<div className="Home__content__image" style={{ "backgroundImage": "url(" + homepageLogo + ")" }}></div>
								</div>
							</div>
						</div>
						<div className="Admin__row underline">
							<div className="Admin__row__item">banner 圖片</div>
							<div className="Admin__row__item4">
								<div className="Home_Image_controll">
									<label className="uploadLabel"><i className="far fa-edit"></i>
										<input type="file" className="uploadInput" name="promotionImage" onChange={this.fileUpload} />
									</label>
									<div onClick={()=>this.deleteImage("promotionImage")}><i className="fa fa-trash"></i></div>

								</div>
								<div>
									<div className="Home__content__image" style={{ "backgroundImage": "url(" + promotionImage + ")" }}></div>
								</div>
							</div>
						</div>
						<div className="Admin__row underline">
							<div className="Admin__row__item">banner 中文說明</div>
							<div className="Admin__row__item4">
								<input type="text" onChange={this.handleChange} name="promotionContent_tw" defaultValue={this.state.promotionContent_tw} />
							</div>
						</div>
						<div className="Admin__row underline">
							<div className="Admin__row__item">banner 英文說明</div>
							<div className="Admin__row__item4">
								<input type="text" onChange={this.handleChange} name="promotionContent_en" defaultValue={this.state.promotionContent_en} />
							</div>
						</div>
						<div className="Admin__row underline">
							<div className="Admin__row__item">banner 簡中說明</div>
							<div className="Admin__row__item4">
								<input type="text" onChange={this.handleChange} name="promotionContent_cn" defaultValue={this.state.promotionContent_cn} />
							</div>
						</div>
					</div>

				}

			</div>
		);
	}
}

export default Home;