import React, { Component, createRef } from 'react'
import { withRouter } from "react-router-dom";

import axios from "axios";
import LocalStorage from "../../Helpers/LocalStorage";
import './TeacherAbilityPage.less'
import StudentModal from './PlanStep3/StudentModal'
import Moment from 'react-moment'

class TeacherAbilityPage extends Component {
    constructor(props) {
        super(props);
        this.computeTeacherAbilityPointersShow()
        console.log({ props: this.props })
        this.state = {
            teacherAbility: JSON.parse(JSON.stringify(this.props.teacherAbility ? this.props.teacherAbility : {})),
            teacherAbilityPointers: this.props.teacherAbilityPointers
        }

    }

    computeTeacherAbilityPointersShow = () => {
        this.props.teacherAbilityPointers.classes.forEach(classObject => {
            let isShowClass = false
            classObject.pointers.forEach(pointer => {
                if (!isShowClass)
                    isShowClass = pointer.positions.indexOf(this.props.currentPosition) > -1
            })
            classObject.isShowClass = isShowClass
        });
    }

    componentDidMount = () => {
        console.log({ teacherAbility: this.state.teacherAbility })
    }

    getOptionTitle = (itemAttributeKey) => {
        const pointerLevels = this.props.teacherAbilityPointers.pointerLevels;
        const pointerLevel = pointerLevels.filter(item => item.key === itemAttributeKey).pop()
        return pointerLevel.displayName.tw
    }

    handleOptionChange = (event) => {
        let position = event.target.getAttribute('data-position')
        let classId = event.target.getAttribute('data-class-id')
        let pointerId = event.target.getAttribute('data-pointer-id')
        let pointerLevelKey = event.target.getAttribute('data-pointer-level-key')
        let teacherAbility = this.state.teacherAbility;

        if (!teacherAbility["author"])
            teacherAbility["author"] = this.getAuthor()

        if (!teacherAbility["position"])
            teacherAbility["position"] = position

        if (!teacherAbility["content"])
            teacherAbility["content"] = {}

        if (!teacherAbility["content"][classId])
            teacherAbility["content"][classId] = {}

        teacherAbility["content"][classId][pointerId] = pointerLevelKey

        this.setState({
            teacherAbility: teacherAbility
        }, () => {
            this.props.isChangeAbilityContent()
        })
    }

    getAuthor() {
        let nthusteam = LocalStorage.getLocalStorage("nthusteam");
        if (nthusteam != null && nthusteam.apiToken != null) {
            return nthusteam.GUID;
        } else {
            this.props.history.push("/signin");
        }
    }

    saveTeacherAbility = () => {
        if (this.isAllQuestionSelected()) {
            this.props.setTeacherAbility(this.state.teacherAbility)
            this.props.closeTeacherAbilityPointersPage()
        }
        else {
            window.alert("全部選項都要填寫!")
        }
    }

    closeTeacherAbility = () => {
        this.setState({
            teacherAbility: Object.assign({}, this.props.teacherAbility)
        }, () => {
            this.props.closeTeacherAbilityPointersPage()
        })
    }

    isRadioChecked = (content, classId, pointerId, pointerLevelKey) => {
        try {
            if (content[classId][pointerId] === pointerLevelKey) {
                return true
            }
        }
        catch (e) {

        }
        return false
    }

    isAllQuestionSelected = () => {
        let isAllQuestionSelected = true;

        let content = this.state.teacherAbility.content
        let teacherAbilityPointers = JSON.parse(JSON.stringify(this.props.teacherAbilityPointers))
        teacherAbilityPointers.classes.filter(classObject => classObject.isShowClass).forEach(_class => {
            if (content && content[_class._id]) {
                _class.pointers.forEach(pointer => {
                    if (isAllQuestionSelected && !content[_class._id][pointer._id]) {
                        isAllQuestionSelected = false;
                        pointer.isEmpty = true
                    }
                })
            }
            else {
                isAllQuestionSelected = false;
                _class.pointers.forEach(pointer => {
                    pointer.isEmpty = true
                })
            }
        })

        if(!isAllQuestionSelected){
            this.setState({ teacherAbilityPointers: teacherAbilityPointers })
        }
        
        return isAllQuestionSelected
    }

    render() {
        const pointerLevels = this.props.teacherAbilityPointers.pointerLevels
        const teacherAbilityContent = (this.props.teacherAbility && this.props.teacherAbility.content) ? this.props.teacherAbility.content : {}
        const { currentPosition } = this.props
        return (
            <div className="TeacherAbilityPage">
                <div className="title">設定能力指標</div>
                <div className="TeacherAbilityPage_control-area">
                    <button type="button" onClick={this.saveTeacherAbility}>儲存</button>
                    <button type="button" onClick={this.closeTeacherAbility}>取消</button>
                </div>
                <div className="TeacherAbilityPage_content-area">
                    {this.state.teacherAbilityPointers.classes.filter(classObject => classObject.isShowClass).map((item, key_1) => {
                        return <div key={key_1} className="TeacherAbilityPage__Pointers">
                            <div className="TeacherAbilityPage__Class"
                                dangerouslySetInnerHTML={{ __html: `${item.title.tw.replaceAll(/\r\n|\r|\n/g, '<br/>')} ${item.removed ? '(Removed)' : ''}` }}></div>
                            <div className="TeacherAbilityPage__ClassContent">
                                <div className="TeacherAbilityPage__ClassDescription"
                                    dangerouslySetInnerHTML={{ __html: `${(item.description && item.description.tw) ? item.description.tw.replaceAll(/\r\n|\r|\n/g, '<br/>') : ""} ${item.removed ? '(Removed)' : ''}` }}></div>
                                {item.pointers.filter(pointer => pointer.positions.indexOf(currentPosition) > -1).map((pointer, key_2) => {
                                    return <div key={key_2} className="TeacherAbilityPage__PointerItem">
                                        <div className={`TeacherAbilityPage__PointerItem_title ${pointer.isEmpty ? 'empty' : ''}`}
                                            dangerouslySetInnerHTML={{ __html: `${pointer.title.tw.replaceAll(/\r\n|\r|\n/g, '<br/>')}` }}></div>
                                        <div className="TeacherAbilityPage__PointerItem_content">
                                            {
                                                pointerLevels.map((pointerLevel, key_3) => {
                                                    return <label key={key_3} htmlFor={`${pointer._id}_${pointerLevel.key}`}>
                                                        <div className="TeacherAbilityPage__PointerItem_Option">
                                                            <div className="TeacherAbilityPage__PointerItem_Option_Title">

                                                                {this.isRadioChecked(teacherAbilityContent, item._id, pointer._id, pointerLevel.key) ?
                                                                    <div><input
                                                                        data-position={currentPosition}
                                                                        data-class-id={item._id}
                                                                        data-pointer-id={pointer._id}
                                                                        data-pointer-level-key={pointerLevel.key}
                                                                        type="radio"
                                                                        id={`${pointer._id}_${pointerLevel.key}`}
                                                                        name={pointer._id} onChange={this.handleOptionChange}
                                                                        defaultChecked
                                                                    />
                                                                        {pointerLevel.displayName.tw}
                                                                    </div> :
                                                                    <div><input
                                                                        data-position={currentPosition}
                                                                        data-class-id={item._id}
                                                                        data-pointer-id={pointer._id}
                                                                        data-pointer-level-key={pointerLevel.key}
                                                                        type="radio"
                                                                        id={`${pointer._id}_${pointerLevel.key}`}
                                                                        name={pointer._id} onChange={this.handleOptionChange}
                                                                    />
                                                                        {pointerLevel.displayName.tw}
                                                                    </div>}
                                                            </div>
                                                            <div className="TeacherAbilityPage__PointerItem_Option_Content"
                                                                dangerouslySetInnerHTML={{ __html: `${(pointer.itemAttribute[pointerLevel.key]) ? pointer.itemAttribute[pointerLevel.key].tw.replaceAll(/\r\n|\r|\n/g, '<br/>') : ""}` }}></div>
                                                        </div>
                                                    </label>
                                                })
                                            }</div>
                                    </div>
                                })}
                            </div>
                        </div>
                    })}
                </div>
            </div>
        )
    }
}

export default withRouter(TeacherAbilityPage);