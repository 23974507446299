import React, { Component } from 'react'
import Loader from 'react-loader-spinner'
import LocalStorage from '../../Helpers/LocalStorage'
import './Resource.less'

class Resource extends Component {
    constructor(props) {
        super(props);
        this.nthusteamAdmin = LocalStorage.getLocalStorage("nthusteamAdmin")
        this.state = {
            _id:this.props.resource?this.props.resource._id : null,
            articleName:this.props.resource?this.props.resource.name : '',
            articleUrl:this.props.resource?this.props.resource.url: '',
            articleDescription: this.props.resource?this.props.resource.description: '',
            classes: this.props.resource?this.props.resource.classes: [],
            isAvaiableSave: false,
            loading: false,
        }
    }

    componentDidMount = ()=>{
        this.checkAvaiableSave() 
    }

    handleChange = (e) => {        
        this.setState({
            [e.target.name]: e.target.value
        },()=>{
           this.checkAvaiableSave() 
        });
    }

    checkAvaiableSave = ()=>{
        this.setState({isAvaiableSave: this.state.articleUrl && this.state.articleName})
    }

    saveChang = () => {
        // this.setState({ "loading": true })
        const { _id, articleName, articleUrl, classes, articleDescription } = this.state
        console.log({state:this.state})
        let classIds = classes
        if(!this.props.notConvertClasses){
            classIds = classes.map(classObject=>{ return classObject._id})
        }        
        if (_id) {
            this.props.updateRecommendResource(_id, articleName, articleUrl, classIds, articleDescription, { loading: false, "openModal": false })
        }
        else {
            this.props.createRecommendResource(articleName, articleUrl, classIds, articleDescription, { loading: false, "openModal": false })
        }
    }

    joinClassToResource = () => {
        if (this.state.articleClass && !this.state.classes.some(item => item._id === this.state.articleClass)) {
            this.setState({
                classes: [...this.state.classes, this.props.classItems.find(item => item._id === this.state.articleClass)]
            })
        }
    }

    removeClassToResource = (id) => {
        this.setState({
            classes: this.state.classes.filter(item => item._id != id)
        })
    }


    render() {
        return (
            <div className="Resource">
                <div className="Admin__row Resource__title">
                    {this.props.title}
                </div>
                <div className="Admin__row underline">
                    <div className="Admin__row__item">文章名稱</div>
                    <div className="Admin__row__item4">
                        <input type="text" onChange={this.handleChange} name="articleName" defaultValue={this.props.resource?this.props.resource.name:''} />
                    </div>
                </div>
                <div className="Admin__row underline">
                    <div className="Admin__row__item">網址</div>
                    <div className="Admin__row__item4">
                        <input type="text" onChange={this.handleChange} name="articleUrl" defaultValue={this.props.resource?this.props.resource.url:''} />
                    </div>
                </div>
                <div className="Admin__row underline">
                    <div className="Admin__row__item">類型</div>
                    <div className="Admin__row__item4">
                        <select className="Admin__row__item4" name="articleClass" onChange={this.handleChange}>
                            <option></option>
                            {this.props.classItems.map((classObject, key) => {
                                return <option key={key} value={classObject._id}>{classObject.title.tw}</option>
                            })}
                        </select>
                        <button type="button" onClick={this.joinClassToResource}>加入</button>
                    </div>
                </div>
                {this.state.classes.length > 0 && <div className="Admin__row underline">
                    <div className="Admin__row__item"></div>
                    <div className="Admin__row__item4 classDisplayArea">{this.state.classes.map((classObject, key) => {
                        return <div key={key} className="classItem">{classObject.title.tw} {classObject.removed?'(removed)':''}
                            <div onClick={() => { this.removeClassToResource(classObject._id) }}>
                                <i className="fas fa-times" style={{ "fontSize": "15px" }}></i>
                            </div>
                        </div>
                    })}</div>
                </div>
                }
                <div className="Admin__row underline">
                    <div className="Admin__row__item">摘要說明</div>
                    <div className="Admin__row__item4">
                        <textarea
                            value={this.state.articleDescription}
                            name="articleDescription" className="articleDescription" onChange={this.handleChange}></textarea>
                    </div>
                </div>
                <div className="Admin__row controlArea">
                    {this.state.loading ?
                        <button className="saveBtn" onClick={this.saveChang}>
                            <Loader
                                type="Oval"
                                color="#7f1083"
                                height={22}
                                width={22}
                                visible={true}
                            />
                        </button> : <>
                            {this.state.isAvaiableSave && <button className="saveBtn" onClick={this.saveChang}>儲存</button>}
                        </>
                    }
                </div>
            </div>
        );
    }
}

export default Resource;