import React, { Component } from 'react'

import './ContactUs.less'

import { withTranslation } from 'react-i18next';

class ContactUs extends Component {


    render() {

        const { t } = this.props;
        return (
            <div className="contactusContent">
                <div className="contactusContent_title">
                {t('ContactUs.ContactUs')}
                </div>
                <div className="contactusContent_desc">
                {t('ContactUs.problem')}
                </div>
                <div className="contactusContent_conditions">
                    <div className="contactusContent_conditions_item">
                        <i className="far fa-clock"></i>
                        <div className="contactusContent_conditions_item_word">
                            <div>{t('ContactUs.time')}:</div>
                            <div>8:30 - 17:30</div>
                            <div>{t('ContactUs.day')}</div>
                        </div>
                    </div>
                    <div className="contactusContent_conditions_item">
                        <i className="fas fa-phone"></i>
                        <div className="contactusContent_conditions_item_word">
                            <div>{t('ContactUs.phone')}:</div>
                            <div>03 - 5715131</div>
                            <div>分機: 73363</div>
                        </div>
                    </div>
                    <div className="contactusContent_conditions_item">
                        <i className="far fa-envelope"></i>
                        <div className="contactusContent_conditions_item_word">
                            <div>{t('ContactUs.mail')}:</div>
                            <div>tsinghuasteamschool@gmail.com</div>
                        </div>
                    </div>
                </div>
                <i></i>
            </div>
        )
    }
}

export default withTranslation()(ContactUs);