import React, { Component } from 'react';
import Loader from 'react-loader-spinner'
import ReactModal from 'react-modal';
import LocalStorage from '../../../Helpers/LocalStorage'
import TeachersManagement from './TeachersManagement'
import axios from 'axios';
import './Course.less'
import TextField from '@material-ui/core/TextField';
import { Dialog, DialogTitle, DialogContent, withStyles, Tooltip } from '@material-ui/core';

class Course extends Component {
    constructor(props) {
        super(props);
        this.nthusteamAdmin = LocalStorage.getLocalStorage("nthusteamAdmin")
        this.state = {
            ...this.props,
            "loading": false,
            openTeacherModal: false,
            showSaveBtn: false,
            waitingParticipatedTeachersOfCourse: false,
            signatures: [],
            deanSignature: this.props.deanSignature,
            committeeSignature: this.props.committeeSignature
        }
    }

    componentDidMount = () => {
        this.initSignatures()
    }

    initSignatures = async () => {
        this.getSignaturesAction().then(datas => {
            this.setState({
                signatures: datas.map(data => { data.id = data._id; return data }),
                showSaveBtn: this.isShowSaveBtn()
            })
        })
    }

    getSignaturesAction = () => {
        return new Promise(resolve => {
            axios.get(`${window.CONFS.apiServer}/admin/signatures`).then(res => {
                resolve(res.data.data)
            }).catch(function (error) {
                console.log('fail post =>', error)
                // window.alert("Server Error")
            });
        })
    }

    handleChange = (e) => {
        // console.log({ v: e.target.value })
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            // console.log({ showBtn: this.isShowSaveBtn() })
            // console.log(this.state)    
            this.setState({ showSaveBtn: this.isShowSaveBtn() })
        });
    }

    isShowSaveBtn = () => {
        // console.log(this.state)
        if (!this.state.name) {
            return false
        }

        if (!this.state.openDateTime) {
            return false
        }

        if (!this.state.endDateTime) {
            return false
        }

        if (!this.state.hours || !(/^\d+\.{0,1}\d*$/g).test(this.state.hours) || !parseFloat(this.state.hours) > 0) {
            return false
        }

        if (!this.state.url || !(/^(http:\/\/|https:\/\/)/gi).test(this.state.url)) {
            return false
        }

        if (!this.state.deanSignature || !this.state.committeeSignature) {
            return false
        }
        return true
    }

    saveChange = () => {
        this.setState({ "loading": true })
        const { courseItemId, name, hours, openDateTime, endDateTime, content, url, _id, isPublic, deanSignature, committeeSignature } = this.state
        let course = {
            id: _id,
            courceTypeId: courseItemId,
            name: {
                tw: name
            },
            openDateTime: openDateTime,
            endDateTime: endDateTime,
            hours: hours,
            content: {
                tw: content
            },
            url: url,
            isPublic: (isPublic == null) ? true : JSON.parse(isPublic),
            deanSignature: deanSignature,
            committeeSignature: committeeSignature
        }
        this.props.createAndUpdateCourse(course).then(
            this.props.getCourses
        ).then((datas) => {
            this.props.closeCourse()
        })
    }

    openTeacherModal = (courseId) => {
        this.setState({ waitingParticipatedTeachersOfCourse: true }, () => {
            this.getParticipatedTeachersStatus(courseId).then(teachers => {

                teachers.forEach(teacher => {
                    teacher.isCheckedIn = teacher.isCheckedIn ? true : false
                    teacher.isCheckedOut = teacher.isCheckedOut ? true : false
                })
                let selectedTeachers = teachers.filter(teacher => teacher.isSelected)
                selectedTeachers.forEach(selectedTeacher => {
                    if (!(selectedTeacher.adminFullName && selectedTeacher.adminIdentifyID)) {
                        selectedTeacher.adminFullName = null;
                        selectedTeacher.adminIdentifyID = null;
                    }
                })
                this.setState({
                    waitingParticipatedTeachersOfCourse: false,
                    openTeacherModal: true,
                    teacherPage: <TeachersManagement
                        courseId={courseId}
                        courseName={this.props.name}
                        teachers={teachers}
                        closeTeacherModal={this.closeTeacherModal}
                        selectedTeachers={selectedTeachers} />
                })
            })
        })


    }

    getParticipatedTeachersStatus = (courseId) => {
        return new Promise(resolve => {
            axios.get(window.CONFS.apiServer + `/admin/course/${courseId}/participated-teachers-status`)
                .then(res => {
                    if (res.data.errorCode > 0) {
                        console.log(res)
                    }
                    else {
                        resolve(res.data.data)
                    }
                })
                .catch(function (error) {
                    console.log('fail post =>', error)
                });
        })
    }

    closeTeacherModal = () => {
        this.setState({ openTeacherModal: false })
    }

    getSignatureObject = (signatureId) => {
        return this.state.signatures.find(signature => signature._id === signatureId)
    }

    render() {
        const isPublic = (this.props.isPublic == null) ? true : this.props.isPublic
        return (
            <div className="Course">
                <div className="Admin__row Course__title">
                    {this.props.title}
                </div>
                <div className="course-content">
                    <div className="Admin__row underline">
                        {this.state._id && <>
                            {this.state.waitingParticipatedTeachersOfCourse ? <Loader
                                type="Oval"
                                color="#7f1083"
                                height={22}
                                width={22}
                                visible={true}
                            /> :
                                <button className="manageTeachersBtn" onClick={() => { this.openTeacherModal(this.state._id) }}>管理參與教師</button>
                            }</>
                        }
                    </div>
                    <div className="Admin__row underline">
                        <div className="Admin__row__item item_required">活動名稱</div>
                        <div className="Admin__row__item4">
                            <input type="text" onChange={this.handleChange} name="name" defaultValue={this.props.name} />
                        </div>
                    </div>
                    <div className="Admin__row underline">
                        <div className="Admin__row__item item_required">開始時間</div>
                        <div className="Admin__row__item4">
                            <TextField
                                id="date"
                                type="date"
                                name="openDateTime"
                                onChange={this.handleChange}
                                defaultValue={this.props.openDateTime}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            {/* <input type="text" onChange={this.handleChange} name="openDateTime" defaultValue={this.props.openDateTime} /> */}
                        </div>
                    </div>
                    <div className="Admin__row underline">
                        <div className="Admin__row__item item_required">結束時間</div>
                        <div className="Admin__row__item4">
                            <TextField
                                id="date"
                                type="date"
                                name="endDateTime"
                                onChange={this.handleChange}
                                defaultValue={this.props.endDateTime}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                    </div>
                    <div className="Admin__row underline">
                        <div className="Admin__row__item item_required">時數</div>
                        <div className="Admin__row__item4">
                            <input type="text" onChange={this.handleChange} name="hours" defaultValue={this.props.hours} />
                        </div>
                    </div>
                    <div className="Admin__row underline">
                        <div className="Admin__row__item">詳情</div>
                        <div className="Admin__row__item4">
                            <textarea onChange={this.handleChange} name="content" defaultValue={this.props.content}></textarea>
                        </div>
                    </div>
                    <div className="Admin__row underline">
                        <div className="Admin__row__item item_required">報名入口</div>
                        <div className="Admin__row__item4">
                            <input type="text" onChange={this.handleChange} name="url" defaultValue={this.props.url} placeholder="http:// or https:// 開頭" />
                        </div>
                    </div>
                    <div className="Admin__row underline">
                        <div className="Admin__row__item item_required">公開</div>
                        <div className="Admin__row__item4">
                            <label htmlFor="isPublicTrue"><div className="optionItem"><input id="isPublicTrue" type="radio" onChange={this.handleChange} defaultChecked={isPublic == true} value={true} name="isPublic" />顯示</div></label>
                            <label htmlFor="isPublicFalse"><div className="optionItem"><input id="isPublicFalse" type="radio" onChange={this.handleChange} defaultChecked={isPublic == false} value={false} name="isPublic" />隱藏</div></label>
                        </div>
                    </div>
                    <div className="Admin__row underline">
                        <div className="Admin__row__item item_required">簽名檔-院長</div>
                        <div className="Admin__row__item4">
                            <select value={this.props.deanSignature} name="deanSignature" onChange={this.handleChange}>
                                <option value={null}></option>
                                {this.state.signatures.map(signature => { return <option key={signature._id} value={signature._id}>{signature.title}</option> })}
                            </select>
                            <div className="signature-image" style={{ backgroundImage: this.getSignatureObject(this.state.deanSignature) ? `url('${window.CONFS.storageUrl}/${this.getSignatureObject(this.state.deanSignature).fileName}')` : "" }}></div>
                        </div>
                    </div>
                    <div className="Admin__row underline">
                        <div className="Admin__row__item item_required">簽名檔-委員</div>
                        <div className="Admin__row__item4">
                            <select name="committeeSignature" value={this.props.deanSignature} onChange={this.handleChange}>
                                <option value={null}></option>
                                {this.state.signatures.map(signature => { return <option key={signature._id} value={signature._id}>{signature.title}</option> })}
                            </select>
                            <div className="signature-image" style={{ backgroundImage: this.getSignatureObject(this.state.committeeSignature) ? `url('${window.CONFS.storageUrl}/${this.getSignatureObject(this.state.committeeSignature).fileName}')` : "" }}></div>
                        </div>
                    </div>
                </div>
                <div className="Admin__row controlArea">
                    {this.state.loading ?
                        <button className="saveBtn" onClick={this.saveChang}>
                            <Loader
                                type="Oval"
                                color="#7f1083"
                                height={22}
                                width={22}
                                visible={true}
                            />
                        </button> :
                        <>
                            {this.state.showSaveBtn && <button className="saveBtn" onClick={this.saveChange}>儲存</button>}
                        </>
                    }
                </div>
                <ReactModal
                    isOpen={this.state.openTeacherModal}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={() => { this.setState({ openTeacherModal: false }) }}
                    style={{ overlay: { "background": "#000000a3" }, content: { "width": "800px", "height": "80vh", "margin": "0 auto", "top": "100px" } }}
                >
                    <div className="modalControllArea">
                        <div onClick={() => { this.setState({ openTeacherModal: false }) }} id="closeModal">
                            <i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
                        </div>
                    </div>
                    {this.state.teacherPage}
                </ReactModal>
            </div>
        );
    }
}

export default Course;