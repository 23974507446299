import React, { Component } from 'react'
import axios from 'axios';
import Loader from 'react-loader-spinner'
import './Certification.less'
import moment from 'moment'
import LessonPlanHelper from '../../Helpers/LessonPlanHelper'
import LevelCourseHourSetting from '../../LevelCourseHourSetting'
import { withTranslation } from 'react-i18next';

class Certification extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            msg: [],
            searchResult: null
        }

        this.certificationCode = React.createRef();
        this.name = React.createRef();
    }

    search = () => {
        let certificationCode = this.certificationCode.current.value.trim()
        if (!certificationCode) {
            this.setState({ msg: "證號必填" })
            return
        }

        this.setState({
            isLoading: true
        }, () => {
            this.getCertificationResult(certificationCode).then(data => {
                console.log(this.generateSearchResult(data))
                this.setState({
                    isLoading: false,
                    searchResult: this.generateSearchResult(data)
                })
            })
        })
    }

    getCertificationResult = (certificationCode) => {
        return new Promise((resolve) => {
            let param = `certificationCode=${certificationCode}`//encodeURIComponent(`certificationCode=${certificationCode}&name=${name}`)
            axios.get(`${window.CONFS.apiServer}/member/certifications?${param}`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(function (error) {
                    console.log('fail post =>', error)
                    // window.alert("Server Error")
                });
        });
    }

    generateSearchResult = (data) => {
        if (!data.adminFullName) {
            return <div className='search-result'>查無資料</div>
        }

        let level2 = data.type === "lessonplan" ? data.levelCertification.level2 : null
        if (level2) {
            level2.startAt = moment(level2.approveDate)
            level2.expiredAt = moment(level2.startAt).clone();
            level2.expiredAt.add(LevelCourseHourSetting.periodTime, LevelCourseHourSetting.timeUnit).add(-1, "day")
            let excludeLevel1 = JSON.parse(JSON.stringify(data.courses)).filter(course => !data.levelCertification.level1.level1CourseCertifications.includes(course._id))
            let now = moment()
            while (now.diff(level2.expiredAt, LevelCourseHourSetting.timeUnit) > 0 && !level2.isExpired) {
                LessonPlanHelper.repeatUpdateLevel2Period(level2, excludeLevel1, data.passedShares, data.systemAdminRole)
            }
        }

        return <div className='search-result'>
            <div className='row header'>
                <div className='name'>姓名</div><div className='certification-code'>編號</div><div className='pass-date'>認證時間</div><div className='content'>證書</div><div className='end-date'>效期</div>
            </div>
            <div className='row'>
                <div className='name'>{data.adminFullName}</div>
                <div className='certification-code'>{data.type === "lessonplan" ? level2.certificationNumber : data.code}</div>
                <div className='pass-date'>{data.type === "lessonplan" ? moment(level2.approveDate).format("YYYY-MM-DD") : moment(data.endDateTime).format("YYYY-MM-DD")}</div>
                <div className='content'>{data.certificatoinName}</div>
                <div className='end-date'>{data.type === "lessonplan" ? moment(level2.expiredAt).format("YYYY-MM-DD") : "活動"}</div>
            </div>
        </div>

    }
    render() {

        const { t } = this.props;
        return (
            <div className="certification">
                <div className="certification_title">
                    證書查詢
                </div>
                <div className="search-panel">
                    {/* <div className="row">
                        <div className="title">姓名</div>
                        <div className="input-value">
                            <input type="text" ref={this.name} />
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="title">證號</div>
                        <div className="input-value">
                            <input type="text" ref={this.certificationCode} />
                        </div>
                    </div>
                    <div className="row">
                        {this.state.isLoading ? <Loader
                            type="Oval"
                            color="#7f1083"
                            height={22}
                            width={22}
                            visible={true}
                        /> : <button className='btn' type="type" onClick={this.search}>查詢</button>}
                    </div>
                    <div className="row response">
                        {this.state.msg}
                    </div>
                </div>
                {this.state.searchResult}
            </div>
        )
    }
}

export default withTranslation()(Certification);