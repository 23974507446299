import i18n from "i18next";
import { initReactI18next } from "react-i18next";


import en from './Assets/i18n/en.json';
import tw from './Assets/i18n/zh-TW.json';

// the translations
// (tip move them in a JSON file and import them)
export const resources = {
  en: {
    translation: en
  },
  tw: {
    translation: tw
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "tw",
    fallbackLng: "tw",

    keySeparator: '.', // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
    ,react: {
      bindI18n: 'languageChanged',
    }
  });

  export default i18n;