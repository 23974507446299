import React, { Component } from 'react'
import axios from "axios";
import LocalStorage from "../../../Helpers/LocalStorage";
import Moment from 'react-moment'

import './Step2Basic.less'
import PlanInstances from './PlanInstances'
import InstanceView from './InstanceView'
import { resolve } from 'dns';

import { withTranslation } from 'react-i18next';

class Step2Basic extends Component {

    constructor(props) {
        super(props)

        const { lessonPlanClasses = [] } = this.props;
        // const classes = this.initResourcesInLessonPlanClasses(lessonPlanClasses)
        console.log({ "lessonPlanClasses": lessonPlanClasses })
        this.state = {
            instances: lessonPlanClasses,
            myGUID: this.getGUID()
        }
        this.setAllState = this.setAllState.bind(this);
        this.removeInstancesByIndex = this.removeInstancesByIndex.bind(this);
    }

    initResourcesInLessonPlanClasses = (lessonPlanClasses) => {

        lessonPlanClasses.forEach(item => {
            item.id = item._id;
            delete item._id;

            if (item.videos) {
                item.videos.forEach(r => {
                    r.id = r._id;
                    delete r._id;
                })
            }

            if (item.photos) {
                item.photos.forEach(r => {
                    r.id = r._id;
                    delete r._id;
                })
            }

            if (item.resources) {
                item.resources.forEach(r => {
                    r.id = r._id;
                    delete r._id;
                })
            }
        })
        return lessonPlanClasses
    }

    setAllState(k, v) {
        console.log('set all instance key:', k, ', value:', v);
        let i = k ? parseInt(k) : 0;
        let instances = this.state.instances ? this.state.instances : [];
        instances[i] = v;
        this.setState({ instances: instances }, () => {
            this.props.setIsChanged(true)
            this.props.setIsChangedData(true)
        });
    }

    async getNewLessonPlan() {

        await this.props.getNewPlan()

        const url = `${window.CONFS.apiServer}/lessonplan/${this.props.match.params.id}`;
        axios
            .get(url)
            .then(res => {

                if (res.data.errorCode !== 0) {
                    window.alert(res.data.errorMessage)
                }
                if (res.data.data) {

                    this.props.getNewPlan();
                    console.log('res =>>', res.data.data.lessonPlanClasses)
                    let classes = this.initResourcesInLessonPlanClasses(res.data.data.lessonPlanClasses);

                    this.setState({ 'instances': classes, 'myGUID': this.getGUID() });

                    return resolve();
                }
            })
            .catch(function (error) {
                console.log(error);
            })

    }


    addPlanInstances = () => {

        let GUID = this.getGUID();

        let planInstances = {
            planId: this.props.planId,
            creator: GUID,
            step: "",
            videos: [],
            photos: [],
            resources: [],
            students: []
        }

        let list = this.state.instances ? this.state.instances : [];
        list.push(planInstances);
        this.setState({ instances: list });
    }

    getGUID = () => {
        let nthusteam = LocalStorage.getLocalStorage("nthusteam");

        if (nthusteam != null && nthusteam.GUID != null) {
            return nthusteam.GUID;
        } else {
            this.props.history.push("/signin");
        }
    }

    sendLessonPlanClasses = (e) => {
        let list = []

        if (this.state.instances) {

            let instances = JSON.parse(JSON.stringify(this.state.instances))
            if (!this.checkTeacherAbilityData(instances)) {
                alert("[認證資料]-「STEAM教學能力」自評選單必填")
                this.setState({ instances: instances });
                return false;
            }

            list = this.state.instances.map(i => {
                i.id = i.id === '' ? null : i.id;
                if (i._id) {
                    i.id = i._id;
                    delete i._id;
                }

                i.students = i.students.map(student => {
                    if (student._id) {
                        student.id = student._id
                        delete student._id
                    }
                    return student
                })
                return i
            })

            list = JSON.parse(JSON.stringify(list))
        }

        this.convertLessonPlanClassResourceClssesToIds(list)
        this.convertLessonPlanClassResources_idToId(list)
        this.deleteLessonPlanClassResources_type(list)

        const self = this;

        const url = `${window.CONFS.apiServer}/lessonplanclasses`


        const callback = res => {

            console.log('step2basic callback =>', ((res.data.errorCode === 2002) || (res.data.errorCode && res.data.errorCode === 9999)))


            if (res.data.errorCode && res.data.errorCode === 2002) {
                window.alert(res.data.errorMessage);
                return null;
            } else if (res.data.errorCode && res.data.errorCode === 9999) {

                window.alert(res.data.errorMessage);
                return null;

            } else {

                window.alert("儲存成功");
                this.props.setIsChanged(false)
                this.props.setIsChangedData(false)
                self.getNewLessonPlan()
                    .then(r => {
                        this.setState({ instances: this.props.lessonPlanClasses });
                    });

            }

        }

        const errorCallback = function (error) {
            window.alert("伺服器異常");
        }

        axios.post(url, list).then(callback).catch(errorCallback);
    }

    convertLessonPlanClassResourceClssesToIds = (instances) => {
        instances.forEach(instance => {
            instance.resources.forEach(resource => {
                resource.classes = resource.classes.map(classObject => { return classObject._id })
            })
        })
    }

    convertLessonPlanClassResources_idToId = (instances) => {
        instances.forEach(instance => {
            instance.resources.forEach(resource => {
                resource.id = resource._id
                delete resource._id
                if (resource.type === "new") {
                    resource.id = null
                }
            })
        })
    }

    deleteLessonPlanClassResources_type = (instances) => {
        instances.forEach(instance => {
            instance.resources.forEach(resource => {
                delete resource.type
            })
        })
    }

    checkTeacherAbilityData = (instances) => {
        let result = true
        instances.filter(instance => instance.creator === this.getGUID())
            .forEach(instance => {
                if(!(instance.teacherAbility && JSON.stringify(instance.teacherAbility) != "{}")){
                    result = false
                    return false
                }                
            })

        return result
    }

    removeInstancesByIndex = (index) => {

        let list = this.state.instances || []

        if (list[index]) {

            if (list[index].id || list[index]._id) {

                let data = [{
                    "id": list[index].id || list[index]._id,
                    "removed": true
                }]

                axios.post(
                    `${window.CONFS.apiServer}/lessonplanclasses`, data)
                    .then(res => {
                        if (res.data.errorCode && res.data.errorCode === 2002) {
                            window.alert(res.data.errorMessage);
                        }

                        this.getNewLessonPlan();

                    }).catch(function (error) {
                        console.log("fail removeInstancesByIndex", error);
                    });
            } else {
                list.splice(index, 1);
                this.setState({ instances: list });
            }

        }

    }

    getGUID = () => {
        let nthusteam = LocalStorage.getLocalStorage("nthusteam");
        return nthusteam ? nthusteam.GUID : null;
    }

    render() {

        const { showAllDates, waitingApprove, t, loginRole, approveStep2, planSetting } = this.props;
        const { myGUID, instances } = this.state;

        return (
            <div className="step2Body">
                <h3>

                </h3>
                <div>
                    <div className="step2BasicTitle">{t('step2.title')}</div>
                    {
                        (showAllDates || waitingApprove || approveStep2) ?
                            <div></div> :
                            <button className="addNewStepBtn" onClick={this.addPlanInstances}>{t('step2.addStep')}</button>
                    }

                    {this.props.loginRole === "admin" ?
                        <div className="stepStatusArea">
                            {this.props.approveStep2 === true ?
                                <div className="approvedLessonPlanStatus">{t('step2.pass')} (<Moment format="YYYY/MM/DD HH:mm">{this.props.approveStep2At}</Moment>)</div> :
                                <button className="submitApproveLessonPlanBtn" onClick={() => this.props.approveLessonPlanStep(2)}>{t('step1.classPass')}</button>
                            }
                        </div> :
                        <div className="stepStatusArea">
                            {this.props.approveStep2 === true &&
                                <div className="approvedLessonPlanStatus">{t('step2.pass')} (<Moment format="YYYY/MM/DD HH:mm">{this.props.approveStep2At}</Moment>)</div>
                            }
                        </div>
                    }
                </div>
                <br />
                {
                    waitingApprove || showAllDates || approveStep2 ?
                        <div className="step2Content">
                            { // InstanceView
                                instances &&
                                this.computeAvaiableInstances(instances).map((i, k) =>

                                    <InstanceView
                                        planSetting={this.props.planSetting}
                                        setAllState={this.setAllState}
                                        key={k}
                                        indexKey={k}
                                        id={i.id}
                                        step={i.step}
                                        teachers={this.props.teachers}
                                        videos={i.videos}
                                        photos={i.photos}
                                        resources={i.resources}
                                        students={i.students}
                                        creator={i.creator}
                                        planId={this.props.planId}
                                        removeInstancesByIndex={this.removeInstancesByIndex}
                                        showAllDates={showAllDates}
                                        approveStep2={approveStep2}
                                        teacherAbility={i.teacherAbility}
                                        loginRole={loginRole}

                                    />

                                )
                            }
                        </div>
                        : <div className="step2Content">{
                            this.state.instances &&
                            this.state.instances.map((i, k) => {
                                if (myGUID === i.creator) {

                                    return <div key={k} >
                                        <PlanInstances
                                            planSetting={planSetting}
                                            setAllState={this.setAllState}
                                            key={k}
                                            indexKey={k}
                                            id={i.id}
                                            step={i.step}
                                            videos={i.videos}
                                            photos={i.photos}
                                            resources={i.resources}
                                            students={i.students}
                                            creator={i.creator}
                                            planId={this.props.planId}
                                            removeInstancesByIndex={this.removeInstancesByIndex}
                                            showAllDates={showAllDates}
                                            approveStep2={approveStep2}
                                            waitingApprove={waitingApprove}
                                            loginRole={loginRole}
                                            testCallBack={this.sendLessonPlanClasses}
                                            teacherAbility={i.teacherAbility}
                                            isShowEditBtn={this.props.isShowEditBtn}
                                            isTeacherAbilityAvailable={i.isTeacherAbilityAvailable}
                                        />
                                    </div>
                                }
                                else {
                                    return null;
                                }
                            })
                        }
                            {
                                waitingApprove
                                    ? ''
                                    : <div>{this.state.instances.length > 0 &&
                                        <button className="sendLessonPlanClasses" onClick={this.sendLessonPlanClasses}>{t('step2.save')}</button>
                                    }</div>
                            }

                        </div>
                }

            </div>
        )
    }

    computeAvaiableInstances = (instances) => {
        if (this.props.loginRole === "admin") {
            return instances
        }

        const { myGUID } = this.state
        if (myGUID) {
            return instances.filter(instance => instance.creator === myGUID)
        }
        return instances
    }
}

export default withTranslation()(Step2Basic);
// export default Step2Basic;