import React, { Component } from 'react';
import axios from 'axios';
import Moment from 'react-moment';
import './RecommendResources.less'

import { withTranslation } from 'react-i18next';

class RecommendResources extends Component {
	constructor(props) {
		super(props);
		this.state = {
			datas: [],
			keyword: '',
			loading: false,
			openModal: false,
			resourceClasses: [],
			selectedResourceClasses: [],
			resource: ''
		}
	}

	componentDidMount = () => {
		this.setState({ "loading": true })
		this.getRecommandClassesAction().then(
			(classes) => {
				this.getRecommendResource().then((datas) => {
					this.setState({
						datas: datas,
						resourceClasses: classes,
						loading: false
					})
				})
			}
		)
	}

	setThisState = (data) => {
		console.log({ "data": data })
		this.setState(data)
	}

	getRecommandClassesAction = () => {
		return new Promise((resolve => {
			axios.get(window.CONFS.apiServer + '/lessonplan/resource/classes')
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res.data.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
				});
		}))
	}

	getRecommendResource = () => {

		const { qstr = '' } = this.state

		return new Promise((resolve) => {
			this.setState({ "loading": true })
			axios.get(window.CONFS.apiServer + '/lessonplanresources', {
				params: {
					keyword: qstr,
					classIds: this.state.selectedResourceClasses.map(classObject => { return classObject._id })
				}
			})
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res.data.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
				});
		});
	}

	getNewRecommendResource = () => {

		this.getRecommendResource().then((datas) => {
			this.setState({ "datas": datas, loading: false })
		});
	}


	handleChange = event => {

		this.setState({
			[event.target.name]: event.target.value
		});
	}

	joinRecommendClasses = () => {
		if (this.state.recommendClassSelectorValue && !this.state.selectedResourceClasses.some(item => item._id === this.state.recommendClassSelectorValue)) {
			this.setState({
				selectedResourceClasses: [...this.state.selectedResourceClasses, this.state.resourceClasses.find(item => item._id === this.state.recommendClassSelectorValue)]
			})
		}
	}

	removeRecommendClass = (id) => {
		this.setState({
			selectedResourceClasses: this.state.selectedResourceClasses.filter(item => item._id != id)
		})
	}


	render() {

		const { t } = this.props;
		return (
			<div className="RecommendResources">

				<div className="title">
					<span>{t('recommendResources.recommendResources')}</span>
				</div>

				<div className="search">
					<div className="search-item">
						<div className="title"> {t('recommendResources.keyword')}:  </div>
						<input className="qstr" name="qstr" onChange={this.handleChange}></input>
						<button className="searchBtn" onClick={this.getNewRecommendResource}>{t('recommendResources.search')}</button>
					</div>
					<div className="search-item">
						<div className="title"> {t('recommendResources.classes')}:  </div>
						<select name="recommendClassSelectorValue" onChange={this.handleChange}>
							<option></option>
							{this.state.resourceClasses && this.state.resourceClasses.map((classObject, k) => {
								return <option key={k} value={classObject._id}>{classObject.title.tw}</option>
							})}
						</select>
						<button className="joinBtn" onClick={this.joinRecommendClasses}>{t('recommendResources.joinBtn')}</button>
					</div>
					<div className="search-item">
						<div className="selected-classes">
							{this.state.selectedResourceClasses.map((classObject, k) => {
								return <div className="selected-class" key={k}>{classObject.title.tw}
									<div onClick={() => { this.removeRecommendClass(classObject._id) }}>
										<i className="fas fa-times" style={{ "fontSize": "15px" }}></i>
									</div></div>
							})}
						</div>
					</div>
				</div>
				<div className="RecommendResources__content">
					<table>
						<thead>
							<tr>
								<td>{t('recommendResources.name')}</td>
								<td style={{ "width": "150px" }}>{t('recommendResources.time')}</td>
							</tr>
						</thead>

						<tbody>
							{this.state.datas.map((item, k) => {
								return (<tr key={k} title={this.getTitle(item)}>
									<td><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></td>
									<td>
										<Moment format="YYYY/MM/DD HH:mm">{item.createdAt}</Moment>
									</td>
								</tr>)
							})
							}
						</tbody>
					</table>
				</div>
			</div>
		);
	}

	getTitle = (resource) => {
		let classNames = resource.classes ? resource.classes.map(classObject => { return `${classObject.title.tw} ${classObject.removed ? '(removed)' : ''}` }) : []
		return `${classNames} \n\r ${resource.description || ''}`
	}
}

export default withTranslation()(RecommendResources);