import React, { Component, createRef } from "react";
import axios from "axios";
import { Player } from 'video-react';

import LocalStorage from "../../../Helpers/LocalStorage";
import ReactModal from 'react-modal';
import "./PlanInstances.less";
import StudentModal from "../PlanStep3/StudentModal";
import UploadFile from "./UploadFile"
import "../../../../node_modules/video-react/dist/video-react.css";
import Resource from '../../../Admin/RecommendResources/Resource'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import TeacherAbilityPage from "../TeacherAbilityPage";
import TeacherAbilityDisplay from "../TeacherAbilityDisplay";
import { withTranslation } from 'react-i18next';

class PlanInstances extends Component {
  constructor(props) {
    super(props);

    const { id, step, videos, photos, resources, students, ownerTeacher, creator } = this.props;
    this.state = {
      id: id ? id : null,
      step: step ? step : '',
      ownerTeacher: ownerTeacher,
      videos: videos ? videos : [],
      photos: photos ? photos : [],
      creator: creator || '',
      resources: resources ? resources : [],
      students: students ? students : [],
      isReactModalOpen: false,
      isReactModalContent: <div></div>,
      reactModalHeight: "30vh",
      isOpenStepTeacherAbility: false,
      stepTeacherAbilityPage: null,
      openResourceModal: false,
      resourcePage: null,
      isChangeAbilityContent: false

    }

    this.modal = createRef();
    this.addVideo = this.addVideo.bind(this)
    this.addPhoto = this.addPhoto.bind(this)
    this.closeModal = this.closeModal.bind(this);
    this.updateStudents = this.updateStudents.bind(this);
    this.removeStudent = this.removeStudent.bind(this);
  }

  componentDidUpdate(preProps) {
    if (this.props !== preProps) {
      this.state = { ...this.props }
    }

  }

  getName() {
    let name;
    const { teachers, creator } = this.props;

    if (teachers && creator) {
      let teacher = teachers.filter(t => t.GUID === creator)
      name = teacher[0].firstName + '' + teacher[0].lastName;
    }

    if (!name) {
      let nthusteam = LocalStorage.getLocalStorage("nthusteam");
      name = nthusteam.name || '';
    }

    return name;
  }

  onChangeHandler = event => {
    const data = new FormData();

    // let validFilesTypes = ["word", "pdf"];
    // let validFilesTypes = ['png', 'jpg', 'jpeg', 'gif'];

    let id = event.target.id;
    // let ext = "";

    // 副檔名驗證
    // if (event.target.files[0]) {
    //   let filePath = event.target.files[0].name;
    //   let temp = filePath.split(".");
    //   ext = temp[temp.length - 1];
    //   // filePath.endsWith('.pdf')

    //   if (!validFilesTypes.includes(ext))
    //     console.warn(event.target.files[0].name + " is not " + validFilesTypes);
    // }

    data.append("file[]", event.target.files[0]);
    let token = this.getAuthorize();

    // 上傳檔案
    axios.post(`${window.CONFS.apiServer}/sys/uploadFiles`, data, {
      headers: { "X-Authorize": token }
    })
      .then(res => {
        // {
        //   "errorCode": 9999, 
        //   "errorMessage": "Failure", 
        //   "status": "ERROR"
        // }

        console.log('PlanInstances uploadFiles res =>', res);
        let name = res.data.data[0].fileName;
        let uuid = res.data.data[0].uuidName;

        // window.open("https://storage.hisoft.site/" + res.data.data[0].uuidName)
        if (uuid) {
          this.setState({ [id]: { fileName: name, path: uuid } });
        } else {
          window.alert('上傳失敗，請再試一次')
          // this.setState({ uploadPercent: '', [type]: true })
        }
      })
      .catch(function (error) {
        console.log("送出異常", error);
      });
  };

  handleChange = event => {
    let instance = this.getInstance()
    instance[event.target.name] = event.target.value;
    this.props.setAllState(this.props.indexKey, instance);
  }

  getInstance = () => {
    const { planId, id, step, photos, resources, students, ownerTeacher, videos, creator, teacherAbility } = this.props;

    let instance = {
      lessonPlanId: planId,
      ownerTeacher: ownerTeacher || '',
      id: id || null,
      step: step || '',

      creator: creator || '',
      photos: photos || [],
      videos: videos || [],
      resources: resources || [],
      students: students || [],
      teacherAbility: teacherAbility || {}
    }
    return JSON.parse(JSON.stringify(instance));
  }

  handleDesChange = (des, event) => {
    // let obj = this.state[des] || {};
    // obj['description'] = event.target.value;
    // this.setState({
    //   [des]: obj
    // });
    this.setState({
      [des]: {
        ...this.state[des],
        description: event.target.value
      }
    });
  }

  handleResourceChange = e => {
    // let resource = this.state.resource || {}
    // resource[e.target.name] = e.target.value;
    // this.setState({ resource: resource });
    this.setState({
      resource: {
        ...this.state.resource,
        [e.target.name]: e.target.value
      }
    });
  }

  addResource = () => {
    let instance = this.getInstance()
    let list = instance.resources ? instance.resources : [];
    let newResource = JSON.parse(JSON.stringify(this.state.resource))
    list.push(newResource);

    if (newResource.url && newResource.url.indexOf('http') !== 0) {
      window.alert('網址開頭必須為 http')
    } else {
      instance.resources = list
      this.props.setAllState(this.props.indexKey, instance);
    }
  }

  openAddFile = (fileType, title) => {
    let saveFn;
    if (fileType === "video")
      saveFn = this.addVideo
    else
      saveFn = this.addPhoto
    this.setState({
      isReactModalContent: <UploadFile
        title={title}
        fileType={fileType}
        saveFn={saveFn} />,
      isReactModalOpen: true,
      reactModalHeight: "50vh"
    })
  }

  addPhoto = (photoDemo) => {
    let instance = this.getInstance()
    let list = instance.photos ? instance.photos : [];
    list.push(photoDemo);
    instance.photos = list
    this.props.setAllState(this.props.indexKey, instance);
  }

  addVideo = (videoDemo) => {
    let instance = this.getInstance()
    let list = instance.videos ? instance.videos : [];
    list.push(videoDemo);
    instance.videos = list
    this.props.setAllState(this.props.indexKey, instance);
  }

  removeTargetElement = (index, targetType) => {
    let instance = this.getInstance()
    let list = instance[targetType] || [];
    list.splice(index, 1);
    instance[targetType] = list;

    this.props.setAllState(this.props.indexKey, instance);
  }

  addStudent = () => {
    let list = this.state.students ? this.state.students : [];
    list.push({
      name: "",
      plans: [],
      videos: [],
      photos: [],
      files: []
    });
    console.log('addStudent list=>', list)
    this.setState({ students: list });
    let instance = this.getInstance()
    instance.students = list
    this.props.setAllState(this.props.indexKey, instance);
  }

  getAuthorize() {
    let nthusteam = LocalStorage.getLocalStorage("nthusteam");
    if (nthusteam != null && nthusteam.apiToken != null) {
      return nthusteam.apiToken;
    } else {
      this.props.history.push("/signin");
    }
  }

  openModal = (data, targetKey) => {
    console.log('currentStudentPlan =>', data)
    this.setState({
      currentStudentPlan: data,
      showModal: true,
      targetKey: targetKey
    })
  }

  closeModal = (e) => {
    this.setState({ showModal: false });
  }

  updateStudents = (idx, student) => {

    const { students = [] } = this.state
    students[idx] = student;

    let list = students;
    let instance = this.getInstance()
    instance.students = list || []
    this.props.setAllState(this.props.indexKey, instance);
  }

  removeStudent = (idx) => {

    const { students = [] } = this.state

    if (students.length > idx) {
      students.splice(idx, 1)
    }

    let list = []

    if (students) {

      list = students.map(i => {
        i.id = i.id === '' ? null : i.id;
        if (i._id) {
          i.id = i._id;
          delete i._id;
        }
        return i
      })
    }
    let instance = this.getInstance()
    instance.students = list || []
    this.props.setAllState(this.props.indexKey, instance);
  }

  deleteInstances = (indexKey) => {
    this.props.removeInstancesByIndex(indexKey);
  }

  senStudent = () => {

    const { testCallBack } = this.props;

    testCallBack();
    this.setState({ showModal: false });

  }

  checkConfirm = (indexKey) => {
    // <button onClick={this.deleteStudent}>{t('step3.delete')}</button>
    const { t } = this.props
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui alertButtonBox'>
            <h1>確定刪除嗎？</h1>
            {/* <p>You want to delete this file?</p> */}
            <button onClick={onClose}>取消</button>
            <button
              onClick={() => {
                this.deleteInstances(indexKey);
                onClose();
              }}
            >
              {t('step3.delete')}
            </button>
          </div>
        );
      }
    });
  };

  openStepTeacherAbilityPointersPage = () => {
    
    this.setState({
      isOpenStepTeacherAbility: true,
      stepTeacherAbilityPage: <TeacherAbilityPage
        teacherAbilityPointers={this.props.planSetting.teacherAbilityPointers}
        currentPosition={this.props.step}
        teacherAbility={this.props.teacherAbility?this.props.teacherAbility[this.props.step]:{}}
        setTeacherAbility={this.setTeacherAbility}
        closeTeacherAbilityPointersPage={this.closeStepTeacherAbilityPointersPage}
        isChangeAbilityContent={this.isChangeAbilityContent}
      />
    })
  }

  closeStepTeacherAbilityPointersPage = () => {
    this.setState({
      isOpenStepTeacherAbility: false
    })
  }

  setTeacherAbility = (teacherAbilityData) => {
    const { indexKey, planId, id, step, photos, resources, students, ownerTeacher, videos, creator, teacherAbility } = this.props;

    let instance = {
      lessonPlanId: planId,
      ownerTeacher: ownerTeacher,
      id: id || null,
      step: step || '',
      creator: creator || '',
      photos: photos || [],
      videos: videos || [],
      resources: resources || [],
      students: students || [],
      teacherAbility: {
        ...teacherAbility,
        [step]: teacherAbilityData
      } || {}
    }

    this.props.setAllState(indexKey, instance);

  }

  openResource = (resource) => {
    if (!resource) {
      this.setState({
        resourcePage: <Resource
          resource={resource}
          createRecommendResource={this.createRecommendResource}
          getRecommendResource={() => { this.setState({ openResourceModal: false }) }}
          classItems={this.props.planSetting.teacherLessonplanResourceClasses}
          notConvertClasses={true}
          title="新增教學資源"
        />,
        openResourceModal: true
      })
    }
    else {
      this.setState({
        resourcePage: <Resource
          resource={resource}
          updateRecommendResource={this.updateRecommendResource}
          getRecommendResource={() => { this.setState({ openResourceModal: false }) }}
          classItems={this.props.planSetting.teacherLessonplanResourceClasses}
          notConvertClasses={true}
          title="更新教學資源"
        />,
        openResourceModal: true
      })
    }
  }

  createRecommendResource = (name, url, classes, description, changeState) => {
    return new Promise(resolve => {
      let instance = this.getInstance()
      let list = instance.resources ? instance.resources : [];
      let newResource = {
        _id: list.length + 1,
        name: name,
        url: url,
        classes: classes,
        description: description,
        type: "new"
      }
      list.push(newResource);

      if (newResource.url && newResource.url.indexOf('http') !== 0) {
        window.alert('網址開頭必須為 http')
        return false
      } else {
        instance.resources = list
        this.props.setAllState(this.props.indexKey, instance);
        this.setState({ openResourceModal: false })
      }
    })
  }

  updateRecommendResource = (id, name, url, classes, description, changeState) => {
    return new Promise(resolve => {

      if (url && url.indexOf('http') !== 0) {
        window.alert('網址開頭必須為 http')
        return false
      }

      let instance = this.getInstance()
      let list = instance.resources ? instance.resources : [];
      let resource = list.find(resource => { return resource._id === id })
      resource.name = name
      resource.url = url
      resource.classes = classes
      resource.description = description

      instance.resources = list
      this.props.setAllState(this.props.indexKey, instance);
      this.setState({ openResourceModal: false })
    })
  }

  isChangeAbilityContent = () => {
    this.setState({ isChangeAbilityContent: true })
  }

  render() {

    const { planSetting, videos = [], photos = [], resources = [], students = [], step, indexKey, waitingApprove, t } = this.props;
    const { currentStudentPlan = {}, targetKey } = this.state

    return (
      <div className="instancesBody">
        {/* {JSON.stringify(this.props.step)}<hr/>
        {JSON.stringify(this.props.teacherAbility)} */}
        <table border="1" className="instancesTable">
          <tbody>
            <tr>
              <td colSpan="2">
                {
                  waitingApprove
                    ? <div className="tableHeader"></div>
                    : <div className="tableHeader" >
                      <i className="fas fa-align-justify" /> {" "}
                      <div className="deleteInstances" onClick={() => this.checkConfirm(indexKey)}><i className="fas fa-trash" /></div>
                    </div>
                }

              </td>
            </tr>
            <tr>
              <td className="instancesTitle">教學者</td>
              <td><div className="creatorDiv">{this.getName()}</div></td>
            </tr>
            <tr>
              <td className="instancesTitle">教學階段</td>
              <td>
                <select name="step" className="step" onChange={this.handleChange} defaultValue={step || ''}>
                  <option></option>
                  {
                    planSetting &&
                    planSetting.lessonPlanSteps.map((i, k) => {
                      return <option key={k} value={i._id} >{i.stepName}</option>
                    }
                    )
                  }
                </select>
              </td>
            </tr>
            <tr>
              <td className={`instancesTitle ${this.isTeacherAbilityAvailable() && 'warning'}`}>
                <div>
                  <span className="notice">*</span>{t('step1.teacherAbilityLabel')}
                  {this.props.isShowEditBtn && step &&
                    <div><button className="teacherAbilityManageBtn" onClick={this.openStepTeacherAbilityPointersPage}>{t('step1.teacherAbilityManageBtn')}</button></div>
                  }
                </div>
              </td>
              <td className={`${this.isTeacherAbilityAvailable() && 'warning'} `}>
                {this.isTeacherAbilityAvailable() && <div className="notice">設定未完整</div>}
                {this.props.planSetting && this.props.teacherAbility && this.props.teacherAbility[this.props.step] && <TeacherAbilityDisplay teacherAbilityPointers={this.props.planSetting.teacherAbilityPointers}
                  currentPosition={this.props.step}
                  teacherAbility={this.props.teacherAbility[this.props.step]} />}
              </td>
            </tr>
            <tr>
              <td className="instancesTitle">教學影片</td>
              <td>
                {
                  waitingApprove
                    ? <div></div>
                    : <button onClick={() => this.openAddFile("video", "新增教學影片")} className="addFile">新增教學影片</button>
                }
                <div className="videoList">
                  { // 已上傳影片區
                    videos &&
                    videos.map((i, k) =>
                      <div className="videoItem" key={k}>
                        {/* <video className="video" controls>
                          <source src={window.CONFS.storageUrl + "/" + i.path} type="video/mp4" />
                          Your browser does not support HTML5 video.
                        </video> */}
                        <div className="videoItem__top">
                          {
                            waitingApprove
                              ? <div className="video__btn-remove" ></div>
                              : <div className="video__btn-remove" onClick={() => this.removeTargetElement(k, 'videos')}><i className="fas fa-trash"></i></div>
                          }
                          <div className="video__content">
                            <div className="video__content__title"><a href={window.CONFS.storageUrl + "/" + i.path} target="_blank">{i.fileName || ''}</a></div>
                          </div>
                        </div>
                        <Player
                          playsInline
                          poster={window.CONFS.storageUrl + "/" + i.path}
                          src={window.CONFS.storageUrl + "/" + i.path}
                        />
                        <div className="video__content">
                          <div className="video__content__desc">{i.description || ''}</div>
                        </div>
                      </div>
                    )
                  }
                </div>
              </td>
            </tr>
            <tr>
              <td className="instancesTitle">活動照片</td>
              <td>
                {
                  waitingApprove
                    ? <div></div>
                    : <button onClick={() => this.openAddFile("photo", "新增活動照片")} className="addFile">新增活動照片</button>
                }
                <div className="photoList">
                  { // 已上傳photo
                    photos &&
                    photos.map((i, k) =>
                      <div className="photoItem" key={k}>
                        <div className="photoItem__top">
                          {
                            waitingApprove
                              ? <div className="photo__btn-remove"></div>
                              : <div className="photo__btn-remove" onClick={() => this.removeTargetElement(k, 'photos')}><i className="fas fa-trash"></i></div>
                          }
                          <div className="photo__content">
                            <div className="photo__content__title">{i.fileName || ''}</div>
                          </div>
                        </div>
                        <div className="photo" style={{ "backgroundImage": "url(" + window.CONFS.storageUrl + "/" + i.path + ")" }}></div>
                        <div className="photo__content">
                          <div className="photo__content__desc">{i.description || ''}</div>
                        </div>
                      </div>
                    )
                  }
                </div>
              </td>
            </tr>
            <tr>
              <td className="instancesTitle">教學資源</td>
              <td className="resourceArea">
                {
                  waitingApprove
                    ? <div></div>
                    : <div id="resourceControll">
                      <button type="button" className="carete-resource-btn" onClick={() => { this.openResource() }}>新增</button>
                    </div>
                }
                {
                  resources &&
                  <>
                    {resources.map((item, k) => {
                      return (<div className="resource-item" key={k} title={this.getTitle(item)}>
                        <button onClick={() => { item._id = k + 1; this.openResource(item) }} className="RecommendResources__content__editBtn">編輯</button>
                        <button onClick={() => this.deleteResource(item._id)} className="RecommendResources__content__deleteBtn">刪除</button>
                        <a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a>
                      </div>)
                    })
                    }
                  </>
                }
              </td>
            </tr>
            <tr>
              <td className="instancesTitle">學生成果</td>
              <td>
                {/* <div>
                  <button onClick={this.addStudent} className="addStudent">新增學生</button>
                </div> */}
                <div>
                  <table border="1" className="student">
                    <thead>
                      <tr>
                        {
                          waitingApprove
                            ? <td></td>
                            : <td>
                              <button onClick={this.addStudent} className="addStudent">新增學生</button>
                            </td>
                        }
                        <td>{t('step2.name')}</td>
                        <td>{t('step2.plan')}</td>
                        <td>{t('step2.studentVideo')}</td>
                        <td>{t('step2.studentPhoto')}</td>
                        <td>{t('step2.studentFile')}</td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        students &&
                        students.map((i, k) =>
                          <tr key={k}>
                            {
                              waitingApprove
                                ? <td></td>
                                : <td>
                                  <button onClick={() => this.openModal(i, k)} className="editorBtn" >編輯</button>
                                </td>

                            }
                            <td><div>{i.name}</div></td>
                            <td>{i.plans && i.plans.length}</td>
                            <td>{i.videos && i.videos.length}</td>
                            <td>{i.photos && i.photos.length}</td>
                            <td>{i.files && i.files.length}</td>
                          </tr>
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {
          this.state.showModal &&
          <StudentModal
            currentStudentPlan={currentStudentPlan}
            // teachers={teachers} 
            closeModal={this.closeModal}
            updateStudents={this.updateStudents}
            removeStudent={this.removeStudent}
            targetKey={targetKey}
            loginRole={this.props.loginRole}
            testCallBack={this.senStudent}
          />
        }
        <ReactModal
          isOpen={this.state.isReactModalOpen}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => { this.setState({ isReactModalOpen: false }) }}
          style={{ overlay: { "background": "#000000a3" }, content: { "width": "75%", "height": this.state.reactModalHeight, "margin": "0 auto" } }}
        >
          <div className="modalControllArea">
            <div id="modalCloseBtn" onClick={() => { this.setState({ isReactModalOpen: false }) }}>
              <i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
            </div>
          </div>
          {this.state.isReactModalContent}
        </ReactModal>
        <ReactModal
          isOpen={this.state.isOpenStepTeacherAbility}
          shouldCloseOnOverlayClick={false}
          onRequestClose={() => { this.setState({ isOpenStepTeacherAbility: false }) }}
          style={{ overlay: { "background": "#000000a3" }, content: { "width": "70%", "height": "85vh", "margin": "0 auto" } }}
        >
          <div className="modalControllArea">
            <div onClick={() => {
              if (this.state.isChangeAbilityContent) {
                if (!window.confirm("您操作的設定未儲存, 是否確定要關閉?")) {
                  return false
                }
              }

              this.setState({ isOpenStepTeacherAbility: false, isChangeAbilityContent: false })
            }}>
              <i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
            </div>
          </div>
          {this.state.stepTeacherAbilityPage}
        </ReactModal>
        <ReactModal
          isOpen={this.state.openResourceModal}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => { this.setState({ openResourceModal: false }) }}
          style={{ overlay: { "background": "#000000a3" }, content: { "width": "600px", "height": "60vh", "margin": "0 auto" } }}
        >
          <div className="modalControllArea">
            <div onClick={() => { this.setState({ openResourceModal: false }) }}>
              <i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
            </div>
          </div>
          {this.state.resourcePage}
        </ReactModal>
      </div>
    );
  }

  getTitle = (resource) => {
    let classNames = resource.classes ? resource.classes.map(classObject => { return `${classObject.title.tw} ${classObject.removed ? '(removed)' : ''}` }) : []
    return `${classNames} \n\r ${resource.description || ''}`
  }

  isTeacherAbilityAvailable = () => {
    return this.props.isTeacherAbilityAvailable != undefined && !this.props.isTeacherAbilityAvailable
  }
}

export default withTranslation()(PlanInstances);