import React, { Component } from 'react';
import Loader from 'react-loader-spinner'
import axios from 'axios';
import Resource from './Resource'
import Moment from 'react-moment';
import ReactModal from 'react-modal';
import './RecommendResources.less'
import ClassesManagement from './ClassesManagement'

class RecommendResources extends Component {
	constructor(props) {
		super(props);
		this.state = {
			datas: [],
			keyword: "",
			loading: false,
			openModal: false,
			resource: "",
			isOpenClassManagementModel: false,
			isClassManagementBtnLoading: false,
			classManagementPage: null
		}
		this.getRecommendResource = this.getRecommendResource.bind(this)
		this.setThisState = this.setThisState.bind(this)
	}

	componentDidMount = () => {
		this.setState({ "loading": true })
		this.getClasses().then((classItems) => {
			this.getRecommendResource().then((datas) => {
				this.setState({
					datas: datas,
					loading: false,
					classItems: classItems
				})
			})
		})
	}

	setThisState = (data) => {
		console.log({ "data": data })
		this.setState(data)
	}

	getRecommendResource = () => {
		return new Promise((resolve) => {
			this.setState({ "loading": true })
			axios.get(window.CONFS.apiServer + '/admin/lessonplanresources')
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res.data.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				});
		});
	}

	createRecommendResource = (name, url, classes, description, changeState) => {
		this.createRecommendResourceAction(name, url, classes, description).then(
			this.getRecommendResource
		).then((datas) => {
			this.setState({
				datas: datas,
				...changeState
			})
		})
	}

	createRecommendResourceAction = (name, url, classes, description) => {
		return new Promise((resolve) => {
			this.setState({ "loading": true })
			axios.post(window.CONFS.apiServer + '/admin/lessonplanresource',
				{
					name: name,
					url: url,
					classes: classes,
					description: description
				})
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				})
		});
	}

	updateRecommendResource = (id, name, url, classes, description, changeState) => {
		this.updateRecommendResourceAction(id, name, url, classes, description).then(
			this.getRecommendResource
		).then((datas) => {
			this.setState({
				datas: datas,
				...changeState
			})
		})
	}

	updateRecommendResourceAction = (id, name, url, classes, description) => {
		return new Promise((resolve) => {
			this.setState({ "loading": true })
			axios.post(window.CONFS.apiServer + '/admin/lessonplanresource',
				{
					id: id,
					name: name,
					url: url,
					classes: classes,
					description: description
				})
				.then(res => {
					if (res.data.errorCode > 0) {
						console.log(res)
					}
					else {
						resolve(res)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				})
		});
	}

	openResource = (resource) => {
		if (!resource) {
			this.setState({
				resource: <Resource
					resource={resource}
					createRecommendResource={this.createRecommendResource}
					setParentState={this.setThisState}
					classItems={this.state.classItems}
					title="新增教學與學習資源"
				/>,
				openModal: true
			})
		}
		else {
			this.setState({
				resource: <Resource
					resource={resource}
					updateRecommendResource={this.updateRecommendResource}
					classItems={this.state.classItems}
					setParentState={this.setThisState}
					title="更新教學與學習資源"
				/>,
				openModal: true
			})
		}
	}

	deleteResource = (id) => {
		if (!window.confirm("確定要刪除資料?")) {
			return false;
		}
		axios.post(window.CONFS.apiServer + '/admin/lessonplanresource',
			{
				id: id,
				removed: true
			})
			.then(res => {
				if (res.data.errorCode > 0) {
					console.log(res)
				}
				else {
					this.getRecommendResource().then((datas) => {
						this.setState({ "datas": datas, loading: false })
					})
				}
			})
			.catch(function (error) {
				console.log('fail post =>', error)
				// window.alert("Server Error")
			})
	}

	openClassesManagement = () => {
		this.setState({ isClassManagementBtnLoading: true }, () => {
			this.getClasses().then(data => {
				this.setState({
					isClassManagementBtnLoading: false,
					isOpenClassManagementModel: true,
					classManagementPage: <ClassesManagement classes={data} getClasses={this.getClasses} />
					// loadPointers={this.loadPointers}
				})
			})
		})
	}

	getClasses = () => {
		return new Promise((resolve) => {
			axios.get(window.CONFS.apiServer + '/lessonplan/resource/classes')
				.then(res => {
					if (res.data.errorCode > 0) {
						window.CONFS.validateAdminLoginSession(res.data, this.props.history)
						console.log(res)
					}
					else {
						resolve(res.data.data)
					}
				})
				.catch(function (error) {
					console.log('fail post =>', error)
					// window.alert("Server Error")
				});
		});
	}

	getTitle = (resource) => {
		let classNames = resource.classes ? resource.classes.map(classObject => { return `${classObject.title.tw} ${classObject.removed ? '(removed)' : ''}` }) : []
		return `${classNames} \n\r ${resource.description || ''}`
	}

	render() {
		return (
			<div className="RecommendResources">
				<div className="title">
					<span>教學與學習資源推薦管理</span>
				</div>
				<div className="RecommendResources__controlArea">
					<button onClick={() => this.openResource()}>新增文章</button>
					<button onClick={() => this.openClassesManagement()}>類型管理</button>
				</div>
				<div className="RecommendResources__content">
					<table>
						<thead>
							<tr>
								<td>文章名稱</td>
								<td style={{ "width": "150px" }}>建立時間</td>
								<td style={{ "width": "150px" }}></td>
							</tr>
						</thead>
						{this.state.loading ?
							<tbody>
								<tr>
									<td className="loading" colSpan="3">
										<Loader
											type="Oval"
											color="#7f1083"
											height={22}
											width={22}
											visible={true}
										/>
									</td>
								</tr>
							</tbody> :
							<tbody>
								{this.state.datas.map((item, k) => {
									return (<tr key={k} title={this.getTitle(item)}>
										<td><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></td>
										<td>
											<Moment format="YYYY/MM/DD HH:mm">{item.createdAt}</Moment>
										</td>
										<td>
											<button onClick={() => this.openResource(item)} className="RecommendResources__content__editBtn">編輯</button>
											<button onClick={() => this.deleteResource(item._id)} className="RecommendResources__content__deleteBtn">刪除</button>
										</td>
									</tr>)
								})
								}
							</tbody>
						}
					</table>
				</div>
				<ReactModal
					isOpen={this.state.openModal}
					shouldCloseOnOverlayClick={true}
					onRequestClose={() => { this.setState({ "openModal": false }) }}
					style={{ overlay: { "background": "#000000a3" }, content: { "width": "600px", "height": "60vh", "margin": "0 auto" } }}
				>
					<div className="modalControllArea">
						<div onClick={() => { this.setState({ "openModal": false }) }}>
							<i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
						</div>
					</div>
					{this.state.resource}
				</ReactModal>
				<ReactModal
					isOpen={this.state.isOpenClassManagementModel}
					shouldCloseOnOverlayClick={true}
					onRequestClose={() => { this.setState({ isOpenClassManagementModel: false }) }}
					style={{ overlay: { "background": "#000000a3" }, content: { "width": "600px", "height": "80vh", "margin": "0 auto" } }}
				>
					<div className="modalControllArea">
						<div onClick={() => { this.setState({ isOpenClassManagementModel: false }) }}>
							<i className="fas fa-times" style={{ "fontSize": "25px" }}></i>
						</div>
					</div>
					{this.state.classManagementPage}
				</ReactModal>
			</div>
		);
	}
}

export default RecommendResources;