import React, { Component } from "react";
import axios from "axios";
import { withTranslation } from 'react-i18next';

import SideBar from "./Index/SideBar";
import BackHome from "./Index/BackHome";
import LessonPlans from "./LessonPlans/LessonPlans";
import LocalStorage from "../Helpers/LocalStorage";
// import SignUp from '../Home/Auth/SignUp'
import MemberProfile from '../Home/Auth/MemberProfile'
import Share from './Share/Share'
import Index from './Index/Index'
import LessonPlanResources from '../Admin/LessonPlanResources/LessonPlanResources'
import RecommendResources from './RecommendResources/RecommendResources'

import "./Management.less";

import { resources } from '../i18n'

class Management extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    }
    this.nthusteam = LocalStorage.getLocalStorage("nthusteam")
    this.init()
  }

  showModal = () => {
    this.setState({
      visible: true
    })
    return
  }

  init = () => {
    let apiToken = ""
    this.initI18n();
    if (this.nthusteam && this.nthusteam.apiToken) {
      apiToken = this.nthusteam.apiToken
      this.initMemberRequestHeader(apiToken)
    }
    this.initMemberResponseHandler()
  }

  initI18n = () => {
    const { i18n } = this.props;
    i18n.changeLanguage('tw');
  }

  initMemberRequestHeader = (apiToken) => {
    axios.interceptors.request.eject(window.CONFS.memberAxiosSetting.requestHeader)
    window.CONFS.memberAxiosSetting.requestHeader = axios.interceptors.request.use(
      config => {
        config.headers["X-Authorize"] = apiToken;
        return config;
      },
      error => Promise.reject(error)
    );
  }

  initMemberResponseHandler = () => {
    axios.interceptors.response.eject(window.CONFS.memberAxiosSetting.responseHandler)
    window.CONFS.memberAxiosSetting.responseHandler = axios.interceptors.response.use(res => {
      if (res.data.errorCode === 1000 || res.data.errorCode === 1011) {
        axios.interceptors.request.eject(window.CONFS.memberAxiosSetting.requestHeader)
        axios.interceptors.response.eject(window.CONFS.memberAxiosSetting.responseHandler)
        console.log("Management.js 請重新登入")
        alert("請重新登入");
        this.props.history.push("/signin?redirect=" + window.location.pathname)
      }
      return res;
    }, function (error) {
      return Promise.reject(error);
    });
  }


  changeLang = (lang) => {
    const { i18n } = this.props;
    i18n.changeLanguage(lang)

  }

  generateContentByPath = (t, features) => {
    let content = ""
    if (this.props.match.params.action === "lessonPlans" || this.props.match.params.action === "lessonPlan") {
      content = <div className="home">
        <SideBar t={t} />
        <LessonPlans t={t} />
      </div>
    }
    else if (this.props.match.params.action === "personProfile") {
      content = <div className="home">
        <SideBar t={t} />
        <MemberProfile t={t} />
        {/* <PersonProfile /> */}
      </div>
    }
    else if (this.props.match.params.action === "recommendedResources") {
      content = <div className="home">
        <SideBar t={t} />
        <RecommendResources t={t} />
        {/* <PersonProfile /> */}
      </div>
    }
    else if (this.props.match.params.action === "lessonplanresources" || this.props.match.params.action === "lessonplanresource") {
      content = <div className="home">
        <SideBar t={t} />
        <LessonPlanResources t={t} />
      </div>
    }
    else if (this.props.match.params.action === "share") {
      content = <div className="home">
        <SideBar t={t} />
        <Share t={t} />
      </div>
    }
    else if ((/\/management(\/.+)*\/?/g).test(this.props.match.path)) {
      content = <div className="home">
        <SideBar t={t} />
        <Index t={t} />
      </div>
    }
    else if (this.props.match.params.action && !(features.includes(this.props.match.params.action))) {
      content = <div className="home">
        {console.log({ "errorPath": this.props.match.params.action })}
        <BackHome />
      </div>
    }
    return content
  }

  render() {
    // const btn = (props) => <button onClick={i18n.changeLanguage(props.lang)}>{props.lang}</button>
    // return Object.keys(resources).map((key) => <Button lang={key} />)

    const { t } = this.props;
    const features = ['lessonPlans', 'lessonPlan', 'personProfile', 'lessonplanresources', 'lessonplanresource', 'share', 'recommendedResources', 'certification']

    return (
      <div className="management">
        {this.generateContentByPath(t, features)}
        {/* (this.props.match.params.action && !(features.includes(this.props.match.params.action))) && */}
      </div>
    );
  }
}

export default withTranslation()(Management);