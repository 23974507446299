import React from 'react';
import './App.less';
import Home from './Home/Home';
import Management from './Management/Management';
import Admin from './Admin/Admin';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


function App() {
  return (
    <Router>
      <div className="container">
        <Switch>
          <Route exact path='/' component={Home} />          
          <Route exact path='/management' component={Management} />
          <Route exact path='/management/certification/:actionId/:subAction/:id' component={Management} />
          <Route exact path='/management/certification/:actionId' component={Management} />
          <Route exact path='/management/:action/:id/:method' component={Management} />
          <Route exact path='/management/:action' component={Management} />
          <Route exact path='/management/:action/:id' component={Management} />
          <Route exact path='/admin' component={Admin} />
          <Route exact path='/admin/:action' component={Admin} />
          <Route exact path='/admin/pagemanagement/:action/:id' component={Admin} />
          <Route exact path='/admin/pagemanagement/:action' component={Admin} />
          <Route exact path='/admin/ability_management/:action' component={Admin} />
          <Route exact path='/admin/membership/:action/:GUID/approve_certification/:id' component={Admin} />
          <Route exact path='/admin/membership/:action/:GUID/approve_certification' component={Admin} />
          <Route exact path='/admin/membership/:action/:GUID/certification/:actionId' component={Admin} />
          <Route exact path='/admin/membership/:action/:GUID/certification/:actionId/lessonplan/:id' component={Admin} />
          <Route exact path='/admin/membership/:action/:GUID/certifications' component={Admin} />
          <Route exact path='/admin/membership/:action/:id/admin/:method' component={Admin} />
          <Route exact path='/admin/membership/:action/:GUID/:id' component={Admin} />
          <Route exact path='/admin/membership/:action/:GUID' component={Admin} />          
          <Route exact path='/admin/membership/:action' component={Admin} />
          <Route exact path='/admin/:action/:GUID/:id' component={Admin} />
          <Route exact path='/admin/:action/:id' component={Admin} />          
          <Route exact path='/:action/:id' component={Home} />
          <Route exact path='/:action' component={Home} />
        </Switch>
      </div>
    </Router>

  );
}

export default App;